import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_select, {
      modelValue: $setup.element_id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.element_id) = $event)),
      name: "taxesDetails",
      class: _normalizeClass(!$props.multiselect ? $props.custom_class : ''),
      multiple: $props.multiselect,
      "collapse-tags": $props.multiselect,
      size: $props.size,
      disabled: $props.disabled,
      clearable: "",
      placeholder: `${_ctx.$t('iselect')}`,
      filterable: "",
      remote: "",
      "reserve-keyword": "",
      "remote-method": $setup.selectTaxes,
      onClick: $setup.getTaxesDetails,
      loading: $setup.inputTaxesDetails.loading,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:element_id', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.inputTaxesDetails.options, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: `${item.id}-${item.tax_type ? item.tax_type.name : ''}-${
          item.percentage
        }`,
            value: item.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(`${item.id}`) + "-" + _toDisplayString(`${item.tax_type ? item.tax_type.name : ""}`) + "-" + _toDisplayString(`${item.percentage}`), 1)
            ]),
            _: 2
          }, 1032, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "class", "multiple", "collapse-tags", "size", "disabled", "placeholder", "remote-method", "onClick", "loading"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString($setup.errorMessage), 1)
  ]))
}