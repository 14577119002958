import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import type { Contact } from "@/interfaces/general/ContactInterfaces";
import type { PackagingType } from "@/interfaces/general/PackagingTypeInterfaces";

@Module
export default class ImoDeclarationModule extends VuexModule {
  declarationList = [] as any;
  declarationTable = [] as any;
  ImoContactList: Contact[] = [];
  ImoPackagingTypeList: PackagingType[] = [];

  get DeclarationList(): [] {
    return this.declarationList;
  }

  get DeclarationTable(): [] {
    return this.declarationTable;
  }

  get ImoContact(): Contact[] {
    return this.ImoContactList;
  }

  get ImoPackagingType(): PackagingType[] {
    return this.ImoPackagingTypeList;
  }

  @Mutation
  ["setDeclarationList"](payload) {
    this.declarationList.push(payload);
  }

  @Mutation
  ["setDeclarationTable"](payload) {
    this.declarationTable.push(payload);
  }

  @Mutation
  ["resetImoDeclarationModule"]() {
    this.declarationList = [];
    this.declarationTable = [];
    this.ImoContactList = [];
    this.ImoPackagingTypeList = [];
  }

  @Mutation
  ["setImoContact"](payload: Contact[]) {
    this.ImoContactList = payload;
  }

  @Mutation
  ["setImoPackagingType"](payload: PackagingType[]) {
    console.log("Datos de ImoPackagingType recibidos:", payload);
    this.ImoPackagingTypeList = payload;
  }
  
}
