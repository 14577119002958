import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
interface toEquipment {
  id?: number;
  transportation_order_id?: number;
  shipload_id?: number;
  to_special_condition_id?: number;
  registered_equipment_id?: number;
  equipment_id?: number;
  registration_number?: string;
  equipment_type?: string;
  equipment_size?: string;
  iso?: string;
  tare?: string;
  connected_onboard?: number;
  connected_in_station?: number;
  connected_transport?: number;
  relative_humidity?: string;
  contain_origin?: string;
  contain_destination?: string;
  origin_service?: string;
  destination_service?: string;
  is_shipper_owner?: number;
  seal_number?: string;
  observations?: string;
  allowed_by_pif?: number;
  allowed_by_customs?: number;
  weighers?: number;
  is_empty?: number;
  to_special_condition?: number;
  registered_equipment?: number;
}

interface toEquipmentNoCheck {
  id?: number;
  transportation_order_id?: number;
  shipload_id?: number;
  to_special_condition_id?: number;
  registered_equipment_id?: number;
  equipment_id?: number;
  registration_number?: string;
  equipment_type?: string;
  equipment_size?: string;
  iso?: string;
  tare?: string;
  connected_onboard?: number;
  connected_in_station?: number;
  connected_transport?: number;
  relative_humidity?: string;
  contain_origin?: string;
  contain_destination?: string;
  origin_service?: string;
  destination_service?: string;
  is_shipper_owner?: number;
  seal_number?: string;
  observations?: string;
  allowed_by_pif?: number;
  allowed_by_customs?: number;
  weighers?: number;
  is_empty?: number;
  to_special_condition?: number;
  registered_equipment?: number;
}

interface toParticipant {
  id?: number;
  to_participant_type_id?: number;
  entity_id?: number;
  ftx_entity?: string;
  address_id?: number;
  ftx_address?: string;
  contact_id?: number;
  ftx_contact?: string;
  observations?: string;
}

interface toEventList {
  id?: number;
  transportation_order_id?: number;
  event_type_id?: number;
  date_time?: string;
  observations?: string;
}

interface toItineraries {
  id?: number;
  type?: string;
  is_main?: string;
  location_id?: number;
  place?: string;
  start_date?: string;
  end_date?: string;
  reference?: string;
  goods?: string;
  packaging_type_id?: number;
  packages?: number;
  gross_weight?: number;
  volume?: string;
  linear_meters?: string;
  observations?: string;
  order?: number;
}

@Module
export default class OtModule extends VuexModule {
  toEquipmentList: toEquipment[] = [];
  toEquipmentListNoCheck: toEquipmentNoCheck[] = [];
  toParticipantList: toParticipant[] = [];
  toEventList: toEventList[] = [];
  toItinerariesList: toItineraries[] = [];
  actionMode = true;

  get actionModeStatus() {
    return this.actionMode;
  }
  get EquipmentList(): toEquipment[] {
    return this.toEquipmentList;
  }
  get EquipmentListNoCheck(): toEquipmentNoCheck[] {
    return this.toEquipmentListNoCheck;
  }
  get ParticipantList(): toParticipant[] {
    return this.toParticipantList;
  }
  get EventList(): toEventList[] {
    return this.toEventList;
  }
  get ItinerariesList(): toItineraries[] {
    return this.toItinerariesList;
  }

  @Mutation
  ["setActionMode"](payload) {
    this.actionMode = payload;
  }

  //To Itineraries
  @Mutation
  ["setOtItinerariesList"](payload) {
    this.toItinerariesList = payload;
  }
  @Mutation
  ["addOtItinerariesList"](payload) {
    this.toItinerariesList.push(payload);
  }
  @Mutation
  ["createOtItinerariesList"]() {
    this.toItinerariesList.push({
      type: "",
      is_main: "0",
      location_id: undefined,
      place: "",
      start_date: "",
      end_date: "",
      reference: "",
      goods: "",
      packaging_type_id: undefined,
      packages: undefined,
      volume: "",
      linear_meters: "",
      observations: "",
      order: undefined,
      gross_weight: 0,
    });
  }
  @Mutation
  ["clearOtItinerariesList"]() {
    this.toItinerariesList = [];
  }

  //To Participant
  @Mutation
  ["setParticipantList"](payload) {
    this.toParticipantList = payload;
  }
  @Mutation
  ["addParticipantList"](payload) {
    this.toParticipantList.push(payload);
  }
  @Mutation
  ["createParticipantList"]() {
    this.toParticipantList.push({
      to_participant_type_id: undefined,
      entity_id: undefined,
      ftx_entity: "",
      address_id: undefined,
      ftx_address: "",
      contact_id: undefined,
      ftx_contact: "",
      observations: "",
    });
  }
  @Mutation
  ["clearParticipantList"]() {
    this.toParticipantList = [];
  }

  //To Events
  @Mutation
  ["setEventList"](payload) {
    this.toEventList = payload;
  }
  @Mutation
  ["addEventList"](payload) {
    this.toEventList.push(payload);
  }
  @Mutation
  ["createEventList"]() {
    this.toEventList.push({
      id: undefined,
      transportation_order_id: undefined,
      event_type_id: undefined,
      date_time: "",
      observations: "",
    });
  }
  @Mutation
  ["clearEventList"]() {
    this.toEventList = [];
  }

  //To Equipment
  @Mutation
  ["setEquipmentList"](payload) {
    this.toEquipmentList = payload;
  }

  @Mutation
  ["addEquipmentList"](payload) {
    this.toEquipmentList.push(payload);
  }

  @Mutation
  ["createEquipmentList"]() {
    this.toEquipmentList.push({
      transportation_order_id: undefined,
      shipload_id: undefined,
      to_special_condition_id: undefined,
      registered_equipment_id: undefined,
      equipment_id: undefined,
      registration_number: "",
      equipment_type: "",
      equipment_size: "",
      iso: "",
      tare: "",
      connected_onboard: undefined,
      connected_in_station: undefined,
      connected_transport: undefined,
      relative_humidity: "",
      contain_origin: "",
      contain_destination: "",
      origin_service: "",
      destination_service: "",
      is_shipper_owner: undefined,
      seal_number: "",
      observations: "",
      allowed_by_pif: undefined,
      weighers: undefined,
      is_empty: undefined,
      to_special_condition: undefined,
      registered_equipment: undefined,
    });
  }

  @Mutation
  ["clearEquipmentList"]() {
    this.toEquipmentList = [];
  }

  //To Equipment
  @Mutation
  ["setEquipmentListNoCheck"](payload) {
    this.toEquipmentListNoCheck = payload;
  }

  @Mutation
  ["addEquipmentListNoCheck"](payload) {
    this.toEquipmentListNoCheck.push(payload);
  }

  @Mutation
  ["createEquipmentListNoCheck"]() {
    this.toEquipmentListNoCheck.push({
      transportation_order_id: undefined,
      shipload_id: undefined,
      to_special_condition_id: undefined,
      registered_equipment_id: undefined,
      equipment_id: undefined,
      registration_number: "",
      equipment_type: "",
      equipment_size: "",
      iso: "",
      tare: "",
      connected_onboard: undefined,
      connected_in_station: undefined,
      connected_transport: undefined,
      relative_humidity: "",
      contain_origin: "",
      contain_destination: "",
      origin_service: "",
      destination_service: "",
      is_shipper_owner: undefined,
      seal_number: "",
      observations: "",
      allowed_by_pif: undefined,
      weighers: undefined,
      is_empty: undefined,
      to_special_condition: undefined,
      registered_equipment: undefined,
    });
  }

  @Mutation
  ["clearEquipmentListNoCheck"]() {
    this.toEquipmentListNoCheck = [];
  }
}
