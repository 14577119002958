import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface FormSaleJob {
  id?: number;
  client: string;
  entities_id: number | undefined;
  client_reference: string;
  contact_email: string;
  contact_name: string;
  correspondent_id: number | undefined;
  date: string;
  file_creator: string;
  file_id: number | undefined;
  incoterm_types_id: number | undefined;
  matter: string;
  office_id: number | undefined;
  payment_method: string;
  template: number | undefined;
  sale_job_offer_types_id: number | undefined;
  sales_associate: number | undefined;
  sale_job_statuses_id: number | undefined;
  sale_job_traffic_types_id: number | undefined;
  sale_job_shipped_vias_id: number | undefined;
  sale_job_languages_id: number | undefined;
  sale_job_operation_types_id: number | undefined;
  sale_job_document_types_id: number | undefined;
  valid_from: string;
  valid_until: string;
}

interface SaleJobVesselRoute {
  sale_jobs_id: number;
  locations_id: number;
  order: number;
  sale_job_route_types_id: number;
  postal_code: string;
  address: string;
  observations: string;
  maritime_services_id: number;
  frequency: string;
}
interface TransitCargo {
  sale_jobs_id: number;
  equipment_types_id: number;
  equipments_id: number;
  units: number;
  description: string;
  weight: number;
  bulks: number;
  packaging_types_id: number;
  width: number;
  length: number;
  height: number;
  volume: number;
  dangerous: boolean;
  metric_unit: string;
  equipment_class: string;
  goods_group: string;
  linear_meters: string;
  assessable_weight: string;
  unit_weight: string;
  order: number;
}
interface SaleJobTab {
  tab?: string;
  item_id?: number;
}

@Module
export default class SaleJobModule extends VuexModule {
  loadingJob = true;
  saleJobId: undefined;
  saleJob: FormSaleJob = {
    id: undefined,
    client: "",
    entities_id: undefined,
    client_reference: "",
    contact_email: "",
    contact_name: "",
    correspondent_id: undefined,
    date: "",
    file_creator: "",
    file_id: undefined,
    incoterm_types_id: undefined,
    matter: "",
    office_id: undefined,
    payment_method: "",
    template: undefined,
    sale_job_offer_types_id: undefined,
    sales_associate: undefined,
    sale_job_statuses_id: undefined,
    sale_job_traffic_types_id: undefined,
    sale_job_shipped_vias_id: undefined,
    sale_job_languages_id: undefined,
    sale_job_operation_types_id: undefined,
    sale_job_document_types_id: undefined,
    valid_from: "",
    valid_until: "",
  };
  operationType: any = 1;
  saleJobTab: SaleJobTab = {};
  saleJobVesselRouteList: SaleJobVesselRoute[] = [];
  saleJobTransitCargoList: TransitCargo[] = [];
  saleJobRoutesList: any[] = [];
  saleJobEquipmentList: any[] = [];
  saleJobContainerTypeList: any[] = [];
  saleJobPackagingTypeList: any[] = [];
  saleJobsList: any[] = [];
  SaleJobStatusList: any[] = [];
  SalesTrafficTypeList: any[] = [];
  SalesDocumentTypeList: any[] = [];
  SaleJobOfferTypeList: any[] = [];
  SaleJobCategoryList: any[] = [];
  SaleJobLanguageList: any[] = [];
  SaleJobTypeShippedViaList: any[] = [];
  SaleJobOperationTypeList: any[] = [];

  get LoadingSalesJobs(): boolean {
    return this.loadingJob;
  }

  get SaleJobId(): any {
    return this.saleJobId;
  }

  get SaleJobItem(): any {
    return this.saleJob;
  }

  get OperationType(): any {
    return this.operationType;
  }

  get SaleJobTabItem(): SaleJobTab {
    return this.saleJobTab;
  }

  get SaleJobOperationType(): any {
    return this.SaleJobOperationTypeList;
  }
  get SaleJobTypeShippedVia(): any {
    return this.SaleJobTypeShippedViaList;
  }
  get SaleJobLanguage(): any {
    return this.SaleJobLanguageList;
  }
  get SaleJobOfferType(): any {
    return this.SaleJobOfferTypeList;
  }
  get SaleJobCategory(): any {
    return this.SaleJobCategoryList;
  }
  get SalesDocumentType(): any {
    return this.SalesTrafficTypeList;
  }
  get SalesTrafficType(): any {
    return this.SalesTrafficTypeList;
  }
  get saleJobStatus(): any {
    return this.SaleJobStatusList;
  }

  get saleJobs() {
    return this.saleJobsList;
  }

  get saleJobVesselRoute(): SaleJobVesselRoute[] {
    return this.saleJobVesselRouteList;
  }

  get saleJobTransitCargo(): TransitCargo[] {
    return this.saleJobTransitCargoList;
  }

  get saleJobRoutes() {
    return this.saleJobRoutesList;
  }

  get saleJobEquipment() {
    return this.saleJobEquipmentList;
  }

  get saleJobContainerType() {
    return this.saleJobContainerTypeList;
  }

  get saleJobPackagingType() {
    return this.saleJobPackagingTypeList;
  }

  @Mutation
  ["setLoadingJob"](payload) {
    this.loadingJob = payload;
  }

  @Mutation
  ["setSaleJobId"](payload) {
    this.saleJobId = payload;
  }

  @Mutation
  ["setSaleJob"](payload) {
    this.saleJob = payload;
  }

  @Mutation
  ["setOperationType"](payload) {
    this.operationType = payload;
  }

  @Mutation
  ["setSaleJobTab"](payload) {
    this.saleJobTab = payload;
  }

  @Mutation
  ["removeSaleJobTab"]() {
    this.saleJobTab = {};
  }

  @Mutation
  ["setSaleJobOperationType"](payload) {
    this.SaleJobOperationTypeList = payload;
  }
  @Mutation
  ["setSaleJobTypeShippedVia"](payload) {
    this.SaleJobLanguageList = payload;
  }
  @Mutation
  ["setSaleJobLanguage"](payload) {
    this.SaleJobLanguageList = payload;
  }
  @Mutation
  ["setSaleJobOfferType"](payload) {
    this.SaleJobOfferTypeList = payload;
  }
  @Mutation
  ["setSaleJobCategory"](payload) {
    this.SaleJobCategoryList = payload;
  }
  @Mutation
  ["setSaleJobDocumentType"](payload) {
    this.SalesDocumentTypeList = payload;
  }
  @Mutation
  ["setSaleJobTrafficType"](payload) {
    this.SalesTrafficTypeList = payload;
  }
  @Mutation
  ["setSaleJobStatus"](payload) {
    this.SaleJobStatusList = payload;
  }

  @Mutation
  ["setSaleJobs"](payload) {
    this.saleJobsList.push(payload);
  }

  @Mutation
  ["setSaleJobVesselRoute"](payload) {
    this.saleJobVesselRouteList.push(payload);
  }
  @Mutation
  ["editSaleJobVesselRoute"](payload) {
    this.saleJobVesselRouteList[payload.index]["ue"] = payload.is_ue;
  }
  @Mutation
  ["ressetSaleJobVesselRoute"]() {
    this.saleJobVesselRouteList = [];
  }
  @Mutation
  ["setOrderSaleJobVesselRoute"](payload) {
    const newIndex = payload.newIndex;
    const oldIndex = payload.oldIndex;
    const newOrder = this.saleJobVesselRouteList[newIndex].order;
    const oldOrder = this.saleJobVesselRouteList[oldIndex].order;
    this.saleJobVesselRouteList[oldIndex].order = newOrder;
    this.saleJobVesselRouteList[newIndex].order = oldOrder;
  }
  @Mutation
  ["cleanSaleJobVesselRoute"]() {
    this.saleJobVesselRouteList = [];
  }
  @Mutation
  ["removeSaleJobVesselRoute"](index) {
    this.saleJobVesselRouteList.splice(index, 1);
  }

  @Mutation
  ["setSaleJobTransitCargo"](payload) {
    this.saleJobTransitCargoList.push(payload);
  }
  @Mutation
  ["editSaleJobTransitCargo"](payload) {
    this.saleJobTransitCargoList[payload.index]["ue"] = payload.is_ue;
  }
  @Mutation
  ["ressetSaleJobTransitCargo"]() {
    this.saleJobTransitCargoList = [];
  }
  @Mutation
  ["setOrderSaleJobTransitCargo"](payload) {
    const newIndex = payload.newIndex;
    const oldIndex = payload.oldIndex;
    const newOrder = this.saleJobTransitCargoList[newIndex].order;
    const oldOrder = this.saleJobTransitCargoList[oldIndex].order;
    this.saleJobTransitCargoList[oldIndex].order = newOrder;
    this.saleJobTransitCargoList[newIndex].order = oldOrder;
  }
  @Mutation
  ["cleanSaleJobTransitCargo"]() {
    this.saleJobTransitCargoList = [];
  }
  @Mutation
  ["removeSaleJobTransitCargo"](index) {
    this.saleJobTransitCargoList.splice(index, 1);
  }

  @Mutation
  ["setSaleJobRoutes"](payload) {
    this.saleJobRoutesList = payload;
  }

  @Mutation
  ["setSaleJobEquipment"](payload) {
    this.saleJobEquipmentList = payload;
  }

  @Mutation
  ["setSaleJobContainerType"](payload) {
    this.saleJobContainerTypeList = payload;
  }

  @Mutation
  ["setSaleJobPackagingType"](payload) {
    this.saleJobPackagingTypeList = payload;
  }

  @Mutation
  ["resetSaleJobModule"]() {
    this.loadingJob = true;
    this.saleJobId = undefined;
    this.saleJob = {
      id: undefined,
      client: "",
      entities_id: undefined,
      client_reference: "",
      contact_email: "",
      contact_name: "",
      correspondent_id: undefined,
      date: "",
      file_creator: "",
      file_id: undefined,
      incoterm_types_id: undefined,
      matter: "",
      office_id: undefined,
      payment_method: "",
      template: undefined,
      sale_job_offer_types_id: undefined,
      sales_associate: undefined,
      sale_job_statuses_id: undefined,
      sale_job_traffic_types_id: undefined,
      sale_job_shipped_vias_id: undefined,
      sale_job_languages_id: undefined,
      sale_job_operation_types_id: undefined,
      sale_job_document_types_id: undefined,
      valid_from: "",
      valid_until: "",
    };
    this.operationType = undefined;
    this.saleJobTab = {};
    this.saleJobVesselRouteList = [];
    this.saleJobTransitCargoList = [];
    this.saleJobRoutesList = [];
    this.saleJobEquipmentList = [];
    this.saleJobContainerTypeList = [];
    this.saleJobPackagingTypeList = [];
    this.saleJobsList = [];
    this.SaleJobStatusList = [];
    this.SalesTrafficTypeList = [];
    this.SalesDocumentTypeList = [];
    this.SaleJobOfferTypeList = [];
    this.SaleJobCategoryList = [];
    this.SaleJobLanguageList = [];
    this.SaleJobTypeShippedViaList = [];
    this.SaleJobOperationTypeList = [];
  }
}
