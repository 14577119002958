import ApiService from "@/core/services/ApiService";
import { ForwarderMaritimeVoyageEndpointEnum } from "@/enums/api_endpoints/forwarder/ForwarderMaritimeVoyageEnum";
import { ForwarderMaritimeVoyageItem } from "@/interfaces/forwarder/ForwarderMaritimeVoyageInterfaces";
import { ForwarderOriginMaritimeVoyage } from "@/interfaces/forwarder/ForwarderOriginMaritimeVoyageInterfaces";
import { ForwarderTrafficType } from "@/interfaces/forwarder/ForwarderTrafficType";
import { ForwarderVessel } from "@/interfaces/forwarder/ForwarderVesselInterfaces";
import { AxiosResponse } from "axios";

export async function mountMaritimeGuide(store, id: number) {
    await ApiService.get(`${ForwarderMaritimeVoyageEndpointEnum.CRUD}${id}`).then(({ data }: AxiosResponse<ForwarderMaritimeVoyageItem>) => {
        data.cancelled = data.cancelled === 1 ? true : false;
        store.commit("resetForwarderModule");
        store.commit("setforwarderVessel", [data.vessel as ForwarderVessel]);
        store.commit("setForwarderTrafficType", [data.forwarder_job_traffic_type as ForwarderTrafficType]);
        store.commit("setforwarderOriginMaritimeVoyage", [data.origin as ForwarderOriginMaritimeVoyage]);
        store.commit("setforwarderDestinationMaritimeVoyage", [data.destination as ForwarderOriginMaritimeVoyage]);
        store.commit("setforwarderMaritimeVoyageItem", data as ForwarderMaritimeVoyageItem);
        store.commit("setLoading", false);
        store.commit("setLoadingForwarder", false);
    });
};