export const GeneralES = {
    Operations: 'Operaciones',
    Description: 'Descripción',
    EquipmentType: 'Tipo de equipamiento',
    Equipments: 'Equipamientos',
    Units: 'Unidades',
    Weight: 'Peso',
    Bulks: 'Bultos',
    PackagingType: 'Tipo de embalaje',
    Width: 'Ancho',
    Height: 'Alto',
    Length: 'Longitud',
    Volume: 'Volumen',
    Autocalculated: 'Autocalculado',
    Metric_unit: 'Unidad métrica',
    Equipment_class: 'Clase de equipamiento',
    Goods_group: 'Grupo de bienes',
    Linear_meters: 'Metros lineales',
    Assessable_weight: 'Peso evaluable',
    Unit_weight: 'Peso unitario',
    Dangerous: 'Peligroso',
    TotalsTable: 'Tabla de totales',
    RegistrationNumber: 'Matrícula',
    Size: 'Tamaño',
    Type: 'Tipo',
    Tare: 'Tara',
    SpecialConditions: 'Condiciones Especiales',
    ConnectedTo: 'Conectado %{transport}',
    OnBoard: 'A bordo',
    OnTerminal: 'A terminal',
    OnTransport: 'A Transporte',
    RelativeHumidity: 'Humedad Relativa',
    SealNumber: 'Número de precinto',
    OriginContainerization: 'Contenerización de Origen',
    DestinationContainerization: 'Contenerización de destino',
    Calculate: 'Calcular',
    Containers: 'Contenedores',
    Goods: 'Mercancías'
}