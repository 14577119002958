enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  SHOW_LOADING = "showLoading",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  GET_LOG_ACTIVITIES = "getLogActivities",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  LOAD_PREFERENCES = "loadPreferences",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  MY_PROFILE = "myProfile",

  SAVE_COMPANY = "saveCompany",
  SAVE_COMPANY_GROUP = "saveCompanyGroup",
  SAVE_COMPANY_GROUP_OFFICE = "saveCGOffice",
  SAVE_COMPANY_GROUP_ADDRESS = "saveOAddress",
  REMOVE_COMPANY_GROUP_ADDRESS = "removeOAddress",

  LOAD_DATA = "loadElements",
  LOAD_MF_DATA = "loadMFData",

  GET_QUOTATIONS_SALE = "getQuotationsSale",
  GET_QUOTATIONS_COST = "getQuotationsCost",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_LOG_ACTIVITIES = "logActivities",
  SET_USER = "setUser",
  SET_PREFERENCES = "setPreferences",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  UPDATE_BOOKING = "updateBooking",
  SET_MARITIME_FILETAB = "setMaritimeFileTab",
  REMOVE_MARITIME_FILETAB = "removeMaritimeFileTab",
  SET_BL_BO_TAB = "setBlBoTab",
  REMOVE_BL_BO_TAB = "removeBlBoTab",
}

export { Actions, Mutations };
