import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import type { ForwarderJobParticipantList } from "@/interfaces/forwarder/ForwarderJobParticipantInterfaces";
import type {
  ForwarderJobList,
  ForwarderJobItem,
} from "@/interfaces/forwarder/ForwarderJobInterfaces";
import type { ForwarderJobCargo } from "@/interfaces/forwarder/ForwarderJobCargoInterfaces";
import type {
  ForwarderOfferItem,
  ForwarderOfferList,
} from "@/interfaces/forwarder/ForwarderOfferInterfaces";
import type {
  Address,
  Alert,
  Tree,
} from "@/interfaces/general/GeneralInterfaces";
import type { ForwarderChangeOffer } from "@/interfaces/forwarder/OfferChangeForwarderInterfaces";
import type { ForwarderJobRoute } from "@/interfaces/forwarder/ForwarderJobRouteInterfaces";
import type { ForwarderOfferType } from "@/interfaces/forwarder/ForwarderOfferTypeInterfaces";
import type { ForwarderJobPaymentMethod } from "@/interfaces/forwarder/ForwarderJobPaymentMethodInterfaces";
import type { ForwarderJobShortForm } from "@/interfaces/forwarder/ForwarderJobShortInterfaces";
import type { ForwarderOfferObservationItem } from "@/interfaces/forwarder/ForwarderOfferObservationInterfaces";
import type { Entity } from "@/interfaces/general/EntityInterfaces";
import type { ForwarderAerialVoyage } from "@/interfaces/forwarder/ForwarderAerialVoyageInterfaces";
import type { ForwarderAirWayBill } from "@/interfaces/forwarder/ForwarderAirWayBillsInterfaces";
import type { ForwarderAerialVoyageRoute } from "@/interfaces/forwarder/ForwarderAerialVoyageRouteInterfaces";
import type { ForwarderTrafficType } from "@/interfaces/forwarder/ForwarderTrafficType";
import type { ForwarderVessel } from "@/interfaces/forwarder/ForwarderVesselInterfaces";
import type { ForwarderOriginMaritimeVoyage } from "@/interfaces/forwarder/ForwarderOriginMaritimeVoyageInterfaces";
import type { ForwarderMaritimeVoyage } from "@/interfaces/forwarder/ForwarderMaritimeVoyageInterfaces";
import type { ForwarderCarrier } from "@/interfaces/forwarder/ForwarderCarrierInterfaces";
import type { Communication } from "@/interfaces/general/CommunicationInterfaces";
import type { Contact } from "@/interfaces/general/ContactInterfaces";
import type { ForwarderTerrestrialVoyage } from "@/interfaces/forwarder/ForwarderTerrestrialVoyageInterfaces";
import type { ForwarderJobRouteAll } from "@/interfaces/forwarder/ForwarderJobRouteInterfaces";
import type { User } from "@/interfaces/general/UserInterfaces";
import type { ForwarderEquipmentInvolved } from "@/interfaces/forwarder/ForwarderEquipmentInvolved";

interface ForwarderTab {
  tab?: string;
  item_id?: number;
}

@Module
export default class ForwarderModule extends VuexModule {
  loadingForwarder = true;
  forwarderTab: ForwarderTab = {};
  forwarderJobShortId: undefined;
  forwarderJobId: undefined;
  forwarderJob: undefined;

  forwarderChangeOfferList: ForwarderChangeOffer | undefined;
  routesConfirmedList: ForwarderJobRouteAll | undefined;

  // forwarderJobItem will store the data of a job that is being edited.
  forwarderJobItem: ForwarderJobItem | undefined;
  // forwarderOffer will store data of an Offer that is being edited.
  forwarderOfferItem: ForwarderOfferItem | undefined;

  forwarderJobShort: ForwarderJobShortForm | undefined;
  forwarderObservationsTabList:
    | { notes: string | undefined; incidences: string | undefined }
    | undefined;

  // When working with an offer, its observations will be stored here.
  forwarderOfferObservation: ForwarderOfferObservationItem | undefined;

  // When working with a Job/Offer, its route will be stored here.
  forwarderJobRouteList: ForwarderJobRoute[] = [];

  // When working with an offer, offer templates for the select will be stored here.
  forwarderOfferTemplateList: ForwarderOfferList[] = [];

  // save list of participants in component ForwarderOverwiewTab
  forwarderJobParticipantList: ForwarderJobParticipantList[] = [];

  // save list of goods in component ForwarderTransitCargoTab
  forwarderJobCargoList: ForwarderJobCargo[] = [];

  // When working with a Job/Offer, its alerts will be stored here.
  forwarderAlertList: Alert[] = [];

  // Store sibling house jobs (same master_id). Used in ForwarderJobDocuments
  forwarderDocumentJobList: ForwarderJobList[] = [];
  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving an Offer, its offerType data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.
  forwarderOfferTypeList: ForwarderOfferType[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving an Offer, its paymentMethod data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.
  forwarderJobPaymentMethodList: ForwarderJobPaymentMethod[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving an Offer, its paymentMethod data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderJobSalesAssociateList: User[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // List of addresses when working with a Forwarder file.
  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  ForwarderAddressList: Address[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // List of addresses when working with a Forwarder file.
  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  ForwarderCommunicationList: Communication[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // List of contacts when working with a Forwarder file.
  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  ForwarderContactList: Contact[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // List of clients when working with a Forwarder file.
  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  ForwarderClientList: Object[] = [];
  ForwarderClientGeneralList: Object[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // List of providers when working with a Forwarder file.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  ForwarderProviderList: Object[] = [];

  // List of providers when working with a Forwarder file.

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  ForwarderUserList: Object[] = [];
  ForwarderUserListAgency: Object[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderJobCategoryList: Object[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderJobIncotermList: Object[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderOfficeList: Object[] = [];

  // save list of participants in component ForwarderOverwiewTab

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderOfferStatusList: Object[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderTrafficTypeList: ForwarderTrafficType[] = [];

  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderJobLanguageList: Object[] = [];

  // Array for showing a select
  forwarderAerialVoyageList: ForwarderAerialVoyage[] = [];

  forwarderAerialVoyageItem: ForwarderAerialVoyage | undefined;

  //Array for showing a select

  forwarderAirWayBillList: ForwarderAirWayBill[] = [];
  // When working with the select, the list of options to choose from will be stored here.
  // Also, when retrieving a Job, its data data will be stored here so the component
  // can display it correctly when loaded. If its not, only the ID will be shown.

  // <<<< !!!!!CHANGE OBJECT FOR THE SPECIFIC TYPE WHEN IT IS DEFINED!!! >>>>>
  forwarderJobTrafficTypeList: Object[] = [];
  forwarderJobTrafficList: Object[] = [];

  // Forwarder Maritime
  forwarderMaritimeVoyageList: ForwarderMaritimeVoyage[] = [];
  forwarderMaritimeVoyageItem: ForwarderMaritimeVoyage | undefined;
  forwarderVesselList: ForwarderVessel[] = [];
  forwarderOriginMaritimeVoyageList: ForwarderOriginMaritimeVoyage[] = [];
  forwarderDestinationMaritimeVoyageList: ForwarderOriginMaritimeVoyage[] = [];

  // Forwarder Terrestrial
  forwarderCarrier: ForwarderCarrier[] = [];
  forwarderCarrierGeneralList: ForwarderCarrier[] = [];

  // Forwarder Equipment Involved
  forwarderEquipmentInvolved: ForwarderEquipmentInvolved[] = [];

  forwarderEntityList: Entity[] = [];
  // When loading a forwarder file, its tree structure will be stored here
  forwarderFileTre: Tree[] = [];

  // Custom Vars
  forwarderRegistrationNumberOptions = [];
  forwarderEquipmentInvolvedSave = [];
  forwarderRelationshipEquipments = [];

  forwarderAerialVoyageRoute: ForwarderAerialVoyageRoute | undefined;
  forwarderAerialVoyageRouteList: ForwarderAerialVoyageRoute[] = [];

  entityTypeList: any[] = [];
  forwarderJobList: ForwarderJobList[] = [];
  forwarderTerrestrialVoyageList: ForwarderTerrestrialVoyage[] = [];
  forwarderTerrestrialVoyageItem: ForwarderTerrestrialVoyage | undefined;
  forwarderJobVoyageList: any[] = [];
  forwarderJobFreigthTypesList: any[] = [];
  ForwarderJobServiceLinesList: any[] = [];
  forwarderJobOfferList: any[] = [];
  forwarderJobGroupageTypeList: any[] = [];
  forwarderJobGoodStatusList: any[] = [];
  forwarderJobRoutesList: ForwarderJobRouteAll[] = [];
  forwarderJobRoutesDList: any[] = [];
  forwarderJobEquipmentList: any[] = [];
  ForwarderJobEquipmentTypeList: any[] = [];
  forwarderJobPackagingTypeList: any[] = [];
  forwarderJobStatusList: any[] = [];
  forwarderJobFreightTypesList: any[] = [];
  forwarderDocumentTypeList: any[] = [];
  forwarderCorrespondentList: any[] = [];
  forwarderCorrespondentGeneralList: any[] = [];
  forwarderVoyageList: any[] = [];
  forwarderVoyageTypeList: any[] = [];
  forwarderJobTypeShippedViaList: any[] = [];
  forwarderJobOperationTypeList: Object[] = [];
  forwarderMasterJobList: any[] = [];
  forwarderMasterIdJobList: any[] = [];
  forwarderJobMastersList: any[] = [];
  forwarderCreator: Object[] = [];
  forwarderLast_Update: Object[] = [];
  contactList: any[] = [];

  get ForwarderOfferTemplateList(): ForwarderOfferList[] {
    return this.forwarderOfferTemplateList;
  }
  get ForwarderJobParticipant(): ForwarderJobParticipantList[] {
    return this.forwarderJobParticipantList;
  }
  get ForwarderJobCargo(): ForwarderJobCargo[] {
    return this.forwarderJobCargoList;
  }
  get ForwarderObservationsTab():
    | {
        notes: string | undefined;
        incidences: string | undefined;
      }
    | undefined {
    return this.forwarderObservationsTabList;
  }
  get ForwarderAlerts(): Alert[] {
    return this.forwarderAlertList;
  }
  get ForwarderVoyage(): any {
    return this.forwarderVoyageList;
  }
  get ForwarderVoyageType(): any {
    return this.forwarderVoyageTypeList;
  }
  get LoadingForwarder(): boolean {
    return this.loadingForwarder;
  }
  get ForwarderJobiId(): any {
    return this.forwarderJobId;
  }
  get ForwarderCorrespondent(): any {
    return this.forwarderCorrespondentList;
  }
  get ForwarderCorrespondentGeneral(): any {
    return this.forwarderCorrespondentGeneralList;
  }
  get ForwarderJobItem(): any {
    return this.forwarderJobItem;
  }
  get ForwarderRegistrationNumberOptions(): Object[] {
    return this.forwarderRegistrationNumberOptions;
  }
  get ForwarderEquipmentInvolvedSave() {
    return this.forwarderEquipmentInvolvedSave;
  }
  get ForwarderRelationshipEquipments() {
    return this.forwarderRelationshipEquipments;
  }
  get ForwarderJobGroupageType(): any {
    return this.forwarderJobGroupageTypeList;
  }
  get ForwaderJobGoodStatus(): any {
    return this.forwarderJobGoodStatusList;
  }
  get ForwaderJobVoyage(): any {
    return this.forwarderJobVoyageList;
  }
  get ForwarderMasterId(): any {
    return this.forwarderMasterIdJobList;
  }
  get ForwaderCarrier(): any {
    return this.forwarderCarrier;
  }
  get ForwarderCarrierGeneral(): any {
    return this.forwarderCarrierGeneralList;
  }
  get ForwarderMaritimeVoyage(): ForwarderMaritimeVoyage[] {
    return this.forwarderMaritimeVoyageList;
  }
  get ForwarderMaritimeVoyageItem(): ForwarderMaritimeVoyage | undefined {
    return this.forwarderMaritimeVoyageItem;
  }
  get ForwarderVessel(): ForwarderVessel[] | undefined {
    return this.forwarderVesselList;
  }
  get ForwarderMaritimeVoyageOrigin():
    | ForwarderOriginMaritimeVoyage[]
    | undefined {
    return this.forwarderOriginMaritimeVoyageList;
  }
  get ForwarderMaritimeVoyageDestination():
    | ForwarderOriginMaritimeVoyage[]
    | undefined {
    return this.forwarderDestinationMaritimeVoyageList;
  }
  get ForwarderAerialVoyage(): ForwarderAerialVoyage[] {
    return this.forwarderAerialVoyageList;
  }
  get ForwarderAerialVoyageItem(): ForwarderAerialVoyage | undefined {
    return this.forwarderAerialVoyageItem;
  }
  get ForwarderAerialVoyageRoute(): ForwarderAerialVoyageRoute[] {
    return this.forwarderAerialVoyageRouteList;
  }
  get ForwarderEquipmentInvolved(): ForwarderEquipmentInvolved[] {
    return this.forwarderEquipmentInvolved;
  }
  get ForwarderAerialVoyageRouteItem(): ForwarderAerialVoyageRoute | undefined {
    return this.forwarderAerialVoyageRoute;
  }
  get ForwarderAirWayBill(): ForwarderAirWayBill[] {
    return this.forwarderAirWayBillList;
  }
  get ForwarderTerrestrialVoyageList(): ForwarderTerrestrialVoyage[] {
    return this.forwarderTerrestrialVoyageList;
  }
  get ForwarderTerrestrialVoyageItem(): ForwarderTerrestrialVoyage | undefined {
    return this.forwarderTerrestrialVoyageItem;
  }
  get ForwarderJobSelect(): any {
    return this.forwarderJobList;
  }
  get ForwarderDocumentJobList(): any {
    return this.forwarderDocumentJobList;
  }
  get ForwarderMastter(): any {
    return this.forwarderMasterJobList;
  }
  get ForwaderJobFreightTypes(): any {
    return this.forwarderJobFreigthTypesList;
  }
  get ForwaderJobOffer(): any {
    return this.forwarderJobOfferList;
  }
  get ForwaderJobTrafficType(): Object {
    return this.forwarderJobTrafficTypeList;
  }
  get ForwaderJobTraffic(): Object {
    return this.forwarderJobTrafficList;
  }
  get ForwarderJobServiceLines(): any {
    return this.ForwarderJobServiceLinesList;
  }
  get ForwarderJobShortId(): any {
    return this.forwarderJobShortId;
  }
  get ForwarderJobShortItem(): ForwarderJobShortForm | undefined {
    return this.forwarderJobShort;
  }
  get ForwarderOfferItem(): ForwarderOfferItem | undefined {
    return this.forwarderOfferItem;
  }
  get ForwarderTabItem(): ForwarderTab {
    return this.forwarderTab;
  }
  get ForwarderJobRoute(): any {
    return this.forwarderJobRouteList;
  }
  get ForwarderOfferObservation(): ForwarderOfferObservationItem | undefined {
    return this.forwarderOfferObservation;
  }
  get ForwarderJobRoutes(): ForwarderJobRouteAll[] {
    return this.forwarderJobRoutesList;
  }
  get ForwarderJobRoutesD(): any {
    return this.forwarderJobRoutesDList;
  }
  get ForwarderJobEquipment(): any {
    return this.forwarderJobEquipmentList;
  }
  get ForwarderJobEquipmentType(): any {
    return this.ForwarderJobEquipmentTypeList;
  }
  get ForwarderJobPackagingType(): any {
    return this.forwarderJobPackagingTypeList;
  }
  get ForwarderJobStatus(): any {
    return this.forwarderJobStatusList;
  }
  get ForwarderJobFreightTypes(): any {
    return this.forwarderJobFreightTypesList;
  }
  get EntityType(): any {
    return this.entityTypeList;
  }
  get ForwarderTrafficType(): any {
    return this.forwarderTrafficTypeList;
  }
  get ForwarderDocumentType(): any {
    return this.forwarderDocumentTypeList;
  }
  get ForwarderOfferType(): ForwarderOfferType[] {
    return this.forwarderOfferTypeList;
  }
  get ForwarderJobPaymentMethod(): ForwarderJobPaymentMethod[] {
    return this.forwarderJobPaymentMethodList;
  }
  get ForwarderJobSalesAssociate(): User[] {
    return this.forwarderJobSalesAssociateList;
  }
  get ForwarderAddress(): Address[] {
    return this.ForwarderAddressList;
  }
  get ForwarderCommunication(): Communication[] {
    return this.ForwarderCommunicationList;
  }
  get ForwarderContact(): Contact[] {
    return this.ForwarderContactList;
  }
  get ForwarderClient(): Object[] {
    return this.ForwarderClientList;
  }
  get ForwarderClientGeneral(): Object[] {
    return this.ForwarderClientGeneralList;
  }
  get ForwarderProvider(): Object[] {
    return this.ForwarderProviderList;
  }
  get ForwarderUser(): Object[] {
    return this.ForwarderUserList;
  }
  get ForwarderUserAgency(): Object[] {
    return this.ForwarderUserListAgency;
  }

  get ForwarderJobCategory(): Object[] {
    return this.forwarderJobCategoryList;
  }
  get ForwarderJobIncoterm(): Object[] {
    return this.forwarderJobIncotermList;
  }
  get ForwarderOffice(): Object[] {
    return this.forwarderOfficeList;
  }
  get ForwarderOfferStatus(): Object[] {
    return this.forwarderOfferStatusList;
  }
  get ForwarderJobLanguage(): any {
    return this.forwarderJobLanguageList;
  }
  get ForwarderEntity(): Entity[] {
    return this.forwarderEntityList;
  }
  get ForwarderJobTypeShippedVia(): any {
    return this.forwarderJobTypeShippedViaList;
  }
  get ForwarderJobOperationType(): Object[] {
    return this.forwarderJobOperationTypeList;
  }
  get forwarderContact(): any {
    return this.contactList;
  }
  // get Obtain for data to offer a promoter
  get Offerforwarder(): any {
    return this.forwarderChangeOfferList;
  }
  get routesConfirmed(): any {
    return this.routesConfirmedList;
  }
  get ForwarderMasters(): any {
    return this.forwarderJobMastersList;
  }
  get ForwarderRoutesConfirmed(): any {
    return this.routesConfirmedList;
  }
  get ForwarderCreator(): Object[] {
    return this.forwarderCreator;
  }
  get ForwarderLast_Update(): Object[] {
    return this.forwarderLast_Update;
  }
  // set Obtain for data to offer a promoter
  @Mutation
  ["setCreator"](payload: Object[]) {
    this.forwarderCreator = payload
  }
  @Mutation
  ["setLast_Update"](payload: Object[]) {
    this.forwarderLast_Update = payload
  }
  @Mutation
  ["setForwarderMasterId"](payload: any) {
    this.forwarderMasterIdJobList = payload;
  }
  @Mutation
  ["setRoutesConfirmed"](payload: ForwarderJobRouteAll) {
    this.routesConfirmedList = payload;
  }
  @Mutation
  ["setForwarderOffer"](payload: ForwarderChangeOffer) {
    this.forwarderChangeOfferList = payload;
  }
  // Add one more to the list in ForwarderOverviewTab
  @Mutation
  ["setForwarderMastter"](payload: any) {
    this.forwarderMasterJobList = payload;
  }
  @Mutation
  ["setForwarderJobMaster"](payload) {
    this.forwarderJobMastersList = payload;
  }
  ["addsetForwarderJobMaster"](payload) {
    this.forwarderJobMastersList.push(payload);
  }
  @Mutation
  ["addForwarderJobParticipant"](payload: ForwarderJobParticipantList) {
    this.forwarderJobParticipantList.push(payload);
  }
  @Mutation
  ["setForwarderJobParticipant"](payload: ForwarderJobParticipantList[]) {
    this.forwarderJobParticipantList = payload;
  }
  @Mutation
  ["setVoyage"](payload) {
    this.forwarderVoyageList = payload;
  }
  @Mutation
  ["setVoyageType"](payload) {
    this.forwarderVoyageTypeList = payload;
  }
  @Mutation
  ["setForwarderCorrespondent"](payload) {
    this.forwarderCorrespondentList = payload;
  }
  @Mutation
  ["setForwarderCorrespondentGeneral"](payload) {
    this.forwarderCorrespondentGeneralList = payload;
  }
  // Remove one more to the list in ForwarderOverviewTab
  @Mutation
  ["removeForwarderJobParticipant"](index: number) {
    this.forwarderJobParticipantList.splice(index, 1);
  }
  // Add one more to the list in ForwarderTransitCargoTab
  @Mutation
  ["addForwarderJobCargo"](payload: ForwarderJobCargo) {
    this.forwarderJobCargoList.push(payload);
  }
  // Set the list in ForwarderTransitCargoTab
  @Mutation
  ["setForwarderJobCargo"](payload: ForwarderJobCargo[]) {
    this.forwarderJobCargoList = payload;
  }
  // Remove one more to the list in ForwarderTransitCargoTab
  @Mutation
  ["removeForwarderJobCargo"](index: number) {
    this.forwarderJobCargoList.splice(index, 1);
  }
  // Add one more to the list in ForwarderTransitCargoTab
  @Mutation
  ["setForwarderAlert"](payload: Alert) {
    this.forwarderAlertList.push(payload);
  }
  // Remove one more to the list in ForwarderTransitCargoTab
  @Mutation
  ["removeForwarderAlert"](index: number) {
    this.forwarderAlertList.splice(index, 1);
  }
  @Mutation
  ["setForwarderObservationsTab"](payload: {
    notes: string | undefined;
    incidences: string | undefined;
  }) {
    this.forwarderObservationsTabList = payload;
  }
  @Mutation
  ["setForwarderJobId"](payload) {
    this.forwarderJobId = payload;
  }
  @Mutation
  ["setForwarderJobItem"](payload) {
    this.forwarderJobItem = payload;
  }
  @Mutation
  ["setForwarderJobGroupageType"](payload) {
    this.forwarderJobGroupageTypeList = payload;
  }
  @Mutation
  ["setforwaderJobGoodStatus"](payload) {
    this.forwarderJobGoodStatusList = payload;
  }
  @Mutation
  ["setEntityType"](payload) {
    this.entityTypeList = payload;
  }
  @Mutation
  ["setforwarderRegistrationNumberOptions"](payload) {
    this.forwarderRegistrationNumberOptions = payload;
  }
  @Mutation
  ["setforwarderEquipmentInvolvedSave"](payload) {
    this.forwarderEquipmentInvolvedSave = payload;
  }
  @Mutation
  ["setforwarderRelationshipEquipments"](payload) {
    this.forwarderRelationshipEquipments = payload;
  }
  @Mutation
  ["setforwarderJobOffer"](payload) {
    this.forwarderJobOfferList = payload;
  }
  @Mutation
  ["setforwarderJobTrafficType"](payload: Object[]) {
    this.forwarderJobTrafficTypeList = payload;
  }
  @Mutation
  ["setforwarderJobTraffic"](payload) {
    this.forwarderJobTrafficList = payload;
  }
  @Mutation
  ["setforwarderJobServiceLines"](payload) {
    this.ForwarderJobServiceLinesList = payload;
  }
  @Mutation
  ["setforwarderJobFreigthTypes"](payload) {
    this.forwarderJobFreigthTypesList = payload;
  }
  @Mutation
  ["setforwarderJobVoyage"](payload) {
    this.forwarderJobVoyageList = payload;
  }
  @Mutation
  ["setforwarderTerrestrialVoyage"](payload: ForwarderTerrestrialVoyage[]) {
    this.forwarderTerrestrialVoyageList = payload;
  }
  @Mutation
  ["setforwarderTerrestrialVoyageItem"](payload: ForwarderTerrestrialVoyage) {
    this.forwarderTerrestrialVoyageItem = payload;
  }
  @Mutation
  ["setForwarderDocumentJobList"](payload) {
    this.forwarderDocumentJobList = payload;
  }
  @Mutation
  ["setforwarderCarrier"](payload: ForwarderCarrier[]) {
    this.forwarderCarrier = payload;
  }
  @Mutation
  ["setforwarderCarrierGeneral"](payload: ForwarderCarrier[]) {
    this.forwarderCarrierGeneralList = payload;
  }
  @Mutation
  ["setforwarderMaritimeVoyage"](payload: ForwarderMaritimeVoyage[]) {
    this.forwarderMaritimeVoyageList = payload;
  }
  @Mutation
  ["setforwarderMaritimeVoyageItem"](payload: ForwarderMaritimeVoyage) {
    this.forwarderMaritimeVoyageItem = payload;
  }
  @Mutation
  ["setforwarderVessel"](payload: ForwarderVessel[]) {
    this.forwarderVesselList = payload;
  }
  @Mutation
  ["setforwarderOriginMaritimeVoyage"](
    payload: ForwarderOriginMaritimeVoyage[]
  ) {
    this.forwarderOriginMaritimeVoyageList = payload;
  }
  @Mutation
  ["setforwarderDestinationMaritimeVoyage"](
    payload: ForwarderOriginMaritimeVoyage[]
  ) {
    this.forwarderDestinationMaritimeVoyageList = payload;
  }
  @Mutation
  ["setforwarderAerialVoyage"](payload: ForwarderAerialVoyage[]) {
    this.forwarderAerialVoyageList = payload;
  }
  @Mutation
  ["setforwarderAerialVoyageItem"](payload: ForwarderAerialVoyage) {
    this.forwarderAerialVoyageItem = payload;
  }
  @Mutation
  ["setForwarderAerialVoyageRoute"](payload: ForwarderAerialVoyageRoute[]) {
    this.forwarderAerialVoyageRouteList = payload;
  }
  @Mutation
  ["setForwarderEquipmentInvolved"](payload: ForwarderEquipmentInvolved[]) {
    this.forwarderEquipmentInvolved = payload;
  }
  @Mutation
  ["addForwarderEquipmentInvolved"](payload: ForwarderEquipmentInvolved) {
    this.forwarderEquipmentInvolved.push(payload);
  }
  @Mutation
  ["addforwarderAerialVoyageRoute"](payload: ForwarderAerialVoyageRoute) {
    this.forwarderAerialVoyageRouteList.push(payload);
  }
  @Mutation
  ["setforwarderAirWayBill"](payload: ForwarderAirWayBill[]) {
    this.forwarderAirWayBillList = payload;
  }
  @Mutation
  ["addforwarderAerialVoyage"](payload: ForwarderAerialVoyage) {
    this.forwarderAerialVoyageList.push(payload);
  }
  @Mutation
  ["setLoadingForwarder"](payload) {
    this.loadingForwarder = payload;
  }

  @Mutation
  ["setForwarderJobShortId"](payload) {
    this.forwarderJobShortId = payload;
  }

  @Mutation
  ["setForwarderOfferItem"](payload: ForwarderOfferItem) {
    this.forwarderOfferItem = payload;
  }

  @Mutation
  ["setForwarderJobShortItem"](payload) {
    this.forwarderJobShort = payload;
  }

  @Mutation
  ["setForwarderTab"](payload) {
    this.forwarderTab = payload;
  }

  @Mutation
  ["removeForwarderTab"]() {
    this.forwarderTab = {};
  }

  @Mutation
  ["addForwarderJobRoute"](payload: ForwarderJobRoute) {
    this.forwarderJobRouteList.push(payload);
  }

  @Mutation
  ["removeForwarderJobRoute"](index: number) {
    this.forwarderJobRouteList.splice(index, 1);
  }

  @Mutation
  ["ressetForwarderJobRoute"]() {
    this.forwarderJobRouteList = [];
  }

  @Mutation
  ["setForwarderOfferObservation"](payload: ForwarderOfferObservationItem) {
    this.forwarderOfferObservation = payload;
  }

  @Mutation
  ["setForwarderJobRoutes"](payload) {
    this.forwarderJobRoutesList = payload;
  }

  @Mutation
  ["setForwarderJobRoutesD"](payload) {
    this.forwarderJobRoutesDList = payload;
  }

  @Mutation
  ["setForwarderOffice"](payload) {
    this.forwarderOfficeList = payload;
  }

  @Mutation
  ["setForwarderJobEquipment"](payload) {
    this.forwarderJobEquipmentList = payload;
  }

  @Mutation
  ["setForwarderJobEquipmentType"](payload) {
    this.ForwarderJobEquipmentTypeList = payload;
  }

  @Mutation
  ["setForwarderJobPackagingType"](payload) {
    this.forwarderJobPackagingTypeList = payload;
  }

  @Mutation
  ["setForwarderOfferTemplateList"](payload: ForwarderOfferList[]) {
    this.forwarderOfferTemplateList = payload;
  }

  @Mutation
  ["setForwarderJobStatus"](payload) {
    this.forwarderJobStatusList = payload;
  }

  @Mutation
  ["setforwarderJobFreightTypes"](payload) {
    this.forwarderJobFreightTypesList = payload;
  }

  @Mutation
  ["setForwarderJobPaymentMethod"](payload: ForwarderJobPaymentMethod[]) {
    this.forwarderJobPaymentMethodList = payload;
  }

  @Mutation
  ["setForwarderTrafficType"](payload) {
    this.forwarderTrafficTypeList = payload;
  }

  @Mutation
  ["setForwarderDocumentType"](payload) {
    this.forwarderDocumentTypeList = payload;
  }
  @Mutation
  ["setForwarderOfferType"](payload: ForwarderOfferType[]) {
    this.forwarderOfferTypeList = payload;
  }

  @Mutation
  ["setForwarderJobSalesAssociate"](payload: User[]) {
    this.forwarderJobSalesAssociateList = payload;
  }

  @Mutation
  ["setForwarderAddress"](payload: Address[]) {
    this.ForwarderAddressList = payload;
  }
  @Mutation
  ["addForwarderAddress"](payload: Address) {
    this.ForwarderAddressList.push(payload);
  }

  @Mutation
  ["setForwarderCommunication"](payload: Communication[]) {
    this.ForwarderCommunicationList = payload;
  }
  @Mutation
  ["addForwarderCommunication"](payload: Communication) {
    this.ForwarderCommunicationList.push(payload);
  }

  @Mutation
  ["setForwarderContact"](payload: Contact[]) {
    this.ForwarderContactList = payload;
  }

  @Mutation
  ["setForwarderClient"](payload: Object[]) {
    this.ForwarderClientList = payload;
  }
  @Mutation
  ["setForwarderClientGeneral"](payload: Object[]) {
    this.ForwarderClientGeneralList = payload;
  }
  @Mutation
  ["addForwarderClient"](payload: Object) {
    this.ForwarderClientList.push(payload);
  }

  @Mutation
  ["setForwarderProvider"](payload: Object[]) {
    this.ForwarderProviderList = payload;
  }
  @Mutation
  ["setForwarderUser"](payload: []) {
    this.ForwarderUserList = payload;
  }
  @Mutation
  ["addForwarderUser"](payload: Object) {
    this.ForwarderUserList.push(payload);
  }
  @Mutation
  ["addForwarderUserAgency"](payload: Object) {
    this.ForwarderUserListAgency.push(payload);
  }
  @Mutation
  ["setForwarderUserAgency"](payload: []) {
    this.ForwarderUserListAgency = payload;
  }

  @Mutation
  ["setForwarderJobCategory"](payload: Object[]) {
    this.forwarderJobCategoryList = payload;
  }
  @Mutation
  ["addForwarderJobCategory"](payload: Object) {
    this.forwarderJobCategoryList.push(payload);
  }

  @Mutation
  ["setForwarderJobIncoterm"](payload: Object[]) {
    this.forwarderJobIncotermList = payload;
  }

  @Mutation
  ["setForwarderOfferStatus"](payload: Object[]) {
    this.forwarderOfferStatusList = payload;
  }
  @Mutation
  ["setForwarderJobLanguage"](payload: Object[]) {
    this.forwarderJobLanguageList = payload;
  }
  @Mutation
  ["addForwarderJobLanguage"](payload: Object) {
    this.forwarderJobLanguageList.push(payload);
  }
  // utily list participants
  @Mutation
  ["setForwarderEntity"](payload: Entity[]) {
    this.forwarderEntityList = payload;
  }
  @Mutation
  ["addForwarderEntity"](payload: Entity) {
    this.forwarderEntityList.push(payload);
  }
  @Mutation
  ["removeForwarderEntity"](index: number) {
    this.forwarderEntityList.splice(index, 1);
  }

  @Mutation
  ["setForwarderJobTypeShippedVia"](payload: Object[]) {
    this.forwarderJobTypeShippedViaList = payload;
  }

  @Mutation
  ["setForwarderJobOperationType"](payload: Object[]) {
    this.forwarderJobOperationTypeList = payload;
  }

  @Mutation
  ["setContact"](payload) {
    this.contactList = payload;
  }

  @Mutation
  ["resetForwarderModule"]() {
    this.forwarderJobItem = undefined;
    this.forwarderJobVoyageList = [];
    this.loadingForwarder = true;
    this.forwarderTab = {};
    this.forwarderLast_Update = [];
    this.forwarderCreator = [];
    this.forwarderOfferTypeList = [];
    this.forwarderVoyageTypeList = [];
    this.forwarderVoyageList = [];
    this.forwarderJobCategoryList = [];
    this.forwarderJobGroupageTypeList = [];
    this.forwarderJobTypeShippedViaList = [];
    this.forwarderJobOperationTypeList = [];
    this.forwarderJobGoodStatusList = [];
    this.ForwarderJobServiceLinesList = [];
    this.forwarderMasterJobList = [];
    this.forwarderCarrierGeneralList = [];
    this.forwarderJobFreightTypesList = [];
    this.forwarderJobPaymentMethodList = [];
    this.forwarderJobStatusList = [];
    this.ForwarderClientGeneralList = [];
    this.forwarderCorrespondentGeneralList = [];
    this.ForwarderCommunicationList = [];
    this.ForwarderUserList = [];
    this.ForwarderClientList = [];
    this.ForwarderAddressList = [];
    this.ForwarderUserListAgency = [];
    this.forwarderOfferItem = undefined;
    this.forwarderJobTrafficList = [];
    this.forwarderJobRouteList = [];
    this.forwarderOfferObservation = undefined;
    this.forwarderJobRoutesList = [];
    this.forwarderJobParticipantList = [];
    this.forwarderJobEquipmentList = [];
    this.ForwarderJobEquipmentTypeList = [];
    this.forwarderJobCargoList = [];
    this.forwarderAlertList = [];
    this.forwarderAirWayBillList = [];
    this.forwarderTrafficTypeList = [];
    this.forwarderAerialVoyageRouteList = [];
    this.forwarderAerialVoyageItem = undefined;
    this.forwarderMaritimeVoyageItem = undefined;
    this.forwarderVesselList = [];
    this.forwarderOriginMaritimeVoyageList = [];
    this.forwarderDestinationMaritimeVoyageList = [];
    this.forwarderCarrier = [];
    this.ForwarderContactList = [];
    this.forwarderTerrestrialVoyageItem = undefined;
  }
}
