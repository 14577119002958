import ApiService from "@/core/services/ApiService";
import { ForwarderOfferEndpointEnum } from "@/enums/api_endpoints/forwarder/ForwarderOfferEnums";
import { ForwarderOfferItem } from "@/interfaces/forwarder/ForwarderOfferInterfaces";
import { Alert } from "@/interfaces/general/GeneralInterfaces";
import { ForwarderJobRouteList } from "@/interfaces/forwarder/ForwarderJobRouteInterfaces";
import type { ForwarderJobRouteAll } from "@/interfaces/forwarder/ForwarderJobRouteInterfaces";
import { AxiosResponse } from "axios";

export async function mountOffer(store, id: number) {
  await ApiService.get(`${ForwarderOfferEndpointEnum.CRUD}/${id}`).then(
    ({ data }) => {
      console.log(data)
      store.commit("resetForwarderModule");
      store.commit("setCreator", [data.creator]);
      store.commit("setLast_Update", [data.last_update]);
      store.commit("setForwarderOfferItem", data as ForwarderOfferItem);
      store.commit("setForwarderProvider", [data.forwarder_job_provider]);
      store.commit("setForwarderOfferType", [data.forwarder_offer_types]);
      store.commit("setForwarderJobIncoterm", [
        data.forwarder_job_incoterm_types,
      ]);
      store.commit("setForwarderClient", [data.client]);
      store.commit("setForwarderContact", [data.contact]);
      store.commit("setForwarderCommunication", [data.communication]);
      store.commit("setForwarderOffice", [data.office]);
      store.commit("setForwarderJobPaymentMethod", [
        data.forwarder_job_payment_method,
      ]);
      store.commit("setForwarderJobSalesAssociate", [data.sales_associate]);
      store.commit("setForwarderOfferStatus", [data.forwarder_offer_statuses]);
      store.commit("setforwarderJobTrafficType", [
        data.forwarder_job_traffic_types,
      ]);
      store.commit("setForwarderJobTypeShippedVia", [
        data.forwarder_job_shipped_vias,
      ]);
      store.commit("setForwarderJobOperationType", [
        data.forwarder_job_operation_types,
      ]);
      store.commit("setForwarderJobLanguage", [data.forwarder_job_languages]);
      store.commit("setForwarderJobRoutes", data.forwarder_job_route);

      // store.commit("setForwarderJobPaymentMethod", data.forwarder_job_payment_method);
      if (data.forwarder_job_route) {
        if (data.forwarder_job_route) {
          // We need to create a new object based on the incoming route data
          // for simplicity in its manipulation in the front-end.
          const ruta: ForwarderJobRouteList[] =
            data.forwarder_job_route as ForwarderJobRouteList[];
          const dataDetails: ForwarderJobRouteList[] = [];
          ruta.forEach((element: ForwarderJobRouteList, index: number) => {
            // Create a new object based on the data of the original.
            let newData = { ...element };
            // Delete data that would be otherwise duplicated between the route and its details.
            delete newData.created_at;
            delete newData.updated_at;
            delete newData.deleted_at;
            // Push the new object, combined with its own details, to the array.
            dataDetails.push(
              Object.assign(newData, element.forwarder_job_route_details)
            );
            // Delete the details property, as its data is already on the same level as the parent.
            delete newData.forwarder_job_route_details;
          });
          store.commit("setForwarderJobRoutesD", dataDetails);
        }
      }

      if (data.forwarder_job_cargo) {
        store.commit("setForwarderJobCargo", data.forwarder_job_cargo);

        // data.forwarder_job_cargo.forEach(function (item) {
        //   store.commit(
        //     "addForwarderEquipmentInvolved",
        //     item.equipment_cargo[0]?.equipment
        //   );
        // });
      }
      if (data.observations) {
        store.commit("setForwarderOfferObservation", data.observations);
      }

      if (data.alert) {
        data.alert.forEach((x: Alert) => {
          store.commit("setForwarderAlert", x);
        });
      }

      store.commit("setLoading", false);
      store.commit("setLoadingForwarder", false);
    }
  );
}