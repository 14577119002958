import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class MaritimeFileSelectsModule extends VuexModule {
  blList: any[] = [];

  incotermList: any[] = [];

  get incoterm(): any {
    return this.incotermList;
  }

  get BL(): any[] {
    return this.blList;
  }

  @Mutation
  ["setIncoterm"](payload) {
    this.incotermList = payload;
  }

  @Mutation
  ["setBLs"](payload) {
    this.blList = payload;
  }

  @Mutation
  ["addBL"](payload) {
    if (payload && Array.isArray(this.blList)) {
      const index = this.blList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.blList.push(payload);
      }
    }
  }

  @Mutation
  ["resetFileSelectModule"]() {
    this.blList = [];
  }
}
