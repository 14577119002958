import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.style)
  }, [
    _createVNode(_component_el_input, {
      modelValue: _ctx.current_val,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current_val) = $event)),
      type: "number",
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.step,
      placeholder: _ctx.placeholder,
      readonly: _ctx.readonly,
      disabled: _ctx.disabled,
      size: _ctx.size,
      "controls-position": _ctx.positionContols,
      onChange: _ctx.validateInput
    }, null, 8, ["modelValue", "min", "max", "step", "placeholder", "readonly", "disabled", "size", "controls-position", "onChange"]),
    (_ctx.showError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("invalidInputNumber")), 1))
      : _createCommentVNode("", true)
  ], 2))
}