import ApiService from "@/core/services/ApiService";
import { ForwarderTerrestrialVoyageEndpointEnum } from "@/enums/api_endpoints/forwarder/ForwarderTerrestrialVoyageEnum";
import { ForwarderCarrier } from "@/interfaces/forwarder/ForwarderCarrierInterfaces";
import { Communication } from "@/interfaces/general/CommunicationInterfaces";
import { Contact } from "@/interfaces/general/ContactInterfaces";
import { ForwarderTerrestrialVoyageItem } from "@/interfaces/forwarder/ForwarderTerrestrialVoyageInterfaces";
import { ForwarderTrafficType } from "@/interfaces/forwarder/ForwarderTrafficType";
import { AxiosResponse } from "axios";

export async function mountTerrestrialGuide(store, id: number) {
    await ApiService.get(`${ForwarderTerrestrialVoyageEndpointEnum.CRUD}/${id}`).then(({ data }: AxiosResponse<ForwarderTerrestrialVoyageItem>) => {
        store.commit("resetForwarderModule");
        store.commit("setForwarderTrafficType", [data.forwarder_job_traffic_type as ForwarderTrafficType]);
        store.commit("setforwarderCarrier", data.carrier as ForwarderCarrier)
        store.commit("setForwarderCommunication", data.communications as Communication)
        store.commit("setForwarderContact", data.contact as Contact);
        store.commit("setforwarderTerrestrialVoyageItem", data as ForwarderTerrestrialVoyageItem);

        store.commit("setLoading", false);
        store.commit("setLoadingForwarder", false);
    });
};