import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class DocumentsModule extends VuexModule {
  arrival_notice_dialog = false;
  send_billing_dialog = false;
  send_pdf_dialog = false;
  import_file_dialog = false;
  import_file_dialog_ot = false;
  reload = false;
  import_option = null;
  elements_select_dialog = [] as any;
  edit_item = null;
  audio_item = null;
  video_item = null;
  email_item = null;
  mails = [] as any;

  get ArrivalNoticeDialog(): boolean {
    return this.arrival_notice_dialog;
  }

  get SendBillingDialog(): boolean {
    return this.send_billing_dialog;
  }

  get SendPdfDialog(): boolean {
    return this.send_pdf_dialog;
  }

  get ImportFileDialog(): boolean {
    return this.import_file_dialog;
  }

  get ImportFileDialogOt(): boolean {
    return this.import_file_dialog_ot;
  }

  get EditItem(): any {
    return this.edit_item;
  }

  get ImportOption(): any {
    return this.import_option;
  }

  get ElementSelectDialog(): any {
    return this.elements_select_dialog;
  }

  get Reload(): any {
    return this.reload;
  }

  get AudioItem(): any {
    return this.audio_item;
  }

  get VideoItem(): any {
    return this.video_item;
  }

  get EmailItem(): any {
    return this.email_item;
  }

  get Mails(): any {
    return this.mails;
  }

  @Mutation
  ["setArrivalNoticeDialog"](payload) {
    this.arrival_notice_dialog = payload;
  }

  @Mutation
  ["setSendBillingDialog"](payload) {
    this.send_billing_dialog = payload;
  }

  @Mutation
  ["setSendPdfDialog"](payload) {
    this.send_pdf_dialog = payload;
  }

  @Mutation
  ["setImportFileDialog"](payload) {
    this.import_file_dialog = payload.show;
    this.import_option = payload.option;
    this.elements_select_dialog = payload.data;
  }

  @Mutation
  ["setImportFileTODialog"](payload) {
    this.import_file_dialog_ot = payload.show;
    this.import_option = payload.option;
    this.elements_select_dialog = payload.data;
  }

  @Mutation
  ["setEditItem"](payload) {
    this.edit_item = payload;
  }

  @Mutation
  ["setAudioItem"](payload) {
    this.audio_item = payload;
  }

  @Mutation
  ["setVideoItem"](payload) {
    this.video_item = payload;
  }

  @Mutation
  ["setEmailItem"](payload) {
    this.email_item = payload;
  }
  @Mutation
  ["setMails"](payload) {
    this.mails = payload;
  }

  @Mutation
  ["addMail"](payload) {
    this.mails.push(payload);
  }

  @Mutation
  ["removeMail"](index) {
    this.mails.splice(index, 1);
  }

  @Mutation
  ["setReload"](payload) {
    this.reload = payload;
  }

  @Mutation
  ["ressetDocumentsModule"]() {
    this.arrival_notice_dialog = false;
    this.send_billing_dialog = false;
    this.send_pdf_dialog = false;
    this.import_file_dialog = false;
    this.reload = false;
    this.import_option = null;
    this.elements_select_dialog = [];
    this.audio_item = null;
    this.video_item = null;
    this.email_item = null;
    this.mails = [];
  }
}
