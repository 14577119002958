import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";

interface RecentlyActivities {
  id: string;
  tag: string;
  name: string;
  url: string;
  data: any;
  time: string;
  datetime: number;
  icon: string;
  tab: string;
  selected_bl: any;
  booking_pos_selected: number;
  bl_pos_selected: number;
}
export interface LogActivities {
  id: number;
  name: string;
  id_element: number;
  tag: string;
  user_id: number;
  url: string;
  updated_at: string;
  icon: string;
}
export interface ShowDialogObject {
  url?: string;
  show?: boolean;
}

@Module
export default class ActivitiesLogsModule extends VuexModule {
  recentlyList: RecentlyActivities[] = [];
  activityLogs: LogActivities[] = [];
  saveForm = false;
  showBackDialog: ShowDialogObject = {};

  get ActivityLogs(): LogActivities[] {
    return this.activityLogs;
  }
  get RecentlyActivities(): RecentlyActivities[] {
    return this.recentlyList;
  }

  get GetSaveFor(): boolean {
    return this.saveForm;
  }
  get GetShowBackDialog(): ShowDialogObject {
    return this.showBackDialog;
  }

  @Mutation ["setsaveForm"](payload) {
    this.saveForm = payload;
  }
  @Mutation ["setShowBackDialog"](payload) {
    this.showBackDialog = payload;
  }
  @Mutation ["setTabData"](payload) {
    // console.log("setTabData payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.tab = payload.tab;
      }
    });
  }
  @Mutation ["setBookingPos"](payload) {
    // console.log("setBookingPos payload", payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.booking_pos_selected = payload.booking_pos_selected;
      }
    });
  }

  @Mutation ["updateRoutes"](payload) {
    // console.log("updateRoutes payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.data.bookingInfo.bookings[payload.booking_pos_selected].routes =
          payload.routes;
      }
    });
  }
  @Mutation ["updateParticipants"](payload) {
    // console.log("updateParticipants payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.data.bookingInfo.bookings[payload.booking_pos_selected].participants =
          payload.participants;
      }
    });
  }
  @Mutation ["updateEquipmentEnvolved"](payload) {
    // console.log("updateEquipmentEnvolved payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.data.bookingInfo.bookings[
          payload.booking_pos_selected
        ].equipment_involveds = payload.equipment_involveds;
      }
    });
  }
  @Mutation ["updateGoodsTE"](payload) {
    // console.log("updateEquipmentEnvolved payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.data.bookingInfo.bookings[payload.booking_pos_selected].goods =
          payload.goods;
      }
    });
  }
  @Mutation ["setBookingForm"](payload) {
    // console.log("setBookingForm payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.data.bookingInfo.bookings[
          payload.booking_pos_selected
        ].maritime_voyage_id = payload.form.maritime_voyage_id;
        x.data.bookingInfo.bookings[payload.booking_pos_selected].trip_type_id =
          payload.form.trip_type_id;
        x.data.bookingInfo.bookings[
          payload.booking_pos_selected
        ].booking_number = payload.form.booking_number;
        x.data.bookingInfo.bookings[payload.booking_pos_selected].booking_date =
          payload.form.booking_date;
        x.data.bookingInfo.bookings[payload.booking_pos_selected].reference =
          payload.form.reference;
      }
    });
  }

  @Mutation ["setBlForm"](payload) {
    // console.log("setBlForm payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.selected_bl.maritime_voyage_id = payload.form.maritime_voyage_id;
        x.selected_bl.trip_type_id = payload.form.trip_type_id;
        x.selected_bl.booking_number = payload.form.booking_number;
        x.selected_bl.booking_date = payload.form.booking_date;
        x.selected_bl.reference = payload.form.reference;
      }
    });
  }

  @Mutation ["setBlPos"](payload) {
    // console.log("setBlPos payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.bl_pos_selected = payload.bl_pos_selected;
      }
    });
  }
  @Mutation ["setBl"](payload) {
    // console.log("setBl payload",payload);
    this.recentlyList.forEach((x) => {
      if (x.id === payload.id) {
        x.selected_bl = payload.selected_bl;
      }
    });
  }

  @Action
  ["GET_LOGACTIVITIES_API"]() {
    return new Promise<void>((resolve, reject) => {
      const preferences = JSON.parse(
        localStorage.getItem("preferences") as any
      );
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      ApiService.query(`/api/historical_activities/${user.id}`, {
        params: {
          number_shows: preferences.number_activities_log,
        },
      })
        .then((resp) => {
          this.context.commit(Mutations.SET_LOG_ACTIVITIES, resp.data);
          this.context.commit("setLoadingHistorical", true);
          resolve();
        })
        .catch((e) => {
          console.log(e);
          reject();
        });
    });
  }

  @Mutation
  [Mutations.SET_LOG_ACTIVITIES](data) {
    let logActivities = data;
    if (!Array.isArray(logActivities)) {
      if (logActivities.maritime_files) {
        logActivities.maritime_files.forEach((value) => {
          value.tag = "maritime_files";
          if (value.properties.attributes) {
            value.visible = true;
            value.maritime_id = value.properties.attributes.id;
            value.url = `/maritimefile/${value.properties.attributes.id}`;
            value.time = new Date(
              value.properties.attributes.updated_at
            ).toLocaleDateString();
            value.maritime_file_id = value.properties.attributes.id;
            value.name = value.properties.attributes.code;
            value.icon =
              document.location.origin + "/media/icons/menu/vessels01.svg";
          } else {
            value.visible = false;
          }
        });
      } else {
        logActivities.maritime_files = [];
      }
      if (logActivities.bookings) {
        logActivities.bookings.forEach((x) => {
          x.tag = "bookings";
          if (x.properties.attributes) {
            x.visible = true;
            x.maritime_file_id =
              x.properties.attributes["maritime_voyage.maritime_file_id"];
            x.time = new Date(
              x.properties.attributes.booking_date
            ).toLocaleDateString();
            x.booking_id = x.properties.attributes.id;
            x.name = x.properties.attributes.booking_number
              ? x.properties.attributes.booking_number
              : `booking_${x.properties.attributes.id}`;
            x.url = `/maritimefile/${x.maritime_file_id}`;
            x.icon =
              document.location.origin + "/media/icons/menu/vessels01.svg";
          } else {
            x.visible = true;
          }
        });
      } else {
        logActivities.billings = [];
      }
      if (logActivities.bls) {
        logActivities.bls.forEach((x) => {
          x.tag = "bls";
          if (x.properties.attributes) {
            x.visible = true;
            x.time = new Date(
              x.properties.attributes.bl_date
            ).toLocaleDateString();
            x.booking_id = x.properties.attributes.id;
            x.bl_id = x.properties.attributes.id;
            x.maritime_file_id = x.properties.maritime_file_id;
            x.name = x.properties.attributes.bl_number
              ? x.properties.attributes.bl_number
              : `$bl_${x.properties.attributes.id}`;
            x.url = `/maritimefile/${x.maritime_file_id}`;
            x.icon =
              document.location.origin + "/media/icons/menu/vessels01.svg";
          } else {
            x.visible = false;
          }
        });
      } else {
        logActivities.bls = [];
      }
      if (logActivities.billings) {
        logActivities.billings.forEach((x) => {
          x.tag = "bill";
          if (x.properties.attributes) {
            x.visible = true;
            x.billing_id = x.properties.attributes.id;
            x.time = new Date(
              x.properties.attributes.updated_at
            ).toLocaleDateString();
            x.name = x.properties.attributes.bill_number
              ? x.properties.attributes.bill_number
              : `billing_${x.properties.attributes.id}`;
            x.url = `/bill_register/${x.billing_id}`;
            x.icon =
              document.location.origin + "/media/icons/menu/vessels01.svg";
          } else {
            x.visible = false;
          }
        });
      }else {
        logActivities.billings=[];
      }
      localStorage.setItem("logs_activities", JSON.stringify(logActivities));
    } else {
      logActivities = {} as any;
      logActivities.bls = [];
      logActivities.maritime_files = [];
      logActivities.bookings = [];
      logActivities.billings = [];
      localStorage.setItem("logs_activities", JSON.stringify(logActivities));
    }
  }

  @Mutation
  ["addRecentlyAtivity"](payload) {
    this.recentlyList = this.recentlyList.filter(
      (item) => item.id !== payload.id
    );
    const preferences = JSON.parse(localStorage.getItem("preferences") as any);
    if (this.recentlyList.length === preferences.number_windows) {
      this.recentlyList.pop();
    }
    switch (payload.tag) {
      case "general":
        payload.icon =
          document.location.origin + "/media/icons/menu/general01.svg";
        break;
      case "maritime":
        payload.icon =
          document.location.origin + "/media/icons/menu/vessels01.svg";
        break;
      case "cotization":
        payload.icon =
          document.location.origin + "/media/icons/menu/finances01.svg";
        break;
    }
    this.recentlyList.push(payload);
    this.recentlyList = this.recentlyList.reverse();
  }

  @Mutation
  ["addDataActivity"](payload) {
    this.recentlyList.forEach((item) => {
      if (item.id === payload.id) {
        item.name = payload.name;
        item.data = payload.data;
      }
    });
  }
}
