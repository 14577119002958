import ApiService from "@/core/services/ApiService";
import StorageService from "@/core/services/StorageService";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { permissions } from "@/core/helpers/functions";

interface userData {
  id?: number;
  name?: string;
  email?: string;
  born_date?: string;
  nif_cif?: string;
  phone?: number;
  avatar?: string;
  offices?: [];
  permissions_list?: [];
  companies?: [];
  companies_group?: [];
  roles?: [];
}

@Module
export default class GeneralModule extends VuexModule {
  localSearch = "";
  permissionslist = [];
  menusList = [];
  userDataContent: userData = {};
  roles = [] as any;
  GeneralPDF = [] as any;
  countries = [] as any;

  get generalPDF(): any {
    return this.GeneralPDF
  }
  /**
   * Get string for search
   * @returns text
   */
  get userData(): userData {
    return this.userDataContent;
  }
  /**
   * Get string for search
   * @returns text
   */
  get searchLocal(): string {
    return this.localSearch || "";
  }

  /**
   * Get string for search
   * @returns array
   */
  get Countries(): any[] {
    return this.countries || [];
  }

  // my permissions
  get permissions(): Record<string, any> {
    return this.permissionslist;
  }

  get menus(): Record<string, any> {
    return this.menusList;
  }

  @Mutation
  ["setSearchLocal"](text) {
    this.localSearch = text;
  }

  @Mutation
  ["setRoles"](payload: any) {
    this.roles.push(payload);
  }

  @Mutation
  ["setGeneralPDF"](payload: any) {
    this.GeneralPDF = payload;
  }

  @Mutation
  ["addGeneralPDF"](payload: any) {
    this.GeneralPDF.push(payload);
  }

  @Mutation
  ["setPermissions"](permissions) {
    StorageService.savePermissions(permissions);
    this.permissionslist = permissions;
  }
  @Mutation
  ["setCountries"](countries) {
    if (countries && Array.isArray(this.countries)) {
      const index = this.countries.findIndex((x) => x.id === countries.id);
      if (index === -1) {
        this.countries.push(countries);
      }
    }
  }

  @Mutation
    ["clearCountries"]() {
     this.countries =[];
   }

  @Mutation
  ["setUserData"](payload) {
    this.userDataContent = payload;
  }


  @Mutation
  ["resetPermissions"]() {
    this.permissionslist = [];
    this.menusList = [];
  }

  @Mutation
  ["setMenu"](menus) {
    StorageService.saveMenus(menus);
    this.menusList = menus;
  }

  @Action
  ["myPermissions"]() {
    ApiService.get("/api/permissions/me")
      .then(({ data }) => {
        this.context.commit("setPermissions", data);
        return data;
      })
      .then(() => {
        this.context.dispatch("myMenu");
      })
      .catch(() => {
        console.error("error al obtener los permisos");
      });
  }

  @Action
  ["myMenu"]() {
    permissions()
      .then((menus) => {
        this.context.commit(
          "setMenu",
          menus.filter((ele) => !ele.pages || ele.pages.length > 0)
        );
      })
      .catch(() => {
        console.error("error al setear los permisos");
      });
  }
}
