export enum ForwarderJobEndpointEnum {
    Form = "/forwarder/job/job",
    CRUD = '/api/forwarder/job/jobs',
    OfferToMultipleJobs = "/api/forwarder/job/multi_job_offer_asignment/",
    MasterToMultipleHouse = "/api/forwarder/job/multi_house_assignment/",
    FormTypes = "/api/forwarder/job/form_types",
    UploadFiles = '/api/forwarder/job/jobs/attach_file/'
};

export enum ForwarderJobSearchFieldsEnum {
    Master = 'master_id',
    Client = 'client_id',
    Offer = 'forwarder_offer_id',
    Communication = 'communications_id',
    Contact = 'contact_id',
    Correspondent = 'correspondent_id',
    Voyage = 'forwarder_voyage_id',
    VoyageType = 'forwarder_voyage_type',
    Creator = 'file_creator_id',
    LastUpdate = 'file_last_updated_by',
    SalesAssociate = 'sales_associate_id',
    ResponsibleAssociate = 'responsible_associate_id',
    Incoterm = 'forwarder_job_incoterm_types_id',
    Office = 'office_id',
    PaymentMethod = 'forwarder_job_payment_method_id',
    ServiceLines = 'forwarder_job_service_lines_id',
    Carrier = 'forwarder_job_carrier_id',
    FreightType = 'forwarder_job_freight_types_id',
    GroupageType = 'forwarder_job_groupage_types_id',
    TrafficType = 'forwarder_job_traffic_types_id',
    ShippedVia = 'forwarder_job_shipped_vias_id',
    OperationTypes = 'forwarder_job_operation_types_id',
    Categories = 'forwarder_job_categories_id',
    GoodStatus = 'forwarder_job_good_statuses_id',
    ClientReference = 'client_reference',
    Matter = 'matter',
    Incidences = 'incidences',
    Notes = 'notes',
    BookingNumber = 'booking_number',
    MasterBLNumber = 'master_bl_number',
    HouseBLNumber = 'house_bl_number',
    FileId = 'file_id',
    Orphan = 'orphan',
    ExecutionDateFrom = 'execution_date_from',
    ExecutionDateTo = 'execution_date_to',
    CreatedFrom = 'created_from',
    CreatedTo = 'created_to',
    UpdatedFrom = 'updated_from',
    UpdatedTo = 'updated_to',
    Page = 'page',
    Pagination = 'pagination',
};