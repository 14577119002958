import { computed, ref, watch } from "vue";
import store from "@/store";

interface ProfileDetails {
  id: number;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
let user = computed(() => JSON.parse(localStorage.getItem("user") || "{}"));
setTimeout(function () {
  user = computed(() => JSON.parse(localStorage.getItem("user") || "{}"));
}, 100);
// const user = computed(() => store.getters.userData);
const DocMenuConfig: Record<string, any> = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: document.location.origin + "/media/icons/menu/dashboard02.svg",
        fontIcon: "bi-app-indicator",
      },
      // PROFILE
      {
        svgIcon: document.location.origin + "/media/icons/menu/users02.svg",
        sectionTitle: "profile",
        route: "/profile",
        sub: [
          {
            heading: "accountOverview",
            route: "/user/" + user.value.id + "/overview",
          },
          {
            heading: "settings",
            route: "/user/" + user.value.id + "/settings",
          },
          {
            heading: "layoutBuilder",
            route: "/profile/builder",
            svgIcon:
              document.location.origin +
              "/media/icons/duotone/Interface/Settings-02.svg",
            fontIcon: "bi-layers",
          },
        ],
      },
    ],
  },
  // GENERAL
  {
    pages: [
      // Companies
      {
        svgIcon: document.location.origin + "/media/icons/menu/general02.svg",
        sectionTitle: "general",
        sub: [
          {
            sectionTitle: "companies",
            route: "/companies/index",
            sub: [
              {
                heading: "companiesGroupIndex",
                route: "/companies/companiesgroup/index",
                permission: "companies group: index",
              },
              {
                heading: "companiesIndex",
                route: "/companies/index",
                permission: "companies: index",
              },
              {
                heading: "officesIndex",
                route: "/companies/offices/index",
                permission: "offices: index",
              },
            ],
          },
          {
            svgIcon: document.location.origin + "/media/icons/menu/users02.svg",
            sectionTitle: "user",
            route: "/users",
            sub: [
              {
                heading: "usersgroupIndex",
                route: "/usersgroup",
                permission: "roles: index",
              },
              {
                heading: "usersIndex",
                route: "/users",
                permission: "users: index",
              },
            ],
          },
          {
            svgIcon:
              document.location.origin +
              "/media/icons/duotone/Home/Home-heart.svg",
            sectionTitle: "catalogs",
            route: "/catalogs",
            sub: [
              {
                heading: "generals",
                route: "/generals",
                permission: "addresses: index",
                sub: [
                  {
                    heading: "maritime_file",
                    route: "/maritimefiles",
                    permission: "maritime files: index",
                  },
                  {
                    heading: "bookings",
                    route: "/bookings",
                    permission: "maritime files: index",
                  },
                  {
                    heading: "bills_of_lading",
                    route: "/bills_of_lading",
                    permission: "maritime files: index",
                  },
                ],
              },
              {
                heading: "iBilling",
                route: "/billings",
                permission: "measure unit: index",
              },
              {
                heading: "measures_unit",
                route: "/measures-unit-catalogs",
                permission: "measure unit: index",
              },
              {
                heading: "maritime_file",
                route: "/file",
                permission: "maritime file type: index",
              },
              {
                heading: "imessaging",
                route: "/messaging",
                permission: "ports: index",
              },
              {
                heading: "equipments",
                route: "/equipments-catalogs",
                permission: "equipments: index",
              },
              {
                heading: "regions",
                route: "/regions",
                permission: "location: index",
              },
              {
                heading: "iforwarder",
                route: "/forwarders",
                permission: "ports: index",
              },
              {
                heading: "others",
                route: "/others",
                permission: "ports: index",
              },
            ],
          },
          {
            heading: "manuals",
            route: "/manuals/index"
          }
        ],
      },
    ],
  },
  {
    pages: [
      {
        svgIcon:
          document.location.origin +
          "/media/icons/duotone/Communication/Add-user.svg",
        sectionTitle: "entities",
        sub: [
          {
            heading: "entities",
            route: "/entities",
            permission: "entities: index",
          },
          {
            heading: "contacts",
            route: "/contacts",
            permission: "contacts type: index",
          },
        ],
      },
    ],
  },
  // MARITIME AGENCY

  {
    pages: [
      {
        svgIcon: document.location.origin + "/media/icons/menu/vessels01.svg",
        sectionTitle: "maritime_agency",
        sub: [
          {
            svgIcon:
              document.location.origin +
              "/media/icons/duotone/Home/Home-heart.svg",
            sectionTitle: "maritime_files",
            sub: [
              {
                heading: "maritime_file",
                route: "/maritimefiles",
                permission: "maritime files: index",
              },
              {
                heading: "bookings",
                route: "/bookings",
                permission: "maritime files: index",
              },
              {
                heading: "bills_of_lading",
                route: "/bills_of_lading",
                permission: "maritime files: index",
              },
              {
                heading: "Upload File",
                route: "/upload",
                permission: "maritime files: index",
              },
              {
                heading: "transport_orders",
                route: "/transport_orders",
                permission: "transport order: index",
              },
              {
                heading: "timodeclaration",
                route: "/imo_declarations",
                permission: "transport order: index",
              },
              {
                heading: "tdispatches",
                route: "/dispatches",
                permission: "dispatch: index",
              },
              {
                heading: "tshipsloads",
                route: "/equipments",
                permission: "dispatch: index",
              },
              {
                heading: "ifilecalendar",
                route: "/calendar",
                permission: "maritime files: index",
              },
              {
                heading: "tiPrebilling3",
                route: "/prebillings",
                permission: "dispatch: index",
              },
            ],
          },
          {
            svgIcon:
              document.location.origin +
              "/media/icons/duotone/Home/Home-heart.svg",
            sectionTitle: "billing",
            sub: [
              {
                heading: "billing_management",
                route: "/billing_management",
                permission: "billing: index",
              },
              {
                heading: "bill_register",
                route: "/bill_new",
                permission: "billing: index",
              },
            ],
          },
        ],
      },
    ],
  },
  // FORWARDER
  {
    pages: [
      // Companies
      {
        svgIcon: document.location.origin + "/media/icons/menu/agencies02.svg",
        sectionTitle: "iforwarder",
        sub: [
          {
            heading: "iforwarderjobs",
            route: "/forwarder/offers",
            permission: "forwarder offer: index",
          },
          {
            svgIcon: document.location.origin + "/media/icons/menu/percent.svg",
            sectionTitle: "iforwarder",
            sub: [
              {
                heading: "files",
                route: "/forwarder/job/jobs",
                permission: "forwarder job: index",
              },
              {
                heading: "assing_master",
                route: "/forwarder/Assing-master",
                permission: "forwarder job: index",
              },
              {
                heading: "assing_offer",
                route: "/forwarder/Assign-Offer",
                permission: "forwarder job: index",
              },
            ],
          },
          {
            heading: "forwarder_air_way_bill",
            route: "/forwarder/forwarder_air_way_bill",
            permission: "forwarder job: index",
          },
          {
            svgIcon: document.location.origin + "/media/icons/menu/percent.svg",
            sectionTitle: "titrips",
            sub: [
              {
                heading: "taerialvoyage",
                route: "/forwarder/aerial_voyages",
                permission: "forwarder aerial voyage: index",
              },
              {
                heading: "timaritimetrips",
                route: "/forwarder/maritime_voyages",
                permission: "forwarder maritime voyage: index",
              },
              {
                heading: "tterrestrialvoyage",
                route: "/forwarder/terrestrial_voyages",
                permission: "forwarder terrestrial voyage: index",
              },
            ],
          },
          {
            svgIcon: document.location.origin + "/media/icons/menu/percent.svg",
            sectionTitle: "talerts",
            sub: [
              {
                heading: "tsystemalert",
                route: "/forwarder/system_alerts",
                permission: "system alert: index",
              },
              {
                heading: "tsystemalerttype",
                route: "/forwarder/system_alert_types",
                permission: "system alert type: index",
              },
            ],
          },
        ],
      },
    ],
  },
  // Quotations
  {
    pages: [
      {
        svgIcon: document.location.origin + "/media/icons/menu/percent.svg",
        sectionTitle: "quotationsMenu",
        sub: [
          {
            heading: "tariff",
            route: "/tariff",
            permission: "maritime files: index",
          },
          {
            heading: "tariff_criteria",
            route: "/tariff_criteria",
            permission: "maritime files: index",
          },
          {
            svgIcon: document.location.origin + "/media/icons/menu/percent.svg",
            sectionTitle: "cost",
            sub: [
              {
                heading: "tariff_criteria_level",
                route: "/cost_tariff_criteria_level_list",
                permission: "maritime files: index",
              },
              {
                heading: "tariffs",
                route: "/cost_tariffs_list",
                permission: "maritime files: index",
              },
            ],
          },
          {
            svgIcon: document.location.origin + "/media/icons/menu/percent.svg",
            sectionTitle: "sell",
            sub: [
              {
                heading: "tariff_criteria_level",
                route: "/sell_tariff_criteria_level_list",
                permission: "maritime files: index",
              },
              {
                heading: "tariffs",
                route: "/sell_tariffs_list",
                permission: "maritime files: index",
              },
            ],
          },
          {
            heading: "quotations",
            route: "/quotations",
            permission: "maritime files: index",
          },
          {
            heading: "manage_quotations",
            route: "/manage_quotations",
            permission: "maritime files: index",
          },
          {
            heading: "manage_offers",
            route: "/manage_offers",
            permission: "maritime files: index",
          },
        ],
      },
    ],
  },
  // CRM
  {
    pages: [
      {
        svgIcon: document.location.origin + "/media/icons/menu/people-fill.svg",
        sectionTitle: "crm",
        sub: [
          {
            heading: "mcrm",
            route: "/crm_list",
            permission: "maritime files: index",
          },
        ],
      },
    ],
  },

  {
    pages: [
      {
        sectionTitle: "chat",
        svgIcon: document.location.origin + "/media/icons/menu/chat02.svg",
        fontIcon: "bi-chat-left",
        sub: [
          {
            heading: "messages",
            route: "/messages",
          },
        ],
      },
    ],
  },
  //Maintenance

  {
    pages: [
      {
        sectionTitle: "maintenance",
        svgIcon: document.location.origin + "/media/icons/menu/logistics02.svg",
        sub: [
          {
            heading: "imessaginpanel",
            route: "/messaging-panel",
          },
          {
            heading: "icontainerMovement",
            route: "/container-movements-panel",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
