import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { hasPermission } from "@/core/helpers/functions";
import JwtService from "@/core/services/JwtService";
import TypeEntities from "@/views/catalog/type_entities/TypeEntities.vue";
import { mountOffer } from "@/composables/forwarder/LoadOfferData";
import {
  mountedForwarderJobBig,
  mountedForwarderJobShort,
} from "@/composables/forwarder/LoadForwarderJobData";
import { mountAerialGuide } from "@/composables/forwarder/LoadForwarderAerialVoyageData";
import { mountMaritimeGuide } from "@/composables/forwarder/LoadForwarderMaritimeVoyageData";
import { mountTerrestrialGuide } from "@/composables/forwarder/LoadForwarderTerrestrialVoyageData";
import { mountGeneralPDFs } from "@/composables/LoadGeneralManuals";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      authorization: true,
    },
    children: [
      // dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/dashboard-item/:item_id",
        name: "dashboardItem",
        component: () => import("@/views/catalog/dashboard/Dashboards.vue"),
      },
      {
        path: "/overviewtab",
        name: "overviewtab",
        component: () =>
          import("@/components/maritime-file/tabs/OverviewTab.vue"),
      },

      {
        path: "/CustomsTab",
        name: "CustomsTab",
        component: () =>
          import("@/components/maritime-file/tabs/CustomsTab.vue"),
          
      },
      // end dashboard

      // profile
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Index.vue"),
        children: [
          {
            path: "overview",
            name: "ProfileOverview",
            component: () => import("@/views/profile/Overview.vue"),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () => import("@/views/profile/Settings.vue"),
          },
          {
            path: "builder",
            name: "builder",
            component: () => import("@/views/profile/Builder.vue"),
          },
        ],
      },
      // end profile

      {
        path: "/manuals/index",
        name: "manuals",
        component: () => import("@/views/manuals/Index.vue")
      },

      //users group or roles
      {
        path: "/usersgroup",
        name: "usersgroup",
        component: () => import("@/views/users/UsersGroup.vue"),
        meta: {
          permission: "roles: index",
        },
      },
      //users group or roles

      // users
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/Users.vue"),
        meta: {
          permission: "users: index",
        },
      },
      {
        path: "/user/:user_id",
        name: "userProfile",
        component: () => import("@/views/profile/Index.vue"),
        children: [
          {
            path: "overview",
            name: "user-profile-overview",
            component: () => import("@/views/profile/Overview.vue"),
          },
          {
            path: "settings",
            name: "user-profile-settings",
            component: () => import("@/views/profile/Settings.vue"),
          },
        ],
      },
      // end users

      // companies
      {
        path: "/companies",
        name: "companies",
        component: () => import("@/views/companies/Index.vue"),
        children: [
          {
            path: "index",
            name: "companiesIndex",
            component: () => import("@/views/companies/Companies.vue"),
          },
          {
            path: "create",
            name: "companiesCreate",
            component: () => import("@/views/companies/CompaniesCU.vue"),
          },
          {
            path: "update/:id",
            name: "companiesUpdate",
            component: () => import("@/views/companies/CompaniesCU.vue"),
          },
          {
            path: "companiesgroup",
            name: "companiesGroup",
            component: () => import("@/views/companies/Index.vue"),
            children: [
              {
                path: "index",
                name: "companiesGroupIndex",
                component: () => import("@/views/companies/CompaniesGroup.vue"),
                meta: {
                  permission: "companies group: index",
                },
              },
              {
                path: "create",
                name: "companiesGroupCreate",
                component: () =>
                  import("@/views/companies/CompaniesGroupCU.vue"),
              },
              {
                path: ":id",
                name: "companiesGroupUpdate",
                component: () =>
                  import("@/views/companies/CompaniesGroupCU.vue"),
              },
            ],
          },
          {
            path: "offices",
            name: "offices",
            component: () => import("@/views/companies/Index.vue"),
            children: [
              {
                path: "index",
                name: "officesIndex",
                component: () => import("@/views/companies/Offices.vue"),
                meta: {
                  permission: "offices: index",
                },
              },
              {
                path: "create",
                name: "officesCreate",
                component: () => import("@/views/companies/OfficesCU.vue"),
              },
              {
                path: ":id",
                name: "officeUpdate",
                component: () => import("@/views/companies/OfficesCU.vue"),
              },
            ],
          },
          // end oficces crud
        ],
      },
      // end companies

      //Catalogs
      {
        path: "/catalogs",
        name: "catalogs",
        component: () => import("@/views/catalog/General.vue"),
        meta: {
          permission: "type entities: index",
        },
      },
      {
        path: "/measures-unit-catalogs",
        name: "measuresUnitCatalogs",
        component: () => import("@/views/catalog/MeasureUnits.vue"),
        meta: {
          permission: "measure unit: index",
        },
      },
      {
        path: "/billing_series_types",
        name: "BillingSeriesType",
        component: () =>
          import("@/views/catalog/billing_serie_types/BillingSeriesType.vue"),
        meta: {
          permission: "billing series types: index",
        },
      },
      {
        path: "/billing_series",
        name: "BillingSeries",
        component: () =>
          import("@/views/catalog/billing_serie/BillingSeries.vue"),
        meta: {
          permission: "billing serie: index",
        },
      },
      {
        path: "/generals",
        name: "generals",
        component: () => import("@/views/catalog/Generals.vue"),
        meta: {
          permission: "addresses: index",
        },
      },
      {
        path: "/billings",
        name: "billings",
        component: () => import("@/views/catalog/Billing.vue"),
        meta: {
          permission: "addresses: index",
        },
      },
      {
        path: "/file",
        name: "file",
        component: () => import("@/views/catalog/File.vue"),
        meta: {
          permission: "maritime file type: index",
        },
      },
      {
        path: "/equipments-catalogs",
        name: "equipmentsCatalogs",
        component: () => import("@/views/catalog/Equipments.vue"),
        meta: {
          permission: "equipments: index",
        },
      },
      {
        path: "/equipments-catalogs-create",
        name: "equipmentsCatalogsCreate",
        component: () => import("@/views/catalog/equipment/EquipmentsForm.vue"),
        meta: {
          permission: "equipments: create",
        },
      },
      {
        path: "/equipments-catalogs/:id",
        name: "equipmentsCatalogsEdit",
        component: () => import("@/views/catalog/equipment/EquipmentsForm.vue"),
        meta: {
          permission: "equipments: update",
        },
      },
      {
        path: "/messaging",
        name: "imessaging",
        component: () => import("@/views/catalog/Messaging.vue"),
        meta: {
          permission: "location: index",
        },
      },
      {
        path: "/regions",
        name: "regions",
        component: () => import("@/views/catalog/Regions.vue"),
        meta: {
          permission: "location: index",
        },
      },
      {
        path: "/forwarders",
        name: "forwarders",
        component: () => import("@/views/catalog/Forwarder.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/others",
        name: "others",
        component: () => import("@/views/catalog/Others.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/dashboards",
        name: "dashboards",
        component: () => import("@/views/catalog/dashboard/Dashboard.vue"),
        meta: {
          permission: "type entities: index",
        },
      },
      {
        path: "/type-entities",
        name: "typeEntities",
        component: TypeEntities,
        meta: {
          permission: "type entities: index",
        },
      },
      {
        path: "/entities",
        name: "entities",
        component: () => import("@/views/catalog/entities/Entities.vue"),
        meta: {
          permission: "entities: index",
        },
      },
      {
        path: "/billing_concepts_cost",
        name: "billing_concepts_cost",
        component: () =>
          import("@/views/catalog/billing_concepts/billing_concepts_cost.vue"),
        meta: {
          permission: "billing concept: index",
        },
      },
      {
        path: "/billing_concepts_sale",
        name: "billing_concepts_sale",
        component: () =>
          import("@/views/catalog/billing_concepts/billing_concepts_sale.vue"),
        meta: {
          permission: "billing concept: index",
        },
      },
      {
        path: "/concepts_codes",
        name: "concepts_codes",
        component: () =>
          import("@/views/catalog/concepts_codes/concepts_codes.vue"),
        meta: {
          permission: "billing concept: index",
        },
      },
      {
        path: "/concepts_types",
        name: "concepts_types",
        component: () =>
          import("@/views/catalog/concepts_types/concepts_types.vue"),
        meta: {
          permission: "entities: index",
        },
      },
      {
        path: "/cost_types",
        name: "cost_types",
        component: () => import("@/views/catalog/cost_types/cost_types.vue"),
        meta: {
          permission: "entities: index",
        },
      },
      {
        path: "/entities/:id",
        name: "entity",
        component: () => import("@/views/catalog/entities/Index.vue"),
        meta: {
          permission: "entities: show",
        },
        children: [
          {
            path: "general",
            name: "entities-general",
            component: () =>
              import("@/views/catalog/entities/components/General.vue"),
          },
          {
            path: "addresses",
            name: "entities-addresses",
            component: () =>
              import("@/views/catalog/entities/components/Addresses.vue"),
          },
          {
            path: "contacts",
            name: "entities-contacts",
            component: () =>
              import("@/views/catalog/entities/components/Contacts.vue"),
          },
          {
            path: "payments",
            name: "entities-payments",
            component: () =>
              import("@/views/catalog/entities/components/Payments.vue"),
          },
          {
            path: "documents",
            name: "entities-documents",
            component: () =>
              import("@/views/catalog/entities/components/Documents.vue"),
          },
        ],
      },
      {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/catalog/entities/Contacts.vue"),
      },
      {
        path: "/contact/:id",
        name: "contact",
        component: () => import("@/views/catalog/entities/contact/Index.vue"),
        children: [
          {
            path: "general",
            name: "contact-general",
            component: () =>
              import(
                "@/views/catalog/entities/contact/components/ContactGeneral.vue"
              ),
          },
        ],
      },
      {
        path: "/address_type",
        name: "address_type",
        component: () => import("@/views/catalog/address_type/AddressType.vue"),
        meta: {
          permission: "addresses: index",
        },
      },
      {
        path: "/measures_unit",
        name: "measures_unit",
        component: () => import("@/views/catalog/measure_unit/MeasureUnit.vue"),
        meta: {
          permission: "measure unit: index",
        },
      },
      {
        path: "/measures_type",
        name: "measures_type",
        component: () =>
          import("@/views/catalog/measures_type/MeasuresType.vue"),
        meta: {
          permission: "measures type: index",
        },
      },
      {
        path: "/contacts_type",
        name: "contacts_type",
        component: () =>
          import("@/views/catalog/contacts_type/ContactsType.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/taxes",
        name: "taxes",
        component: () => import("@/views/catalog/taxes/taxes.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/taxes_detail",
        name: "taxes_detail",
        component: () =>
          import("@/views/catalog/taxes_detail/taxes_detail.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/international_codes",
        name: "international_codes",
        component: () =>
          import("@/views/catalog/international_codes/international_codes.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/countries",
        name: "countries",
        component: () => import("@/views/catalog/countries/countries.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/paymentTypes",
        name: "paymentTypes",
        component: () =>
          import("@/views/catalog/paymentTypes/paymentTypes.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/paymentTypesDetails",
        name: "paymentTypesDetails",
        component: () =>
          import("@/views/catalog/paymentTypesDetails/paymentTypesDetails.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/currencies",
        name: "currencies",
        component: () => import("@/views/catalog/currencies/currencies.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/packaging_type",
        name: "packaging_type",
        component: () =>
          import("@/views/catalog/packaging_type/packaging_type.vue"),
        meta: {
          permission: "contacts type: index",
        },
      },
      {
        path: "/identification_type",
        name: "identification_type",
        component: () =>
          import("@/views/catalog/identification_type/identification_type.vue"),
        meta: {
          permission: "identification type: index",
        },
      },
      {
        path: "/transport_order_status",
        name: "transport_order_status",
        component: () =>
          import(
            "@/views/catalog/transport_order_status/TransportOrderStatus.vue"
          ),
        meta: {
          permission: "transport order status: index",
        },
      },
      {
        path: "/transport_order_type",
        name: "transport_order_type",
        component: () =>
          import("@/views/catalog/transport_order_type/TransportOrderType.vue"),
        meta: {
          permission: "transport order type: index",
        },
      },
      {
        path: "/maritime_file_type",
        name: "maritime_file_type",
        component: () =>
          import("@/views/catalog/maritime_file_type/MaritimeFileType.vue"),
        meta: {
          permission: "maritime file type: index",
        },
      },
      {
        path: "/subdivisions",
        name: "subdivisions",
        component: () =>
          import("@/views/catalog/subdivisions/subdivisions.vue"),
        meta: {
          permission: "maritime file type: index",
        },
      },
      {
        path: "/routes_type",
        name: "routes_type",
        component: () => import("@/views/catalog/routes_type/RoutesType.vue"),
        meta: {
          permission: "routes type: index",
        },
      },
      {
        path: "/equipments_type",
        name: "equipments_type",
        component: () =>
          import("@/views/catalog/equipment_type/EquipmentsType.vue"),
        meta: {
          permission: "equipments type: index",
        },
      },
      {
        path: "/equipmentsCatalogList",
        name: "equipmentsCatalogList",
        component: () => import("@/views/catalog/equipment/Equipments.vue"),
        meta: {
          permission: "equipments: index",
        },
      },
      {
        path: "/equipments_subtype",
        name: "equipments_subtype",
        component: () =>
          import("@/views/catalog/equipment_subtypes/EquipmentsSubType.vue"),
        meta: {
          permission: "equipments subtype: index",
        },
      },
      {
        path: "/equipment_group",
        name: "equipment_group",
        component: () =>
          import("@/views/catalog/equipment_groups/EquipmentGroup.vue"),
        meta: {
          permission: "equipment group: index",
        },
      },
      {
        path: "/customs_tariff_code",
        name: "customs_tariff_code",
        component: () =>
          import("@/views/catalog/customs_tariff_code/CustomsTariffCode.vue"),
        meta: {
          permission: "equipments subtype: index",
        },
      },
      {
        path: "/registered_equipment",
        name: "registered_equipment",
        component: () =>
          import(
            "@/views/catalog/registered_equipment/RegisteredEquipment.vue"
          ),
        meta: {
          permission: "equipments subtype: index",
        },
      },
      {
        path: "/sectors_type",
        name: "sectors_type",
        component: () => import("@/views/catalog/sectors_type/SectorsType.vue"),
        meta: {
          permission: "sectors type: index",
        },
      },
      {
        path: "/maritime_file_operations",
        name: "maritime_file_operations",
        component: () =>
          import(
            "@/views/catalog/maritime_file_operations/MaritimeFileOperations.vue"
          ),
        meta: {
          permission: "maritime file operations: index",
        },
      },
      {
        path: "/measure_unit_type",
        name: "measure_unit_type",
        component: () =>
          import("@/views/catalog/measure_unit_type/MeasureUnitType.vue"),
        meta: {
          permission: "measure unit type: index",
        },
      },
      {
        path: "/media_type",
        name: "media_type",
        component: () => import("@/views/catalog/media_type/MediaType.vue"),
        meta: {
          permission: "media type: index",
        },
      },
      {
        path: "/participant_type",
        name: "participant_type",
        component: () =>
          import("@/views/catalog/participants_type/ParticipantsType.vue"),
        meta: {
          permission: "participant type: index",
        },
      },
      {
        path: "/location_type",
        name: "location_type",
        component: () =>
          import("@/views/catalog/location_type/LocationType.vue"),
        meta: {
          permission: "location: index",
        },
      },
      {
        path: "/distances",
        name: "idistances",
        component: () => import("@/views/catalog/kilometers/Kilometers.vue"),
        meta: {
          permission: "location: index",
        },
      },
      {
        path: "/goods_type",
        name: "goods_type",
        component: () => import("@/views/catalog/goods_type/GoodsType.vue"),
        meta: {
          permission: "goods type: index",
        },
      },
      {
        path: "/smpurposes",
        name: "smpurposes",
        component: () => import("@/views/catalog/smpurposes/SMPurposes.vue"),
        meta: {
          permission: "smpurposes: index",
        },
      },
      {
        path: "/smrequirements",
        name: "smrequirements",
        component: () =>
          import("@/views/catalog/smrequirements/SMRequirements.vue"),
        meta: {
          permission: "smrequirements: index",
        },
      },
      {
        path: "/smoperation_type",
        name: "smoperation_type",
        component: () =>
          import("@/views/catalog/smoperation_types/SMOperationTypes.vue"),
        meta: {
          permission: "smoperation type: index",
        },
      },
      {
        path: "/docks",
        name: "docks",
        component: () => import("@/views/catalog/docks/Docks.vue"),
        meta: {
          permission: "docks: index",
        },
      },
      {
        path: "/vessels",
        name: "vessels",
        component: () => import("@/views/catalog/vessel/Vessels.vue"),
        meta: {
          permission: "vessel: index",
        },
      },
      {
        path: "/maritime_services",
        name: "maritime_services",
        component: () =>
          import("@/views/catalog/maritime_services/MaritimeServices.vue"),
        meta: {
          permission: "maritime services: index",
        },
      },
      {
        path: "/car_brands",
        name: "car_brands",
        component: () => import("@/views/catalog/car_brands/CarBrands.vue"),
        meta: {
          permission: "car brands: index",
        },
      },
      {
        path: "/forwarder_job_incoterm_type",
        name: "forwarder_job_incoterm_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_incoterm_type/ForwarderJobIncotermType.vue"
          ),
        meta: {
          permission: "forwarder job incoterm type: index",
        },
      },
      {
        path: "/forwarder_job_status",
        name: "forwarder_job_status",
        component: () =>
          import("@/views/catalog/forwarder_job_status/ForwarderJobStatus.vue"),
        meta: {
          permission: "forwarder job status: index",
        },
      },
      {
        path: "/forwarder_job_traffic_type",
        name: "forwarder_job_traffic_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_traffic_type/ForwarderJobTrafficType.vue"
          ),
        meta: {
          permission: "forwarder job traffic type: index",
        },
      },
      {
        path: "/forwarder_job_offer_type",
        name: "forwarder_job_offer_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_offer_type/ForwarderJobOfferType.vue"
          ),
        meta: {
          permission: "forwarder offer type: index",
        },
      },
      {
        path: "/forwarder_job_shipped_via",
        name: "forwarder_job_shipped_via",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_shipped_via/ForwarderJobShippedVia.vue"
          ),
        meta: {
          permission: "forwarder job shipped via: index",
        },
      },
      {
        path: "/forwarder_job_operation_type",
        name: "forwarder_job_operation_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_operation_type/ForwarderJobOperationType.vue"
          ),
        meta: {
          permission: "forwarder job operation type: index",
        },
      },
      {
        path: "/forwarder_job_language",
        name: "forwarder_job_language",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_languages/ForwarderJobLanguage.vue"
          ),
        meta: {
          permission: "forwarder job language: index",
        },
      },
      {
        path: "/forwarder_job_route_type",
        name: "forwarder_job_route_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_route_type/ForwarderJobRouteType.vue"
          ),
        meta: {
          permission: "forwarder job route type: index",
        },
      },
      {
        path: "/forwarder_job_category",
        name: "forwarder_job_category",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_categories/ForwarderJobCategory.vue"
          ),
        meta: {
          permission: "forwarder job category: index",
        },
      },
      {
        path: "/forwarder_job_freight_type",
        name: "forwarder_job_freight_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_freight_type/ForwarderJobFreightType.vue"
          ),
        meta: {
          permission: "forwarder job freight type: index",
        },
      },
      {
        path: "/forwarder_job_good_status",
        name: "forwarder_job_good_status",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_good_status/ForwarderJobGoodStatus.vue"
          ),
        meta: {
          permission: "forwarder job good status: index",
        },
      },
      {
        path: "/forwarder_job_groupage_type",
        name: "forwarder_job_groupage_type",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_groupage_type/ForwarderJobGroupageType.vue"
          ),
        meta: {
          permission: "forwarder job groupage type: index",
        },
      },
      {
        path: "/forwarder_job_payment_method",
        name: "forwarder_job_payment_method",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_payment_method/ForwarderJobPaymentMethod.vue"
          ),
        meta: {
          permission: "forwarder job payment method: index",
        },
      },
      {
        path: "/forwarder_job_service_lines",
        name: "forwarder_job_service_lines",
        component: () =>
          import(
            "@/views/catalog/forwarder_job_service_lines/ForwarderJobServiceLines.vue"
          ),
        meta: {
          permission: "forwarder job service line: index",
        },
      },
      {
        path: "/forwarder_job_offer_observation_templates",
        name: "forwarder_job_offer_observation_templates",
        component: () =>
          import(
            "@/views/catalog/forwarder_offer_observation_templates/ForwarderOfferObservationTemplates.vue"
          ),
        meta: {
          permission: "forwarder offer observation template: index",
        },
      },
      {
        path: "/forwarder_offer_status",
        name: "forwarder_offer_status",
        component: () =>
          import(
            "@/views/catalog/forwarder_offer_status/ForwarderOfferStatus.vue"
          ),
        meta: {
          permission: "forwarder offer status: index",
        },
      },
      {
        path: "/forwarder_air_way_bill",
        name: "forwarder_air_way_bill",
        component: () =>
          import(
            "@/views/catalog/forwarder_air_way_bill/ForwarderAirWayBill.vue"
          ),
        meta: {
          permission: "forwarder air way bill: index",
        },
      },
      {
        path: "/forwarder_air_freight_company",
        name: "forwarder_air_freight_company",
        component: () =>
          import(
            "@/views/catalog/forwarder_air_freight_company/ForwarderAirFreightCompany.vue"
          ),
        meta: {
          permission: "forwarder air freight company: index",
        },
      },
      {
        path: "/ports",
        name: "ports",
        component: () => import("@/views/catalog/ports/Ports.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/email-templates",
        name: "emailtemplates",
        component: () =>
          import("@/views/catalog/email_template/EmailTemplates.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/templates",
        name: "templates",
        component: () => import("@/views/catalog/template/Templates.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/vars",
        name: "vars",
        component: () => import("@/views/catalog/vars/Vars.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/messaging_groupage_type",
        name: "imessaging_groupage_type",
        component: () =>
          import("@/views/catalog/groupage_type/GroupageType.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/messaging_cargo_type",
        name: "imessaging_iftdgn_cargo_type",
        component: () => import("@/views/catalog/cargo_type/CargoType.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/messaging_operation_instruction",
        name: "imessaging_operation_instruction",
        component: () =>
          import(
            "@/views/catalog/operation_instruction/OperationInstruction.vue"
          ),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/messaging_transport_method",
        name: "imessaging_transport_method",
        component: () =>
          import("@/views/catalog/transport_method/TransportMethod.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      //End catalogs

      //facturation
      {
        path: "/billing_management",
        name: "billing_management",
        component: () => import("@/views/facturation_data/billing.vue"),
        meta: {
          permission: "billing: index",
        },
      },
      {
        path: "/bill_new",
        name: "bill_new",
        component: () => import("@/views/facturation_data/bill_register.vue"),
        meta: {
          permission: "billing: index",
        },
      },
      {
        path: "/bill_register/:id",
        name: "bill_register",
        component: () => import("@/views/facturation_data/bill_register.vue"),
        meta: {
          permission: "billing: index",
        },
      },
      //end facturation

      //Maritime file
      {
        path: "/maritimefiles",
        name: "maritimeFiles",
        component: () => import("@/views/maritime_file/MaritimeFiles.vue"),
        meta: {
          permission: "maritime files: index",
        },
      },
      {
        path: "/maritimefile",
        name: "maritimefileCreate",
        component: () => import("@/views/maritime_file/MaritimeFileForm.vue"),
        meta: {
          permission: "maritime files: create",
        },
      },
      {
        path: "/maritimefile/:id",
        name: "maritimefileEdit",
        component: () => import("@/views/maritime_file/MaritimeFileForm.vue"),
        meta: {
          permission: "maritime files: update",
        },
      },
      {
        path: "/bookings",
        name: "mf_bookings",
        component: () => import("@/views/maritime_file/Bookings.vue"),
        meta: {
          permission: "bookings: index",
        },
      },
      {
        path: "/bills_of_lading",
        name: "mf_bl",
        component: () => import("@/views/maritime_file/BillsOfLading.vue"),
        meta: {
          permission: "bills of lading: index",
        },
      },
      {
        path: "/upload",
        name: "mf_upload",
        component: () => import("@/views/maritime_file/upload.vue"),
        meta: {
          permission: "mf has file: index",
        },
      },
      {
        path: "/transport_orders",
        name: "mf_transport_orders",
        component: () => import("@/views/maritime_file/TransportOrders.vue"),
        meta: {
          permission: "transport order: index",
        },
      },
      {
        path: "/imo_declarations",
        name: "imo_declarations",
        component: () => import("@/views/maritime_file/ImoDeclarations.vue"),
        meta: {
          permission: "transport order: index",
        },
      },
      {
        path: "/dispatches",
        name: "dispatches",
        component: () => import("@/views/maritime_file/Dispatches.vue"),
        meta: {
          permission: "dispatch: index",
        },
      },
      {
        path: "/equipments",
        name: "equipments",
        component: () => import("@/views/maritime_file/Equipments.vue"),
        meta: {
          permission: "equipments: index",
        },
      },
      {
        path: "/calendar",
        name: "calendar",
        component: () => import("@/views/maritime_file/Calendar.vue"),
        meta: {
          permission: "maritime files: index",
        },
      },
      {
        path: "/prebillings",
        name: "prebillings",
        component: () => import("@/views/maritime_file/Prebillings.vue"),
        meta: {
          permission: "equipments: index",
        },
      },
      {
        path: "/prebillings",
        name: "prebillingsCreate",
        component: () => import("@/views/maritime_file/PrebillingForm.vue"),
        meta: {
          permission: "equipments: create",
        },
      },
      {
        path: "/prebillings/:id",
        name: "prebillingsEdit",
        component: () => import("@/views/maritime_file/PrebillingForm.vue"),
        meta: {
          permission: "equipments: update",
        },
      },
      
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/chat/Chat.vue"),
      },
      {
        path: "/messages",
        name: "messages",
        component: () => import("@/views/chat/Chat.vue"),
      },
      {
        path: "/forwarder/offer",
        name: "offer",
        component: () => import("@/views/forwarder/OfferForm.vue"),
        meta: {
          permission: "forwarder offer: create",
        },
      },
      {
        path: "/forwarder/offer/:id",
        name: "OfferEdit",
        component: () => import("@/views/forwarder/OfferForm.vue"),
        meta: {
          permission: "forwarder offer: update",
        },
      },
      {
        path: "/forwarder/offers",
        name: "toffers",
        component: () => import("@/views/forwarder/Offer.vue"),
        meta: {
          permission: "forwarder offer: index",
        },
      },
      {
        path: "/forwarder/Assing-master",
        name: "master",
        component: () => import("@/views/forwarder/ForwarderMaster.vue"),
      },
      {
        path: "/forwarder/Assign-Offer",
        name: "AssignOffer",
        component: () => import("@/views/forwarder/ForwarderAssignOffer.vue"),
      },
      {
        path: "/forwarder/forwarder_air_way_bill",
        name: "Forwarder Air Way Bill",
        component: () =>
          import(
            "@/views/catalog/forwarder_air_way_bill/ForwarderAirWayBill.vue"
          ),
      },
      {
        path: "/forwarder/job_short",
        name: "JobShort",
        component: () => import("@/views/forwarder/ForwarderJobShortForm.vue"),
      },
      {
        path: "/forwarder/job_short/:id",
        name: "JobShortEdit",
        component: () => import("@/views/forwarder/ForwarderJobShortForm.vue"),
      },
      {
        path: "/forwarder/job/jobs",
        name: "forwarderJobs",
        component: () => import("@/views/forwarder/ForwarderJobs.vue"),
        meta: {
          permission: "forwarder job: index",
        },
      },
      {
        path: "/forwarder/confirm-offer",
        name: "ForwarderConfirmOffer",
        component: () => import("@/views/forwarder/ForwarderConfirmOffer.vue"),
        meta: {
          permission: "forwarder job: index",
        },
      },
      {
        path: "/forwarder/job/job",
        name: "forwarderJobsForm",
        component: () => import("@/views/forwarder/ForwarderJobsForm.vue"),
        meta: {
          permission: "forwarder job: create",
        },
      },
      {
        path: "/forwarder/job/job/:id/:short?",
        name: "forwarderJobsFormEdit",
        component: () => import("@/views/forwarder/ForwarderJobsForm.vue"),
        meta: {
          permission: "forwarder job: update",
        },
      },
      {
        path: "/forwarder/aerial_voyage",
        name: "aerialVoyage",
        component: () => import("@/views/forwarder/AerialVoyageForm.vue"),
        meta: {
          permission: "forwarder aerial voyage: create",
        },
      },
      {
        path: "/forwarder/aerial_voyage/:id",
        name: "aerialVoyageEdit",
        component: () => import("@/views/forwarder/AerialVoyageForm.vue"),
        meta: {
          permission: "forwarder aerial voyage: update",
        },
      },
      {
        path: "/forwarder/aerial_voyages",
        name: "aerialVoyages",
        component: () => import("@/views/forwarder/AerialVoyage.vue"),
        meta: {
          permission: "forwarder aerial voyage: index",
        },
      },
      {
        path: "/forwarder/maritime_voyage",
        name: "maritimeVoyage",
        component: () => import("@/views/forwarder/MaritimeVoyageForm.vue"),
        meta: {
          permission: "forwarder maritime voyage: create",
        },
      },
      {
        path: "/forwarder/maritime_voyage/:id",
        name: "maritimeVoyageEdit",
        component: () => import("@/views/forwarder/MaritimeVoyageForm.vue"),
        meta: {
          permission: "forwarder maritime voyage: update",
        },
      },
      {
        path: "/forwarder/maritime_voyages",
        name: "maritimeVoyages",
        component: () => import("@/views/forwarder/MaritimeVoyage.vue"),
        meta: {
          permission: "forwarder maritime voyage: index",
        },
      },
      {
        path: "/forwarder/terrestrial_voyage",
        name: "terrestrialVoyage",
        component: () => import("@/views/forwarder/TerrestrialVoyageForm.vue"),
        meta: {
          permission: "forwarder terrestrial voyage: create",
        },
      },
      {
        path: "/forwarder/terrestrial_voyage/:id",
        name: "terrestrialVoyageEdit",
        component: () => import("@/views/forwarder/TerrestrialVoyageForm.vue"),
        meta: {
          permission: "forwarder terrestrial voyage: update",
        },
      },
      {
        path: "/forwarder/terrestrial_voyages",
        name: "terrestrialVoyages",
        component: () => import("@/views/forwarder/TerrestrialVoyage.vue"),
        meta: {
          permission: "forwarder terrestrial voyage: index",
        },
      },
      {
        path: "/forwarder/system_alerts",
        name: "systemAlerts",
        component: () => import("@/views/system_alert/SystemAlert.vue"),
        meta: {
          permission: "system alert: index",
        },
      },
      {
        path: "/forwarder/system_alert",
        name: "systemAlert",
        component: () => import("@/views/system_alert/SystemAlertForm.vue"),
        meta: {
          permission: "system alert: create",
        },
      },
      {
        path: "/forwarder/system_alert/:id",
        name: "systemAlertEdit",
        component: () => import("@/views/system_alert/SystemAlertForm.vue"),
        meta: {
          permission: "system alert: update",
        },
      },
      {
        path: "/forwarder/system_alert_types",
        name: "systemAlertTypes",
        component: () => import("@/views/system_alert/SystemAlertType.vue"),
        meta: {
          permission: "system alert type: index",
        },
      },
      {
        path: "/forwarder/system_alert_type",
        name: "systemAlertType",
        component: () => import("@/views/system_alert/SystemAlertTypeForm.vue"),
        meta: {
          permission: "system alert type: create",
        },
      },
      {
        path: "/forwarder/system_alert_type/:id",
        name: "systemAlertTypeEdit",
        component: () => import("@/views/system_alert/SystemAlertTypeForm.vue"),
        meta: {
          permission: "system alert type: update",
        },
      },
      //CRM
      {
        path: "/crm_list",
        name: "crm",
        component: () => import("@/views/crm/CRM.vue"),
        meta: {
          permission: "actions: index",
        },
      },
      {
        path: "/crm",
        name: "crmCreate",
        component: () => import("@/views/crm/CRMForm.vue"),
        meta: {
          permission: "actions: create",
        },
      },
      {
        path: "/crm/:id",
        name: "crmEdit",
        component: () => import("@/views/crm/CRMForm.vue"),
        meta: {
          permission: "actions: update",
        },
      },
      //Messaging Panel
      {
        path: "/messaging-panel",
        name: "imessaginpanel",
        component: () =>
          import("@/views/maintenance/messaging_panel/MessagingPanel.vue"),
      },
      //Container Movements Panel
      {
        path: "/container-movements-panel",
        name: "icontainerMovement",
        component: () =>
          import(
            "@/views/maintenance/container_movements_panel/ContainerMovementsPanel.vue"
          ),
      },
      //Quotations
      {
        path: "/tariff",
        name: "tariff",
        component: () => import("@/views/quotations/Rate.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/tariff_criteria",
        name: "tariff_criteria",
        component: () => import("@/views/quotations/RateCriteria.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/cost_tariff_criteria_level_list",
        name: "costTariffCriteriaLevel",
        component: () => import("@/views/quotations/RateCostCriteriaLevel.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/cost_tariff_criteria_level",
        name: "costTariffCriteriaLevelCreate",
        component: () =>
          import("@/views/quotations/RateCostCriteriaLevelForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/cost_tariff_criteria_level/:id",
        name: "costTariffCriteriaLevelEdit",
        component: () =>
          import("@/views/quotations/RateCostCriteriaLevelForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/sell_tariff_criteria_level_list",
        name: "sellTariffCriteriaLevel",
        component: () => import("@/views/quotations/RateSaleCriteriaLevel.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/sell_tariff_criteria_level",
        name: "sellTariffCriteriaLevelCreate",
        component: () =>
          import("@/views/quotations/RateSaleCriteriaLevelForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/sell_tariff_criteria_level/:id",
        name: "sellTariffCriteriaLevelEdit",
        component: () =>
          import("@/views/quotations/RateSaleCriteriaLevelForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/cost_tariffs_list",
        name: "costTariffs",
        component: () => import("@/views/quotations/TariffCostOficial.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/cost_tariffs",
        name: "costTariffsCreate",
        component: () => import("@/views/quotations/TariffCostOficialForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/cost_tariffs/:id",
        name: "costTariffsEdit",
        component: () => import("@/views/quotations/TariffCostOficialForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/sell_tariffs_list",
        name: "sellTariffs",
        component: () => import("@/views/quotations/TariffSaleOficial.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/sell_tariffs",
        name: "sellTariffsCreate",
        component: () => import("@/views/quotations/TariffSaleOficialForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/sell_tariffs/:id",
        name: "sellTariffsEdit",
        component: () => import("@/views/quotations/TariffSaleOficialForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/quotations",
        name: "quotations",
        component: () => import("@/views/quotations/Quotations.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/manage_quotations",
        name: "manage_quotations",
        component: () => import("@/views/quotations/ManageQuotations.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/manage_quotations/:id",
        name: "manage_quotations_edit",
        component: () => import("@/views/quotations/QuotationDetails.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/manage_offers",
        name: "manage_offers",
        component: () => import("@/views/quotations/ManageOffers.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/create_offer",
        name: "create_offer",
        component: () => import("@/views/quotations/OfferForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
      {
        path: "/manage_offers/:id",
        name: "manage_offers_edit",
        component: () => import("@/views/quotations/OfferForm.vue"),
        meta: {
          permission: "ports: index",
        },
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to, from) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  const denied = !hasPermission(to.meta.permission as string);
  if (from.path !== "/" && to.meta.permission && denied) {
    router.push({ name: "403" });
  }

  if (!JwtService.getToken() && to.meta.authorization) {
    store.commit("logOut");
    router.push("sign-in");
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  if (to.name === "forwarderJobsFormEdit" && to.params.id) {
    const id = Array.isArray(to.params.id) ? to.params.id[0] : to.params.id;

    if (!store.getters.ForwarderOfferItem) {
      if (to.params.short) {
        await mountedForwarderJobShort(store, parseInt(id));
      } else {
        await mountedForwarderJobBig(store, parseInt(id));
      }
    }
  }

  if (to.name === "OfferEdit") {
    await mountOffer(
      store,
      typeof to.params.id === "string"
        ? parseInt(to.params.id)
        : parseInt(to.params.id[0])
    );
  }

  if (to.name === "aerialVoyageEdit") {
    await mountAerialGuide(
      store,
      typeof to.params.id === "string"
        ? parseInt(to.params.id)
        : parseInt(to.params.id[0])
    );
  }

  if (to.name === "maritimeVoyageEdit") {
    await mountMaritimeGuide(
      store,
      typeof to.params.id === "string"
        ? parseInt(to.params.id)
        : parseInt(to.params.id[0])
    );
  }

  if (to.name === "terrestrialVoyageEdit") {
    await mountTerrestrialGuide(
      store,
      typeof to.params.id === "string"
        ? parseInt(to.params.id)
        : parseInt(to.params.id[0])
    );
  }

  if (to.name === "manuals") {
    await mountGeneralPDFs(store)
  }
});

export default router;
