import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { integer } from "@vee-validate/rules";
interface Messages {
  type?: string;
  image?: string;
  name?: string;
  time?: string;
  text?: string;
}
interface NewMessages {
  id?: number;
  message?: string;
  user_from?: number;
  user_to?: number;
  created_at?: string;
}
interface Conversations {
  id?: number;
  name?: string;
  text?: string;
  is_online?: number;
  created_at?: string;
}
interface activeConversations {
  id?: number;
  name?: string;
  is_online?: number;
  created_at?: string;
}
@Module
export default class ChatModule extends VuexModule {
  messageList: Messages[] = [];
  conversationList: Conversations[] = [];
  NewMessageList: NewMessages[] = [];
  activeConversation: activeConversations = {};

  get Messages(): Messages[] {
    return this.messageList;
  }
  get Conversations(): Conversations[] {
    return this.conversationList;
  }

  get ActiveConversations(): activeConversations {
    return this.activeConversation;
  }
  get newMessages(): NewMessages[] {
    return this.NewMessageList;
  }

  @Mutation
  ["setMessageList"](payload) {
    this.activeConversation = payload;
  }

  @Mutation
  ["clearMessageList"](payload) {
    this.messageList = [];
  }

  @Mutation
  ["setActiveConversation"](payload) {
    this.activeConversation = payload;
  }

  @Mutation
  ["setNewMessageList"](payload) {
    this.NewMessageList.push(payload);
  }

  @Mutation
  ["addMessageToList"](payload) {
    this.messageList.push(payload);
  }

  @Mutation
  ["addConversationToList"](payload) {
    this.conversationList.push(payload);
  }

  @Mutation
  ["clearConversation"]() {
    this.conversationList = [];
  }

  @Mutation
  ["updateConversationStatus"](payload) {
    const conversation = this.conversationList.find(
      (c) => c.id === payload.conversation
    ) as any;
    if (conversation) {
      conversation.updated_at = new Date();
      conversation.is_new = true;
    }
    this.conversationList.sort(function (a: any, b: any) {
      const a_date = new Date(a.updated_at) as any;
      const b_date = new Date(b.updated_at) as any;
      return b_date - a_date;
    });
    // this.conversationList = [];
  }
}
