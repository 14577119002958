
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "TaxesDetailsSelect",
  props: {
    tax_detail_id: {
      type: Number,
      default: undefined,
    },
    tax_id: {
      type: Number,
      default: undefined,
    },
    office_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "custom-select w-100 p-0 chevron",
    },
  },
  emits: ["update:element_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const taxesData = computed(() => store.getters.GetTaxesDetails);
    const inputTaxesDetails = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const inputTaxesType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isTaxRequired = (value) => {
      if (props.required && !value) {
        return t("rtaxes");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "taxes",
      isTaxRequired
    );

    const getTaxesDetails = async () => {
      if (inputTaxesDetails.value.options.length < 5) {
        const { data } = await ApiService.query(`/api/taxes_detail/lists`, {
          params: {
            page: 1,
            per_page: 10,
            offices: props.office_id ? [props.office_id] : [],
            taxes: props.tax_id ? [props.tax_id] : [],
          },
        });
        inputTaxesDetails.value.options = data.taxes_details;
        inputTaxesDetails.value.list = data.taxes_details;
      }
    };
    const selectTaxes = (query) => {
      if (query !== "") {
        inputTaxesDetails.value.loading = true;
        setTimeout(() => {
          inputTaxesDetails.value.loading = false;
          inputTaxesDetails.value.options = inputTaxesDetails.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputTaxesDetails.value.options.length) {
            ApiService.query(`/api/taxes_detail`, {
              params: {
                page: 1,
                name: query,
                per_page: 10,
                offices: props.office_id ? [props.office_id] : [],
                taxes: props.tax_id ? [props.tax_id] : [],
              },
            }).then(({ data }) => {
              store.commit("setTaxesDetails", data.taxes_details);
              inputTaxesDetails.value.list = data.taxes_details;
              inputTaxesDetails.value.options = data.taxes_details;
            });
          }
        }, 200);
      } else {
        inputTaxesDetails.value.options = taxesData.value;
      }
    };

    watch(
      () => props.tax_id,
      (first) => {
        element_id.value = first;
      }
    );

    watch(
      () => taxesData.value,
      (first) => {
        inputTaxesDetails.value.list = first;
        inputTaxesDetails.value.options = first;
      }
    );

    onMounted(() => {
      element_id.value = props.tax_detail_id;
      if (props.mounted) {
        getTaxesDetails();
      } else {
        inputTaxesDetails.value.list = taxesData.value;
        inputTaxesDetails.value.options = taxesData.value;
      }
    });

    return {
      element_id,
      inputTaxesDetails,
      inputTaxesType,
      errorMessage,
      selectTaxes,
      getTaxesDetails,
    };
  },
};
