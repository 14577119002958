import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
interface FileStoredData {
  id?: number;
  check?: boolean;
  name?: string;
  type?: string;
  size?: string;
  user?: string;
  date?: string;
  path?: string;
  category?: string;
}

@Module
export default class FilesModule extends VuexModule {
  file_stored_data = [] as any;

  get FileStoredD() {
    return this.file_stored_data;
  }

  @Mutation
  ["setFileStored"](payload) {
    this.file_stored_data = [...payload];
  }

  @Mutation
  ["addFileStoredData"](file) {
    const file_split = file.filename.split(".");
    this.file_stored_data.push({
      id: file.id,
      check: false,
      name: file.filename,
      type: file_split[1].toLowerCase(),
      size: "-",
      user: "-",
      date: file.created_at,
      path: file.url,
      category:
        file.type == "E" ? "Entrada" : file.type == "S" ? "Salida" : "Reporte",
    });
  }

  @Mutation
  ["deleteFileStored"](key) {
    this.file_stored_data.splice(key, 1);
  }

  @Mutation
  ["clearFileStored"]() {
    const _this = this as any;
    this.file_stored_data.forEach(function (value: any, key: any) {
      _this.file_stored_data.splice(key, 1);
    });
  }
}
