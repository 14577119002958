import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { any } from "pusher-js/types/src/core/utils/collections";

interface ContactData {
  key?: number;
  id?: number;
  name?: string;
  gender?: string;
  born_date?: string;
  identification?: string;
  contact_type_id?: number;
  is_select?: number;
  entities?: number;
  communications?: [];
}

interface EDataModel {
  key?: number;
  id?: number;
  type?: string;
  office_id?: number;
  payment_days?: [];
  applies?: [];
  payment_type_detail_id?: number;
}

interface EDataPaymentTypeDetail {
  edataId?: number;
  id?: number;
  payment_type_detail_id?: number;
  name?: string;
  payment_number?: number;
  frequency?: number;
  first_payment_deferral?: number;
}

interface EntityResponse {
  id?: number;
  name?: string;
  legal_name?: string;
  identification_type_id?: number;
  identification?: string;
  website?: string;
  entity_types?: number[];
  sectors?: [];
  offices?: [];
  contacts?: [];
  addresses?: [];
}

interface EntityOperatorResponse {
  id?: number;
  name?: string;
  legal_name?: string;
  identification_type_id?: number;
  identification?: string;
  website?: string;
  entity_types?: number[];
  sectors?: [];
  offices?: [];
  contacts?: [];
  addresses?: [];
}

interface Media {
  keyContact?: number;
  contact_id?: number;
  media_id?: number;
  value?: string;
  remark?: string;
  id?: string;
}

interface EntityType {
  id?: number;
  name?: string;
  description?: string;
}

interface AccountingPlan {
  id?: number;
  name?: string;
  currency_id?: number;
}

interface Sector {
  id?: number;
  name?: string;
  description?: string;
}

interface BillingSerie {
  id?: number;
  code?: string;
  type?: string;
  observations?: string;
}

interface Office {
  id?: number;
  name?: string;
  remarks?: string;
}

interface Communication {
  id?: number;
  media_id?: number;
  office_id?: number;
  entity_id?: number;
  contact_id?: number;
  value?: string;
  remark?: string;
  media?: Media;
}

interface Identification {
  id?: number;
  identification_type_id?: number;
  value?: string;
}

interface Addresses {
  id?: number;
  company_id?: number;
  office_id?: number;
  entity_id?: number;
  contact_id?: number;
  street?: string;
  number?: string;
  floor_number?: string;
  door?: string;
  location_id?: number;
  zip_code?: string;
  latitude?: string;
  longitude?: string;
  address_types?: number[];
}

@Module
export default class EntitiesModule extends VuexModule {
  temp_bill = [] as any;
  showLoading = true;
  contactId: undefined;
  eDataPaymentTypeDetailID: undefined;
  addMediaSetup: undefined;
  eData: EDataModel[] = [];
  acPlan: AccountingPlan[] = [];
  eDataPaymentTypeDetail: EDataPaymentTypeDetail[] = [];
  contactsList: ContactData[] = [];
  mediaList: Media[] = [];
  communicationsList: Communication[] = [];
  identificationsList: Identification[] = [];
  addressesList: Addresses[] = [];
  entityTypesList: EntityType[] = [];
  officesList: Office[] = [];
  allOffices: Office[] = [];
  sectorList: Sector[] = [];
  billingSerieList: BillingSerie[] = [];
  entityResponseData: EntityResponse = {};
  syncData: any[] = [];
  syncFlag: any[] = [];
  entitiesResponseData: EntityResponse[] = [];
  EntityOperatorResponseData: EntityOperatorResponse[] = [];
  contactResponseData: ContactData = {};
  currentMF = {} as any;

  get tempBilling() {
    return this.temp_bill;
  }
  get getCurrentMF() {
    return this.currentMF;
  }
  get isLoading() {
    return this.showLoading;
  }
  get mediaSetup() {
    return this.addMediaSetup;
  }
  get EconomicData(): EDataModel[] {
    return this.eData;
  }
  get EconomicDataPTD(): EDataPaymentTypeDetail[] {
    return this.eDataPaymentTypeDetail;
  }
  get EconomicDataPTDID() {
    return this.eDataPaymentTypeDetailID;
  }
  get ContactSelected() {
    return this.contactId;
  }
  get EntityData(): EntityResponse {
    return this.entityResponseData;
  }
  get Sync(): any[] {
    return this.syncData;
  }
  get SyncFlag(): any[] {
    return this.syncFlag;
  }
  get EntitiesData(): EntityResponse[] {
    return this.entitiesResponseData;
  }
  get Contacts(): ContactData[] {
    return this.contactsList;
  }
  get ContactData(): ContactData {
    return this.contactResponseData;
  }
  get Medias(): Media[] {
    return this.mediaList;
  }
  get Communications(): Communication[] {
    return this.communicationsList;
  }
  get Identifications(): Identification[] {
    return this.identificationsList;
  }
  get Addresses(): Addresses[] {
    return this.addressesList;
  }
  get EntityTypes(): EntityType[] {
    return this.entityTypesList;
  }
  get Offices(): Office[] {
    return this.officesList;
  }
  get AllOffices(): Office[] {
    return this.allOffices;
  }
  get acPlans(): AccountingPlan[] {
    return this.acPlan;
  }
  get Sectors(): Sector[] {
    return this.sectorList;
  }
  get BillingSeries(): BillingSerie[] {
    return this.billingSerieList;
  }

  @Mutation
  ["setTempBill"](payload) {
    this.temp_bill = payload;
  }

  @Mutation
  ["clearTempBill"]() {
    this.temp_bill = [];
  }
  @Mutation
  ["clearcurrentMF"]() {
    this.currentMF = {};
  }

  @Mutation
  ["setcurrentMF"](payload) {
    this.currentMF = payload;
  }

  @Mutation
  ["addTempBill"](payload: any) {
    this.temp_bill.push(payload);
  }

  @Mutation
  ["setSyncData"](payload) {
    this.syncData = payload;
  }

  @Mutation
  ["addSyncData"](payload) {
    this.syncData.push(payload);
  }

  @Mutation
  ["removeSyncData"](index) {
    this.syncData.splice(index, 1);
  }

  @Mutation
  ["setSyncFlag"](payload) {
    this.syncFlag = payload;
  }

  @Mutation
  ["addSyncFlag"](payload) {
    this.syncFlag.push(payload);
  }

  @Mutation
  ["removeSyncFlag"](index) {
    this.syncFlag.splice(index, 1);
  }

  @Mutation
  ["setEntityData"](payload) {
    this.entityResponseData = payload;
  }

  @Mutation
  ["setMediaSetup"](payload) {
    this.addMediaSetup = payload;
  }

  @Mutation
  ["setEData"](payload) {
    this.eData = payload;
  }

  @Mutation
  ["setacPlan"](payload) {
    this.acPlan.push(payload);
  }

  @Mutation
  ["addEData"](payload) {
    this.eData.push(payload);
  }

  @Mutation
  ["setLoading"](payload) {
    this.showLoading = payload;
  }

  @Mutation
  ["restoreEData"](payload) {
    // this.eData = payload;
    this.eData.splice(0, this.eData.length);
  }

  @Mutation
  ["setEDataPaymentTypeDetails"](payload) {
    this.eDataPaymentTypeDetail.push(payload);
  }

  @Mutation
  ["removeEDataPaymentTypeDetails"]() {
    this.eDataPaymentTypeDetail = [];
  }

  @Mutation
  ["setEDataPaymentTypeDetailsID"](payload) {
    this.eDataPaymentTypeDetailID = payload;
  }

  @Mutation
  ["setContactSelected"](id) {
    this.contactId = id;
  }

  @Mutation
  ["setEntities"](payload) {
    this.entitiesResponseData = payload;
  }

  @Mutation
  ["setEntityOperator"](payload) {
    this.entitiesResponseData = payload;
  }

  @Mutation
  ["createEntities"](payload) {
    this.entitiesResponseData.push({
      id: 0,
      name: "",
      legal_name: "",
      identification_type_id: 1,
      identification: "",
      website: "",
    });
  }

  @Mutation
  ["setOffices"](payload) {
    this.officesList = payload;
  }
  @Mutation
  ["setAllOffices"](payload) {
    this.allOffices = payload;
  }

  @Mutation
  ["emptyOffices"]() {
    this.officesList = [];
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ["createOffice"](payload) {
    this.officesList.push({
      id: payload.id ? payload.id : undefined,
      name: payload.name ? payload.name : "",
      remarks: payload.remarks ? payload.remarks : "",
    });
  }

  @Mutation
  ["setEntityTypes"](payload) {
    this.entityTypesList = payload;
  }

  @Mutation
  ["createEntityTypes"](payload) {
    this.entityTypesList.push({
      id: payload.id ? payload.id : undefined,
      name: payload.name ? payload.name : "",
      description: payload.description ? payload.description : "",
    });
  }

  @Mutation
  ["setSectors"](payload) {
    this.sectorList = payload;
  }

  @Mutation
  ["createSector"](payload) {
    this.sectorList.push({
      id: payload.id ? payload.id : undefined,
      name: payload.name ? payload.name : "",
      description: payload.description ? payload.description : "",
    });
  }

  @Mutation
  ["setBillingSerie"](payload) {
    this.billingSerieList = payload;
  }

  @Mutation
  ["createBillingSerie"](payload) {
    this.billingSerieList.push({
      id: payload.id ? payload.id : undefined,
      code: payload.code ? payload.code : "",
      type: payload.type ? payload.type : "",
      observations: payload.observations ? payload.observations : "",
    });
  }

  @Mutation
  ["setContact"](payload) {
    this.contactResponseData = payload;
  }

  @Mutation
  ["setContacts"](payload) {
    this.contactsList = payload;
  }

  @Mutation
  ["createContact"](payload) {
    this.contactsList.push({
      key: payload.key,
      id: undefined,
      name: "",
      gender: "M",
      born_date: "",
      identification: "",
      contact_type_id: payload.contact_type_id,
      entities: payload.entities,
      communications: [],
    });
  }

  @Mutation
  ["updateContact"](payload) {
    this.contactsList[payload.pos] = payload.contact;
  }

  @Mutation
  ["setMedias"](payload) {
    this.mediaList = payload;
  }

  @Mutation
  ["clearMedias"](payload) {
    this.mediaList = [];
  }
  @Mutation
  ["clearContacts"](payload) {
    this.contactsList = [];
  }

  @Mutation
  ["createMedias"](payload) {
    this.mediaList.push({
      keyContact: payload.keyContact,
      contact_id: payload.contact_id,
      media_id: payload.media_id,
      value: payload.value,
      remark: payload.remark,
      id: payload.id ? payload.id : undefined,
    });
  }

  @Mutation
  ["setCommunications"](payload) {
    this.communicationsList = payload;
  }

  @Mutation
  ["createCommunications"](payload) {
    this.communicationsList.push({
      id: undefined,
      media_id: payload.media_id,
      office_id: payload.office_id,
      entity_id: payload.entity_id,
      contact_id: payload.contact_id,
      value: payload.value,
      remark: payload.remark,
      media: payload.media,
    });
  }

  @Mutation
  ["addCommunication"](payload) {
    this.communicationsList.push(payload);
  }

  @Mutation
  ["removeCommunication"](index) {
    this.communicationsList.splice(index, 1);
  }

  @Mutation
  ["setIdentification"](payload) {
    this.identificationsList = payload;
  }
  @Mutation
  ["addIdentification"](payload) {
    this.identificationsList.push(payload);
  }

  @Mutation
  ["removeIdentification"](index) {
    this.identificationsList.splice(index, 1);
  }

  @Mutation
  ["createIdentification"](payload) {
    this.identificationsList.push({
      id: undefined,
      identification_type_id: payload.identification_type_id,
      value: payload.value,
    });
  }

  @Mutation
  ["setAddresses"](payload) {
    this.addressesList = payload;
  }

  @Mutation
  ["emptyAddresses"]() {
    this.addressesList = [];
  }

  @Mutation
  ["createAddresses"](payload) {
    this.addressesList.push({
      id: undefined,
      company_id: payload.company_id,
      office_id: payload.office_id,
      entity_id: payload.entity_id,
      contact_id: payload.contact_id,
      location_id: payload.location_id,
      street: "",
      number: "",
      floor_number: "",
      door: "",
      zip_code: "",
      latitude: "",
      longitude: "",
      address_types: [],
    });
  }
}
