import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

@Module
export default class LoadElementsModule extends VuexModule {
  loadData = true;
  totalFiles: number | undefined = undefined;
  filesList: any[] = [];
  totalBookings: number | undefined = undefined;
  bookingsList: any[] = [];
  bookingsSelectList: any[] = [];
  totalBL: number | undefined = undefined;
  blList: any[] = [];
  blsList: any[] = [];
  fileTypeList: any[] = [];
  officeList: any[] = [];
  portList: any[] = [];
  vesselList: any[] = [];
  entitiesList: any[] = [];
  shipConsigneeList: any[] = [];
  customerList: any[] = [];
  contactList: any[] = [];
  maritimeServiceList: any[] = [];
  AddressList: any[] = [];
  operationList: any[] = [];
  smoperationList: any[] = [];

  requirementList: any[] = [];
  purposeList: any[] = [];
  docksList: any[] = [];
  goodsList: any[] = [];
  locationList: any[] = [];
  containerMovementsType: any[] = [];
  tablesList: any[] = [];
  columnList: any[] = [];
  tripTypeList: any[] = [];
  maritimeVoyageList: any[] = [];
  routeTypeList: any[] = [];
  participantTypeList: any[] = [];
  equipmentList: any[] = [];
  registerEquipmentList: any[] = [];
  shiploadList: any[] = [];
  packagingTypeList: any[] = [];
  equipmentTypeList: any[] = [];
  measureTypeList: any[] = [];
  measureUnitList: any[] = [];
  customsTariffCodeList: any[] = [];
  dangerousGoodsTypeList: any[] = [];

  billingConceptsList: any[] = [];
  billingConceptCodesList: any[] = [];
  paymentTypeList: any[] = [];
  freigthPaymentTypeList: any[] = [];
  costTypeList: any[] = [] as any;
  currencyList: any[] = [] as any;
  taxesList: any[] = [] as any;

  crmActionsList: any[] = [];
  totalActions: number | undefined = undefined;
  actionTypeList: any[] = [];
  actionStatusList: any[] = [];
  actionReasonList: any[] = [];
  actionResultList: any[] = [];
  participantsList: any[] = [];
  usersList: any[] = [];
  flagsList: any[] = [];

  selectedTable = false;
  selectedCard = false;
  loadingStatus = false;
  providerList = [] as any;
  shipOwnerList = [] as any;
  payments_details = [] as any;
  serieList: any[] = [];
  get Load(): boolean {
    return this.loadData;
  }

  get TotalFiles(): any {
    return this.totalFiles;
  }
  get LoadingStatus(): any {
    return this.loadingStatus;
  }

  get Files(): any[] {
    return this.filesList;
  }
  get PaymentDetails(): any[] {
    return this.payments_details;
  }

  get TotalBookings(): any {
    return this.totalBookings;
  }

  get BookingsView(): any[] {
    return this.bookingsList;
  }

  get BookingsSelect(): any[] {
    return this.bookingsSelectList;
  }

  get TotalBl(): any {
    return this.totalBL;
  }

  get BLView(): any[] {
    return this.blList;
  }

  get Bls(): any[] {
    return this.blsList;
  }

  get FileType(): any[] {
    return this.fileTypeList;
  }

  get Office(): any[] {
    return this.officeList;
  }

  get Port(): any[] {
    return this.portList;
  }

  get Vessel(): any[] {
    return this.vesselList;
  }

  get Entity(): any[] {
    return this.entitiesList;
  }

  get ShipConsignee(): any[] {
    return this.shipConsigneeList;
  }

  get Customer(): any[] {
    return this.customerList;
  }

  get Contact(): any[] {
    return this.contactList;
  }

  get MaritimeService(): any[] {
    return this.maritimeServiceList;
  }
  get Address(): any[] {
    return this.AddressList;
  }

  get Operation(): any[] {
    return this.operationList;
  }
  get SMOperation(): any[] {
    return this.smoperationList;
  }

  get Requirements(): any[] {
    return this.requirementList;
  }

  get Purposes(): any[] {
    return this.purposeList;
  }
  get Docks(): any[] {
    return this.docksList;
  }

  get GoodTypes(): any[] {
    return this.goodsList;
  }

  get Locations(): any[] {
    return this.locationList;
  }

  get Tables(): any[] {
    return this.tablesList;
  }

  get Columns(): any[] {
    return this.columnList;
  }

  get TripType(): any[] {
    return this.tripTypeList;
  }

  get MaritimeVoyage(): any[] {
    return this.maritimeVoyageList;
  }

  get RouteType(): any[] {
    return this.routeTypeList;
  }

  get ParticipantType(): any[] {
    return this.participantTypeList;
  }
  get ContainerMovementsType(): any[] {
    return this.containerMovementsType;
  }

  get EquipmentsMF(): any[] {
    return this.equipmentList;
  }

  get RegisterEquipments(): any[] {
    return this.registerEquipmentList;
  }

  get Shipload(): any[] {
    return this.shiploadList;
  }

  get PackagingType(): any[] {
    return this.packagingTypeList;
  }

  get EquipmentType(): any[] {
    return this.equipmentTypeList;
  }

  get MeasureType(): any[] {
    return this.measureTypeList;
  }

  get MeasureUnit(): any[] {
    return this.measureUnitList;
  }

  get CustomsTariffCode(): any[] {
    return this.customsTariffCodeList;
  }

  get DangerousGoodsType(): any[] {
    return this.dangerousGoodsTypeList;
  }

  get BillingConcepts(): any[] {
    return this.billingConceptsList;
  }

  get BillingConceptCodes(): any[] {
    return this.billingConceptCodesList;
  }

  get PaymentTypes(): any[] {
    return this.paymentTypeList;
  }

  get FreightPaymentTypes(): any[] {
    return this.freigthPaymentTypeList;
  }

  get CostTypes(): any[] {
    return this.costTypeList;
  }

  get ResetSelectedTable(): boolean {
    return this.selectedTable;
  }
  get ResetSelectedCard(): boolean {
    return this.selectedCard;
  }

  get Currencies(): any[] {
    return this.currencyList;
  }

  get Taxes(): any[] {
    return this.taxesList;
  }

  get CRMActions(): any[] {
    return this.crmActionsList;
  }

  get TotalActions(): any {
    return this.totalActions;
  }

  get ActionType(): any {
    return this.actionTypeList;
  }

  get ActionStatus(): any {
    return this.actionStatusList;
  }

  get ActionReason(): any {
    return this.actionReasonList;
  }

  get ActionResult(): any {
    return this.actionResultList;
  }

  get ParticipantsCRM(): any {
    return this.participantsList;
  }

  get UsersCRM(): any {
    return this.usersList;
  }

  get Flags(): any {
    return this.flagsList;
  }
  get Providers(): any[] {
    return this.providerList;
  }
  get Customers(): any[] {
    return this.customerList;
  }
  get ShipOwners(): any[] {
    return this.shipOwnerList;
  }

  get Series(): any[] {
    return this.serieList;
  }

  @Mutation
  ["setLoadData"](payload) {
    this.loadData = payload;
  }

  @Mutation
  ["setTotalFiles"](payload) {
    this.totalFiles = payload;
  }
  @Mutation
  ["setProvider"](payload) {
    this.providerList = payload;
  }
  @Mutation
  ["addProvider"](payload) {
    if (payload && Array.isArray(this.providerList)) {
      const index = this.providerList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.providerList.push(payload);
      }
    }
  }
  @Mutation
  ["clearProvider"]() {
    this.providerList = [];
  }

  @Mutation
  ["setShipowner"](payload) {
    this.shipOwnerList = payload;
  }
  @Mutation
  ["addShipowner"](payload) {
    if (payload && Array.isArray(this.shipOwnerList)) {
      const index = this.shipOwnerList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.shipOwnerList.push(payload);
      }
    }
  }
  @Mutation
  ["clearShipowner"]() {
    this.shipOwnerList = [];
  }
  @Mutation
  ["clearCustomer"]() {
    this.customerList = [];
  }
  @Mutation
  ["setResetSelectedTable"](payload) {
    this.selectedTable = payload;
  }
  @Mutation
  ["setResetSelectedCard"](payload) {
    this.selectedCard = payload;
  }

  @Mutation
  ["setFiles"](payload) {
    this.filesList = payload;
  }

  @Mutation
  ["setTotalBookings"](payload) {
    this.totalBookings = payload;
  }

  @Mutation
  ["setBookings"](payload) {
    this.bookingsList = payload;
  }

  @Mutation
  ["setBookingsSelect"](payload) {
    this.bookingsSelectList = payload;
  }

  @Mutation
  ["setLoadingStatus"](payload) {
    this.loadingStatus = payload;
  }

  @Mutation
  ["setTotalBL"](payload) {
    this.totalBL = payload;
  }

  @Mutation
  ["setBL"](payload) {
    this.blList = payload;
  }

  @Mutation
  ["setBls"](payload) {
    this.blsList = payload;
  }

  @Mutation
  ["clearBls"]() {
    this.blsList = [];
  }

  @Mutation
  ["setFileType"](payload) {
    this.fileTypeList = payload;
  }

  @Mutation
  ["addFileType"](payload) {
    if (payload && Array.isArray(this.fileTypeList)) {
      const index = this.fileTypeList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.fileTypeList.push(payload);
      }
    }
  }

  @Mutation
  ["addPaymentDetail"](payload) {
    if (payload && Array.isArray(this.payments_details)) {
      const index = this.payments_details.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.payments_details.push(payload);
      }
    }
  }
  @Mutation
  ["setPaymentDetails"](payload) {
    this.payments_details = payload;
  }

  @Mutation
  ["clearPaymentDetails"]() {
    this.payments_details = [];
  }

  @Mutation
  ["addOffice"](payload) {
    if (payload && Array.isArray(this.officeList)) {
      const index = this.officeList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.officeList.push(payload);
      }
    }
  }

  @Mutation
  ["setOffice"](payload) {
    this.officeList = payload
  }

  @Mutation
  ["clearOffice"]() {
    this.officeList = [];
  }

  @Mutation
  ["setPort"](payload) {
    this.portList = payload;
  }

  @Mutation
  ["addPort"](payload) {
    const index = this.portList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.portList.push(payload);
    }
  }

  @Mutation
  ["setVessel"](payload) {
    this.vesselList = payload;
  }

  @Mutation
  ["addVessel"](payload) {
    const index = this.vesselList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.vesselList.push(payload);
    }
  }

  @Mutation
  ["setEntity"](payload) {
    this.entitiesList = payload;
  }

  @Mutation
  ["addEntity"](payload) {
    const index = this.entitiesList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.entitiesList.push(payload);
    }
  }

  @Mutation
  ["setshipConsignee"](payload) {
    this.shipConsigneeList = payload;
  }

  @Mutation
  ["addshipConsignee"](payload) {
    const index = this.shipConsigneeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.shipConsigneeList.push(payload);
    }
  }

  @Mutation
  ["setCustomer"](payload) {
    this.customerList = payload;
  }

  @Mutation
  ["addCustomer"](payload) {
    const index = this.customerList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.customerList.push(payload);
    }
  }

  @Mutation
  ["setContact"](payload) {
    this.contactList = payload;
  }

  @Mutation
  ["addContact"](payload) {
    const index = this.contactList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.contactList.push(payload);
    }
  }

  @Mutation
  ["setMaritimeService"](payload) {
    this.maritimeServiceList = payload;
  }

  @Mutation
  ["setAddress"](payload) {
    this.AddressList = payload;
  }

  @Mutation
  ["addMaritimeService"](payload) {
    const index = this.maritimeServiceList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.maritimeServiceList.push(payload);
    }
  }

  @Mutation
  ["setOperation"](payload) {
    this.operationList = payload;
  }

  @Mutation
  ["addOperation"](payload) {
    const index = this.operationList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.operationList.push(payload);
    }
  }

  @Mutation
  ["setSMOperation"](payload) {
    this.smoperationList = payload;
  }

  @Mutation
  ["addSMOperation"](payload) {
    const index = this.smoperationList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.smoperationList.push(payload);
    }
  }

  @Mutation
  ["setRequirements"](payload) {
    this.requirementList = payload;
  }

  @Mutation
  ["addRequirements"](payload) {
    const index = this.requirementList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.requirementList.push(payload);
    }
  }

  @Mutation
  ["setPurposes"](payload) {
    this.purposeList = payload;
  }

  @Mutation
  ["addPurposes"](payload) {
    const index = this.purposeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.purposeList.push(payload);
    }
  }

  @Mutation
  ["setDocks"](payload) {
    this.docksList = payload;
  }

  @Mutation
  ["addDocks"](payload) {
    const index = this.docksList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.docksList.push(payload);
    }
  }

  @Mutation
  ["setGoods"](payload) {
    this.goodsList = payload;
  }

  @Mutation
  ["addGoods"](payload) {
    const index = this.goodsList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.goodsList.push(payload);
    }
  }

  @Mutation
  ["setLocation"](payload) {
    this.locationList = payload;
  }
  @Mutation
  ["setContainerMovementTypes"](payload) {
    this.containerMovementsType = payload;
  }

  @Mutation
  ["addLocation"](payload) {
    const index = this.locationList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.locationList.push(payload);
    }
  }
  @Mutation
  ["clearLocation"]() {
    this.locationList = [];
  }

  @Mutation
  ["setTables"](payload) {
    this.tablesList.push(payload);
  }

  @Mutation
  ["setColumns"](payload) {
    this.columnList.push(payload);
  }

  @Mutation
  ["setTripType"](payload) {
    this.tripTypeList = payload;
  }

  @Mutation
  ["addTripType"](payload) {
    const index = this.tripTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.tripTypeList.push(payload);
    }
  }

  @Mutation
  ["clearTripType"]([]) {
    this.tripTypeList = [];
  }

  @Mutation
  ["clearMaritimeVoyage"]([]) {
    this.maritimeVoyageList = [];
  }

  @Mutation
  ["setMaritimeVoyage"](payload) {
    this.maritimeVoyageList = payload;
  }

  @Mutation
  ["addMaritimeVoyage"](payload) {
    const index = this.maritimeVoyageList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.maritimeVoyageList.push(payload);
    }
  }

  @Mutation
  ["setRouteType"](payload) {
    this.routeTypeList = payload;
  }

  @Mutation
  ["addRouteType"](payload) {
    const index = this.routeTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.routeTypeList.push(payload);
    }
  }

  @Mutation
  ["setParticipantType"](payload) {
    this.participantTypeList = payload;
  }

  @Mutation
  ["addParticipantType"](payload) {
    const index = this.participantTypeList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.participantTypeList.push(payload);
    }
  }

  @Mutation
  ["setEquipment"](payload) {
    this.equipmentList = payload;
  }

  @Mutation
  ["addEquipment"](payload: any) {
    if (this.equipmentList.length) {
      const index = this.equipmentList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.equipmentList.push(payload);
      }
    } else {
      this.equipmentList.push(payload);
    }
  }

  @Mutation
  ["setShipload"](payload) {
    this.shiploadList = payload;
  }

  @Mutation
  ["setRegisterEquipment"](payload) {
    this.registerEquipmentList = payload;
  }

  @Mutation
  ["addRegisterEquipment"](payload) {
    const index = this.registerEquipmentList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.registerEquipmentList.push(payload);
    }
  }

  @Mutation
  ["setPackagingType"](payload) {
    this.packagingTypeList =
      "packaging_types" in payload ? payload.packaging_types : payload;
  }

  @Mutation
  ["addPackagingType"](payload) {
    const index = this.packagingTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.packagingTypeList.push(payload);
    }
  }

  @Mutation
  ["setEquipmentType"](payload) {
    this.equipmentTypeList = payload;
  }

  @Mutation
  ["addEquipmentType"](payload: any) {
    const index = this.equipmentTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.equipmentTypeList.push(payload);
    }
  }

  @Mutation
  ["setMeasureType"](payload) {
    this.measureTypeList = payload;
  }

  @Mutation
  ["addMeasureType"](payload: any) {
    const index = this.measureTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.measureTypeList.push(payload);
    }
  }

  @Mutation
  ["setMeasureUnit"](payload) {
    this.measureUnitList =
      "measure_units" in payload ? payload.measure_units : payload;
  }

  @Mutation
  ["addMeasureUnit"](payload) {
    const index = this.measureUnitList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.measureUnitList.push(payload);
    }
  }

  @Mutation
  ["setCustomsTariffCode"](payload) {
    this.customsTariffCodeList = payload;
  }

  @Mutation
  ["addCustomsTariffCode"](payload) {
    const index = this.customsTariffCodeList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.customsTariffCodeList.push(payload);
    }
  }

  @Mutation
  ["setDangerousGoodsType"](payload) {
    this.dangerousGoodsTypeList = payload;
  }

  @Mutation
  ["addDangerousGoodsType"](payload) {
    const index = this.dangerousGoodsTypeList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.dangerousGoodsTypeList.push(payload);
    }
  }

  @Mutation
  ["setPaymentTypes"](payload) {
    this.paymentTypeList = payload;
  }

  @Mutation
  ["addPaymentType"](payload) {
    const index = this.paymentTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.paymentTypeList.push(payload);
    }
  }

  @Mutation
  ["setFreigthPaymentTypes"](payload) {
    this.freigthPaymentTypeList = payload;
  }

  @Mutation
  ["addFreightPaymentType"](payload) {
    const index = this.freigthPaymentTypeList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.freigthPaymentTypeList.push(payload);
    }
  }

  @Mutation
  ["setBillingConcepts"](payload) {
    this.billingConceptsList = payload;
  }

  @Mutation
  ["addBillingConcepts"](payload) {
    const index = this.billingConceptsList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.billingConceptsList.push(payload);
    }
  }

  @Mutation
  ["clearBillingConcepts"]() {
    this.billingConceptsList = [];
  }

  @Mutation
  ["setBillingConceptCodes"](payload) {
    this.billingConceptCodesList = payload;
  }

  @Mutation
  ["addBillingConceptCodes"](payload) {
    const index = this.billingConceptCodesList.findIndex(
      (x) => x.id === payload.id
    );
    if (index === -1) {
      this.billingConceptCodesList.push(payload);
    }
  }

  @Mutation
  ["clearBillingConceptCodes"]() {
    this.billingConceptCodesList = [];
  }

  @Mutation
  ["setCostType"](payload) {
    this.costTypeList = payload;
  }

  @Mutation
  ["addCostType"](payload) {
    const index = this.costTypeList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.costTypeList.push(payload);
    }
  }

  @Mutation
  ["setCurrency"](payload) {
    this.currencyList = payload;
  }
  @Mutation
  ["clearCurrency"]() {
    this.currencyList = [];
  }

  @Mutation
  ["addCurrency"](payload) {
    if (payload !== null || payload != undefined) {
      const index = this.currencyList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.currencyList.push(payload);
      }
    }
  }

  @Mutation
  ["setTaxes"](payload) {
    this.taxesList = payload;
  }
  @Mutation
  ["clearTaxes"]() {
    this.taxesList = [];
  }

  @Mutation
  ["addTaxes"](payload) {
    const index = this.taxesList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.taxesList.push(payload);
    }
  }

  @Mutation
  ["setCrmActions"](payload) {
    this.crmActionsList = payload;
  }

  @Mutation
  ["addCrmActions"](payload) {
    const index = this.crmActionsList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.crmActionsList.push(payload);
    }
  }

  @Mutation
  ["setTotalActions"](payload) {
    this.totalActions = payload;
  }

  @Mutation
  ["setActionType"](payload) {
    this.actionTypeList = payload;
  }

  @Mutation
  ["addActionType"](payload) {
    if (payload && Array.isArray(this.actionTypeList)) {
      const index = this.actionTypeList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.actionTypeList.push(payload);
      }
    }
  }

  @Mutation
  ["setActionStatus"](payload) {
    this.actionStatusList = payload;
  }

  @Mutation
  ["addActionStatus"](payload) {
    if (payload && Array.isArray(this.actionStatusList)) {
      const index = this.actionStatusList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.actionStatusList.push(payload);
      }
    }
  }

  @Mutation
  ["setActionReason"](payload) {
    this.actionReasonList = payload;
  }

  @Mutation
  ["addActionReason"](payload) {
    if (payload && Array.isArray(this.actionReasonList)) {
      const index = this.actionReasonList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.actionReasonList.push(payload);
      }
    }
  }

  @Mutation
  ["setActionResult"](payload) {
    this.actionResultList = payload;
  }

  @Mutation
  ["addActionResult"](payload) {
    if (payload && Array.isArray(this.actionResultList)) {
      const index = this.actionResultList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.actionResultList.push(payload);
      }
    }
  }

  @Mutation
  ["setParticipants"](payload) {
    this.participantsList = payload;
  }

  @Mutation
  ["addParticipants"](payload) {
    if (payload && Array.isArray(this.participantsList)) {
      const index = this.participantsList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.participantsList.push(payload);
      }
    }
  }

  @Mutation
  ["setUser"](payload) {
    this.usersList = payload;
  }

  @Mutation
  ["addUser"](payload) {
    if (payload && Array.isArray(this.usersList)) {
      const index = this.usersList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.usersList.push(payload);
      }
    }
  }

  @Mutation
  ["setFlags"](payload) {
    this.flagsList = payload;
  }

  @Mutation
  ["setSerie"](payload) {
    this.serieList = payload;
  }
  @Mutation
  ["addSerie"](payload) {
    if (payload && Array.isArray(this.serieList)) {
      const index = this.serieList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.serieList.push(payload);
      }
    }
  }
  @Mutation
  ["clearSerie"]() {
    this.serieList = [];
  }

  @Mutation
  ["resetLoadElementsModule"]() {
    this.loadData = true;
    this.totalFiles = undefined;
    this.filesList = [];
    this.totalBookings = undefined;
    this.bookingsList = [];
    this.totalBL = undefined;
    this.blList = [];
    this.blsList = [];
    this.fileTypeList = [];
    this.officeList = [];
    this.portList = [];
    this.vesselList = [];
    this.entitiesList = [];
    this.shipConsigneeList = [];
    this.customerList = [];
    this.contactList = [];
    this.maritimeServiceList = [];
    this.operationList = [];
    this.smoperationList = [];
    this.requirementList = [];
    this.purposeList = [];
    this.docksList = [];
    this.goodsList = [];
    this.locationList = [];
    this.tablesList = [];
    this.columnList = [];
    this.tripTypeList = [];
    this.maritimeVoyageList = [];
    this.routeTypeList = [];
    this.participantTypeList = [];
    this.equipmentList = [];
    this.shiploadList = [];
    this.registerEquipmentList = [];
    this.packagingTypeList = [];
    this.equipmentTypeList = [];
    this.measureTypeList = [];
    this.measureUnitList = [];
    this.customsTariffCodeList = [];
    this.dangerousGoodsTypeList = [];
    this.billingConceptsList = [];
    this.billingConceptCodesList = [];
    this.paymentTypeList = [];
    this.freigthPaymentTypeList = [];
    this.costTypeList = [];
    this.currencyList = [];
    this.taxesList = [];
    this.crmActionsList = [];
    this.totalActions = undefined;
    this.actionTypeList = [];
    this.actionStatusList = [];
    this.actionReasonList = [];
    this.actionResultList = [];
    this.participantsList = [];
    this.usersList = [];
    this.flagsList = [];
    this.providerList = [];
    this.customerList = [];
    this.shipOwnerList = [];
  }

  @Action
  [Actions.LOAD_DATA]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/catalogs/loaddata")
        .then(({ data }) => {
          this.context.commit(
            "setPort",
            data.ports.map((x) => x.port)
          );
          this.context.commit(
            "setOffice",
            data.offices.map((x) => x.office)
          );
          this.context.commit(
            "setVessel",
            data.vessels.map((x) => x.vessel)
          );
          this.context.commit(
            "setshipConsignee",
            data.ship_consignees.map((x) => x.ship_consignee)
          );
          this.context.commit(
            "setCustomer",
            data.customers.map((x) => x.customer)
          );
          this.context.commit(
            "setContact",
            data.contacts.map((x) => x.contact)
          );
          this.context.commit("setOffice", data.offices);
          this.context.commit("setFileType", data.file_types);
          this.context.commit("setEntity", data.entities);
          this.context.commit("setAddress", data.address);
          this.context.commit("setMaritimeService", data.services);
          this.context.commit("setOperation", data.maritime_operations);
          this.context.commit("setSMOperation", data.operations);
          this.context.commit("setRequirements", data.ship_mooring_req);
          this.context.commit("setPurposes", data.ship_mooring_pur);
          this.context.commit("setGoods", data.goods_type);
          this.context.commit("setLocation", data.locations);
          this.context.commit("setTripType", data.trip_types);
          this.context.commit("setMaritimeVoyage", data.voyages);
          this.context.commit("setRouteType", data.route_types);
          this.context.commit("setParticipantType", data.participant_types);
          this.context.commit("setEquipment", data.equipments);
          this.context.commit("setRegisterEquipment", data.register_equipments);
          this.context.commit("setPackagingType", data.packaging_types);
          this.context.commit("setMeasureType", data.measure_types);
          this.context.commit("setMeasureUnit", data.measure_units);
          this.context.commit("setCustomsTariffCode", data.cutoms_tariff);
          this.context.commit(
            "setDangerousGoodsType",
            data.dangerous_goods_type
          );
          this.context.commit("setEquipmentType", data.equipment_types);
          this.context.commit("setPaymentTypes", data.payment_types);
          this.context.commit("setBillingConcepts", data.billing_concepts);
          this.context.commit("setCostType", data.cost_types);
          this.context.commit("setCurrency", data.currencies);
          this.context.commit("setTaxes", data.taxes);
          this.context.commit(
            "setFreigthPaymentTypes",
            data.freigth_payment_types
          );
          this.context.commit("setCrmActions", data.crm);
          this.context.commit("setTotalActions", data.crm_total);
          this.context.commit("setActionType", data.action_type);
          this.context.commit("setActionStatus", data.action_status);
          this.context.commit("setActionReason", data.action_reason);
          this.context.commit("setActionResult", data.action_result);
          this.context.commit("setUser", data.users);
          this.context.commit("setFlags", data.flags);
          this.context.commit(
            "setMaritimeFileCodes",
            data.codes.map((c) => c.code)
          );
          this.context.commit("setCurrency", data.currencies);
          this.context.commit("setContainerMovementTypes", data.movement_types);
          this.context.commit("setLoadData", false);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_MF_DATA]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/api/catalogs/mfData")
        .then(({ data }) => {
          this.context.commit("setFileType", data.file_types);
          this.context.commit("setRequirements", data.requirements);
          this.context.commit("setPurposes", data.purposes);
          this.context.commit(
            "setMaritimeFileCodes",
            data.codes.map((c) => c.code)
          );
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
