import { createI18n } from "vue-i18n/index";
import { GeneralES } from "./translations/es/General";
import { GeneralEN } from "./translations/en/General";

const oldTranslations = {
  //inglés
  en: {
    go: "Go",
    language: "Language",
    selectlang: "Select language",
    selectview: "Select view type",
    selecnotification: "Select notifications time",
    selectwindows: "Select the number of windows to display",
    selectactivitieslogs: "Select the number of activities logs to display",
    selectpageloader: "Select page loader type",
    selectitemsperpage: "Select items per page",
    displaypageloader: "Display page loader",
    itemsperpage: "Items per page",
    viewtype: "View type",
    en: "English",
    es: "Spanish",
    // menu
    home: "Home",
    dashboard: "Dashboard",
    layoutBuilder: "Settings Layout",
    usersgroupIndex: "Users group",
    user: "Users",
    iuser: "User",
    isalesassociate: "sales associate",
    iresponsibleassociate: "Responsible associate",
    profile: "My Profile",
    profileOverview: "Overview",
    profileDetails: "Profile Details",
    editProfile: "Edit Profile",
    maritimeFileOverview: "Overview",
    tobservations: "Observations",
    account: "Account",
    iun: "UN",
    iAssessableWeight: "Assesable Weight",
    accountOverview: "Overview",
    settings: "Settings",
    permissionsIndex: "Permissions",
    usersIndex: "Users management",
    userCreate: "New user",
    userUpdate: "Edit user",
    companies: "Companies",
    companiesIndex: "Companies management",
    companiesCreate: "New company",
    companiesUpdate: "Edit company",
    companiesGroup: "Group of companies",
    companiesGroupIndex: "Management group of companies",
    companiesGroupCreate: "New group of companies",
    companiesGroupUpdate: "Edit group of companies",
    offices: "Offices",
    officesIndex: "Offices management",
    officesCreate: "New office",
    officesUpdate: "Edit office",
    entityCreate: "New Entity",
    entityUpdate: "Edit Entity",
    contactCreate: "New Contact",
    contactUpdate: "Edit Contact",
    maritime_agency: "Maritime agency",
    crm: "CRM",
    maritime_files: "Maritime files",
    transport_orders: "Transport Orders",
    filters: "Filters",
    maritime_file: "Files",
    files: "Files",
    maritime_trips: "Trips",
    ot_type: "Order Type",
    ot_edifact_status: "Status",
    ot_edipath_status: "Doc Status",
    maritime_trip: "Trip",
    measures_unit: "Measure units",
    measures_type: "Measure types",
    measure_unit_type: "Measure Unit types",
    address_type: "Address types",
    tariff: "Table tariff criteria",
    table: "Table",
    column: "Column",
    tariff_criteria: "Tariff criteria",
    ports: "Ports",
    vessels: "Vessels",
    docks: "Docks",
    contacts_type: "Contacts types",
    contacts: "Contacts",
    routes_type: "Routes types",
    routes: "Routes",
    equipments: "Equipments",
    equipment: "Equipment",
    equipments_type: "Equipment type",
    equipments_subtype: "Equipment subtype",
    equipments_group: "Equipment group",
    sectors_type: "Sectors type",
    sectors: "Sectors",
    itechnicalname: "Technical Name",
    ichapter: "Chapter",
    transport_order_status: "Transport order status",
    transport_order_type: "Transport order type",
    doc_status: "Doc. Status",
    tdoc_status: "Doc.Status",
    maritime_file_type: "Maritime file type",
    maritime_file_operations: "Maritime Files Operations",
    master_job: "Master Job",
    forwarder_job_traffic_type: "Traffic type",
    Confirm_offer: "Confirm offer",
    forwarder_offer_status: "Offer status",
    forwarder_job_status: "Forwarder status",
    forwarder_job_offer_type: "Offer Types",
    forwarder_job_document_type: "Documents type",
    forwarder_job_shipped_via: "Shipped vias",
    forwarder_job_language: "Languages",
    forwarder_job_operation_type: "Operation types",
    forwarder_job_route_type: "Route types",
    forwarder_job_incoterm_type: "Incoterms",
    forwarder_job_category: "Job Categories",
    forwarder_air_way_bill: "Air way bill",
    forwarder_job_freight_type: "Job Freight Type",
    forwarder_voyage_air_freight_route: "Air Freight Route",
    forwader_Voyage: "Voyage",
    sequence_lenght: "Number of air waybills",
    forwarder_job_good_status: "Job Good Status",
    forwarder_job_groupage_type: "Job Groupage Type",
    forwarder_job_payment_method: "Job Payment Method",
    forwarder_job_service_lines: "Job Service Lines",
    forwarder_offer_type: "Offer Type",
    forwarder_job_offer_observation_templates:
      "Job Offer Observation Templates",
    icategory: "Category",
    forwarder_job_categories: "File category",
    media_type: "Communication media type",
    communication_contact: "Contact Communications",
    location_type: "Locations",
    kilometers: "Kilometers",
    synchronize: "Synchronize",
    synchronizeflagid: "Flag ID",
    synchronizeflag: "Flag",
    externalcode: "External code",
    action_type: "Actions type",
    location_origin: "Origin Locations",
    ilocationorigin: "Origen",
    ilocationdestiny: "Destiny",
    ipol: "POL",
    ipod: "POD",
    iaol: "AOL",
    iaod: "AOD",
    ishippinginfo: "Shipping information",
    imerchandisedetails: "Merchandise details",
    imerchandise: "Merchandise",
    iregistered_equipment: "Registered Equipment",
    location_load: "Load Locations",
    location_destiny: "Destiny Locations",
    location_discharge: "Discharge Locations",
    load: "Load",
    discharge: "Discharge",
    location_abroad: "Location abroad",
    ismanifest: "Manifest",
    freightpaymenttype: "Freight payment",
    tfreightpaymenttype: "Freight",
    allowbilling: "Allow billing",
    isMaritime: "Pre-invoice to maritime file",
    tallowbilling: "Billing",
    regions: "Regions",
    others: "Others",
    bookings: "Bookings",
    bills_of_lading: "Bills of lading",
    type_entities: "Entities Type",
    goods_type: "Goods Type",
    entities: "Entities",
    catalogs: "Catalogs",
    id: "Id",
    identifier: "Identifier",
    edataKey: "Economic Data Identifier",
    pick_dates: "Pick one or more dates",
    dates_create: "Creation date",
    accounting_plan: "Accounting plan",
    economic_data: "Economic Data",
    smpurposes: "Ship mooring purposes",
    smrequirements: "Ship mooring Requirements",
    smoperation_type: "Operations types",
    // end menu
    //buttons
    btnSave: "Save",
    tryAgaint: "Try again!",
    btnSend: "Send messaging",
    btnEdit: "Edit",
    btnNew: "New",
    btnGroup: "Group",
    btnDelete: "Delete",
    btnCancel: "Cancel",
    btnEmail: "Send Email",
    btnExportEquipments: "Generate Excel Equipipments",
    iva_detail_sell_id: "Sales Tax Detail",
    iva_detail_cost_id: "Cost Tax Detail",
    unit_cost: "Cost units",
    unit_sell: "Sales unist",
    btnConfirm: "Confirm",
    btnpermanet: "Permanent delete",
    btntemp: "Temporal delete",
    btnCode: "Send Code",
    btnDiscard: "Discard",
    btnCodeWrite: "Write Code",
    btnsoftdelete: "Soft delete",
    btndelete: "Delete",
    btnCopy: "Duplicate",
    btnSelect: "Select",
    btnMove: "Move",
    btnAdd: "Add",
    btnUpdateEquipmentsGoods: "Update Equipments and Goods",
    wait: "Please wait...",
    btnDetails: "See detail",
    loading: "Loading",
    //end buttons

    //inputs
    lblForgotPassword: "Forgot Password?",
    lblForgotPasswordSub: "Enter your email to reset your password.",
    lblForgotPasswordCode: "Write the code that we sent you by email.",
    lblForgotPasswordInput: "Write the new password.",
    lblForgotPasswordInputConfirm: "Confirm the new password.",
    lblEmailCode: "Code",
    ioffertype: "Offer type",
    btndownload: "Download",
    imatter: "Matter",
    iclient: "Client",
    ipotencial_client: "Potential Client",
    icorrespondent: "Correspondent",
    icarrier: "Carrier",
    iclientcontact: "Contact client",
    itransportationvia: "Transport via",
    itransport_type: "Transport type",
    titransport_type: "Transport",
    idescription: "Description",
    ithumbnail: "Thumbnail",
    iurl: "Url",
    admission_status: "Admission Status",
    idocumenttype: "Document type",
    itraffictype: "Traffic type",
    ioperationtype: "Operation type",
    isalejoblanguage: "Offer language",
    ifromto: "Creation From/To",
    customer_reference: "Customer Reference",
    booking_number: "Booking Number",
    house_number: "House Number",
    file_id: "File Id",
    matter: "Matter",
    assing_offer: "Assing Offer",
    assing_master: "Assing Master",
    master_number: "Master Number",
    iarrivefromto: "Arrive From/To",
    iexecution_from_to: "Execution From/To",
    iupdated_from_to: "Updated From/To",
    ivalid_from: "Valid from",
    ivalid_until: "Valid until",
    iclientreference: "Client reference",
    icontactname: "Contact name",
    icontactemail: "Contact E-mail",
    ipaymentmethod: "Payment method",
    ifirst_payment_deferral: "First Payment Deferral",
    ipaymentnumber: "Payment number",
    ifilecreator: "Created by",
    imaritime_service: "Maritime service",
    ifrequency: "Frequency",
    itemplate: "Template",
    itemplate_type: "Template Type",
    itemplates: "Templates",
    icarbrands: "Car Brands",
    ibrand: "Brand",
    imodel: "Model",
    imessaginpanel: "Messaging panel",
    imessaging: "Messaging",
    iemailtemplates: "Email templates",
    ivars: "Vars",
    iprofile: "Profile",
    ioffice: "Office",
    jobid: "Job number",
    iroute: "Route",
    ihour: "Hour",
    iestimatedtime: "Estimated time",
    iemail: "Email",
    iismain: "Is Main",
    imain: "Main",
    inotification: "Events notifications",
    iwindows: "No. of windows to show",
    iactivitieslog: "No. of activity logs to display",
    iexacttime: "Exact time",
    ithirtyminutesbefore: "30 min. before",
    ihourbefore: "1 hour before",
    idaybefore: "1 day before",
    ipassword: "Password",
    oldPassword: "Old Password",
    iassignoffices: "Assign offices",
    imeasureunittype: "MU type",
    iname: "Name",
    ibody: "Body",
    Name: "Name",
    ilevel: "Level",
    emailChangeSuccess: "Email is successfully changed",
    ivalidatedatefrom: "Validate from",
    ivalidatedateto: "Validate to",
    iprohibitededitbooking: "Proh.Ed.Booking",
    iprohibitededitcontract: "Proh.Ed.Contract",
    iissupplied: "Supplied",
    status_led: "",
    Id: "Id",
    iisoptional: "Optional",
    iisconditional: "Conditional",
    iismandatory: "Mandatory",
    iavailabletariffcriteria: "Available criterias",
    iusedtariffcriteria: "Used criterias",
    tariff_criteria_level: "Tariff criteria level",
    tariff_cost_criteria_level: "Cost tariff criteria level",
    tariff_sale_criteria_level: "Sale tariff criteria level",
    tariffs: "Tariffs",
    tariffs_cost: "Cost tariffs",
    tariffs_sale: "Sale tariffs",
    iunitpaidselectable: "Unit paid selectable",
    mt_tariff_criteria_level_add: "Add tariff criteria level",
    mt_tariff_criteria_level_edit: "Edit tariff criteria level",
    mt_billing_concept_type_add: "Add billing concept type",
    mt_billing_concept_type_edit: "Edit billing concept type",
    mt_billing_concept_code_add: "Add billing concept code",
    mt_billing_concept_code_edit: "Edit billing concept code",
    mt_tariff_criteria_add: "Add tariff criteria",
    mt_tariff_criteria_edit: "Edit tariff criteria",
    mt_subdivision_add: "Add Subdivision",
    mt_subdivision_edit: "Edit Subdivision",
    mt_tariff_add: "Add tariff",
    mt_tariff_edit: "Edit tariff",
    btnClear: "Clear",
    ilast_name: "Last name",
    igender: "Gender",
    illoyd: "Lloyd iso",
    iowner: "Owner",
    ioperator: "Operator",
    isize: "Size",
    isizef: "Size",
    itemperature: "Temperature",
    imanufacturing_materials: "Manufacturing materials",
    icoordinates: "Coordinates",
    icountry: "Country",
    iiata_designator: "Iata code",
    iicao_code: "Icao code",
    icountries: "Countries",
    isubdivision: "Subdivision",
    ilocation: "Location",
    ibillingseriestype: "Billing series types",
    ibillingseries: "Billing series",
    ibillingstatus: "Billing status",
    billing_series_type_id: "Billing series",
    last_billing_number: "Last billing number",
    prefix_billing_number: "Prefix billing number",
    billing_customer_required: "Customer is required",
    billing_reference_required: "Reference is required",
    payment_details_required: "Payment type is required",
    customer_address_required: "The customer address field is required",
    invoice_number_required: "The invoice_number field is required",
    tax_code_required: "The tax code is required",
    digits: "Prefix billing number",
    ibilling_series_required: "Billing series is required",
    invalidInputNumber: "The field only accepts numbers",
    ifullcode: "Full Code",
    ifullname: "Full Name",
    iiso: "ISO",
    imaritime: "Maritime",
    isubdivisions: "Subdivisions",
    imaritimefile: "Maritime file",
    imaritime_trip_type: "Maritime trip type",
    iborndate: "Born date",
    iid: "ID",
    iaddress: "Address",
    icommunications: "Communications",
    ifiscaladdress: "Fiscal address",
    iphone: "Phone",
    ifax: "Fax",
    icif: "NIF/CIF",
    icifo: "CIF",
    icompany: "Company",
    ifiscaldata: "Fiscal Data",
    iaccountingplan: "Accounting plan",
    icompaniesgroup: "Companies group",
    ientity: "Entity",
    ivalue: "Value",
    imedia: "Media",
    icosttypeid: "Cost type",
    iapply_to: "Options",
    iunits: "Units",
    iamounts: "Amounts",
    iamounts2: "Margin",
    iamount: "Total Amount",
    iExpirationDates: "Expiration Dates",
    payment_date: "Payment Date",
    payments_number: "Payments Number",
    payment_dates: "Payment Dates",
    iunitamount: "Unist amount",
    itotalamount: "Total",
    icurrency: "Currency",
    itaxid: "Tax",
    itaxtypeid: "Tax type",
    itaxes: "Taxes",
    itaxesDetail: "Taxes Detail",
    itaxesType: "Taxes Type",
    ientityreference: "Entity reference",
    ientitydata: "Entity data",
    iwebsite: "Website",
    istatus: "Status",
    istatusactive: "Active",
    ipath: "Path",
    istatuscancel: "Cancel",
    icurrentpass: "Current Password",
    inewpass: "New Password",
    confirmPass: "Confirm password",
    ianulatioconfirm: "Confirm cancellation",
    confirmOffer: "Cofirm Offer",
    iconfirmnewpass: "Confirm New Password",
    iupdatepass: "Update Password",
    icode: "Code",
    icode_alpha3: "Iso Code",
    icurrency_code: "Currency Code",
    icurrency_name: "Currency name",
    icallingcode: "Calling code",
    inote: "Note",
    iincidence: "Incidence",
    ifile: "File",
    ifileType: "File Type",
    ioffices: "Offices",
    iactionstatus: "Action status",
    iactiontype: "Action type",
    iactionreason: "Action reason",
    iactionresult: "Action result",
    iport: "Port",
    imaritimevoyage: "Maritime voyage",
    isecondaryvoyage: "Secondary voyage",
    tmaritimevoyage: "Voyage",
    tmaritimevoyagep: "Principal voyage",
    tmaritimevoyagesa: "Secondary voyage",
    irejection_motive: "Rejection motive",
    iheader: "Header",
    ifooter: "Footer",
    iinternal_notes: "Internal notes",
    iinternal_notes_Offer: "Internal notes Offer",
    iconcept: "Concept",
    ivessel: "Vessel",
    imanufacturyear: "Manufacture year",
    ifretwork: "Fretwork",
    idock: "Dock",
    idockcompound: "Dock/Compund",
    idockaeat: "Dock AEAT",
    inum_decimals: "Num decimals",
    inum: "Num",
    isymbol: "Symbol",
    isymbol_native: "Native Symbol",
    ishipConsignne: "Ship Consignne",
    iconsignne: "Consignee",
    icustomer: "Customer",
    icontact: "Contact",
    istreet: "Street",
    address_type_id: "Address type",
    address_types: "Address type",
    inumber: "Number",
    iforwarder_voyage: "Forwarder Voyage",
    iforwarder_air_freight_company: "Air freight company",
    flight_number: "Flight number",
    voyage_number: "Voyage number",
    ifloornumber: "Floor number",
    idoor: "Door",
    icheckin: "Checkin",
    icheckout: "Checkout",
    ioperation: "Operation",
    iprefix: "Prefix",
    icalls: "Calls",
    icapacity: "Capacity",
    icapital: "Capital",
    ilength: "Length",
    ibeam: "Beam",
    iheight: "Height",
    itotalunits: "Total units",
    itotalweigth: "Total weight",
    itotalcbn: "Total CBN",
    icbn: "CBN",
    iimport: "Customs manifest I",
    iexport: "Customs manifest E",
    iimportflow: "Import",
    timport: "Import",
    iexportflow: "Export",
    icrosstrade: "Crosstrade",
    itrip: "Trip",
    itripType: "Trip type",
    ttripType: "Type",
    iremarks: "Remarks",
    iextra: "Information",
    iobservation: "Observations",
    iobservationHeads: "Header Observations",
    iobservationFoot: "Footer Observations",
    iAdmissionStatus: "Admission Status",
    iReceptionStatus: "Reception Status",
    iTransportMode: "Transport Mode",
    ishipowner: "Shipowner",
    naviera: "Line",
    ishipowner2: "Shipowner Forecast",
    tiOrderTransport: "Transportation orders",
    orderTransport: "Transportation order",
    file_not_generated: "File not Generated",
    timodeclaration: "IMO declaration",
    tdispatches: "Dispatches",
    tdispatche: "Dispatch",
    customs_situation_document_id: "Customs situation doc.",
    customs_situation_document: "Customs situation doc.",
    rolarSelected: "Rolar Selected",
    addCustomer: "Add Customer",
    description_document: "Document description",
    dispatch_date: "Date",
    mask: "Mask",
    customs_document_number: "Doc. number",
    iTransportOrderType: "Transportation order type",
    tiTransportOrderType: "OT type",
    iTransportOrderStatus: "Transportation order status",
    iTransportType: "Transport type",
    irequestquotation: "Request quotation",
    hidedetails: "Hide details",
    details: "Details",
    tariffcode: "Load",
    paymentunit: "Payment unit",
    iToEquipment: "Equipment",
    ishipload_id: "Shipload",
    ito_special_condition_id: "Special Condition",
    iregistered_equipment_id: "Equipment",
    iequipment_id: "Equipment ID",
    iregistration_number: "Registration Number",
    itruck_registration_number: "Truck Registration Number",
    itrailer_registration_number: "Trailer Registration Number",
    idrivers_name: "Drivers name",
    idestination_address: "Destination address",
    iorigin_address: "Origin address",
    tiregistration_number: "Registration",
    iequipment_type: "Equipment type",
    iequipment_size: "Equipment size",
    itare: "Tare",
    ibulks: "Bulks",
    iconnected_onboard: "Connected on Board",
    indicences: "Incidence",
    iconnected_in_station: "Connected in station",
    iconnected_transport: "Connected transport",
    irelative_humidity: "Relative Humidity",
    icontain_origin: "Contain origin",
    icontain_destination: "Contain destination",
    idestination_service: "Destination destination",
    iis_shipper_owner: "Shipper owner",
    iseal_number: "Seal number owner",
    iorigin_service: "Origin service",
    iallowed_by_pif: "Allowed by pif",
    iallowed_by_customs: "Allowed by customs",
    allowed_files_types: "Allowed file types:",
    iweighers: "Weighers",
    iis_empty: "Empty",
    iemptyequipments: "Empty equipments",
    inumber_onu: "ONU Number",
    iarticle_15: "Article 15",
    isea_pollutant: "Sea Pollutant",
    ilimited_amount_exemptions: "LAE",
    iflash_point: "Flashpoint",
    ifullequipments: "Full equipments",
    iis_full: "Full",
    ito_measures: "Measures",
    ParticipantList: "Participant List",
    EventList: "Temporal Events",
    event_type_id: "Event Types",
    ito_participant_type_id: "Participant type",
    ientity_id: "Entity",
    iftx_entity: "FTX entity",
    iaddress_id: "Address",
    iftx_address: "FTX Address",
    icontact_id: "Contact",
    ftx_contact: "FTX Contact",
    observations: "Observations",
    iis_main: "Is main",
    ipackaging_type_id: "Packaging type",
    ipackages: "Packages",
    ipackagegroup: "Package group",
    igross_weight: "Gross weight",
    igross_weight_verify: "Verified gross weight",
    inumber_of_packages: "Number of packages",
    istart_date: "Start date",
    iend_date: "End date",
    ireference: "Reference",
    ibookingNumber: "Booking number",
    iFiltterName: "Edi Name",
    iwithbl: "With Bl",
    tbookingNumber: "Booking",
    iblNumber: "Bill of lading number",
    imasterBLNumber: "Master Job number",
    ihouseBLNumber: "House Job number",
    ifile_id: "Job identifier",
    ifileNumber: "File number",
    ibookingDate: "Booking date",
    iavailabilitydate: "Availability date",
    istartdate: "Start date",
    ienddate: "End date",
    ibookingref: "Reference",
    ishipper: "Shipper",
    ishipperAddress: "Shipper address",
    iActions: "Actions",
    iBooking: "Booking",
    iDescription: "Description",
    idateselect: "Pick a date",
    itimeselect: "Pick a time",
    idischargedate: "Discharge date",
    idistance: "Distance",
    idistances: "Distances",
    istarttime: "Start time",
    iendtime: "End time",
    inextaction: "Next action date",
    inextactiontype: "Next action type",
    iweight: "Weight",
    iunit_weight: "Unit weight",
    ipercentage: "Porcentage",
    iinternational_codes: "Internacionals codes",
    inational: "National",
    iparticipantType: "Participant type",
    iparticipantsType: "Participants type",
    iparticipant: "Participants",
    iresponsibles: "Responsibles",
    iBilling_concepts: "Billing Concepts",
    iBilling_concepts_cost: "Cost Billing Concepts",
    iBilling_concepts_sale: "Sale Billing Concepts",
    fushion_concept: "Printing name ",
    typetrafic: "Type of traffic",
    iBilling: "Billing",
    permissions: "Users group",
    iconceptsTypes: "Concepts Billings Types",
    iconceptsCodes: "Billings Concepts Codes",
    icostTypes: "Cost Types",
    ipackagingtype: "Packaging type",
    iPaymentsTypes: "Payments Types",
    iPaymentsTypesDetails: "Payments Types Details",
    first_payment_deferral: "First Payment Deferral",
    iPaymentsDays: "Payments Days",
    icurrencies: "Currencies",
    unit_sell_amount: "Unit Price",
    origincurrencies: "Origin currencies",
    torigincurrencies: "Origin",
    exchangecurrencies: "Exchange currencies",
    texchangecurrencies: "Exchange",
    itype: "Type",
    applies: "Applies",
    Type: "Type",
    Size: "Size",
    User: "User",
    irealname: "Display name",
    imin: "Minimum",
    imax: "Maximum",
    irates: "Rates",
    icondition: "Condition",
    iprice: "Price",
    iisfixed: "Is fidex",
    ifixedprice: "Fixed price",
    iunitprice: "Unit price",
    iminprice: "Min. price",
    imaxprice: "Max price",
    ipayunit: "Pay unit",
    ifactor: "Factor",
    iissurcharge: "Surcharge",
    iisextended: "Extended",
    irevenueselect: "Select revenue",
    irevenue: "Revenue",
    irevenuepercent: "Revenue percent",
    irevenueunit: "Revenue unit",
    iisdiscount: "Discount",
    isimple: "Simple",
    irange: "Range",
    itariffcriteriaid: "Criteria",
    itariffcriterialevel: "Level",
    iextension: "Extension",
    icustomernom: "icustomernom",
    ibillinggroup: "ibillinggroup",
    icodconc: "icodconc",
    ibillingunits: "ibillingunits",
    iprevcost: "iprevcost",
    iprevmoney: "iprevmoney",
    isale: "isale",
    idivsale: "idivsale",
    icost: "Cost",
    cost: "Cost",
    sell: "Sell",
    iimpbuy: "Imp buy",
    iimpsale: "Imp sale",
    ipvoucher: "Voucher",
    isupplier: "Supplier",
    inomsupplier: "Nom supplier",
    iobservations: "Observations",
    btnSaveDraft: "Save draft",
    igroup: "Group",
    itablenamerelationship: "Relationship",
    quotations: "Quotations",
    quotationsMenu: "Rates",
    imenu: "Menu",
    manage_quotations: "Manage quotations",
    manage_offers: "Manage Offers",
    offer: "Offer",
    quotation: "Quotation field",
    ititle: "Title",
    isection: "Section",
    iestimatedarrivaldate: "Estimated arrival",
    iarrivaldate: "Arrival date",
    iclosingdate: "Closing date",
    iestimateddeparturedate: "Estimated departure",
    ideparturedate: "Departure date",
    iinitialBL: "Initial BL",
    ifinalBL: "Final BL",
    iinitialDeparture: "Initial departure",
    ifinalDeparture: "Final departure",
    ibs: "BS",
    ibaseSection: "Base section",
    isectionIdentifier: "Section identificator",
    ismrequirement: "Requirement",
    ismpurpose: "Purpose",
    ismoperationType: "Operation type",
    igoodsType: "Goods Type",
    istation: "Station",
    inodata: "No data",
    iselectmaritimevoyage: "Select a maritime voyage",
    iselectcustomer: "Select a customer",
    isendarrivalnoticemail: "Send arrival notice",
    itariffheadings: "Tariff headings",
    isendbillingemail: "Send billing",
    hasbilling: "Has billing",
    isendemail: "Send email",
    isubjectRequired: "Subject is required'",
    isendpdfdialog: "Send attachment",
    iimportdocument: "Import document",
    isend: "Send",
    iselectall: "Select all",
    ideselectall: "Deselect todos",
    ivolume: "Volume",
    iclass: "Class",
    ilinear_meters: "Linear meters",
    iassessable_weight: "Assessable weight",
    igoods_group: "Goods group",
    iequipment_class: "Class of equipment",
    imetric_unit: "Metric unit",
    idangerous: "Dangerous",
    isearch: "Search",
    ielements: "element(s)",
    iconcession: "Concession",
    izone: "Zone",
    ialignment: "Alignment",
    iselect: "Select an element",
    iselectexistent: "Select an existen",
    iallcategories: "All categories",
    iperiod: "Period",
    iselectdate: "Select a date",
    ilegalname: "Legal name",
    iidentificationtypes: "Identification types",
    iidentifications: "Identifications",
    ilogo: "Logo",
    imanufacturingmaterials: "Manufacturing materials",
    imanufacturingdate: "Manufacturing date",
    iequipmenttype: "Equipment type",
    iportauthority: "Port authority",
    iportauthoritycode: "Port authority Code",
    ibillofladingtype: "BL type",
    inextprevioustransportmethod: "Next previous transport method",
    icustomimport: "Customs import",
    icustomexport: "Customs export",
    iaddressrouteedi: "Address route edi",
    icolor: "Color",
    iorder: "Order",
    idate: "Date",
    iexecutionDate: "Execution date",
    icreationdate: "Creation date",
    iblid: "BL id",
    bldate: "BL date",
    mfdate: "Maritime file date",
    icontainer: "Container",
    icontainernumber: "Container number",
    icontent: "Content",
    iregistercontainer: "License number",
    iPackagingType: "Packaging type",
    icontainertype: "Container type",
    icontainermovement: "Container movement",
    igoods: "Goods",
    ishiploadsgoods: "Equipments-Goods",
    iaddot: "TO",
    iimodeclaration: "Declaration",
    iimodeclarationdialog: "Declaración de mercancía peligrosa",
    icancelled: "Cancelado",
    iedigenerated: "Gen. edifact",
    inovehicles: "No. vehículos",
    imessaging_operation_instruction: "Inst. de operación",
    imessaging_iftdgn_cargo_type: "Cargo type",
    timessaging_iftdgn_cargo_type: "Cargo",
    imessaging_transport_method: "Transport method",
    timessaging_transport_method: "Transport",
    imessaging_groupage_type: "Groupage type",
    timessaging_groupage_type: "Groupage",
    igoods_arrival_date: "Arrival date",
    ivessel_loading_date: "Loading date",
    ivessel_discharge_date: "Discharge date",
    igoods_departure_date: "Departure date",
    itraffic_type: "Traffic type",
    titraffic_type: "Traffic",
    tforwarder_air_way_bill_id: "Air way bill",
    iexchangesale: "Exchange sale",
    tiexchangesale: "Sale",
    iexchangepurchase: "Exchange purchase",
    tiexchangepurchase: "Purchase",
    ishipperexchange: "Shipper exchange",
    iconference: "Conference",
    itransport_id: "Transport means identification",
    idispatcher: "Dispatcher",
    irecipient: "Recipient",
    istevedore_emergency_contact: "Contacto emergencia",
    istevedore_emercency_phone: "Teléfono emergencia",
    istevedore_emergency_identifier: "Emergencia",
    ivessel_emergency_phone: "Teléfono de buque de emergencia",
    iemergencytag: "Emergency tag",
    iupdate_or_cancelation_reason: "Razón de actualización o cancelación",
    itransittime: "Tiempo tránsito",
    iimo: "Imo",
    ivgm: "Vgm",
    iequipmentid: "Equipment type",
    iconectedonborad: "Con. onboard",
    iconectedoninstation: "Con. in station",
    iconectedontransport: "Con. on transport",
    is_shipper_owner: "Shipper owned",
    pif: "Go through PIF?",
    customs: "Go through customs?",
    weighers: "Weighers",
    underCover: "Under Cover",
    iequipmentsupplier: "Equipment supplier",
    isealnumber: "Seal number",
    tisealnumber: "Seal",
    iisempty: "Is empty",
    icustomstariffcode: "Customs tariff code",
    icustoms:"Customs",
    itariffcode: "Arancel",
    inumbernrm: "Num NRM",
    iaditionalid: "Aditional id",
    ishipmenttype: "Shipment type",
    icustomstatus: "Customs status",
    ibltypemmpp: "Bl type mmpp",
    imeasure: "Measure",
    imeasuretype: "Measure type",
    imeasureunit: "Measure unit",
    iidshipload: "Shipload id",
    iidgood: "Good id",
    igoodg: "Good Group",
    iidimogood: "DG id",
    ipropershippingname: "Proper shipping name",
    iidequipment: "Equipment id",
    ilocationid: "Location",
    ilocationcode: "Location Code",
    ilocationname: "Location Name",
    iplace: "Place",
    izipcode: "Zip code",
    ilatitude: "Latitude",
    ilongitude: "Longitude",
    iupload: "Click to upload files",
    iupload_file: "Upload file",
    ilegend: "Legend",
    ItinerariesList: "Itineraries List",
    iToShipload: "Shiploads",
    iyes: "Yes",
    ino: "No",
    iconfirmdialog: "Remove element",
    iprefactureDialog: "Select the files and bls to invoice",
    iconfirmduplicatedialog: "Duplicate element",
    iconfirm: "Are you sure yo want to delete the element?",
    inotdelete: "This element cannot be removed",
    iconfirmduplicate: "Are you sure yo want to duplicate the element?",
    idelete: "How do you want to remove the element?",
    iaprove:
      "The maritime voyage office is different from the maritime file office. Are you sure you want to save the changes?",
    iwidth: "Width",
    imode: "Mode",
    iflow: "Flow",
    imaritimefcl: "Maritime FCL",
    imaritimelcl: "Maritime LCL",
    imaritimeroro: "Maritime RoRo",
    imaritimebreakbulk: "Maritime Break Bulk",
    iaerial: "Aereal",
    igroupterrestrial: "Land groupage",
    icompleteterrestrial: "Land complete",
    igroupbooking: "Group bookings",
    iforwarder: "Forwarder",
    iforwarderjob: "Forwarder Job",
    iforwarderjobcategory: "Forwarder Job Category",
    iforwarderjobgroupage: "Type of groupage",
    iforwarderjobgoodstatus: "State of the Merchandise",
    iforwarderjobs: "Offers",
    iforwarderoffers: "Offer",
    iforwarderServicesLine: "Services Lines",
    ientry_transport_movement: "Entry Transport Movement",
    rentry_transport_movement: "Entry Transport Movement Required",
    iexit_transport_movement: "Exit Transport Movement",
    rexit_transport_movement: "Exit Transport Movement Required",
    ientry_regular_service_code: "Entry Regular Service Code",
    rentry_regular_service_code: "Entry Regular Service Code Required",
    iexit_regular_service_code: "Exit Regular Service Code",
    rexit_regular_service_code: "Exit Regular Service Code Required",
    ientry_simplified_communal_transit_procedure: "Entry Simplified Communal Transit Procedure",
    rentry_simplified_communal_transit_procedure: "Entry Simplified Communal Transit Procedure Required",
    iexit_simplified_communal_transit_procedure: "Exit Simplified Communal Transit Procedure",
    rexit_simplified_communal_transit_procedure: "Exit Simplified Communal Transit Procedure Required",
    iimport_declaration_number: "Import declaration number",
    iexport_declaration_number: "Export declaration number",
    icommercial_code: "Commercial Code",
    iifcsum_registration_modification: "Registration/Modification",
    iifcsum_modificate_headers: "Modificar cabeceras",
    iifcsum_cancel: "Cancellation",
    iifcsum_cancel_header_text:"¿Are you sure you want to cancel?",
    iifcsum_cancel_with_replace: "With replacement",
    iifcsum_cancel_without_replace: "No replacement",
    iifcsum_negative_declaration: "Sending refusal of declaration",
    rifcsum_generated: "IFCSUM generated correctly",
    ientry_is_simplified_regime: "Simplified Entry Regime",
    iexit_is_simplified_regime: "Simplified Exit Regime",
    ihumanitary_aid: "Humanitary aid",
    imilitary_purposes: "Military purposes",
    iaccoutrements: "Accountrements",
    origin_airport_id: "Origin airport",
    destination_airport_id: "Destination airport",
    verified: "Verified",
    emailContact: "Contact Email",
    role: "Role",
    phoneValidation: "Phone number must be active",
    //end inputs
    //validate
    rpassword: "Password must be at least 4 character and contain symbols",
    rpasswordrequired:
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
    rname: "Name is a required field",
    rbrand: "Brand is a required field",
    rmodel: "Model is a required field",
    ravatar: "The avatar is required",
    remail: "Email is a required field",
    vemail: "Email must be a valid email",
    rvoyage: "Voyage number is a required field",
    rnumber: "Number is a required field",
    rfreightcompany: "The freight company is a required field",
    rmeasureunittypeid: "Measure unit type is a required field",
    rDesc: "Description is a required field",
    rRemarks: "Remarks is a required field",
    rcode: "Code is a required field",
    rlevel: "Level is a required field",
    rscall: "Scall is a required field",
    rtarifftype: "Type is a required field",
    rcif: "CIF is a required field",
    rstreet: "Street is a required field",
    rvalue: "Value is a required field",
    rcompany: "Company is a required field",
    rcodeunique: "Code must be unique",
    rcodelength: "Code must contain 18 characters max",
    rcodelength1: "Code must contain 8 characters max",
    rdecimal:
      "The field only accepts decimal numbers and contain 8 decimal characters max ",
    rbookingnumber: "Booking number is a required field",
    rshipownerid: "Shipowner is a required field",
    rtriptype: "Trip type is a required field",
    rconsigneeid: "Consignee is a required field",
    rtype: "Consignee type is a required field",
    rrole: "Must assign at least one role",
    rbasesection: "Base Section is a required field",
    rsmrequirement: "Requirement is a required field",
    rsmpurpose: "Purpose is a required field",
    rdock: "Dock is a required field",
    rcriteriatype: "Criteria type is a required field",
    rtablerelationship: "Table relationship is a required field",
    rroutec: "Route is a required field",
    rrequipment: "Equipments are a required field",
    salejoboffertypeid: "Offer type is a required field",
    forwarderjobcategory: "Category is a required field",
    salejobdocumenttypeid: "Document type is a required field",
    incotermid: "Incoterm is a required field",
    internationalCodeid: "International code is a required field",
    cliente: "Cutomer is a required field",
    jobstatusid: "Status is a required field",
    salestraffictypeid: "Traffic type i s a required field",
    airwaybillid: "Air way bill is a required field",
    airfreightcompanyid: "Air freight company i s a required field",
    salejobtypeshippedviaid: "Shipped type is a required field",
    salejoboperationtypeid: "Operation type i s arequired field",
    salejoblanguagesid: "Language is a required field",
    rtable: "Table is a required field",
    rcolumn: "Column is a required field",
    rcheckin: "Checkin is a required field",
    rcheckout: "Checkout is a required field",
    roperationtype: "Operation type is a required field",
    rstationid: "Station is a required field",
    rgoodstypeid: "Goods type is a required field",
    rofficeid: "Office is a required field",
    rpaymentmethod: "Payment method is a required field",
    rofferstatus: "Offer status is a required field",
    rshippedvias: "Shipped vias is a required field",
    ralerttype: "Alert type is a required field",
    rportid: "Port is a required field",
    rdockid: "Dock is a required field",
    rmodeid: "Mode is a required field",
    rmerchandisetype: "Merchandise type is a required field",
    raddresstype: "Address type is a required field",
    raddress: "Address is a required field",
    raccountingplan: "Accounting plan is a required field",
    rshipid: "Vessel is a required field",
    rmedia: "Communication media type is a required field",
    rcommunications: "Communication is a required field",
    rcontacttype: "Contact type is a required field",
    rcontact: "Contact is a required field",
    rcountry: "Country is a required field",
    rentitytype: "Entity type is a required field",
    entitytypes: "Entity Types",
    rbillingconcept: "Billing concept is a required field",
    rbillingconceptcode: "Billing concept code is a required field",
    requipmenttype: "Equipment type is a required field",
    rpackagingtype: "Packaging type is a required field",
    rflagid: "Flag is a required field",
    requipmentsubtype: "Equipment subtype is a required field",
    requipmentgroup: "Equipment group is a required field",
    requipment: "Equipment is a required field",
    rlocation: "Location is a required field",
    rcurrency: "Currency is a required field",
    relement: "Element is a required field",
    rtaxes: "Tax is a required field",
    ractiontype: "Acion type is a required field",
    ractionreason: "Acion reason is a required field",
    ractionresult: "Acion result is a required field",
    ractionstatus: "Acion status is a required field",
    rmaritimefilesoperations: "Maritime files operations is a required field",
    rmaritimefilestype: "Maritime files type is a required field",
    rservice: "Maritime service is a required field",
    rmeasuretypeid: "Measure type is a required field",
    rparticipant: "Participant type is a required field",
    rroute: "Route type is a required field",
    rsection: "Sector type is a required field",
    rtransportorderstatus: "Transport order status is a required field",
    rtransportordertype: "Transport order type is a required field",
    riso: "ISO is a required field",
    rmaritime: "Maritime is a required field",
    ruser: "User is a required field",
    rcompanygroupname: "Companies group name is a required field",
    company_group: "Companies group",
    rcompanyougrpoffice: "Office company and name are required fields",
    rcustomer: "Customer is a required field",
    rentity: "Field is required",
    rmaritimevoyage: "Maritime voyage is required",
    rbookingid: "Booking is a required field",
    rbillofladingdate: "Bill of lading date is a required field",
    rbillofladingnumber: "Bill of lading number is a required field",
    rmteditabletable: "To add a new trip, the data in the table must be valid",
    rbookinggrouperror: "To group bookings the trip type must be the same",
    rflagtabletable:
      "To add a synchronization, the data in the table must be valid",
    rpaymenttype: "Payment type is a required field",
    rdeparturedate: "Departure date is a required field",
    rvalidfrom: "date is a required field",
    restdeparturedate: "Estimated departure date is a required field",
    restarrivaldate: "Estimated arrival date is a required field",
    rarrivaldate: "Arrival date is a required field",
    rclosingdate: "Closing date is a required field",
    ridshipload: "Shipload is a required field",
    ridmessagingoperationinstruction: "Oper.Instruction is a required field",
    ridmessagingiftdgncargotypeid: "Cargo is a required field",
    ridmessagingtransportmethodid: "Transport method id is required field",
    ridmessaginggroupagetypeid: "Groupage type is required field",
    ridmessagingdispatcherid: "Dispatcher is required field",
    ridmessagingrecipientid: "Recipient is required field",
    ridmessagingestimatedoperationtime: "Estimated time is required field",
    ridmessagingTrafficType: "Traffic Type is required field",
    rgceditabletable:
      "To add a new good consignee, the data in the table must be valid",
    raddresstable: "To add a new address, the data in the table must be valid",
    rconatcttable: "To add a new contact, the data in the table must be valid",
    rcommunicationtable:
      "To add a new communication, the data in the table must be valid",
    ridentificationtable:
      "To add a new identification, the data in the table must be valid",
    rsmeditabletable:
      "To add a new ship mooring, the data in the table must be valid",
    rmoeditabletable:
      "To add a new mooring operation, the data in the table must be valid",
    rmaritimefiletables:
      "To submit a new maritime file, there must be at least one element of: trips, calls, good consignees, mooring and mooring operations",
    rmaritimefiledelete: "The selected maritime file cannot be deleted",
    rofficedelete: "The selected office cannot be deleted",
    rimodeclaration:
      "To submit imo declarations, there must be at least one element",
    rcrm: "Fields with * are required",
    rbkeditabletable:
      "To submit a new booking, the data in the table must be valid",
    rtseditabletable:
      "To submit a new transshipment, the data in the table must be valid",
    rbreditabletable:
      "To submit a new booking route, the data in the table must be valid",
    rpeditabletable:
      "To submit a new participant, the data in the table must be valid",
    ritineeditabletable:
      "To submit a new itinerary, the data in the table must be valid",
    reventeditabletable:
      "To submit a new temporal event, the data in the table must be valid",
    rshipsloadsgoods:
      "The Special Condition is required in Equipments and Goods Table",
    inlineprebillingvalidate:
      "<p>- The code.<br>" +
      "- Tax.<br>" +
      "- The currency.<br>" +
      "- The freight.<br>" +
      "- The office.<br>" +
      "- You must select a maritime file or a bill of lading.<br>" +
      "are required.</p>",
    inlineprebillingvalidate1:
      "<p>The values of: <br>" +
      "- Cost.<br>" +
      "- Sell.<br>" +
      "- Units.<br>" +
      "they must be greater than or equal to 0.</p>",
    rpreditabletable:
      "To submit a new amount, the data in the table must be valid",
    rbleditabletable:
      "To submit a new bill of landing, the data in the table must be valid",
    rsleditabletable:
      "To submit a new shipload, the data in the table must be valid",
    rgfeditabletable:
      "To submit a new good feature, the data in the table must be valid",
    rbooking:
      "To submit a new booking, there must be at least one element of: booking, transshipment, booking route, participant and bill of landing",
    rvreditabletable:
      "To submit a new vessel route, the data in the table must be valid",
    rbookingvoyagetype:
      "The booking voyage type doesn't match the maritime file voyage type",
    rgood: "To submit a new good, the data in the table must be valid",
    requipments: "To submit a equipment, the data in the table must be valid",
    rimodeclarartion:
      "To submit a new imo declaration, the data in the table must be valid",
    rgoodmeasure:
      "To submit a new good measure, the data in the table must be valid",
    roverdimensions:
      "To submit a new overdimension, the data in the table must be valid",
    rblprebilling:
      "To submit a new BL prebilling, there must be at least one element of prebilling amounts",
    rrequiredfields: "Check the required fields",
    raerialvoyeageroutes:
      "To submit a new aerial voyage route, there must be at least one aerial voyage route",
    raerialvoyeage:
      "To submit a new aerial voyage, there must be at least two aerial voyage routes",
    rofferroute: "To submit an offer, there must be at least two rosute",
    no_has_bookings: "The selected BL has no bookings",
    rhas_dangerous_goods:
      "The element cannot be remove because has dangerous goods",
    rhas_transportation_order:
      "The element cannot be remove because has transportation orders",
    rsend_email_success: "Se ha enviado el correo",
    runitpayment: "Unit Payment is a required field",
    //end validate
    //labels
    lpermisionsassigned: "Permissions assigned",
    //end labels
    //titles
    timaritimefilelist: "Maritime files",
    toffer: "Offer",
    tjob: "Job",
    tcrm: "CRM - Commercial Managemente",
    mcrm: "Commercial Managemente",
    timaritimetripslist: "Maritime voyages",
    timaritimetrips: "Maritime voyages",
    tisummarystatement: "Summary statement",
    timaritimefile: "Maritime file",
    toffers: "Offers",
    tjobs: "Jobs",
    tcrmform: "CRM",
    titrips: "Voyages",
    taerialvoyage: "Aerial voyages",
    saerialvoyage: "Aerial voyage",
    tterrestrialvoyage: "Terrestrial voyages",
    tvesselroutes: "Vessel routes",
    talerts: "Alerts",
    tsystemactivealert: "System active alerts",
    tsystemalert: "System alert",
    tsystemalerttype: "System alert type",
    ttransitcargo: "Transit cargo",
    tibookings: "Booking",
    tibookingsprincipal: "Main Booking",
    ticustomer: "Customer",
    ttransportation: "Transportation",
    towntransportation: "Own transportation",
    tiprovider: "Supplier",
    tipaymenttype: "Payment type",
    tipaymenttypedetails: "Payment type details",
    tiblnumber: "BL number",
    ttiblnumber: "BL",
    tiPendientes: "Pending",
    tiSelect: "selected",
    tcalls: "Calls",
    troute: "Routes",
    tparticipants: "Participants",
    tmoorings: "Ship Moorings",
    tconsignees: "Goods Consignees",
    tmooringoperation: "Ship Mooring Operation",
    tiBillOfLadings: "Bill of ladings",
    amountBillOfLoadings: "Amount of Bill Of Loadings",
    tisenexs: "Send EXS",
    tisenens: "Send ENS",
    tisenexspartida: "Send by tariff",
    tisenexsbl: "Send by BL",
    tselectall: "Select all",
    tdesselectall: "Des-Select all",
    tlogs: "Logs",
    tibillingconcepts: "Billing concepts",
    iprefactured: "Pre-invoiced",
    inoprefacture: "Not Pre-invoiced",
    tibillingconceptname: "Billing concept",
    tiPrebilling: "Prebilling",
    tiPrebillingE: "Empty Prebilling",
    tiPrebillingF: "AG Comision",
    tiPrebillingT: "Transshipment Prebilling",
    tiPrebilling2: "Prebilling",
    tiPrebillingAll: "Prebilling Bls",
    tiPrebilling3: "Prebilling",
    tiPrebilling4: "Pending billing",
    tiPrebilling5: "Show maritime exp pre-invoicing",
    tiPrebillingConcepts: "Concepts",
    iPreBill: "Billings Line",
    tiDocuments: "Documents",
    tiCurrencyExchange: "Currency exchange",
    tiEntityTypes: "Entity Types",
    toperations: "Operations",
    ttransshipment: "Transshipment",
    tbookingroute: "Booking routes",
    tprebillingamount: "Amounts",
    tgoodsfeatures: "Goods Features",
    tshipsloads: "Equipments",
    iequipment: "Equipment",
    tshipsloadsgoods: "Equipments and goods",
    tgoodsmeasures: "Goods measures",
    toverdimensions: "Equipment measures",
    //endtitles
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signOut: "Sign Out",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    errors: "Errors",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    dangerous: "Dangerous",
    perishable: "Perishable",
    addcontainer: "Add equipment",
    addunits: "Add units",
    removecontainer: "Remove equipment",
    removeunit: "Remove units",
    removeavatar: "Remove Avatar",
    changeavatar: "Change Avatar",
    generals: "Generals",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    btnView: "View",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    activity: "Activity",
    createNewBusinessGroup: "Create new company group",
    createNewOfficeGroup: "Create new office group",
    createNewBusiness: "Create new company",
    sendarrivalnotice: "Send arrival notice",

    //modal titles
    ot_transfers_from_booking: "Transfers from Booking",
    ot_transfers_from_bl: "Transfers from Bill of Landing",
    ot_transfers: "Transfers",
    ot_transit: "Transit",
    ot_shipping_date: "Shipping date",
    tot_shipping_date: "Shipping",
    mt_maritime_file_add: "Add maritime file",
    mt_car_brands_add: "Add car brand",
    mt_car_brands_edit: "Edit car brand",
    mt_add: "Add",
    mt_edit: "Edit",
    mt_maritime_file_edit: "Edit maritime file",
    mt_address_type_add: "Add address type",
    mt_address_type_edit: "Edit address type",
    mt_forwarder_status_add: "Add forwarder status",
    mt_forwarder_status_edit: "Edit forwarder status",
    mt_forwarder_offer_type_add: "Add offer type",
    mt_forwarder_offer_type_edit: "Edit offer type",
    mt_traffic_type_add: "Add traffic type",
    mt_traffic_type_edit: "Edit traffic type",
    mt_forwarder_document_type_add: "Add document type",
    mt_forwarder_document_type_edit: "Edit document type",
    mt_forwarder_shipped_via_add: "Add shipped via",
    mt_forwarder_shipped_via_edit: "Edit shipped via",
    mt_forwarder_language_add: "Add language",
    mt_forwarder_language_edit: "Edit language",
    mt_forwarder_operation_type_add: "Add operation type",
    mt_forwarder_operation_type_edit: "Edit operation type",
    mt_forwarder_route_type_add: "Add route type",
    mt_forwarder_route_type_edit: "Edit route type",
    mt_forwarder_incoterm_type_add: "Add incoterm type",
    mt_forwarder_incoterm_type_edit: "Edit incoterm type",
    mt_forwarder_category_add: "Add job category",
    mt_forwarder_category_edit: "Edit job category",
    mt_forwarder_job_freight_type_add: "Add job freight type",
    mt_forwarder_job_freight_type_edit: "Edit job freight type",
    mt_forwarder_job_good_status_add: "Add job good status",
    mt_forwarder_job_good_status_edit: "Edit job good status",
    mt_forwarder_job_groupage_type_add: "Add job grupage type",
    mt_forwarder_job_groupage_type_edit: "Edit job grupage type",
    mt_forwarder_job_payment_type_add: "Add job payment type",
    mt_forwarder_job_payment_type_edit: "Edit job payment type",
    mt_forwarder_offer_status_add: "Add offer status",
    mt_forwarder_offer_status_edit: "Edit offer status",
    mt_forwarder_job_offer_observation_templates_add:
      "Add job offer observation templates",
    mt_forwarder_job_offer_observation_templates_edit:
      "Edit job offer observation templates",
    mt_forwarder_air_way_bill_add: "Add air way bill",
    mt_forwarder_air_way_bill_edit: "Edit air way bill",
    mt_tariffs_add: "Add table rate criteria",
    mt_tariffs_edit: "Edit table rate criteria",
    mt_port_add: "Add port",
    mt_port_edit: "Edit port",
    mt_vessel_add: "Add vessel",
    mt_vessel_edit: "Edit vessel",
    mt_template_add: "Add template",
    mt_template_edit: "Edit template",
    mt_email_template_add: "Add email template",
    mt_email_template_edit: "Edit email template",
    mt_var_add: "Add variable",
    mt_var_edit: "Edit variable",
    mt_participant_type_add: "Add participant type",
    mt_participant_type_edit: "Edit participant type",
    mt_cost_type_add: "Add cost type",
    mt_cost_type_edit: "Edit cost type",
    mt_tax_detail_add: "Add tax detail",
    mt_tax_detail_edit: "Edit tax detail",
    mt_tax_add: "Add tax",
    mt_tax_edit: "Edit tax",
    mt_docks_add: "Add docks",
    mt_docks_edit: "Edit docks",
    mt_PackagingType_add: "Add Packaging Type",
    mt_PackagingType_edit: "Edit Packaging Type",
    mt_identificationType_add: "Add Identification Type",
    mt_identificationType_edit: "Edit Identification Type",
    mt_BillingConcept_add: "Add concept",
    mt_BillingConcept_edit: "Edit concept",
    mt_paymentType_add: "Add Payment Type",
    mt_paymentType_edit: "Edit Payment Type",
    mt_measure_type_add: "Add measure type",
    mt_measure_type_edit: "Edit measure type",
    mt_measure_unit_edit: "Edit measure unit",
    mt_measure_unit_add: "Add measure unit",
    mt_measure_type_unit_edit: "Edit measure type unit",
    mt_measure_type_unit_add: "Add measure type unit",
    mt_contact_type_edit: "Edit contact type",
    mt_contact_type_add: "Add contact type",
    mt_transport_order_status_edit: "Edit transport order status",
    mt_transport_order_status_add: "Add transport order status",
    mt_transport_order_type_edit: "Edit transport order type",
    mt_transport_order_type_add: "Add transport order type",
    mt_route_type_edit: "Edit route type",
    mt_route_type_add: "Add route type",
    mt_equipment_type_edit: "Edit equipment type",
    mt_equipment_type_add: "Add equipment type",
    mt_payment_type_detail_edit: "Edit payment type detail",
    mt_payment_type_detail_add: "Add payment type detail",
    mt_payment_type_edit: "Edit payment type",
    mt_payment_type_add: "Add payment type",
    mt_currency_type_edit: "Edit currency",
    mt_currency_type_add: "Add currency",
    mt_dashboard_edit: "Edit dashboard",
    mt_dashboard_add: "Add dashboard",
    mt_equipment_subtype_edit: "Edit equipment subtype",
    mt_equipment_subtype_add: "Add equipment subtype",
    mt_equipment_group_edit: "Edit equipment group",
    mt_equipment_group_add: "Add equipment group",
    mt_sector_type_edit: "Edit sector type",
    mt_sector_type_add: "Add sector type",
    mt_maritime_file_operation_edit: "Edit maritime file operation",
    mt_maritime_file_operation_add: "Add maritime file operation",
    mt_media_edit: "Edit communication media type",
    mt_media_add: "Add communication media type",
    mt_location_edit: "Edit location",
    mt_location_add: "Add location",
    mt_sync_add: "Add synchronization",
    mt_sync_edit: "Edit synchronization",
    mt_entity_edit: "Edit entity type",
    mt_entity_add: "Add entity",
    mt_goods_type_edit: "Edit goods type",
    mt_goods_type_add: "Add goods type",
    mt_registered_equipment_add: "Add registered equipment",
    mt_registered_equipment_edit: "Edit registered equipment",
    mt_smpurposes_edit: "Edit ship moorign purpose",
    mt_smpurposes_add: "Add ship moorign purpose",
    mt_smrequirements_edit: "Edit ship mooring requirements",
    mt_smrequirements_add: "Add ship mooring requirements",
    mt_equipment_edit: "Edit equipment",
    mt_equipment_add: "Add equipment",
    mt_add_mf_booking_route: "Add route to booking",
    mt_add_mf_booking_equipment: "Add equipment to booking",
    mt_maritime_trip_edit: "Edit maritime trip",
    mt_maritime_trip_add: "Add maritime trip",
    mt_good_consignee_edit: "Edit consignee",
    mt_good_consignee_add: "Add consignee",
    mt_ship_mooring_edit: "Edit ship mooring",
    mt_ship_mooring_add: "Add ship mooring",
    mt_mooring_operation_edit: "Edit mooring operation",
    mt_mooring_operation_add: "Add mooring operation",
    "Is Main": "Is Main",
    "": "",
    Remarks: "Remarks",
    tifiles: "Import files",
    tiimportemptyfiles: "Import Excel empty equipments",
    //Facturacion
    billing: "Billing",
    i_type: "Type",
    i_sense: "Serie/Number",
    i_office: "Office",
    i_entity: "Entity",
    i_payment_type_detail: "Payment type detail",
    bill_register: "Bill Register",
    billing_management: "Billing Management",
    btnAddToBillingg: "Billing",
    btnGeneratePdf: "Generate PDF",
    igenerateSummaryDeclaration: "Generate Summary Declaration",
    bill_generated:"Billing record generated correctly",
    bill_equipments_generated: "Billing equipments envolves record generated correctly",
    igroupconcepts: "Group by concepts",
    //fin facturacion
    thandlinginformation: "Handling information",
    tnavisioninvoicecomment: "Navision invoice comment",
    tinvoicelinesconcepts: "Concept fusion lines invoice issued",
    taddresses: "Addresses",
    tdestinationaddresses: "Destination addresses",
    tentities: "Entities",
    ifilecalendar: "Board",
    tobstransportation: "Obs. delivery note transport",
    tobsarrivalnotice: "Obs. Arrival notice/Disptach document",
    imessagetype: "Type of message",
    isel: "Sel",
    inumblshipper: "Num. BLArmador",
    isource: "Source",
    accouting_date: "Accounting date",
    taccouting_date: "Accounting",
    expiration_date: "Expiration date",
    texpiration_date: "Expiration",
    bill_total: "Billing total",
    tbill_total: "Total",
    payment_type_detail: "Payment type detail",
    bill_sense: "Bill sense",
    customer: "Customer",
    billing_exercise: "Billing exercise",
    billing_serie_id: "Billing serie",
    bill_number: "Billing number",
    bill_provider_number: "Supplier Billing number",
    tbill_number: "Number",
    bill_address: "Billing address",
    bill_date: "Billing date",
    bill_date_end: "Expiration date",
    contabi_date: "Posting date",
    tbill_date: "Billing",
    taxes_detail_id: "Taxes detail",
    istate: "State",
    imessagingstartdate: "Date from to of entry/exit.",
    imessagingenddate: "End date of entry/exit.",
    IFTMBFD06A: "Bookings",
    IFTMCS: "Bills of lading",
    COPRAR: "Loading/download list",
    load_terminal: "Terminal loading list",
    load_consignee: "Consignee loading list",
    unload_terminal: "Unload terminal list",
    unload_consignee: "Unload consignee list",
    load_list: "Load List",
    unload_list: "Discharge list",
    lsp_list: "Request LSP",
    summary_list: "Summary declaration",
    generate_pdf: "Generate PDF",
    generate_edi: "Generate EDI",
    sidunea_port: "Sidunea port",
    sidunea_coloader: "Sidunea coloader",
    ViewEDI: "View of the edi path",
    btnCreateInvoice: "Invoice",
    ViewDetailEDI: "Details of the edi path",

    maintenance: "Maintenance",
    imessaginpanelnotfound: "File not found",
    recentlyActivity: "Recent activity",
    windows: "Windows",
    notifications: "Notifications",
    logs: "Activity logs",
    billings: "Invoices",
    isaveForm:
      "There are unsaved changes, if you abandon the page you will lose the changes",
    btnabandonar: "Leave",
    icontainerMovement: "Container Movements",
    imovementtypeselect: "Select the type of movement",
    imovementType: "Movement type",
    imovementDesc: "Movement description",
    containerNumber: "Container registration",
    movementDate: "Movement date",
    incorporationDate: "Incorporation Date",
    all: "All",
    with_billings: "With Billings",
    no_billings: "No Billing",
    billing_filter: "Billing Filter",
    ciclecount: "Cycle count",
    movecount: "Movement count",
    islogic: "Logic movements",
    tipoMessageEntrada: "In",
    tipoMessageSalida: "Out",
    ipending: "Pending",
    iread: "Read",
    iincorporating: "Incorporated",
    iEdiError: "EDI with permanent error",
    rbl: "rbl",
    General: "General",
    bill_A: "CANCELED",
    bill_C: "ACCOUNTED",
    bill_E: "ISSUED",
    bill_H: "VALIDATION REFUSED",
    bill_I: "DRAFT",
    bill_P: "PENDING VALIDATION",
    bill_R: "RECEIVED",
    bill_V: "PENDING EMAIL SEND",
    bill_X: "BAD",
    bill_status: "Bill Status",
    iCorrectBill: "Correct",
    bill_payed: "Paid",
    icontabilizar: "Account",
    ianular: "Cancel",
    iother_comcepts: "Other concepts",
    ishipment_items: "Shipments/Items",
    ishipment_items2: "Shipments",
    traslados_items: "Transfers",
    ishipment_veterinarie: "Veterinary/Pharmacy Dispatches",
    ishipment_farmacy: "Dispatches Pharmacy-No items on Invoice",
    ishipment_soivre: "Dispatches Soivre-No items on Invoice",
    ishipment_leroy: "Dispatches Leroy",
    itransmition_late: "Late transmission VGM",
    ipegatinas_imo: "No IMO Stickers ",
    iwaiver: "Waiver",
    itelex: "Telex",
    idae: "DAE Registry",
    iaduana: "Request Customs",
    igrua: "Crane",
    ictf: "CTF",
    diferent_currency: "All pre-bills must have the same currency",
    diferent_origin:
      "You have selected pre-invoices from different bls or maritime files, do you want to continue?",
    imessageanular: "You want to generate a draft of the invoices to cancel?",
    iAnulada: "CANCELED",
    iRecibida: "RECEIVED",
    iPendienteEnvioMail: "PENDING EMAIL SEND",
    iIncobrable: "UNCOLLECTIBLE",
    iContabilizada: "ACCOUNTED",
    iEmitida: "ISSUED",
    iValidacionRechazada: "VALIDATION REFUSED",
    iBorrador: "DRAFT",
    iPendienteValidar: "PENDING VALIDATION",
    iFacturaEmitida: "Invoice issued",
    iFacturaRecibida: "Invoice received",
    iAbono: "Payment",
    iFacturaRectificada: "Invoice rectify",
    //tabla permisos
    index: "List",
    show: "Show",
    store: "Store",
    update: "Update",
    delete: "Delete",
    assignPermissions: "Assign permissions",
    assignRoles: "Assign roles",
    assignOffices: "Assign offices",
    assignPreferences: "Assign preferences",
    attachFiles: "Attach files",
    attachFile: "Attach file",
    viewFiles: "View files",
    groupBookings: "Group",
    sendMessage: "Send message",
    createMessage: "Create message",
    payment: "Payment",
    generate: "Generate",
    historic: "Historic",
    restore: "Restore",
    forceDelete: "Force delete",
    warning: "Warning",
    iAceptar: "Accept",
    entity_address_type_warning:
      "There are addresses of this entity that are missing their address type",
    iconPendinLines: "There must be at least one billing line",
    iconPendinLines2:
      "The fields Concept, description, currency, unit and total are mandatory in a billing line",
    userName: "Username",
    forgotPassLogin: "Forgot your password?",
    login: "Login",
    logout: "Logout",
    unauthenticated: "Unauthenticated",
    loginLoader: "Login...",
    noAccount: "You do not have an account?",
    tryAgain: "Try again",
    edipath_error: "Edipath file not found on server",
    document_error: "File not found on server",
    unexpectedError: "An unexpected error has occurred",
    adminError: "An error has occurred. Consult with the administrator",
    successOperation: "This is a success operation",
    onlyOneTripType: "Select only items with the same trip type",
    passwordChanged: "Your password has been changed",
    passwordMatch: "Passwords must match",
    thanks: "Ok, Thanks",
    documents: "Documents",
    provider_id: "Supplier",
    customer_id: "Customer",
    currency_id: "Curency",
    filesManagement: "Files management",
    filesType: "File type",
    filesTypeAss: "File type associated",
    uploadDate: "Upload date",
    audioDialog: "Audio player",
    videoDialog: "Video player",
    iloading: "Loading",
    itotals: "Totals",
    billing_concept_id: "Concept",
    play: "Play",
    export: "Export",
    exportAll: "Export All",
    exportSelected: "Export Selected",
    ediFiles: "Edi Files",
    ediFilesInfo: "Upload Edi Files or Process files uploaded",
    process: "Process",
    call_sign: "Call Sign",
    igood_measure_total: "Good weight",
    itemperature_min: "Temperature min",
    itemperature_max: "Temperature max",
    ioverdimension: "Oversize",
    ioverdimensionUMEX: "Oversize UMEX",
    ionu: "ONU",
    //Plantillas
    bills_of_lading_date: "Bills of lading date",
    bills_of_lading_number: "Bills of lading number",
    bill_prefix: "Bill prefix",
    billing_status: "Billing status",
    billing_serie: "Billing serie",
    currency: "Currency",
    customer_name: "Customer name",
    customer_identification: "Customer identification",
    customer_email: "Customer email",
    format_bill_number: "Format bill number",
    office_name: "Office Name",
    reference: "Reference",
    contact_name: "Contact name",
    contact_last_name: "Contact lastname",
    trip_type: "Trip type",
    maritime_voyage_code: "Maritime voyage code",
    maritime_file_code: "Maritime file number",
    mf_type_code: "Maritime file type code",
    port_name: "Port name",
    port_prefix: "Port prefix",
    vessel: "Vessel",
    lloyds_iso: "lloyds iso",
    company_name: "Company name",
    company_website: "Company website",
    company_email: "Company email",
    company_cif: "Company CIF",
    ship_consignee_name: "Ship consignee name",
    ship_consignee_email: "Ship consignee email",
    principal: "Primary",
    secundario: "Secondary",
    m_carga: "Charge M.",
    m_descarga: "Discharge M.",
    summary_statements: "Summary Statements",
    orden_partida_ifcsum: "IFCSUM Split Order",
    orden_partida_ifcsum2: "IFCSUM II Split Order",
    igenratereport: "Report",
    error_max_bill_date: "You must select a period of 1 month or less",
    OfficePrintReport: "Print in Report",
    OfficeAdditionalDocument: "Print On Additional Document",
    OfficeBillingEquiptmentsTypeAttachment:
      "Billing: Type of print Equipment Involves",
    daysDelayReturn: "Delay",
    detentions: "Detentions",
    daysUse: "Day in Use",
    statusBilling: "Billing: No/Pre/Yes",
    notify: "Notify",
    tariffType: "Tariff Type",
    notified: "Notified",
    no_notified: "Without notifying",
    yes_notified: "Notified",
  },
  //español
  es: {
    //Facturacion
    go: "ir",
    ionu: "ONU",
    ioverdimension: "Sobremedidas",
    ioverdimensionUMEX: "Sobremedidas UMEX",
    igood_measure_total: "Peso mercancia",
    itemperature_min: "Temperatura min",
    itemperature_max: "Temperatura max",
    iloading: "Cargando",
    call_sign: "Señal de Llamada",
    itotals: "Totales",
    iFacturaEmitida: "Factura emitida",
    iFacturaRecibida: "Factura recibida",
    iAbono: "Abono",
    iFacturaRectificada: "Factura Rectificada",
    iconPendinLines: "Debe existir al menos una línea de facturación",
    iconPendinLines2:
      "Los campos Concepto, descripción, moneda, unidad y total son obligatorios en una línea de facturación",
    iAceptar: "Aceptar",
    iAnulada: "ANULADA",
    iRecibida: "RECIBIDA",
    iPendienteEnvioMail: "PENDIENTE DE ENVÍO EMAIL",
    iIncobrable: "INCOBRABLE",
    iContabilizada: "CONTABILIZADA",
    iEmitida: "EMITIDA",
    iValidacionRechazada: "VALIDACIÓN RECHAZADA",
    iBorrador: "BORRADOR",
    iPendienteValidar: "PENDIENTE DE VALIDAR",
    diferent_currency: "Todas las prefacturas deben tener la misma moneda",
    diferent_origin:
      "Usted ha seleccionado prefacturas de distintos bls o expedientes marítimos,¿Desea continuar?",
    imessageanular: "¿Desea generar un borrador de las facturas a anular?",
    ishipment_items: "Despachos/Partidas",
    ishipment_items2: "Despachos",
    iwaiver: "Waiver",
    itelex: "Telex",
    idae: "Registro DAE",
    iaduana: "Solicito Aduana",
    igrua: "Grua",
    ictf: "CTF",
    traslados_items: "Traslados",
    ishipment_veterinarie: "Despachos Veterinaria/Farmacia",
    ishipment_farmacy: "Despachos Farmacia-No items en Factura",
    ishipment_soivre: "Despachos Farmacia-No items en Factura",
    ishipment_leroy: "Despachos Leroy",
    itransmition_late: "Transmición tardía VGM",
    iother_comcepts: "Otros conceptos",
    ipegatinas_imo: "No Pegatinas IMO",
    bill_payed: "Pagada",
    icontabilizar: "Contabilizar",
    ianular: "Anular",
    iCorrectBill: "Corregir",
    bill_status: "Estado de la factura",
    all: "Todos",
    with_billings: "Con Facturas",
    no_billings: "Sin Facturas",
    billing_filter: "Filtro por Facturas",
    bill_A: "ANULADA",
    bill_C: "CONTABILIZADA",
    bill_E: "EMITIDA",
    bill_H: "VALIDACIÓN RECHAZADA",
    bill_I: "BORRADOR",
    bill_P: "PENDIENTE DE VALIDAR",
    bill_R: "RECIBIDA",
    bill_V: "PENDIENTE DE ENVIÓ EMAIL",
    bill_X: "INCOBRABLE",
    ipending: "Pendiente",
    iread: "Leído",
    iincorporating: "Incorporado",
    iEdiError: "EDI con error permanente",
    tipoMessageEntrada: "Entrada",
    tipoMessageSalida: "Salida",
    islogic: "Movimientos lógicos",
    ciclecount: "Contador de ciclo",
    movecount: "Contador de movimiento",
    movementDate: "Fecha del movimiento",
    incorporationDate: "Fecha del incorporación",
    containerNumber: "Matrícula del contenedor",
    imovementType: "Tipo movimiento",
    imovementDesc: "Descripción movimiento",
    imovementtypeselect: "Seleccione el tipo de movimiento",
    icontainerMovement: "Movimiento de Contenedores",
    btnabandonar: "Abandonar",
    isaveForm:
      "Existen cambios sin guardar, si bandona la página perdera los cambios",
    billings: "Facturas",
    logs: "Log de Actividades",
    notifications: "Notificaciones",
    windows: "Ventanas",
    recentlyActivity: "Actividad reciente",
    imessaginpanelnotfound: "No se encontró el archivo",
    maintenance: "Mantenimiento",
    COPRAR: "Lista de carga/descarga",
    load_list: "Lista de carga",
    unload_list: "Lista de descarga",
    lsp_list: "Solicitar LSP",
    summary_list: "Declaración sumaria",
    load_terminal: "Envío a terminal",
    load_consignee: "Envío a consignatario",
    generate_pdf: "Generar PDF",
    generate_edi: "Generar EDI",
    sidunea_port: "Sidunea puerto",
    sidunea_coloader: "Sidunea cargador",
    ViewEDI: "Vista del edi path",
    ViewDetailEDI: "Detalles del edi path",
    IFTMBFD06A: "Bookings",
    IFTMCS: "Conocimiento",
    imessagingstartdate: "Fecha desde hasta de entrada/salida.",
    imessagingenddate: "Fecha fin de entrada/salida.",
    isource: "Fuente",
    payment_type_detail: "Detalle del tipo de pago",
    bill_sense: "Sentido de la factura",
    customer: "Proveedor",
    billing_exercise: "Ejercicio de facturación",
    billing_serie_id: "Serie de facturación",
    bill_number: "Número de facturación",
    bill_provider_number: "Número de facturación Proveedor",
    tbill_number: "Número",
    bill_address: "Dirección de facturación",
    bill_date: "Fecha de facturación",
    bill_date_end: "Fecha de Expiración",
    contabi_date: "Fecha de Contabilización",
    tbill_date: "Facturación",
    accouting_date: "Fecha de creación",
    taccouting_date: "Creación",
    expiration_date: "Fecha expiración",
    texpiration_date: "Expiración",
    bill_total: "Facturación total",
    tbill_total: "Total",
    taxes_detail_id: "Detalles de Impuestos",
    observations: "Observaciones",
    btnSaveDraft: "Guardar Borrador",
    btnCreateInvoice: "Emitir factura",
    btnDiscard: "Descartar",
    btnCode: "Enviar Código",
    btnCodeWrite: "Escribir Código",
    prebilling_id: "Id Prefacturación",
    billing_concept_id: "Concepto",
    billing_concept: "Descripción",
    fushion_concept: "C. en factura",
    typetrafic: "Tipo de trafico",
    provider_id: "Proveedor",
    customer_id: "Cliente",
    currency_id: "Moneda",
    imessaginpanel: "Panel de mensajería",
    imessaging: "Mensajería",
    idispatcher: "Despachador",
    imessagetype: "Tipo de mensaje",
    isel: "Sel",
    inumblshipper: "Num. BLArmador",
    identification: "Identificación",
    unit_amount: "Monto total",
    unit_sell_amount: "P. Unidad",
    currencies: "Monedas",
    origincurrencies: "Moneda origen",
    torigincurrencies: "Origen",
    exchangecurrencies: "Moneda cambio",
    texchangecurrencies: "Cambio",
    iamount: "Monto total",
    iExpirationDates: "Fechas de expiración",
    imooringDates: "Fecha de atraque",
    iPreBill: "Lineas de facturas",
    taxesDetails: "Detalles del impuesto",
    billing: "Facturación",
    i_type: "Tipo",
    i_sense: "Serie/Número",
    i_office: "Officina",
    i_entity: "Entidad",
    i_payment_type_detail: "Tipo de pago",
    bill_register: "Registro de Facturación",
    billing_management: "Gestión de Facturación",
    btnAddToBillingg: "Facturar",
    btnGeneratePdf: "Generar PDF",
    igenerateSummaryDeclaration: "Generar Declaración Sumaria",
    bill_generated: " Registro de facturacion generado correctamente",
    bill_equipments_generated:
      " Registro de equipos facturados generado correctamente",
    igroupconcepts: "Agrupar por conceptos",
    //fin facturacion
    language: "Idioma",
    selectlang: "Seleccione el idioma",
    selectview: "Seleccione el tipo de vista",
    selecnotification: "Seleccione el tiempo de notificaciones",
    selectwindows: "Seleccione la cantidad de ventanas a mostrar",
    selectactivitieslogs: "Seleccione la cantidad de activities logs a mostrar",
    selectitemsperpage: "Seleccione la cantidad de elementos por página",
    selectpageloader: "Seleccione el cargador de la página",
    displaypageloader: "Visualizar el cargador de la página",
    itemsperpage: "Elementos por página",
    viewtype: "Tipo de vista",
    en: "Ingles",
    es: "Español",
    // menu
    home: "Inicio",
    dashboard: "Tablero",
    layoutBuilder: "Preferencias",
    usersgroupIndex: "Grupos de usuarios",
    user: "Usuarios",
    iuser: "Usuario",
    isalesassociate: "Asociado de ventas",
    iresponsibleassociate: "Asociado responsable",
    profile: "Mi perfil",
    profileOverview: "Descripción general",
    profileDetails: "Detalles del perfil",
    editProfile: "Editar Perfil",
    maritimeFileOverview: "Descripción general",
    tobservations: "Observaciones",
    iis_main: "Principal",
    ipackaging_type_id: "Tipo de embalaje",
    ipackages: "Paquetes",
    ipackagegroup: "Grupo de paquete",
    igross_weight: "Peso bruto",
    igross_weight_verify: "Peso bruto verificado",
    inumber_of_packages: "Número de bultos",
    istart_date: "Fecha inicio",
    iend_date: "Fecha fin",
    account: "Cuenta",
    iun: "UN",
    accountOverview: "Descripción general",
    iAssessableWeight: "Peso Evaluable",
    settings: "Ajustes",
    permissionsIndex: "Permisos",
    usersIndex: "Gestión de usuarios",
    userCreate: "Nuevo usuario",
    userUpdate: "Editar usuario",
    companies: "Empresas",
    companiesIndex: "Gestión de empresas",
    companiesCreate: "Nueva empresa",
    companiesUpdate: "Editar empresa",
    companiesGroup: "Grupo de empresas",
    companiesGroupIndex: "Gestión de grupo de empresas",
    companiesGroupCreate: "Nuevo grupo de empresas",
    companiesGroupUpdate: "Editar grupo de empresas",
    offices: "Oficinas",
    officesIndex: "Gestión de oficinas",
    officesCreate: "Nueva oficina",
    officesUpdate: "Editar oficina",
    entityCreate: "Nueva Entidad",
    entityUpdate: "Editar Entidad",
    contactCreate: "Nuevo Contacto",
    contactUpdate: "Editar Contacto",
    maritime_agency: "Agencia marítima",
    crm: "CRM",
    maritime_files: "Expedientes maritimos",
    transport_orders: "Órdenes de Transporte",
    filters: "Filtros",
    maritime_file: "Expediente",
    files: "Expedientes",
    maritime_trips: "Viajes",
    ot_type: "Tipo de Orden",
    ot_edifact_status: "Estado",
    ot_edipath_status: "Estado del Doc",
    maritime_trip: "Viaje",
    measures_unit: "Unidades de Medidas",
    measures_type: "Tipo de Medidas",
    measure_unit_type: "Tipo de Unidad de Medidas",
    address_type: "Tipo de Direcciones",
    tariff: "Tablas de criterios de tarifas",
    table: "Tabla",
    column: "Columna",
    tariff_criteria: "Criterios de tarifas",
    ports: "Puertos",
    vessels: "Buques",
    docks: "Muelles",
    contacts_type: "Tipos de Contactos",
    contacts: "Contactos",
    routes_type: "Tipo de rutas",
    routes: "Rutas",
    equipments: "Contenedores",
    equipment: "Contenedor",
    equipments_type: "Tipo de equipamiento",
    equipments_subtype: "Subtipo de equipamiento",
    equipments_group: "Grupo de equipamiento",
    sectors_type: "Tipo de sector",
    sectors: "Sectores",
    itechnicalname: "Nombre Técnico",
    ichapter: "Capítulo",
    transport_order_status: "Estado de la orden de transporte",
    transport_order_type: "Tipo de la orden de transporte",
    doc_status: "Estado del Doc.",
    tdoc_status: "Estado Doc.",
    maritime_file_type: "Tipo Expediente maritimo",
    maritime_file_operations: "Tipo de Operacion Expediente maritimo",
    maritime_services: "Tipo de Servicio maritimo",
    forwarder_job_traffic_type: "Tipo de tráfico",
    forwarder_offer_status: "Estatus de oferta",
    master_job: "Expediente Maestro",
    forwarder_job_status: "Estados de transitario",
    forwarder_job_offer_type: "Tipos de ofertas",
    forwarder_job_document_type: "Tipos de documentos",
    forwarder_job_shipped_via: "Vías de entrega",
    forwarder_job_language: "Idiomas",
    forwarder_job_operation_type: "Tipos de operación",
    forwarder_job_route_type: "Tipos de rutas",
    forwarder_job_incoterm_type: "Incoterms",
    forwarder_job_category: "Categorías",
    forwarder_air_way_bill: "Guía aérea",
    forwarder_job_freight_type: "Tipo de Carga",
    forwarder_voyage_air_freight_route: "Ruta aérea de carga",
    forwarder_job_good_status: "Estado de Bienes",
    forwarder_job_groupage_type: "Tipo de Grupaje",
    forwarder_job_payment_method: "Tipo de Método de Pago",
    forwarder_job_service_lines: "Líneas de Servicio",
    forwarder_offer_type: "Tipo de Oferta",
    forwarder_job_offer_observation_templates:
      "Plantillas de observaciεn de ofertas",
    icategory: "Categoría",
    forwarder_job_categories: "Tipo de expediente",
    media_type: "Tipos de medios de comunicación",
    communication_contact: "Comunicaciones de Contactos",
    location_type: "Localidades",
    kilometers: "Kilómetros",
    synchronize: "Sincronizaciones",
    synchronizeflagid: "ID Bandera",
    synchronizeflag: "Bandera",
    externalcode: "Cod. externo",
    action_type: "Tipos de accciones",
    location_origin: "Localidades de Origen",
    ilocationorigin: "Origen",
    ilocationdestiny: "Destino",
    ipol: "POL",
    ipod: "POD",
    iaol: "AOL",
    iaod: "AOD",
    ishippinginfo: "Información sobre el envío",
    imerchandisedetails: "Detalles de la mercancía",
    imerchandise: "Mercancía",
    iregistered_equipment: "Equipamientos registrados",
    location_load: "Localidades de Carga",
    location_destiny: "Localidades de Destino",
    location_discharge: "Localidades de Descarga",
    load: "Carga",
    discharge: "Descarga",
    location_abroad: "Localidad",
    ismanifest: "Manifiesto",
    freightpaymenttype: "Pago flete",
    tfreightpaymenttype: "Flete",
    isMaritime: "Prefacturar a expediente",
    allowbilling: "Perm.Facturación",
    tallowbilling: "Facturacion",
    regions: "Regiones",
    others: "Otros",
    bookings: "Bookings",
    bills_of_lading: "Conocimientos",
    type_entities: "Tipo de Entidades",
    goods_type: "Tipo de bienes",
    entities: "Entidades",
    catalogs: "Catálogos",
    id: "Id",
    accounting_plan: "Datos Contables",
    identifier: "Identificador",
    edataKey: "Identificador de Datos Económicos",
    pick_dates: "Elige una o más fechas",
    dates_create: "Fecha de Creado",
    economic_data: "Datos económicos",
    smpurposes: "Propositos de atraque",
    smrequirements: "Requerimientos de atraque",
    smoperation_type: "Tipos de operaciones",
    // end menu
    //buttons
    btnSave: "Guardar",
    tryAgaint: "Aceptar",
    btnSend: "Enviar mensajería",
    btnEdit: "Editar",
    btnNew: "Nuevo",
    btnGroup: "Agrupar",
    btnDelete: "Eliminar",
    btnCancel: "Cancelar",
    btnEmail: "Enviar Correo",
    btnExportEquipments: "Generar Excel Equipos",
    iva_detail_sell_id: "Detalle Impuesto",
    iva_detail_cost_id: "Detalle Impuesto Coste",
    unit_cost: "Unidades de coste",
    unit_sell: "Unidades",
    btnConfirm: "Confirmar",
    btnpermanet: "Borrado permanente",
    btntemp: "Borrado temporal",
    btnsoftdelete: "Eliminacion suave",
    btndelete: "Eliminacion permanente",
    btnCopy: "Duplicar",
    btnSelect: "Seleccionar",
    btnMove: "Mover",
    btnAdd: "Agregar",
    btnUpdateEquipmentsGoods: "Actualizar Contenedores y Mercancías",
    wait: "Por favor espere...",
    btnDetails: "Ver detalle",
    loading: "Cargando",
    //end buttons

    //inputs
    lblForgotPassword: "¿Ha olvidado su contraseña?",
    lblForgotPasswordSub:
      "Ingrese su correo electrónico para restablecer su contraseña.",
    lblForgotPasswordCode:
      "Escribe el código que te enviamos por correo electrónico.",
    lblForgotPasswordInput: "Escribe la nueva contraseña.",
    lblForgotPasswordInputConfirm: "Confirma la nueva contraseña.",
    lblEmailCode: "Código",
    ioffertype: "Tipo de oferta",
    btndownload: "Descargar",
    idocumenttype: "Tipo de documento",
    imatter: "Asunto",
    iclient: "Cliente",
    ipotencial_client: "Cliente Potencial",
    icorrespondent: "Corresponsal",
    icarrier: "Transportista",
    iclientcontact: "Contacto de cliente",
    itransportationvia: "Vía de transporte",
    itransport_type: "Tipo de Transporte",
    titransport_type: "Transporte",
    idescription: "Descripción",

    ithumbnail: "Imagen",
    iurl: "Dirección",
    admission_status: "Estado de Admisión",
    isalejoblanguage: "Idioma de la oferta",
    ifromto: "Creación Desde/Hasta",
    booking_number: "Numero de Reserva",
    matter: "Asunto",
    house_number: "Numero de casa",
    file_id: "Id de Archivo",
    master_number: "Numero Maestro",
    assing_offer: "Asignar Oferta",
    assing_master: "Asignar Maestro",
    customer_reference: "Referencia de cliente",
    iarrivefromto: "Llegada Desde/Hasta",
    iexecution_from_to: "Ejecución Desde/Hasta",
    iupdated_from_to: "Actualizado Desde/Hasta",
    ivalid_from: "Válido desde",
    ivalid_until: "Válido hasta",
    iclientreference: "Referencia cliente",
    icontactname: "Nombre de contacto",
    icontactemail: "E-mail de contacto",
    ipaymentmethod: "Método de pago",
    ifirst_payment_deferral: "Aplazamiento del primer pago",
    ipaymentnumber: "Número de Pago",
    itraffictype: "Tipo de tráfico",
    ioperationtype: "Tipo de operación",
    ifilecreator: "Creador por",
    imaritime_service: "Servicio marítimo",
    ifrequency: "Frecuencia",
    itemplate: "Plantilla",
    itemplate_type: "Tipo de Plantilla",
    itemplates: "Plantillas",
    icarbrands: "Marcas de Carro",
    ibrand: "Marca",
    imodel: "Modelo",
    iemailtemplates: "Plantillas de correo",
    ivars: "Variables",
    iprofile: "Perfil",
    ioffice: "Oficina",
    jobid: "Número de expediente",
    iroute: "Ruta",
    ihour: "Hora",
    iestimatedtime: "Tiempo estimado",
    iemail: "Correo electronico",
    iismain: "Principal",
    imain: "Principal",
    inotification: "Notificaciones de eventos",
    iwindows: "No. de ventanas a mostrar",
    iactivitieslog: "No. de logs de actividades a mostrar",
    iexacttime: "Hora exacta",
    ithirtyminutesbefore: "30 min. antes",
    ihourbefore: "1 hora antes",
    idaybefore: "1 día antes",
    ipassword: "Contraseña",
    oldPassword: "Contraseña anterior",
    iassignoffices: "Asignar oficinas",
    imeasureunittype: "Tipo de UM",
    iname: "Nombre",
    ibody: "Cuerpo",
    Name: "Nombre",
    ilevel: "Nivel",
    emailChangeSuccess: "Correo electrónico actualizado exitosamente",
    ivalidatedatefrom: "Válida desde",
    ivalidatedateto: "Válida hasta",
    iprohibitededitbooking: "Proh.Ed.Booking",
    iprohibitededitcontract: "Proh.Ed.Contrato",
    iissupplied: "Suplido",
    status_led: "",
    Id: "Id",
    iisoptional: "Opcional",
    iisconditional: "Condicional",
    iismandatory: "Requerido",
    iavailabletariffcriteria: "Criterios disponibles",
    iusedtariffcriteria: "Criterios usados",
    tariff_criteria_level: "Niveles de criterios de tarifas",
    tariff_cost_criteria_level: "Niveles de criterios de tarifas de coste",
    tariff_sale_criteria_level: "Niveles de criterios de tarifas de venta",
    tariffs: "Tarifas",
    tariffs_cost: "Tarifas de coste",
    tariffs_sale: "Tarifas de venta",
    iunitpaidselectable: "Unidad Pagos Seleccionable",
    mt_tariff_criteria_level_add: "Adicionar niveles de criterios de tarifas",
    mt_tariff_criteria_level_edit: "Editar niveles de criterios de tarifas",
    mt_billing_concept_type_add: "Adicionar Tipos de conceptos de facturación",
    mt_billing_concept_type_edit: "Editar Tipos de conceptos de facturación",
    mt_billing_concept_code_add:
      "Adicionar Códigos de conceptos de facturación",
    mt_billing_concept_code_edit: "Editar Códigos de conceptos de facturación",
    mt_tariff_criteria_add: "Adicionar criterios de tarifas",
    mt_tariff_criteria_edit: "Editar criterios de tarifas",
    mt_subdivision_add: "Adicionar Subdivisión",
    mt_subdivision_edit: "Editar Subdivisión",
    mt_tariff_add: "Adicionar tarifa",
    mt_tariff_edit: "Editar tarifa",
    btnClear: "Limpiar",
    ilast_name: "Apellidos",
    igender: "Sexo",
    illoyd: "Lloyd iso",
    iowner: "Dueño",
    ioperator: "Operador",
    isize: "Medidas",
    isizef: "Tamaño",
    itemperature: "Temperatura",
    imanufacturing_materials: "Materiales",
    icoordinates: "Coordenadas",
    icountry: "País",
    iiata_designator: "Código Iata",
    iicao_code: "Código Icao",
    icountries: "Paises",
    isubdivision: "Subdivición",
    ilocation: "Ubicación",
    ibillingseriestype: "Tipos de series de facturación",
    ibillingseries: "Series de facturación",
    ibillingstatus: "Estado de Facturación",
    ibilling_series_required: "La Serie de facturación es requerida",
    invalidInputNumber: "El campo solo admite números",
    billing_series_type_id: "Series de facturación",
    last_billing_number: "Número ultima facturación",
    prefix_billing_number: "Prefijo para el número de facturacion",
    billing_customer_required: "El cliente es requerido",
    billing_reference_required: "La referencia es requerida",
    payment_details_required: "El tipo de pago es requerido",
    customer_address_required: "La direccion de facturación es requerida",
    invoice_number_required: "El numero de factura es requerido",
    tax_code_required: "Los detalles de impuestos son requeridos",
    digits: "Dígitos",
    sense: "Sentido",
    ifullcode: "Código completo",
    ifullname: "Nombre completo",
    iiso: "ISO",
    imaritime: "Maritimo",
    isubdivisions: "Subdiviciones",
    iborndate: "Fecha de nacimiento",
    iid: "Identificador",
    iaddress: "Direcciones",
    icommunications: "Comunicaciones",
    ifiscaladdress: "Dirección fiscal",
    iphone: "Teléfono",
    ifax: "Fax",
    icif: "NIF/CIF",
    icifo: "CIF",
    icompany: "Empresa",
    ifiscaldata: "Datos Fiscales",
    iaccountingplan: "Plan contable",
    icompaniesgroup: "Grupo de empresas",
    ientity: "Entidad",
    imedia: "Media",
    ivalue: "Valor",
    icosttypeid: "Tipo de costo",
    iunits: "Unidades",
    iapply_to: "Opciones",
    iamounts: "Cantidades",
    iamounts2: "Margen",
    payment_date: "Fecha del pago",
    payments_number: "Número de Pagos",
    payment_dates: "Fechas de pago",
    iunitamount: "Cantidad x unidad",
    itotalamount: "Total",
    icurrency: "Moneda",
    itaxes: "Impuestos",
    itaxesDetail: "Detalles de impuestos",
    itaxesType: "Tipo de impuestos",
    itaxid: "Impuesto",
    itaxtypeid: "Tipo de impuesto",
    ientityreference: "Entidad de referencia",
    ientitydata: "Datos de entidad",
    iwebsite: "Sitio web",
    istatus: "Estatus",
    istate: "Estado",
    istatusactive: "Activa",
    ipath: "Ruta",
    istatuscancel: "Cancelada",
    icurrentpass: "Contraseña actual",
    inewpass: "Contraseña nueva",
    ianulatioconfirm: "Confirmar anulación",
    confirmPass: "Confirmar contraseña",
    confirmOffer: "Confirmar Oferta",
    iconfirmnewpass: "Confirmar contraseña nueva",
    iupdatepass: "Actualizar contraseña",
    icode: "Código",
    icode_alpha3: "Código Iso",
    icurrency_code: "Código del pais",
    icurrency_name: "Nombre de la moneda",
    icallingcode: "Prefijo telefónico",
    inote: "Nota",
    iincidence: "Incidencia",
    ifile: "Expediente",
    ifileType: "Tipo de expediente",
    ioffices: "Oficinas",
    iactiontype: "Tipo de acción",
    iactionstatus: "Estado de la acción",
    iactionreason: "Razón de la acción",
    iactionresult: "Resultado de la acción",
    iport: "Puerto",
    imaritimevoyage: "Viaje maritimo",
    isecondaryvoyage: "Viaje secundario",
    tmaritimevoyage: "Viaje",
    tmaritimevoyagep: "Viaje llegada",
    tmaritimevoyagesa: "Viaje salida",
    irejection_motive: "Motivo de Rechazo",
    iheader: "Encabezado",
    ifooter: "Pie de página",
    iinternal_notes: "Notas internas",
    iinternal_notes_Offer: "Notas internas de Offerta",
    iconcept: "Concepto",
    idock: "Muelle",
    idockcompound: "Muelle/Recinto",
    idockaeat: "Muelle AEAT",
    inum_decimals: "Números decimales",
    inum: "Num",
    isymbol: "Símbolo",
    isymbol_native: "Símbolo nativo",
    ivessel: "Buque",
    imanufacturyear: "Año de construcción",
    ifretwork: "Calado",
    ishipConsignne: "Consignatario de buque",
    iconsignne: "Consignatario",
    icustomer: "Cliente",
    icontact: "Contacto",
    istreet: "Calle",
    address_type_id: "Tipo de dirección",
    address_types: "Tipo de dirección",
    inumber: "Número",
    iforwarder_voyage: "Viaje de tránsito",
    iforwarder_air_freight_company: "Compañía aérea de carga",
    flight_number: "No. vuelo",
    voyage_number: "No. viaje",
    forwader_Voyage: "Viaje",
    sequence_lenght: "Cantidad de Guias Aereas",
    ifloornumber: "Piso",
    idoor: "Puerta",
    icheckin: "Llegada",
    icheckout: "Salida",
    ioperation: "Operación",
    iprefix: "Prefijo",
    icalls: "Escala",
    icapacity: "Capacidad",
    icapital: "Capital",
    ilength: "Largo",
    ibeam: "Beam",
    iheight: "Alto",
    itotalunits: "Total unidades",
    itotalweigth: "Total peso",
    itotalcbn: "Total CNB",
    icbn: "CBN",
    iimport: "Declaracion Sumaria I",
    iexport: "Declaracion Sumaria E",
    iimportflow: "Importación",
    timport: "Importar",
    iexportflow: "Exportación",
    icrosstrade: "Crosstrade",
    itrip: "Viaje",
    itripType: "Tipo viaje",
    ttripType: "Tipo",
    iremarks: "Comentarios",
    iextra: "Información",
    iobservation: "Observaciones",
    iobservationHeads: "Observaciones de cabecera",
    iobservationFoot: "Observaciones de pie",
    iAdmissionStatus: "Estado de Admisión",
    iReceptionStatus: "Estado de Recepción",
    iTransportMode: "Modo de transporte",
    ishipowner: "Armador",
    naviera: "Naviera",
    ishipowner2: "PV Armador",
    tiOrderTransport: "Ordenes de Transporte",
    orderTransport: "Orden de Transporte",
    file_not_generated: "Fichero no Generado",
    timodeclaration: "Declaración IMO",
    tdispatches: "Despachos",
    tdispatche: "Despacho",
    customs_situation_document_id: "Doc. situación aduanera",
    customs_situation_document: "Doc. situación",
    rolarSelected: "Rolar Seleccionados",
    addCustomer: "Agregar Cliente",
    description_document: "Doc. Descripción",
    dispatch_date: "Fecha",
    mask: "Máscara",
    customs_document_number: "Número doc.",
    iTransportOrderType: "Tipo de orden de transporte",
    tiTransportOrderType: "Tipo OT",
    iTransportOrderStatus: "Estado de la orden de transporte",
    iTransportType: "Tipo de transporte",
    irequestquotation: "Solicitar cotización",
    hidedetails: "Ocultar detalles",
    details: "Detalles",
    tariffcode: "Carga",
    paymentunit: "Unidad de pago",
    ibookingNumber: "No. booking",
    iFiltterName: "Nombre Edi",
    iwithbl: "Incluir Bl",
    tbookingNumber: "Booking",
    iblNumber: "No. conocimiento",
    imasterBLNumber: "No. conocimiento maestro",
    ihouseBLNumber: "No. conocimiento hijo",
    ifile_id: "Identificador del expediente",
    ifileNumber: "No. expediente",
    ibookingDate: "Fecha booking",
    iToEquipment: "Equipamiento",
    ishipload_id: "Cargamento",
    ito_special_condition_id: "Cond. Especial",
    iregistered_equipment_id: "Equipo registrado",
    iequipment_id: "Equipamiento",
    iregistration_number: "No. registro",
    itruck_registration_number: "No. registro camión",
    itrailer_registration_number: "No registro remolque",
    idrivers_name: "Nombre conductor",
    idestination_address: "Dirección destino",
    iorigin_address: "Dirección origen",
    tiregistration_number: "Registro",
    iequipment_type: "Tipo equip.",
    iequipment_size: "Tamaño equip.",
    itare: "Tara",
    iconnected_onboard: "Con. a bordo",
    indicences: "Incidencias",
    iconnected_in_station: "Con. a terminal",
    iconnected_transport: "Con. transporte",
    irelative_humidity: "Humedad Relativa",
    icontain_origin: "Origen",
    icontain_destination: "Destinación",
    idestination_service: "Dest. servicio",
    iis_shipper_owner: "Dueño del cargador",
    iseal_number: "No. sello",
    iorigin_service: "Serv. de origen",
    iallowed_by_pif: "Perm. por pif",
    iallowed_by_customs: "Perm. por aduana",
    allowed_files_types: "Tipos de Archivos Permitidos:",
    iweighers: "Pesadores",
    iis_empty: "Vacío",
    iemptyequipments: "Equipamientos vacíos",
    inumber_onu: "No. ONU",
    iarticle_15: "Artículo 15",
    isea_pollutant: "Contaminante marino",
    ilimited_amount_exemptions: "LAE",
    iflash_point: "Flashpoint",
    ifullequipments: "Equipamientos llenos",
    iis_full: "Lleno",
    ito_measures: "Medidas",
    ParticipantList: "Lista de participantes",
    EventList: "Eventos Temporales",
    event_type_id: "Tipos de Eventos",
    ito_participant_type_id: "Tipo de participante",
    ientity_id: "Entidad",
    iftx_entity: "Entidad FTX",
    iaddress_id: "Dirección",
    iftx_address: "Dirección FTX",
    icontact_id: "Contacto",
    ftx_contact: "Contacto FTX",
    iavailabilitydate: "Fecha de disponibilidad",
    istartdate: "Fecha inicio",
    ienddate: "Fecha Fin",
    ibookingref: "Referencia",
    ishipper: "Destinatario/Remitente",
    ishipperAddress: "Destinatario/Remitente direccion",
    iActions: "Acciones",
    iBooking: "Booking",
    iDescription: "Descripción",
    idateselect: "Seleccione una fecha",
    itimeselect: "Seleccione una hora",
    idischargedate: "Fecha de alta",
    idistance: "Distancia",
    idistances: "Distancias",
    istarttime: "Hora de inicio",
    iendtime: "Hora fin",
    inextaction: "Fecha de acción siguiente",
    inextactiontype: "Tipo de acción siguiente",
    iweight: "Peso",
    iunit_weight: "Peso unitario",
    ibulks: "Bultos",
    iinternational_codes: "Códigos internacionales",
    inational: "Nacional",
    imaritime_trip_types: "Internacionals codes",
    iparticipantType: "Tipo de participante",
    iparticipantsType: "Tipos de participantes",
    iparticipant: "Participantes",
    iresponsibles: "Responsables",
    iBilling_concepts: "Conceptos de facturación",
    iBilling_concepts_cost: "Conceptos de facturación de coste",
    iBilling_concepts_sale: "Conceptos de facturación de venta",
    iBilling: "Facturación",
    permissions: "Grupos de usuario",
    iconceptsTypes: "Tipos de Conceptos de Facturación",
    iconceptsCodes: "Códigos de Conceptos de Facturación",
    icostTypes: "Tipos de coste",
    iPaymentsTypes: "Tipos de Pagos",
    first_payment_deferral: "Aplazamiento del primer pago",
    iPaymentsTypesDetails: "Detalles de Tipos de Pagos",
    iPaymentsDays: "Dias de Pagos",
    iPackagingType: "Tipos de embalaje",
    itype: "Tipo",
    applies: "Aplicaciones",
    Type: "Tipo",
    Size: "Tamaño",
    User: "Usuario",
    irealname: "Nombre a mostrar",
    imin: "Mínimo",
    imax: "Máximo",
    irates: "Tarifas",
    icondition: "Condición",
    iprice: "Precio",
    iisfixed: "Es fijo",
    ifixedprice: "Precio fijo",
    iunitprice: "Precio unitario",
    iminprice: "Precio min.",
    imaxprice: "Precio max.",
    ipayunit: "Unidad pago",
    ifactor: "Factor",
    iissurcharge: "Recargo",
    iisextended: "Extendido",
    irevenueselect: "Seleccionar ganancia",
    irevenue: "Ganancia",
    irevenuepercent: "Porcentaje de ganancia",
    irevenueunit: "Unidad de ganancia",
    iisdiscount: "Descuento",
    isimple: "Simple",
    ipercentage: "Porciento",
    itariffcriteriaid: "Criterio",
    itariffcriterialevel: "Nivel",
    irange: "Rango",
    iextension: "Extensión",
    icustomernom: "icustomernom",
    ibillinggroup: "ibillinggroup",
    icodconc: "icodconc",
    ibillingunits: "ibillingunits",
    iprevcost: "iprevcost",
    iprevmoney: "iprevmoney",
    isale: "isale",
    idivsale: "idivsale",
    icost: "Coste",
    cost: "Coste",
    sell: "Venta",
    iimpbuy: "iimpbuy",
    iimpsale: "iimpsale",
    ipvoucher: "ipvoucher",
    isupplier: "isupplier",
    inomsupplier: "inomsupplier",
    iobservations: "Observaciones",
    igroup: "igroup",
    itablenamerelationship: "Relación",
    quotations: "Cotizaciones",
    quotationsMenu: "Tarifas",
    imenu: "Menu",
    manage_quotations: "Gestionar cotizaciones",
    manage_offers: "Gestionar ofertas",
    offer: "Oferta",
    quotation: "Campo en cotizaciones",
    ititle: "Título",
    isection: "Tramo",
    iestimatedarrivaldate: "Fecha estimada llegada",
    iarrivaldate: "Fecha llegada",
    iclosingdate: "Fecha cierre",
    iestimateddeparturedate: "Fecha estimada salida",
    ideparturedate: "Fecha Salida",
    iinitialBL: "BL Inicial",
    ifinalBL: "BL Final",
    iinitialDeparture: "Partida inicial",
    ifinalDeparture: "Partida final",
    ibaseSection: "Tramo base",
    isectionIdentifier: "Id tramo",
    ismrequirement: "Requerimiento",
    ismpurpose: "Proposito",
    ismoperationType: "Operación",
    igoodsType: "Mercancia",
    istation: "Terminal",
    inodata: "No hay datos",
    iselectmaritimevoyage: "Seleccione el viaje maritimo",
    iselectcustomer: "Seleccione el cliente",
    isendarrivalnoticemail: "Enviar aviso de llegada",
    itariffheadings: "Partidas arancelarias",
    isendbillingemail: "Enviar factura",
    hasbilling: "Tiene Facturas",
    isendemail: "Enviar correo",
    isubjectRequired: "El asunto es requerido",
    isendpdfdialog: "Enviar adjunto",
    iimportdocument: "Incorporar documento",
    isend: "Enviar",
    iselectall: "Seleccionar todos",
    ideselectall: "Deseleccionar todos",
    ivolume: "Volumen",
    iclass: "Clase",
    ilinear_meters: "Metros lineales",
    iassessable_weight: "Peso tasable",
    igoods_group: "Agrupación mercancía",
    iequipment_class: "Clase de equipamento",
    imetric_unit: "Unidad métrica",
    idangerous: "Peligroso",
    isearch: "Buscar",
    ielements: "elemento(s)",
    iconcession: "Conseción",
    izone: "Zona",
    ialignment: "Alineación",
    iselect: "Seleccione un elemento",
    iselectexistent: "Selecciona uno existente",
    iallcategories: "Todas las categorías",
    iperiod: "Periodo",
    iselectdate: "Seleccione una fecha",
    ilegalname: "Nombre legal",
    iidentificationtypes: "Tipos de identificación",
    iidentifications: "Identificaciones",
    ilogo: "Logo",
    imanufacturingmaterials: "Materiales de producción",
    imanufacturingdate: "Fecha de producción",
    iequipmenttype: "Tipo de equipamiento",
    iportauthority: "Autoridad portuaria",
    iportauthoritycode: "Código de la Autoridad portuaria",
    ibillofladingtype: "Tipo de BL",
    inextprevioustransportmethod: "Siguiente método de transporte anterior",
    icustomimport: "Aduana importación",
    icustomexport: "Aduana exportación",
    iaddressrouteedi: "Dirección ruta edi",
    icolor: "Color",
    iorder: "Orden",
    idate: "Fecha",
    iexecutionDate: "Fecha de ejecución",
    icreationdate: "Fecha creación",
    iblid: "BL id",
    bldate: "Fecha de BL",
    mfdate: "Fecha del Expediente",
    icontainer: "Contenedor",
    icontainernumber: "No. de contenedor",
    icontent: "Contenido",
    iregistercontainer: "Matrícula",
    icontainertype: "Tipo de contenedor",
    icontainermovement: "Movimientos del contenedor",
    ipackagingtype: "Tipo de embalaje",
    igoods: "Mercancías",
    ishiploadsgoods: "Contenedores-Mercancías",
    iaddot: "OT",
    iimodeclaration: "Declaración",
    iimodeclarationdialog: "Declaración de mercancía peligrosa",
    icancelled: "Cancelado",
    iedigenerated: "Gen. edifact",
    inovehicles: "No. vehículos",
    imessaging_operation_instruction: "Inst.Operación",
    imessaging_iftdgn_cargo_type: "Tipo de carga",
    timessaging_iftdgn_cargo_type: "Carga",
    imessaging_transport_method: "Método de transporte",
    timessaging_transport_method: "Transporte",
    imessaging_groupage_type: "Tipo de grupaje",
    timessaging_groupage_type: "Grupaje",
    igoods_arrival_date: "Fecha de llegada",
    ivessel_loading_date: "Fecha de carga",
    ivessel_discharge_date: "Fecha de descarga",
    igoods_departure_date: "Fecha de salida",
    itraffic_type: "Tipo de tráfico",
    titraffic_type: "Tráfico",
    tforwarder_air_way_bill_id: "Guía aérea",
    iexchangesale: "Cambio venta",
    tiexchangesale: "Venta",
    iexchangepurchase: "Cambio compra",
    tiexchangepurchase: "Compra",
    ishipperexchange: "Cambio cargador",
    iconference: "Conferencia",
    itransport_id: "Identificación del medio de transporte",
    irecipient: "Receptor",
    istevedore_emergency_contact: "Contacto de emergencia",
    istevedore_emercency_phone: "Teléf. de emergencia",
    istevedore_emergency_identifier: "ID de emergencia",
    ivessel_emergency_phone: "Teléf. .buque de emergencia",
    iemergencytag: "Etiqueta emergencia",
    iupdate_or_cancelation_reason: "Razón act. o cancelación",
    itransittime: "Transit time",
    iimo: "Imo",
    ivgm: "Vgm",
    iequipmentid: "Tipo de contenedor",
    iconectedonborad: "Con. a bordo",
    iconectedoninstation: "Con. en terminal",
    iconectedontransport: "Con. transporte",
    is_shipper_owner: "Propiedad del remitente",
    pif: "¿Pasa por PIF?",
    customs: "¿Pasa por aduanas?",
    weighers: "Pesadores",
    underCover: "Bajo cubierta",
    iequipmentsupplier: "Proveedor",
    isealnumber: "No. seal",
    tisealnumber: "Seal",
    iisempty: "Vacío",
    icustomstariffcode: "Cod. arancelario",
    icustoms:"Aduanas",
    itariffcode: "Arancel",
    inumbernrm: "Num NRM",
    iaditionalid: "Id adicional",
    ishipmenttype: "Tipo de cargamento",
    icustomstatus: "Situación aduanera",
    ibltypemmpp: "Tipo BL mmpp",
    imeasure: "Medida",
    imeasuretype: "Tipo de Unidad de Medida",
    imeasureunit: "Unidad de Medida",
    iidshipload: "Id cargamento",
    iidgood: "Id mercancía",
    igoodg: "Buen grupo",
    iidimogood: "ID MP",
    ipropershippingname: "Nombre de envío adecuado",
    iidequipment: "Id equipamiento",
    ilocationid: "Localidad",
    ilocationcode: "Código de la localidad",
    ilocationname: "Nombre de la localidad",
    iplace: "Lugar",
    izipcode: "Codigo postal",
    ilatitude: "Latitud",
    ilongitude: "Longitud",
    iupload: "Click para subir ficheros",
    iupload_file: "Subir fichero",
    ilegend: "Leyenda",
    ItinerariesList: "Lista de Itinerarios",
    iToShipload: "Cargamento",
    iyes: "Si",
    ino: "No",
    iconfirmdialog: "Eliminar elemento",
    iprefactureDialog: "Seleccione los expedientes y bls a facturar",
    iconfirmduplicatedialog: "Duplicar elemento",
    iconfirm: "¿Está seguro que desea eliminar el elemento?",
    inotdelete: "Este elemento no puede ser eliminado",
    iconfirmduplicate: "¿Está seguro que desea duplicar el elemento?",
    idelete: "¿Cómo desea elminiar el elemento?",
    iwidth: "Ancho",
    imode: "Modo",
    iflow: "Flujo",
    imaritimefcl: "Marítimo FCL",
    imaritimelcl: "Marítimo LCL",
    imaritimeroro: "Marítimo RoRo",
    imaritimebreakbulk: "Marítimo Break Bulk",
    iaerial: "Aereo",
    igroupterrestrial: "Terrestre Grupaje",
    icompleteterrestrial: "Terrestre Completo",
    //end inputs
    //validate
    rpassword:
      "La contraseña debe de contener al menos 4 caracteres y símbolos",
    rpasswordrequired:
      "Debe contener 8 caracteres, mayusculas, Minusculas, Números y caracteres especiales",
    rname: "El nombre es requerido",
    rbrand: "La marca es requerida",
    rmodel: "El modelo es requerido",
    ravatar: "El avatar es requerido",
    remail: "El correo electrónico es requerido",
    vemail: "Correo electrónico debe ser un correo válido",
    rvoyage: "El número de viaje es requerido",
    rnumber: "El número es requerido",
    rfreightcompany: "la compañía de carga aérea es requerida",
    rmeasureunittypeid: "El tipo de unidad de medida es requerido",
    rDesc: "La descripción es requerida",
    rRemarks: "Necesita establecer un comentario",
    rcode: "El codigo es requerido",
    rlevel: "El nivel es requerido",
    rscall: "La escala es requerida",
    rtarifftype: "El tipo es requerido",
    rcif: "El CIF es requerido",
    rstreet: "La calle es requerida",
    rvalue: "El valor es requerido",
    rcompany: "La Empresa es requerida",
    rcodeunique: "El codigo debe ser unico",
    rcodelength: "El codigo no debe superar los 18 caracteres",
    rcodelength1: "El codigo no debe superar los 8 caracteres",
    rdecimal:
      "El campo solo permite número decimales y con un limite de 8 caracteres decimales",
    rshipownerid: "El armador es requerido",
    rtriptype: "El tipo de viaje es requerido",
    rconsigneeid: "El consignatario de mercancia es requerido",
    rtype: "El tipo de consignatario es requerido",
    rrole: "Debe asignar al menos un rol",
    rbasesection: "El tramo base es requerido",
    rsmrequirement: "El requerimiento es requerido",
    rsmpurpose: "El proposito es requerido",
    rdock: "El muelle es requerido",
    rcriteriatype: "El tipo de criterio es requerido",
    rtablerelationship: "La tabla de las relaciones es requerido",
    rroutec: "La ruta es requerida",
    rrequipment: "Los equipamientos son requeridos",
    salejoboffertypeid: "El tipo de oferta es requerida",
    forwarderjobcategory: "La categoría es requerida",
    salejobdocumenttypeid: "El tipo de documento es requerida",
    incotermid: "El incoterm es requerido",
    internationalCodeid: "El Código internacional es requerido",
    cliente: "El cliente es requerido",
    jobstatusid: "El estatus es requerido",
    salestraffictypeid: "El tipo de tráfico es requerido",
    airwaybillid: "La guía de carga aérea es requerida",
    airfreightcompanyid: "La compañía de carga aérea es requerida",
    salejobtypeshippedviaid: "La vía de transporte es requerida",
    salejoboperationtypeid: "El tipo de operación es requerido",
    salejoblanguagesid: "El idioma de la oferta es requerido",
    rtable: "La tabla es requerida",
    rcolumn: "La columna es requerida",
    rcheckin: "El servicio de llegada es requerido",
    rcheckout: "El servicio de salida es requerido",
    roperationtype: "El tipo de operacion es requerido",
    rstationid: "La estacion es requerida",
    rgoodstypeid: "El tipo de mercancia es requerido",
    rofficeid: "La oficina es requerida",
    rpaymentmethod: "El método de pago es requerido",
    rofferstatus: "El estatus de la oferta es requerido",
    rshippedvias: "El método de envío es requerido",
    ralerttype: "El tipo de alerta es requerida",
    rportid: "El puerto es requerido",
    rdockid: "El muelle es requerido",
    rmodeid: "El modo es requerido",
    rmerchandisetype: "El tipo de mercancía es requerido",
    raddresstype: "El tipo de dirección es requerido",
    raddress: "La direccion es requerida",
    raccountingplan: "El plan contable es requerido",
    rshipid: "El barco es requerido",
    rmedia: "El tipo de medio de comunicación es requerido",
    rcommunications: "La comunicación es requerida",
    rcontacttype: "El tipo de contacto es requerido",
    rcontact: "El contacto es requerido",
    rcountry: "El pais es requerido",
    rentitytype: "El tipo de entidad es requerido",
    entitytypes: "Tipos de Entidad",
    rbillingconcept: "El concepto de prefactura es requerido",
    rbillingconceptcode: "El código de concepto de prefactura es requerido",
    requipmenttype: "El tipo de equipamiento es requerido",
    rpackagingtype: "El tipo de paquete es requerido",
    rflagid: "La bandera es requerida",
    requipmentsubtype: "El subtipo de equipamiento es requerido",
    requipmentgroup: "El grupo de equipamiento es requerido",
    requipment: "El equipamiento es requerido",
    rlocation: "La localización es requerida",
    rcurrency: "La moneda es requerida",
    relement: "El elemento es requerido",
    rtaxes: "El impuesto es requerido",
    ractiontype: "El tipo de acción es requerido",
    ractionreason: "La razón de la acción es requerida",
    ractionresult: "El resultado de la acción es requerido",
    rmaritimefilesoperations:
      "El fichero de operaciones maritimas es requerido",
    rmaritimefilestype: "El tipo de fichero maritimo es requerido",
    rservice: "El servicio marëtimo es requerido",
    rmeasuretypeid: "El tipo de medida es requerido",
    rparticipant: "El tipo de participante es requerido",
    rroute: "El tipo de ruta es requerido",
    rsection: "El tipo de seccion es requerido",
    rtransportorderstatus: "El estado de la orden de transporte es requerido",
    rtransportordertype: "El tipo de orden de transporte es requerido",
    riso: "El ISO es requerido",
    rmaritime: "Marítimo es requerido",
    ruser: "El nombre de usuario es requerido",
    rcompanygroupname: "El nombre del grupo de compañías es requerido",
    company_group: "Grupo de compañías",
    rcompanyougrpoffice: "La compañía y el nombre de la oficina son requeridos",
    rcustomer: "El cliente es requerido",
    rentity: "El campo es requerido",
    rmaritimevoyage: "El viaje es requerido",
    rbookingid: "El booking es requerido",
    rbillofladingdate: "La fecha del conocimiento es requerida",
    rbillofladingnumber: "El número de BL es requerido",
    rpaymenttype: "El tipo de pago es requerido",
    rvalidfrom: "La fecha es requerida",
    rdeparturedate: "La fecha de salida es requerida",
    restdeparturedate: "La fecha estimada de salida es requerida",
    restarrivaldate: "La fecha estimada de llegada es requerida",
    rarrivaldate: "La fecha de llegada es requerida",
    rclosingdate: "La fecha de cierre es requerida",
    ridshipload: "El cargamento es requerido",
    ridmessagingoperationinstruction: "La Inst. de operación es requerida",
    ridmessagingiftdgncargotypeid: "El tipo de carga es requerido",
    ridmessagingtransportmethodid: "El método de transporte es requerido",
    ridmessaginggroupagetypeid: "El tipo de grupaje es requerido",
    ridmessagingdispatcherid: "El despachador es requerido",
    ridmessagingrecipientid: "El receptor es requerido",
    ridmessagingestimatedoperationtime: "El tiempo estimado es requerido",
    ridmessagingTrafficType: "El tipo de tráfico es requerido",
    rmteditabletable:
      "Para adicionar un nuevo viaje, los datos de la tabla deben ser válidos",
    rbookinggrouperror:
      "Para agrupar los bookings debe ser coincidir el tipo de viaje y no debe tener Bls",
    rflagtabletable:
      "Para adicionar una nueva sincronización, los datos de la tabla deben ser válidos",
    rgceditabletable:
      "Para adicionar un nuevo consignatario, los datos de la tabla deben ser válidos",
    raddresstable:
      "Para adicionar una nueva direccion, los datos de la tabla deben ser válidos",
    rconatcttable:
      "Para adicionar un nuevo contacto, los datos de la tabla deben ser válidos",
    rcommunicationtable:
      "Para adicionar una nueva comunicacion, los datos de la tabla deben ser válidos",
    ridentificationtable:
      "Para adicionar una nueva identificacion, los datos de la tabla deben ser válidos",
    rsmeditabletable:
      "Para adicionar un nuevo atraque, los datos de la tabla deben ser válidos",
    rmoeditabletable:
      "Para adicionar una nueva operacion de atraque, los datos de la tabla deben ser válidos",
    rmaritimefiletables:
      "Para adicionar un nuevo expediente maritimo, debe existir al menos un elemento de: viajes, escala, consignatarios de mercancias, atraques y operaciones de atraque",
    rmaritimefiledelete: "El expediente seleccionado no puede ser elimando",
    rofficedelete: "La Oficina seleccionada no puede ser elimanda",
    rimodeclaration:
      "Para adicionar declaraciones de mercancías, debe existir al menos un elemento",
    rcrm: "Los campos con * son campos requeridos",
    rbkeditabletable:
      "Para adicionar un nuevo booking, los datos de la tabla deben ser válidos",
    rtseditabletable:
      "Para adicionar un nuevo transbordo, los datos de la tabla deben ser válidos",
    rbreditabletable:
      "Para adicionar una nueva ruta de booking, los datos de la tabla deben ser válidos",
    rpeditabletable:
      "Para adicionar un nuevo participante, los datos de la tabla deben ser válidos",
    ritineeditabletable:
      "Para adicionar un nuevo itinerario, los datos de la tabla deben ser válidos",
    reventeditabletable:
      "Para adicionar un nuevo evento temporal, los datos de la tabla deben ser válidos",
    rshipsloadsgoods:
      "La Condicion Especial es obligatoria en la Tabla de Contenedores y Mercancias",
    inlineprebillingvalidate:
      "<p>- El código.<br>" +
      "- Impuesto.<br>" +
      "- La moneda.<br>" +
      "- El flete.<br>" +
      "- La oficina.<br>" +
      "- Debe seleccionar un expediente marítimo o un conocimiento.<br>" +
      "son requeridos.</p>",
    inlineprebillingvalidate1:
      "<p>Los valores de: <br>" +
      "- El costo.<br>" +
      "- La Venta.<br>" +
      "- Las Unidades.<br>" +
      "tienen que ser mayor o igual a 0.</p>",
    rpreditabletable:
      "Para adicionar una nueva cantidad, los datos de la tabla deben ser válidos",
    rbleditabletable:
      "Para adicionar una nueva guia de carga, los datos de la tabla deben ser válidos",
    rsleditabletable:
      "Para adicionar un nuevo cargamento, los datos de la tabla deben ser válidos",
    rgfeditabletable:
      "Para adicionar un nuevo bien, los datos de la tabla deben ser válidos",
    rbooking:
      "Para adicionar un nuevo booking, debe existir al menos un elemento de: booking, transbordo, rutas del booking, participantes y guia de carga",
    rvreditabletable:
      "Para adicionar una nueva ruta de buque, los datos de la tabla deben ser válidos",
    rbookingvoyagetype:
      "El tipo de viaje del booking no coincide con el tipo de viaje del expediente maritimo",
    rgood:
      "Para adicionar una nueva mercancía, los datos de la tabla deben ser válidos",
    requipments:
      "Para adicionar un nuevo equipo, los datos de la tabla deben ser válidos",
    rimodeclarartion:
      "Para adicionar una nueva declaración de mercancía peligrosa, los datos de la tabla deben ser válidos",
    rgoodmeasure:
      "Para adicionar una nueva medida de mercancía, los datos de la tabla deben ser válidos",
    roverdimensions:
      "Para adicionar una nueva sobredimensión, los datos de la tabla deben ser válidos",
    rblprebilling:
      "Para adicionar una nueva línea de prefactura de BL, debe existir al menos un elemento en cantidades de la prefactura",
    rrequiredfields: "Revise los campos requeridos",
    raerialvoyeageroutes:
      "Para adicionar una nueva ruta de viaje aéreo, debe existir al menos un elemento en las rutas del viaje",
    raerialvoyeage:
      "Para adicionar un nuevo viaje aéreo, debe existir al menos dos elemento en las rutas del viaje",
    rofferroute:
      "Para adicionar una oferta, debe existir al menos dos elementos en la rutas",
    rhas_dangerous_goods:
      "El elemento no se puede eliminar pues tiene mercancía peligrosa",
    no_has_bookings: "El conocimiento seleccionado no tiene bookings",
    rhas_transportation_order:
      "El elemento no se puede eliminar pues tiene órdenes de transporte",
    rsend_email_success: "Se ha enviado el correo",
    runitpayment: "La unidad de pago es un campo requerido",
    //end validate
    //labels
    lpermisionsassigned: "Permisos asignados",
    //end labels
    //titles
    timaritimefilelist: "Expedientes maritimos",
    toffer: "Oferta",
    tjob: "Expediente",
    tcrm: "CRM - Gestión Comercial",
    mcrm: "Gestión Comercial",
    timaritimetripslist: "Listado de viajes maritimos",
    timaritimetrips: "Viajes maritimos",
    tisummarystatement: "Declaracion sumaria",
    timaritimefile: "Expediente maritimo",
    toffers: "Ofertas",
    tjobs: "Expedientes",
    tcrmform: "CRM",
    titrips: "Viajes",
    taerialvoyage: "Viajes aéreos",
    saerialvoyage: "Viaje aéreo",
    tterrestrialvoyage: "Viajes terrestres",
    talerts: "Alertas",
    tsystemactivealert: "Sistema de alertas activas",
    tsystemalert: "Sistema de alertas",
    tsystemalerttype: "Tipo de alertas",
    system_alert_type_id: "Tipo de alerta",
    tvesselroutes: "Rutas buque",
    ttransitcargo: "Carga del tránsito",
    tibookings: "Booking",
    tibookingsprincipal: "Booking Principal",
    ticustomer: "Cliente",
    ttransportation: "Transporte cedido",
    towntransportation: "Transporte propio",
    tiprovider: "Proveedor",
    tipaymenttype: "Tipo de pago",
    tipaymenttypedetails: "Detalles del tipo de pago",
    tiblnumber: "No. de BL",
    ttiblnumber: "BL",
    tcalls: "Escala",
    troute: "Rutas",
    tparticipants: "Participantes",
    tmoorings: "Atraque",
    tconsignees: "Consignatarios de mercancías",
    tmooringoperation: "Operaciones en el atraque",
    tiBillOfLadings: "Conocimiento",
    amountBillOfLoadings: "Cantidad de Conocimientos",
    tisenexs: "Enviar EXS",
    tisenens: "Enviar ENS",
    ticanexs: "Anular EXS",
    ticanens: "Anular ENS",
    tieditexs: "Modificar EXS",
    tieditens: "Modificar ENS",
    tisenexspartida: "Por Partida",
    tisenexsbl: "Por conocimiento",
    tselectall: "Seleccionar todo",
    tdesselectall: "Des-Seleccionar todo",
    tlogs: "Histórico",
    tibillingconcepts: "Conceptos de prefactura",
    iprefactured: "Prefacturado",
    inoprefacture: "No prefacturado",
    tibillingconceptname: "Concepto de prefactura",
    tiPrebilling: "Prefactura",
    tiPrebillingE: "Prefacturar Vacío",
    tiPrebillingF: "Comisión AG",
    tiPrebillingT: "Prefacturar Transbordo",
    tiPrebilling2: "Prefacturar",
    tiPrebillingAll: "Prefacturar Conocimientos",
    tiPrebilling3: "Prefacturación",
    tiPrebilling4: "Pendientes facturación",
    tiPrebilling5: "Mostrar prefacturación exp marítimo",
    tiPrebillingConcepts: "Conceptos",
    tiPendientes: "Pendientes",
    tiSelect: "Seleccionadas",
    tiDocuments: "Documentos",
    tiCurrencyExchange: "Cambio de Moneda",
    tiEntityTypes: "Tipo de entidades",
    toperations: "Operaciones",
    ttransshipment: "Transbordo",
    tbookingroute: "Rutas booking",
    tprebillingamount: "Cantidades",
    tgoodsfeatures: "Mercancías",
    tshipsloads: "Contenedores",
    iequipment: "Equipamiento",
    tshipsloadsgoods: "Contenedores y mercancías",
    tgoodsmeasures: "Medidas de mercancías",
    toverdimensions: "Medidas del contenedor",

    //endtitles
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signOut: "Cerrar sesion",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    errors: "Errores",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    dangerous: "Peligrosa",
    perishable: "Perecedera",
    addcontainer: "Añadir contenedor",
    addunits: "Añadir unidades",
    removecontainer: "Eliminar contenerdor",
    removeunit: "Eliminar unidades",
    removeavatar: "Eliminar Avatar",
    changeavatar: "Cambiar Avatar",
    generals: "Generales",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    btnView: "Visualizar",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    createNewBusinessGroup: "Crear nuevo grupo de empresas",
    createNewOfficeGroup: "Crear nuevo grupo de oficinas",
    createNewBusiness: "Crear nueva empresa",
    sendarrivalnotice: "Enviar aviso de llegada",

    //modal titles
    mt_add: "Agregar",
    mt_edit: "Editar",
    ot_transfers_from_booking: "Traslados desde Booking",
    ot_transfers_from_bl: "Traslados desde Conocimientos",
    ot_transfers: "Traslados",
    ot_transit: "Transito",
    ot_shipping_date: "Fecha de envío",
    tot_shipping_date: "Envío",
    mt_maritime_file_add: "Agregar archivo marítimo",
    mt_maritime_file_edit: "Modificar archivo marítimo",
    mt_maritime_services_add: "Agregar tipo de servicio marítimo",
    mt_maritime_services_edit: "Modificar tipo de servicio marítimo",
    mt_car_brands_add: "Agregar marca de carro",
    mt_car_brands_edit: "Modificar marca de carro",
    mt_address_type_add: "Agregar tipo de dirección",
    mt_address_type_edit: "Modificar tipo de dirección",
    mt_forwarder_status_add: "Agregar estado de transitario",
    mt_forwarder_status_edit: "Modificar estado de transitario",
    mt_forwarder_offer_type_add: "Agregar tipo de oferta",
    mt_forwarder_offer_type_edit: "Modificar tipo de oferta",
    mt_forwarder_document_type_add: "Agregar tipo de documento",
    mt_forwarder_document_type_edit: "Modificar tipo de documento",
    mt_forwarder_shipped_via_add: "Agregar vía de entrega",
    mt_forwarder_shipped_via_edit: "Modificar vía de entrega",
    mt_forwarder_language_add: "Agregar idioma",
    mt_forwarder_language_edit: "Modificar idioma",
    mt_forwarder_operation_type_add: "Agregar tipo de operación",
    mt_forwarder_operation_type_edit: "Modificar tipo de operación",
    mt_forwarder_route_type_add: "Agregar tipo de ruta",
    mt_forwarder_route_type_edit: "Modificar tipo de ruta",
    mt_forwarder_incoterm_type_add: "Agregar tipo de incoterm",
    mt_forwarder_incoterm_type_edit: "Modificar tipo de incoterm",
    mt_forwarder_category_add: "Agregar categoría job",
    mt_forwarder_category_edit: "Modificar categoría job",
    mt_forwarder_job_freight_type_add: "Agregar tipo de carga job",
    mt_forwarder_job_freight_type_edit: "Modificar tipo de carga job",
    mt_forwarder_job_good_status_add: "Agregar estado de bienes job",
    mt_forwarder_job_good_status_edit: "Modificar estado de bienes job",
    mt_forwarder_job_groupage_type_add: "Agregar tipo de grupaje job",
    mt_forwarder_job_groupage_type_edit: "Modificar tipo de grupaje job",
    mt_forwarder_job_payment_type_add: "Agregar tipo de pago job",
    mt_forwarder_job_payment_type_edit: "Modificar tipo de pago job",
    mt_forwarder_job_service_line_add: "Agregar línea de servicio job",
    mt_forwarder_job_service_line_edit: "Modificar línea de servicio job",
    mt_forwarder_offer_status_add: "Agregar estados de ofertas",
    Confirm_offer: "Confirmar Oferta",
    mt_forwarder_offer_status_edit: "Modificar estados de ofertas",
    mt_forwarder_job_offer_observation_templates_add:
      "Agregar plantilla de observación de ofertas",
    mt_forwarder_job_offer_observation_templates_edit:
      "Editar plantilla de observación de ofertas",
    mt_forwarder_air_way_bill_add: "Agregar guía aérea",
    mt_forwarder_air_way_bill_edit: "Editar guía aérea",
    mt_traffic_type_add: "Agregar tipo de tráfico",
    mt_traffic_type_edit: "Modificar tipo de tráfico",
    mt_tariffs_add: "Agregar tabla de criterio de tarifas",
    mt_tariffs_edit: "Editar tabla de criterio de tarifas",
    mt_port_add: "Agregar puerto",
    mt_port_edit: "Editar puerto",
    mt_vessel_add: "Agregar buque",
    mt_vessel_edit: "Editar buque",
    mt_template_add: "Agregar plantilla",
    mt_template_edit: "Editar plantilla",
    mt_email_template_add: "Agregar plantilla de correo",
    mt_email_template_edit: "Editar plantilla de correo",
    mt_var_add: "Agregar variable",
    mt_var_edit: "Editar variable",
    mt_participant_type_add: "Agregar tipo de participante",
    mt_participant_type_edit: "Editar tipo de participante",
    mt_cost_type_add: "Agregar tipo de costo",
    mt_cost_type_edit: "Editar tipo de costo",
    mt_tax_detail_add: "Agregar detalle de impuesto",
    mt_tax_detail_edit: "Editar detalle de impuesto",
    mt_tax_add: "Agregar impuesto",
    mt_tax_edit: "Editar impuesto",
    mt_docks_add: "Agregar muelle",
    mt_docks_edit: "Editar muelle",
    mt_PackagingType_add: "Agregar tipo de paquete",
    mt_PackagingType_edit: "Editar tipo de paquete",
    mt_identificationType_add: "Agregar tipo de Identificacion",
    mt_identificationType_edit: "Editar tipo de Identificacion",
    mt_BillingConcept_add: "Agregar concepto",
    mt_BillingConcept_edit: "Editar concepto",
    mt_paymentType_add: "Agregar tipo de pago",
    mt_paymentType_edit: "Edit tipo de pago",
    mt_measure_type_add: "Agregar tipo de medida",
    mt_measure_type_edit: "Modificar tipo de medida",
    mt_measure_unit_edit: "Modificar unidad de medida",
    mt_measure_unit_add: "Agregar unidad de medida",
    mt_measure_type_unit_edit: "Modificar tipo de unidad de medida",
    mt_measure_type_unit_add: "Agregar tipo de unidad de medida",
    mt_contact_type_edit: "Modificar tipo de contacto",
    mt_contact_type_add: "Agregar tipo de contacto",
    mt_transport_order_status_edit:
      "Modificar estado de la orden de transporte",
    mt_transport_order_status_add: "Agregar estado de la orden de transporte",
    mt_transport_order_type_edit: "Modificar tipo de la orden de transporte",
    mt_transport_order_type_add: "Agregar tipo de la orden de transporte",
    mt_route_type_edit: "Modificar tipo de ruta",
    mt_route_type_add: "Agregar tipo de ruta",
    mt_equipment_type_edit: "Modificar tipo de equipamiento",
    mt_equipment_type_add: "Agregar tipo de equipamiento",
    mt_payment_type_detail_edit: "Editar detalle de tipo de pago",
    mt_payment_type_detail_add: "Agregar detalle de tipo de pago",
    mt_payment_type_edit: "Editar tipo de pago",
    mt_payment_type_add: "Agregar tipo de pago",
    mt_currency_type_edit: "Editar tipo de moneda",
    mt_currency_type_add: "Agregar tipo de moneda",
    mt_dashboard_edit: "Editar tablero",
    mt_dashboard_add: "Agregar tablero",
    mt_equipment_subtype_edit: "Modificar subtipo de equipamiento",
    mt_equipment_subtype_add: "Agregar subtipo de equipamiento",
    mt_equipment_group_edit: "Modificar grupo de equipamiento",
    mt_equipment_group_add: "Agregar grupo de equipamiento",
    mt_sector_type_edit: "Modificar tipo de sector",
    mt_sector_type_add: "Agregar tipo de sector",
    mt_maritime_file_operation_edit: "Modificar operación",
    mt_maritime_file_operation_add: "Agregar operación",
    mt_media_edit: "Modificar tipo de medio de comunicacion",
    mt_media_add: "Agregar tipo de medio de comunicacion",
    mt_location_edit: "Modificar localización",
    mt_location_add: "Agregar localización",
    mt_entity_type_edit: "Modificar tipo de entidad",
    mt_entity_type_add: "Agregar tipo de entidad",
    mt_entity_edit: "Modificar entidad",
    mt_entity_add: "Agregar entidad",
    mt_goods_type_edit: "Modificar tipo de bienes",
    mt_goods_type_add: "Agregar tipo de bienes",
    mt_registered_equipment_add: "Agregar equipamiento registrado",
    mt_registered_equipment_edit: "Editar equipamiento registrado",
    mt_smpurposes_edit: "Modificar proposito de atraque",
    mt_smpurposes_add: "Agregar proposito de atraque",
    mt_smrequirements_edit: "Modificar requerimiento de atraque",
    mt_smrequirements_add: "Agregar requerimiento de atraque",
    mt_equipment_edit: "Modificar equipamiento",
    mt_equipment_add: "Agregar equipamiento",
    mt_add_mf_booking_route: "Agregar ruta a booking",
    mt_add_mf_booking_equipment: "Agregar equipamiento al booking",
    mt_maritime_trip_edit: "Editar viaje",
    mt_maritime_trip_add: "Adicionar viaje",
    mt_good_consignee_edit: "Editar consignatario",
    mt_good_consignee_add: "Adicionar consignatario",
    mt_ship_mooring_edit: "Editar atraque",
    mt_ship_mooring_add: "Adicionar atraque",
    mt_mooring_operation_edit: "Editar operacion de atraque",
    mt_mooring_operation_add: "Adicionar operacion de atraque",
    "Is Main": "Principal",
    "": "",
    Remarks: "Comentarios",
    tifiles: "Incorporación de ficheros",
    tiimportemptyfiles: "Incorporación Excel equipos vacíos",
    thandlinginformation: "Administrar información",
    tnavisioninvoicecomment: "Comentario factura Navision",
    tinvoicelinesconcepts: "Concepto fusión lineas factura emitida",
    taddresses: "Direcciones",
    tdestinationaddresses: "Direcciones destino",
    tentities: "Entidades",
    ifilecalendar: "Pizarra",
    tobstransportation: "Obs. albarán transporte",
    tobsarrivalnotice: "Obs. Aviso llegada/Documento despacho",
    ireference: "Referencia",
    rbl: "rbl",
    ibs: "ibs",
    General: "General",
    iaprove: "iaprove",
    igroupbooking: "Agrupar bookings",
    iforwarder: "Transitario",
    iforwarderjob: "Transitario Job",
    iforwarderjobcategory: "Categoría del expediente",
    iforwarderjobgroupage: "Tipo de Grupaje",
    iforwarderjobgoodstatus: "Estado de la Mercancia",
    iforwarderjobs: "Ofertas",
    iforwarderoffers: "Oferta",
    iforwarderServicesLine: "Lineas de Serivicios",
    ientry_transport_movement: "Movimiento de Transporte de Entrada",
    rentry_transport_movement: "Movimiento de Transporte de Entrada Requerido",
    iexit_transport_movement: "Movimiento de Transporte de Salida",
    rexit_transport_movement: "Movimiento de Transporte de Salida Requerido",
    ientry_regular_service_code: "Código de Entrada de Servicio Regular",
    rentry_regular_service_code: "Código de Entrada de Servicio Regular Requerido",
    iexit_regular_service_code: "Código de Salida de servicio Regular",
    rexit_regular_service_code: "Código de Salida de servicio Regular Requerido",
    ientry_simplified_communal_transit_procedure: "Procedimiento Simplificado de Tránsito Comunal de Entrada",
    rentry_simplified_communal_transit_procedure: "Procedimiento Simplificado de Tránsito Comunal de Entrada Requerido",
    iexit_simplified_communal_transit_procedure: "Procedimiento Simplificado de Tránsito Comunal de Salida",
    rexit_simplified_communal_transit_procedure: "Procedimiento Simplificado de Tránsito Comunal de Salida Requerido",
    iimport_declaration_number: "Número de declaración de Importación",
    iexport_declaration_number: "Número de declaración de Exportación",
    icommercial_code: "Código Comercial",
    iifcsum_registration_modification: "Alta/Modificación",
    iifcsum_modificate_headers: "Modificar cabeceras",
    iifcsum_cancel: "Cancelación",
    iifcsum_cancel_header_text:"¿Estas seguro de que quieres cancelar",
    iifcsum_cancel_with_replace: "Con Reemplazamiento",
    iifcsum_cancel_without_replace: "Sin Reemplazamiento",
    iifcsum_negative_declaration: "Envio de negativa de declaración",
    rifcsum_generated: "IFCSUM generado correctamente",
    ientry_is_simplified_regime: "Régimen De Entrada Simplificado",
    iexit_is_simplified_regime: "Régimen De Salida Simplificado",
    ihumanitary_aid: "Ayuda humanitaria",
    imilitary_purposes: "Propósitos militares",
    iaccoutrements: "Accesorios",
    origin_airport_id: "Aeropuerto origen",
    destination_airport_id: "Aeropuerto destino",
    verified: "Verificado",
    emailContact: "Email de contacto",
    role: "Rol",
    phoneValidation: "Número de teléfono debe estar activo",
    //tabla permisos
    index: "Listar",
    show: "Ver",
    store: "Crear",
    update: "Actualizar",
    delete: "Borrar",
    assignPermissions: "Asignar permisos",
    assignRoles: "Asignar roles",
    assignOffices: "Asignar oficinas",
    assignPreferences: "Asignar preferencias",
    attachFiles: "Adjuntar archivos",
    attachFile: "Adjuntar archivo",
    viewFiles: "ver archivos",
    groupBookings: "Agrupar",
    sendMessage: "Enviar mensaje",
    createMessage: "Crear mensaje",
    payment: "Pago",
    generate: "Generar",
    historic: "Histórico",
    restore: "Restaurar",
    forceDelete: "Forzar borrado",
    warning: "Advertencia",
    entity_address_type_warning:
      "Existen direcciones de esta entidad a les que le faltan su tipo de dirección",
    userName: "Nombre de Usuario",
    forgotPassLogin: "¿Olvidó su contraseña?",
    login: "Acceder",
    logout: "Salir",
    unauthenticated: "No autenticado",
    loginLoader: "Accediendo...",
    noAccount: "¿No tienes cuenta?",
    tryAgain: "Vuelva a intentarlo",
    edipath_error: "No se encontró el archivo edipath en el servidor",
    document_error: "No se encontró el archivo en el servidor",
    unexpectedError: "Ha ocurrido un error inesperado",
    adminError: "Se ha producido un error. Consulte con el administrador",
    successOperation: "Esta es una operación exitosa",
    onlyOneTripType:
      "Selecciona solo elementos con el mismo tipo de viaje marítimo",
    passwordChanged: "Su contraseña se ha cambiado",
    passwordMatch: "Las contraseñas deben coincidir",
    thanks: "Ok, gracias",
    documents: "Documentos",
    filesManagement: "Gestión de documentos",
    filesType: "Tipo de documento",
    filesTypeAss: "Tipo de documento asociado",
    uploadDate: "Fecha de subida",
    audioDialog: "Reproductor de audio",
    videoDialog: "Reproductor de video",
    play: "Reproducir",
    export: "Exportar",
    exportAll: "Exportar Todo",
    exportSelected: "Exportar Seleccionados",
    ediFiles: "Ficheros Edi",
    ediFilesInfo: "Cargar archivos Edi o procesar archivos cargados",
    process: "Procesar",
    //Plantillas
    bills_of_lading_date: "Fecha del Conocimiento",
    bills_of_lading_number: "Numero de Conocimiento",
    bill_prefix: "Prefijo de Factura",
    billing_status: "Estado de Facturación",
    billing_serie: "Serie de Facturación",
    currency: "Moneda",
    customer_name: "Nombre del Cliente",
    customer_identification: "Identificación del cliente",
    customer_email: "Correo del Cliente",
    format_bill_number: "Formato del numero de factura",
    office_name: "Nombre de Oficina",
    reference: "Referencia",
    contact_name: "Nombre del contacto",
    contact_last_name: "Apellido del contacto",
    trip_type: "Tipo de viaje",
    maritime_voyage_code: "Código de viaje marítimo",
    maritime_file_code: "Numero de expediente",
    mf_type_code: "Código del tipo de expediente",
    port_name: "Nombre del puerto",
    port_prefix: "Prefijo del puerto",
    vessel: "Buque",
    lloyds_iso: "lloyds iso",
    company_name: "Nombre de la empresa",
    company_website: "Sitio Web de la empresa",
    company_email: "Correo de la empresa",
    company_cif: "CIF de la empresa",
    ship_consignee_name: "Nombre del consignatario de viaje",
    ship_consignee_email: "Correo del consignatario de viaje",
    principal: "Principal",
    secundario: "Secundario",
    m_carga: "M. Carga",
    m_descarga: "M. Descarga",
    summary_statements: "Declaraciones Sumarias",
    orden_partida_ifcsum: "Orden Partida IFCSUM",
    orden_partida_ifcsum2: "Orden Partida IFCSUM II",
    igenratereport: "Informe",
    error_max_bill_date: "Debes seleccionar un período de 1 mes o menos.",
    OfficePrintReport: "Print in Report",
    OfficeAdditionalDocument: "Print On Additional Document",
    OfficeBillingEquiptmentsTypeAttachment:
      "Facturación: Tipo de impresion Equipos Involucrados",
    daysDelayReturn: "Demoras",
    detentions: "Detenciones",
    daysUse: "Días en uso",
    statusBilling: "Facturación: No/Pre/Sí",
    notify: "Notificar",
    tariffType: "Tipo tarifa",
    notified: "Notificados",
    no_notified: "Sin notificar",
    yes_notified: "Notificados",
  },
};

const messages = {
  en: {
    ...oldTranslations.en,
    ...GeneralEN,
  },
  es: {
    ...oldTranslations.es,
    ...GeneralES,
  },
};

const i18n = createI18n({
  legacy: false,
  locale:
    navigator.language == "en" || navigator.language == "en-US" ? "en" : "es",
  globalInjection: true,
  messages,
});

export default i18n;
