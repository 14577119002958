// users
const PROFILE = "user" as string;
const MYPERMISIONS = "permissions" as string;
const MYMENUS = "menus" as string;

/**
 * @description get current user from localStorage
 */
export const getProfile = ():
  | Record<string, unknown>
  | Record<string, never> => {
  const profile = window.localStorage.getItem(PROFILE);
  return JSON.parse(profile || "{}");
};

/**
 * @description save current user into localStorage
 * @param token: string
 */
export const saveProfile = (user: Record<string, unknown>): void => {
  window.localStorage.setItem(PROFILE, JSON.stringify(user));
};
// end users

export const savePermissions = (permissions: Record<string, any>): void => {
  window.localStorage.setItem(MYPERMISIONS, JSON.stringify(permissions));
};

export const getPermissions = (): Record<string, any> | [] => {
  const permsisions = window.localStorage.getItem(MYPERMISIONS);
  return JSON.parse(permsisions || "[]");
};

export const saveMenus = (menus: Record<string, any>): void => {
  window.localStorage.setItem(MYMENUS, JSON.stringify(menus));
};

export const getMenus = (): Record<string, any> | [] => {
  const menus = window.localStorage.getItem(MYMENUS);
  return JSON.parse(menus || "[]");
};

export default {
  getProfile,
  saveProfile,
  savePermissions,
  getPermissions,
  getMenus,
  saveMenus,
};
