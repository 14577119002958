import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface Office {
  id?: number;
  company_id: string;
  name: string;
  is_main: boolean;
  logo: string;
  remarks: string;
  users: [];
  addresses: [];
  communications: [];
}

interface Addresses {
  id?: number;
  company_id: string;
  office_id: string;
  entity_id: string;
  contact_id: string;
  street: string;
  number: string;
  floor_number: string;
  door: string;
  location_id: string;
  latitude: string;
  longitude: string;
}

interface Communication {
  id?: number;
  media_id: string;
  office_id: string;
  entity_id: string;
  contact_id: string;
  value: string;
  remark: string;
}

@Module
export default class OfficesModule extends VuexModule {
  itemId: undefined;
  officeItem: Office = {
    company_id: "",
    name: "",
    is_main: false,
    logo: "",
    remarks: "",
    users: [],
    addresses: [],
    communications: [],
  };
  officeList: Office[] = [];
  selectedofficeList: Office[] = [];
  addressesEdit = false;
  communicationEdit = false;
  companiesList: any[] = [];
  entitiesADList: any[] = [];
  contactsADList: any[] = [];
  entitiesCMList: any[] = [];
  contactsCMList: any[] = [];
  locationsList: any[] = [];
  mediasList: any[] = [];
  addressTypeList: any[] = [];
  OfficeClientList: any[] = [];
  ClientOfficeList: any[] = [];
  CorrespondentOfficeList: any[] = [];

  addressesList: Addresses[] = [];
  communicationList: Communication[] = [];

  get ClientOffice(): any {
    return this.ClientOfficeList;
  }
  get getSelectedOffices(): any {
    return this.selectedofficeList;
  }

  get CorrespondentOffice(): any {
    return this.ClientOfficeList;
  }

  get OfficeClient(): any {
    return this.OfficeClientList;
  }

  get OfficeId(): any {
    return this.itemId;
  }

  get OfficeTab(): Office {
    return this.officeItem;
  }

  get AEdit(): boolean {
    return this.addressesEdit;
  }

  get CEdit(): boolean {
    return this.communicationEdit;
  }

  get Companies(): any[] {
    return this.companiesList;
  }

  get EntitiesAD(): any[] {
    return this.entitiesADList;
  }

  get ContactsADOffice(): any[] {
    return this.contactsADList;
  }

  get EntitiesCM(): any[] {
    return this.entitiesCMList;
  }

  get ContactsCMOffice(): any[] {
    return this.contactsCMList;
  }

  get LocationsOffice(): any[] {
    return this.locationsList;
  }

  get AddressOffice(): any[] {
    return this.addressesList;
  }

  get CommunicationOffice(): any[] {
    return this.communicationList;
  }

  get MediaCM(): any[] {
    return this.mediasList;
  }

  get AddressTypeOffice(): any[] {
    return this.addressTypeList;
  }

  get AddressType(): any[] {
    return this.addressTypeList;
  }

  @Mutation
  ["setClientOffice"](payload) {
    this.ClientOfficeList = payload;
  }
  @Mutation
  ["setCorrespondentOffice"](payload) {
    this.CorrespondentOfficeList = payload;
  }
  @Mutation
  ["setSelectedOffices"](payload) {
    this.selectedofficeList = payload;
  }
  @Mutation
  ["setItemSelectedOffices"](payload) {
    this.selectedofficeList.push(payload);
  }
  @Mutation
  ["deleteItemSelectedOffices"](payload) {
    this.selectedofficeList.splice(payload, 1);
  }
  @Mutation
  ["setOfficeClient"](payload) {
    this.OfficeClientList = payload;
  }

  @Mutation
  ["setItem"](payload) {
    this.itemId = payload;
  }

  @Mutation
  ["setOffice"](payload) {
    this.officeItem = payload;
  }

  @Mutation
  ["setOfficeList"](payload) {
    this.officeList = payload;
  }

  @Mutation
  ["setAEdit"](payload) {
    this.addressesEdit = payload;
  }

  @Mutation
  ["setCEdit"](payload) {
    this.communicationEdit = payload;
  }

  @Mutation
  ["setCompany"](payload) {
    this.companiesList = payload;
  }

  @Mutation
  ["addCompany"](payload) {
    if (payload && Array.isArray(this.companiesList)) {
      const index = this.companiesList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.companiesList.push(payload);
      }
    }
  }

  @Mutation
  ["setEntitiesAD"](payload) {
    this.entitiesADList = payload;
  }

  @Mutation
  ["addEntityAD"](payload) {
    if (payload && Array.isArray(this.entitiesADList)) {
      const index = this.entitiesADList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.entitiesADList.push(payload);
      }
    }
  }

  @Mutation
  ["setContactsAD"](payload) {
    this.contactsADList = payload;
  }

  @Mutation
  ["addContactAD"](payload) {
    if (payload && Array.isArray(this.contactsADList)) {
      const index = this.contactsADList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.contactsADList.push(payload);
      }
    }
  }

  @Mutation
  ["setEntitiesCM"](payload) {
    this.entitiesCMList = payload;
  }

  @Mutation
  ["addEntityCM"](payload) {
    if (payload && Array.isArray(this.entitiesCMList)) {
      const index = this.entitiesCMList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.entitiesCMList.push(payload);
      }
    }
  }

  @Mutation
  ["setContactsCM"](payload) {
    this.contactsCMList = payload;
  }

  @Mutation
  ["addContactCM"](payload) {
    if (payload && Array.isArray(this.contactsCMList)) {
      const index = this.contactsCMList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.contactsCMList.push(payload);
      }
    }
  }

  @Mutation
  ["setLocations"](payload) {
    this.locationsList = payload;
  }

  @Mutation
  ["addLocations"](payload) {
    if (payload && Array.isArray(this.locationsList)) {
      const index = this.locationsList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.locationsList.push(payload);
      }
    }
  }

  @Mutation
  ["setMedia"](payload) {
    this.mediasList = payload;
  }

  @Mutation
  ["addMedia"](payload) {
    if (payload && Array.isArray(this.mediasList)) {
      const index = this.mediasList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.mediasList.push(payload);
      }
    }
  }

  @Mutation
  ["setAddressType"](payload) {
    this.addressTypeList = payload;
  }

  @Mutation
  ["addAddressType"](payload) {
    if (payload && Array.isArray(this.addressTypeList)) {
      const index = this.addressTypeList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.addressTypeList.push(payload);
      }
    }
  }

  @Mutation
  ["setAddress"](payload) {
    this.addressesList.push(payload);
  }

  @Mutation
  ["updateAddress"](payload) {
    const index = payload.index;
    this.addressesList[index].id = payload.item.id;
  }

  @Mutation
  ["removeAddress"](index) {
    this.addressesList.splice(index, 1);
  }

  @Mutation
  ["setCommunication"](payload) {
    this.communicationList.push(payload);
  }

  @Mutation
  ["updateCommunication"](payload) {
    const index = payload.index;
    this.communicationList[index].id = payload.item.id;
  }

  @Mutation
  ["removeCommunication"](index) {
    this.communicationList.splice(index, 1);
  }

  @Mutation
  ["resetOfficeSelectModule"]() {
    this.itemId = undefined;
    this.officeItem = {
      company_id: "",
      name: "",
      is_main: false,
      logo: "",
      remarks: "",
      users: [],
      addresses: [],
      communications: [],
    };
    this.companiesList = [];
    this.entitiesADList = [];
    this.contactsADList = [];
    this.entitiesCMList = [];
    this.contactsCMList = [];
    this.addressTypeList = [];
    this.locationsList = [];
    this.mediasList = [];
    this.addressesList = [];
    this.communicationList = [];
    this.officeList = [];
  }
}
