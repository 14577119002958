import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class QuotationsModule extends VuexModule {
  id: undefined;
  saleLevelCont = 0;
  costLevelCont = 0;
  tariffList: [] = [] as any;
  levelTariffList: any[] = [];
  ratesList: any[] = [];
  equipmentsList: any[] = [];
  unitsList: any[] = [];
  quotationsResult: any[] = [];

  get Id(): any {
    return this.id;
  }

  get Tariff(): [] {
    return this.tariffList;
  }

  get SaleCont(): any {
    return this.saleLevelCont;
  }

  get CostCont(): any {
    return this.costLevelCont;
  }

  get LevelTariff(): any[] {
    return this.levelTariffList;
  }

  get Rates(): any[] {
    return this.ratesList;
  }

  get EquipmentsQuotations(): any[] {
    return this.equipmentsList;
  }

  get UnitsQuotations(): any[] {
    return this.unitsList;
  }

  get Quotations(): any[] {
    return this.quotationsResult;
  }

  @Mutation
  ["setId"](payload) {
    this.id = payload;
  }

  @Mutation
  ["setTariff"](payload) {
    this.tariffList = payload;
  }

  @Mutation
  ["setSaleLevelCont"](payload) {
    this.saleLevelCont = payload;
  }

  @Mutation
  ["setCostLevelCont"](payload) {
    this.costLevelCont = payload;
  }

  @Mutation
  ["setLevelTariff"](payload) {
    this.levelTariffList = payload;
  }

  @Mutation
  ["addLevelTariff"](payload) {
    this.levelTariffList.push(payload);
  }

  @Mutation
  ["addRate"](payload) {
    this.ratesList.push(payload);
  }

  @Mutation
  ["removeRate"](payload) {
    const index = this.ratesList.findIndex((x) => x === payload);
    this.ratesList.splice(index, 1);
  }

  @Mutation
  ["cleanRates"]() {
    this.ratesList = [];
  }

  @Mutation
  ["addEquipmentsQuotation"](payload) {
    this.equipmentsList.push(payload);
  }

  @Mutation
  ["cleanEquipmentsQuotation"]() {
    this.equipmentsList = [];
  }

  @Mutation
  ["removeEquipmentsQuotation"](index) {
    this.equipmentsList.splice(index, 1);
  }

  @Mutation
  ["addUnitsQuotation"](payload) {
    this.unitsList.push(payload);
  }

  @Mutation
  ["cleanUnitsQuotation"]() {
    this.unitsList = [];
  }

  @Mutation
  ["removeUnitsQuotation"](index) {
    this.unitsList.splice(index, 1);
  }

  @Mutation
  ["setQuotation"](payload) {
    this.quotationsResult.push(payload);
  }

  @Mutation
  ["resetQuotaton"]() {
    this.quotationsResult = [];
  }

  @Mutation
  ["resetRate"]() {
    this.ratesList = [];
  }

  @Mutation
  ["resetRateModule"]() {
    this.id = undefined;
    this.tariffList = [];
    this.levelTariffList = [];
    this.ratesList = [];
    this.equipmentsList = [];
    this.unitsList = [];
  }

  @Action
  [Actions.GET_QUOTATIONS_SALE](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.query("/api/tariff_level", { params: payload })
        .then((response: any) => {
          this.context.commit("setTariff", response.data);
          this.context.commit("setSaleLevelCont", response.data.total);
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  }

  @Action
  [Actions.GET_QUOTATIONS_COST](payload) {
    return new Promise<any>((resolve, reject) => {
      ApiService.query("/api/tariff_level", { params: payload })
        .then((response: any) => {
          this.context.commit("setTariff", response.data);
          this.context.commit("setCostLevelCont", response.data.total);
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
