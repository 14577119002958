
import { computed, onMounted, ref, watch } from "vue";
import { ElNotification, ElTable } from "element-plus";
import MaritimeVoyageSelect from "@/components/catalogs-select/MaritimeVoyageSelect.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import TaxesDetailsSelect from "@/components/catalogs-select/TaxesDetailsSelect.vue";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";
import InputNumber from "@/components/inputs/InputNumber.vue";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
  page_count: number;
}

interface IHeaderConfiguration {
  column?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

interface Product {
  id: string;
  name: string;
  amount1: string;
  amount2: string;
  amount3: number;
}
interface SummaryMethodProps<T = Product> {
  columns: T[];
  data: T[];
}
export default {
  name: "TableBasicSelect",
  components: {
    SPSelect,
    MaritimeVoyageSelect,
    TaxesDetailsSelect,
    InputNumber,
  },
  emits: [
    "onEnsChange",
    "eventEmitButton",
    "onCellClick",
    "onSelectionChange",
    "onLinkClick",
    "onChangePage",
    "changePrebilling",
    "onClickGuardar",
    "newInline",
    "cancelInsertInline",
    "onEditInline",
    "sendEmail",
    'exportEquipments'
  ],
  props: {
    headers: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    show_totals: {
      type: Boolean,
      default: false,
    },
    paginationComponent: {
      type: Boolean,
      default: true,
    },
    use_check: {
      type: Boolean,
      default: false,
    },
    canSelectRow: {
      type: Function,
      default: () => true,
    },
    data: {
      type: Array,
      required: true,
    },
    show_link: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 1,
    },
    page_count: {
      type: Number,
      default: 1,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    canShow: {
      type: Boolean,
      default: false,
    },
    canRoute: {
      type: Boolean,
      default: false,
    },
    canDuplicate: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    verifyDelete: {
      type: Boolean,
      default: false,
    },
    pint_row: {
      type: Boolean,
      default: false,
    },
    showInsertInline: {
      type: Boolean,
      default: false,
    },
    rowsPerPage: {
      type: Number,
      default: 10,
    },
    exportName: {
      type: String,
      default: "Datos",
    },
    insertInline: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: "global",
    },
    prebillingsData: {
      type: Array,
      default: [] as any,
    },
    sendEmail: {
      type: Boolean,
      default: false,
    },
    getChanges: {
      type: Boolean,
      default: false,
    },
    martimeFileId: {
      type: Number,
      default: null,
    },
    officeId: {
      type: Number,
      default: null,
    },
    exportEquipments: {
      type: Boolean,
      default:false,
    }
  },

  setup: function (props, { emit }) {
    const result = (obj, string) => {
      if (obj === null) return "";
      const parts = string.split(".");
      const newObj = obj[parts[0]];
      if (parts[1]) {
        parts.splice(0, 1);
        const newString = parts.join(".");
        return result(newObj, newString);
      }
      return newObj;
    };
    const list = ref({
      customers: [] as any,
      providers: [] as any,
      shipowners: [] as any,
    });
    const selectedData = computed(() => store.getters.TableSelectList);
    const currentOfficeId = ref("");
    const currenciesChangeList = ref([] as any);
    const currentMFId = ref("");
    const currentBLId = ref("");
    const editIndex = ref<number>(-1);
    const prebillingSearch = ref<{
      cost_type_id: number[];
      offices_id: number[];
    }>({
      cost_type_id: [1],
      offices_id: props.officeId ? [props.officeId] : [],
    });
    const searchOffice = ref<{ cost_type_id: number[] }>({
      cost_type_id: [1],
    });
    const providerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [2],
      offices_id: props.officeId ? [props.officeId] : [],
    });
    const customerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [1, 13],
      offices_id: props.officeId ? [props.officeId] : [],
    });
    const shipOwnerSearch = ref<{
      type_entity_ids: number[];
      offices_id: number[];
    }>({
      type_entity_ids: [7],
      offices_id: props.officeId ? [props.officeId] : [],
    });
    const taxes_details_search = ref<{
      taxes: number[];
      offices: number[];
    }>({
      taxes: [],
      offices: props.officeId ? [props.officeId] : [],
    });
    const dataBls = computed(() => store.getters.BLS);
    const maritimeFiles = computed(() => store.getters.MaritimeFiles);
    const billingConcepts = computed(() => store.getters.BillingConcepts);
    const dataBookings = ref([] as any);
    const { t } = useI18n();
    const clearSelection = computed(() => store.getters.ResetSelectedTable);
    const store = useStore();
    const element_id = ref("") as any;
    const editInline = ref<boolean>(false);
    const dialogvisible = ref(false);
    const dialogvisible2 = ref(false);
    const dialogremovevisible = ref(false);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const preferences = localStorage.getItem("preferences") as any;
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: JSON.parse(preferences).items_per_page,
      total: 1,
      page_count: 1,
    });
    watch(
      () => JSON.parse(preferences).items_per_page,
      (selection, prevSelection) => {
        pagination.value.rowsPerPage = selection;
      }
    );
    watch(
      () => clearSelection.value,
      (first, prevSelection) => {
        if (clearSelection.value) {
          toggleSelection();
        }
      }
    );
    watch(
      () => props.total,
      (selection, prevSelection) => {
        pagination.value.total = selection;
      }
    );
    watch(
      () => props.page_count,
      (selection, prevSelection) => {
        pagination.value.page_count = selection;
      }
    );
    const multipleSelection = computed(() => store.getters.TableSelectList);
    //const multipleSelection = ref<any[]>([]);
    const item_id = ref(null);
    const maritime_voyage_filter = ref({
      trip_type: "",
      office_id: "",
    });
    const editButton = computed(
      () => props.buttons.filter((x) => x.type === "EDIT").length > 0
    );
    const processButton = computed(
      () => props.buttons.filter((x) => x.type === "PROCESS").length > 0
    );
    const contabilizarButton = computed(
      () => props.buttons.filter((x) => x.type === "CONTABILIZAR").length > 0
    );
    const locationButton = computed(
      () => props.buttons.filter((x) => x.type === "LOCATION").length > 0
    );
    const viewButton = computed(
      () => props.buttons.filter((x) => x.type === "VIEW").length > 0
    );
    const playButton = computed(
      () => props.buttons.filter((x) => x.type === "PLAY").length > 0
    );
    const detailsButton = computed(
      () => props.buttons.filter((x) => x.type === "DETAILS").length > 0
    );
    const generateEdiButton = computed(
      () => props.buttons.filter((x) => x.type === "GENERATE_EDI").length > 0
    );
    const duplicateButton = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-1").length > 0
    );
    const duplicateButton1 = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-3").length > 0
    );
    const rolarButton = computed(
      () => props.buttons.filter((x) => x.type === "ROLAR").length > 0
    );
    const duplicate2Button = computed(
      () => props.buttons.filter((x) => x.type === "DUPLICATE-2").length > 0
    );
    const removeButton = computed(
      () => props.buttons.filter((x) => x.type === "REMOVE").length > 0
    );
    const activateButton = computed(
      () => props.buttons.filter((x) => x.type === "ACTIVATE").length > 0
    );
    const downloadButton = computed(
      () => props.buttons.filter((x) => x.type === "DOWNLOAD").length > 0
    );
    const hasEdiPath = (index) => {
      return props.data[index].edi_path_id;
    };

    const handleSizeChange = (val: number) => {};

    const handleCurrentChange = (val: number) => {
      emit("onChangePage", val);
    };

    const hasEnsChange = (val: string, all_data: any) => {
      emit("onEnsChange", { id: all_data.row.id, value: val });
    };

    const toggleSelection = (rows?: any[]) => {
      if (rows) {
        rows.forEach((row) => {
          // TODO: improvement typing when refactor table
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          multipleTableRef.value!.toggleRowSelection(row, undefined);
        });
      } else {
        multipleTableRef.value!.clearSelection();
      }
    };
    const handleSelectionChange = (val: any[]) => {
      emit("onSelectionChange", val);
      //multipleSelection.value = val;
      store.commit("setTableSelectList", val);
    };

    const exportTable = (all) => {
      store.commit("setLoadingStatus", true);
      if (all) {
        emit("exportAll");
      } else {
        ApiService.setResponseTypeBlob();
        let headersNames: string[] = [];
        let headers: string[] = [];
        props.headers.forEach((element) => {
          headersNames.push(t(element.column));
          headers.push(element.key);
        });
        ApiService.post(`/api/export`, {
          params: {
            thsNames: headersNames,
            ths: headers,
            data: selectedData.value,
          },
        })
          .then((data) => {
            let fileUrl = window.URL.createObjectURL(data);
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", props.exportName + `.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
            store.commit("setLoadingStatus", false);
          })
          .catch((error) => {
            console.log(error);
            store.commit("setLoadingStatus", false);
          });
        ApiService.setResponseTypeJson();
      }
    };

    const getItems = computed(() => {
      return JSON.parse(JSON.stringify(props.data));
    });

    const onClickEdit = (index, row) => {
      emit("eventEmitButton", props.data[index].id, "EDIT", index);
    };
    const onClickRoutes = (index, row) => {
      emit("eventEmitButton", props.data[index].id, "ROUTES");
    };
    const onClickView = (index, row) => {
      emit("eventEmitButton", props.data[index].id, "VIEW");
    };
    const onClickPlay = (index, row) => {
      emit("eventEmitButton", props.data[index], "PLAY");
    };
    const onClickDetails = (index, row) => {
      emit("eventEmitButton", props.data[index], "DETAILS");
    };
    const onClickProcess = (index, row) => {
      emit("eventEmitButton", props.data[index], "PROCESS");
    };
    const onClickContabilizar = (index, row) => {
      emit("eventEmitButton", props.data[index], "CONTABILIZAR");
    };
    const onClickLocate = (index, row) => {
      emit("eventEmitButton", props.data[index], "LOCATE");
    };

    const onClickGenerateEdi = (index, row) => {
      emit("eventEmitButton", props.data[index].id, "GENERATE_EDI");
    };

    const onClickDuplicate = (index, row) => {
      item_id.value = props.data[index].id;
      dialogvisible.value = true;
    };

    const onClickMove = async (index, row) => {
      item_id.value = props.data[index].id;
      maritime_voyage_filter.value.trip_type =
        props.data[index].trip_type_id == 2 ||
        props.data[index].trip_type_id == "I" ||
        props.data[index].trip_type_id == "IMPORT" ||
        props.data[index].trip_type_id == "import" ||
        props.data[index].trip_type_id == "Import"
          ? "I"
          : "E";
      const maritime_voyage = (
        await ApiService.get(
          `/api/maritimevoyages/?code=${props.data[index].maritime_voyage_id}`
        )
      ).data.maritime_voyages[0];
      maritime_voyage_filter.value.office_id =
        maritime_voyage.maritime_file.office_id;
      store.commit("clearMaritimeVoyage");
      store.commit("addMaritimeVoyage", maritime_voyage);
      dialogvisible2.value = true;
    };

    const onClickDownload = (index, row) => {
      emit("eventEmitButton", props.data[index], "DOWNLOAD", index);
    };

    const onClickRemove = (index, row) => {
      item_id.value = props.data[index].id;
      // dialogremovevisible.value = true;
      emit("eventEmitButton", props.data[index].id, "REMOVE");
    };

    const onClickDuplicate2 = (index, row) => {
      emit("eventEmitButton", row.id, "DUPLICATE", row.id);
    };

    const duplicateElement = (element_id, item_id) => {
      dialogvisible.value = false;
      emit("eventEmitButton", item_id, "DUPLICATE", element_id);
    };

    const moveElement = (element_id, item_id) => {
      dialogvisible2.value = false;
      emit("eventEmitButton", item_id, "MOVE", element_id);
    };

    const removeElement = (item_id, soft) => {
      dialogremovevisible.value = false;
      emit("eventEmitButton", item_id, "REMOVE", soft);
    };

    const tableRowClassName = ({ row, rowIndex }) => {
      if (props.pint_row && row.is_loss) {
        return "table-row danger-row";
      }
      return "table-row";
    };

    const is_diferent_currency = (dta) => {
      const element = dta[0];
      let val = false;
      dta.forEach((x) => {
        if (x.currency_id !== null && x.currency_id !== element.currency_id) {
          val = true;
        }
      });
      return val;
    };
    const getSummaries = (param) => {
      if (props.show_totals) {
        const { columns, data } = param;
        const exist_diferent_currency = is_diferent_currency(props.data);
        const sums: string[] = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = t("itotals");
            return;
          }
          const values = data.map((item) => {
            if (
              column.property === "cost" ||
              column.property === "venta" ||
              column.property === "armador" ||
              column.property === "amounts" ||
              column.property === "items.bill_total" ||
              column.property === "bill_total"
            ) {
              return Number(item[column.property]);
            }
            return NaN;
          });
          if (
            !values.every((value) => Number.isNaN(value)) &&
            !exist_diferent_currency
          ) {
            sums[index] = `${values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!Number.isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0)}`;
          } else {
            if (data && !values.every((value) => Number.isNaN(value))) {
              let count = 0;
              data.forEach((x, index) => {
                const currenci_exchange = currenciesChangeList.value.find(
                  (y) => y.origin_currency_id === x.currency_id
                );
                if (currenci_exchange) {
                  values[index] =
                    values[index] * parseFloat(currenci_exchange.exchange_sale);
                }
              });
              sums[index] = `${values.reduce((prev, curr) => {
                const value = Number(curr);
                count++;
                if (!Number.isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0)}`;
            } else {
              sums[index] = "";
            }
          }
        });
        const resp = [] as any;
        sums.forEach((x: any) => {
          try {
            x = parseFloat(x).toFixed(2);
            resp.push(x === "NaN" ? "" : x);
          } catch (e) {
            resp.push(x);
          }
        });
        return resp;
      } else {
        return "";
      }
    };
    //Metodos para la creacion en linea de las prebillings

    const loadCurrenciesChanges = async (maritime_file_id) => {
      const { data } = await ApiService.query(`/api/currency_exchanges/`, {
        params: {
          maritime_files: [maritime_file_id],
          order_by_id: "DESC",
          page: 1,
          per_page: 100,
        },
      });
      currenciesChangeList.value = data.currency_exchanges;
    };

    watch(
      () => props.insertInline,
      (first) => {
        if (props.insertInline && editInline.value) {
          editInline.value = false;
          editIndex.value = -1;
        }
      },
      { deep: true }
    );

    const changeBilling = (value) => {
      emit("changePrebilling", value);
    };
    const onClickGuardar = (scope, row) => {
      if (!isValidNewInline(row)) {
        return;
      }
      editInline.value = false;
      editIndex.value = -1;
      emit("onClickGuardar", row);
      currentOfficeId.value = "";
      currentMFId.value = "";
      currentBLId.value = "";
    };

    const isValidNewInline = (row) => {
      console.log(row);
      console.log("rowrowrowrowrowrowrowrowrowrowrowrowrowrowrow");
      if (
        !row.code_id ||
        !row.currency_id ||
        !row.freight_payment_type_id ||
        !row.iva_detail_sell_id ||
        (!row.office_id2 && props.origin === "global") ||
        (!row.maritime_file_id &&
          !row.bills_of_lading_id &&
          props.origin === "global")
      ) {
        ElNotification({
          title: "Error",
          message: t("inlineprebillingvalidate"),
          type: "error",
          dangerouslyUseHTMLString: true,
        });
        return false;
      }
      return true;
    };
    const onCancelInline = (scope) => {
      editIndex.value = -1;
      if (editInline.value) {
        scope.row.code = scope.row.all_data.prebilling_concept.code;
      }
      currentOfficeId.value = "";
      currentMFId.value = "";
      currentBLId.value = "";
      emit("cancelInsertInline", editInline.value ? -1 : scope.$index);
      editInline.value = false;
    };
    const onSendEmail = (scope) => {
      emit("sendEmail", scope.row);
    };
    const onExportEquipments = (scope) => {
      emit('exportEquipments', scope.row)
    }
    const onCellDoubleClick = (row) => {
      if (
        row.id && props.showInsertInline && row.all_data
          ? row.all_data.has_been_billed === false
          : false
      ) {
        currentOfficeId.value = "";
        currentMFId.value = "";
        currentBLId.value = "";
        const index = props.data.findIndex((x) => x.id === row.id);
        if (editInline.value) {
          editInline.value = false;
        }
        editIndex.value = index;
        editInline.value = true;
        store.commit("addBillingConcepts", row.all_data.prebilling_concept);
        let tax_detail = null as any;
        row.all_data.prebilling_amounts.forEach((a) => {
          if (a.cost_type_id === 1) {
            tax_detail = a.taxes_detail;
          }
        });
        if (tax_detail) {
          store.commit("updateTaxesDetails", tax_detail);
        }
        row.maritime_file_id =
          row.maritime_file_id === null ? "" : row.maritime_file_id;
        row.maritime_file = row.maritime_file === null ? "" : row.maritime_file;
        row.bills_of_lading =
          row.bills_of_lading === null ? "" : row.bills_of_lading;
        row.bills_of_lading_id =
          row.bills_of_lading_id === null ? "" : row.bills_of_lading_id;
        if (row.all_data.maritime_file_id) {
          store.commit("updateMaritimeFiles", row.all_data.maritime_file);
        }
        if (row.all_data.bills_of_lading) {
          store.commit("updateBls", row.all_data.bills_of_lading);
        }
        if (row.all_data.customer_id) {
          store.commit("addCustomer", row.all_data.customer);
        }
        if (row.all_data.provider_id) {
          store.commit("addProvider", row.all_data.provider);
        }
        if (row.all_data.shipowner_id) {
          store.commit("addShipowner", row.all_data.shipowner);
        }
        if (row.all_data.location_abroad_id) {
          store.commit("addLocation", row.all_data.location_abroad);
        }
        if (row.all_data.office_id) {
          prebillingSearch.value.offices_id = [row.all_data.office_id];
          providerSearch.value.offices_id = [row.all_data.office_id];
          customerSearch.value.offices_id = [row.all_data.office_id];
          shipOwnerSearch.value.offices_id = [row.all_data.office_id];
          taxes_details_search.value.offices = [row.all_data.office_id];
          store.commit("addOffice", row.all_data.office);
        }
        if (row.freight_payment_type_id) {
          store.commit(
            "addFreightPaymentType",
            row.all_data.freight_payment_type
          );
        }
        store.commit(
          "addCurrency",
          row.all_data.prebilling_amounts[0].currency
        );
        emit("onEditInline");
      }
    };
    const changeBL = async (value, scope) => {
      store.commit("clearOffice");
      if (value) {
        const { data } = await ApiService.get("api/bills_of_lading/" + value);
        if (data.bookings.length) {
          scope.row.bills_of_lading_id = value;
          const booking = data.bookings[0];
          scope.row.office_id2 =
            booking.maritime_voyage.maritime_file.office_id;
          prebillingSearch.value.offices_id = [
            booking.maritime_voyage.maritime_file.office_id,
          ];
          providerSearch.value.offices_id = [
            booking.maritime_voyage.maritime_file.office_id,
          ];
          customerSearch.value.offices_id = [
            booking.maritime_voyage.maritime_file.office_id,
          ];
          shipOwnerSearch.value.offices_id = [
            booking.maritime_voyage.maritime_file.office_id,
          ];
          store.commit(
            "addOffice",
            booking.maritime_voyage.maritime_file.office
          );
          if (booking.maritime_voyage.maritime_file.office.addresses.length) {
            scope.row.location_abroad_id =
              booking.maritime_voyage.maritime_file.office.addresses[0].location_id;
            store.commit(
              "addLocation",
              booking.maritime_voyage.maritime_file.office.addresses[0].location
            );
          }
        } else {
          scope.row.bills_of_lading_id = "";
          ElNotification({
            title: "Error",
            message: t("no_has_bookings"),
            type: "error",
            dangerouslyUseHTMLString: true,
          });
        }
      } else {
        currentBLId.value = "";
        scope.row.bills_of_lading_id = "";
        scope.row.office_id2 = "";
        clearData(scope);
      }
    };

    const changeOffice = (value, scope) => {
      scope.row.office_id2 = value;
      prebillingSearch.value.offices_id = [value];
      providerSearch.value.offices_id = [value];
      customerSearch.value.offices_id = [value];
      shipOwnerSearch.value.offices_id = [value];
      taxes_details_search.value.offices = [value];
      clearData(scope);
    };

    const clearData = (scope) => {
      store.commit("clearBillingConcepts");
      list.value.providers = [];
      list.value.customers = [];
      list.value.shipowners = [];
      scope.row.location_abroad_id = "";
      scope.row.code_id = "";
      scope.row.billing_concept = "";
      scope.row.customer_id = "";
      scope.row.provider_id = "";
      scope.row.shipowner_id = "";
    };

    const changeCode = (val, scope) => {
      if (val) {
        const concept = billingConcepts.value.find((x) => x.id === val);
        scope.row.code_id = val;
        scope.row.billing_concept = concept.name;
      } else {
        scope.row.code_id = "";
      }
    };
    const changeMaritimeFile = (value, scope) => {
      store.commit("clearOffice");
      if (value) {
        scope.row.maritime_file_id = value;
        const mf = maritimeFiles.value.find((x) => x.id === value);
        scope.row.office_id2 = mf.office_id;
        prebillingSearch.value.offices_id = [value];
        providerSearch.value.offices_id = [value];
        customerSearch.value.offices_id = [value];
        shipOwnerSearch.value.offices_id = [value];
        store.commit("addOffice", mf.office);
        if (mf.office.addresses && mf.office.addresses.length) {
          scope.row.location_abroad_id = mf.office.addresses[0].location_id;
          store.commit("addLocation", mf.office.addresses[0].location);
        }
      } else {
        scope.row.maritime_file_id = "";
        scope.row.office_id2 = "";
        clearData(scope);
      }
    };

    onMounted(async () => {
      if (props.getChanges) {
        await loadCurrenciesChanges(props.martimeFileId);
      }
    });
    return {
      item_id,
      maritime_voyage_filter,
      element_id,
      dialogvisible,
      dialogvisible2,
      dialogremovevisible,
      multipleTableRef,
      multipleSelection,
      getItems,
      detailsButton,
      editButton,
      processButton,
      viewButton,
      playButton,
      generateEdiButton,
      duplicateButton,
      duplicateButton1,
      rolarButton,
      duplicate2Button,
      removeButton,
      activateButton,
      pagination,
      downloadButton,
      contabilizarButton,
      locationButton,
      prebillingSearch,
      providerSearch,
      customerSearch,
      shipOwnerSearch,
      taxes_details_search,
      editInline,
      editIndex,
      dataBls,
      dataBookings,
      searchOffice,
      list,
      selectedData,
      hasEdiPath,
      onClickEdit,
      onClickRoutes,
      onClickGenerateEdi,
      onClickDuplicate,
      onClickRemove,
      onClickView,
      onClickPlay,
      onClickDetails,
      onClickProcess,
      onClickDownload,
      onClickMove,
      onClickDuplicate2,
      onSendEmail,
      duplicateElement,
      moveElement,
      removeElement,
      toggleSelection,
      handleSelectionChange,
      handleSizeChange,
      handleCurrentChange,
      result,
      onClickContabilizar,
      onClickLocate,
      tableRowClassName,
      getSummaries,
      exportTable,
      changeBilling,
      onClickGuardar,
      onCellDoubleClick,
      onCancelInline,
      changeOffice,
      changeMaritimeFile,
      changeBL,
      changeCode,
      hasEnsChange,
      onExportEquipments
    };
  },
};
