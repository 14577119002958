import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

interface CompanyGroupTab {
  tab?: string;
  item_id?: number;
}

interface CompanyGroup {
  id?: number;
  name: string;
}

interface Company {
  id?: number;
  company_group_id: number | undefined;
  entity_id?: number;
  accounting_plan?: number;
  name: string;
  cif: string;
  website: string;
  logo: string;
  logodoc: string;
  code: string;
  fiscal_data: string | undefined;
}

interface Office {
  id?: number;
  company_id: number | undefined;
  name: string;
  is_main: boolean;
  logo: string;
  remarks: string;
  users: [];
  addresses: [];
  communications: [];
}

interface Addresses {
  id?: number;
  address_type_id?: number;
  company_id?: number;
  office_id: number | undefined;
  entity_id: number | undefined;
  contact_id: number | undefined;
  street: string;
  number: string;
  floor_number: string;
  door: string;
  location_id: number | undefined;
  latitude: string;
  longitude: string;
}

interface Communication {
  id?: number;
  media_id: number | undefined;
  office_id: number | undefined;
  entity_id: number | undefined;
  contact_id: number | undefined;
  value: string;
  remark: string;
}

@Module
export default class CompaniesGroupModule extends VuexModule {
  itemGCId: undefined;
  companyGroupItem: CompanyGroup = {
    name: "",
  };
  companyGroupTab: CompanyGroupTab = {};
  companyItem: Company = {
    id: undefined,
    company_group_id: undefined,
    entity_id: undefined,
    name: "",
    cif: "",
    website: "",
    logo: "",
    logodoc: "",
    code: "",
    fiscal_data: "",
  };
  officeCItem: Office = {
    id: undefined,
    company_id: undefined,
    name: "",
    is_main: false,
    logo: "",
    remarks: "",
    users: [],
    addresses: [],
    communications: [],
  };

  addressOItem: Addresses = {
    id: undefined,
    address_type_id: undefined,
    company_id: undefined,
    office_id: undefined,
    entity_id: undefined,
    contact_id: undefined,
    street: "",
    number: "",
    floor_number: "",
    door: "",
    location_id: undefined,
    latitude: "",
    longitude: "",
  };
  fromChild = false;
  companiesEdit = false;
  officesEdit = false;
  communicationOEdit = false;
  addressOEdit = false;
  companiesListG: Company[] = [];
  officesList: Office[] = [];
  coaddressesList: Addresses[] = [];
  cocommunicationList: Communication[] = [];

  accountingPlanList: any[] = [];

  get CompanyGroupId(): any {
    return this.itemGCId;
  }

  get CompanyGroup(): any {
    return this.companyGroupItem;
  }

  get CompanyGroupTabItem(): CompanyGroupTab {
    return this.companyGroupTab;
  }

  get Company(): any {
    return this.companyItem;
  }

  get OfficeC(): any {
    return this.officeCItem;
  }

  get AddressOC(): any {
    return this.addressOItem;
  }

  get COEdit(): boolean {
    return this.communicationOEdit;
  }

  get AOEdit(): boolean {
    return this.addressOEdit;
  }

  get UpdateFromChild(): boolean {
    return this.fromChild;
  }

  get CCEdit(): boolean {
    return this.companiesEdit;
  }

  get OOEdit(): boolean {
    return this.officesEdit;
  }

  get CompaniesListG(): Company[] {
    return this.companiesListG;
  }

  get OfficeCompaniesList(): Office[] {
    return this.officesList;
  }

  get COAddressOffice(): any[] {
    return this.coaddressesList;
  }

  get COCommunicationOffice(): any[] {
    return this.cocommunicationList;
  }

  get AccountingPlan(): any[] {
    return this.accountingPlanList;
  }

  @Mutation
  ["setCGItem"](payload) {
    this.itemGCId = payload;
  }

  @Mutation
  ["setCompanyGroup"](payload) {
    this.companyGroupItem = payload;
  }

  @Mutation
  ["setCompanyGroupTabTab"](payload) {
    this.companyGroupTab = payload;
  }

  @Mutation
  ["removeCompanyGroupTabTab"]() {
    this.companyGroupTab = {};
  }

  @Mutation
  ["setCompanyItem"](payload) {
    this.companyItem = payload;
  }

  @Mutation
  ["setOfficeC"](payload) {
    this.officeCItem = payload;
  }

  @Mutation
  ["setAddressOC"](payload) {
    this.addressOItem = payload;
  }

  @Mutation
  ["setFromChild"](payload) {
    this.fromChild = payload;
  }

  @Mutation
  ["setCEdit"](payload) {
    this.companiesEdit = payload;
  }

  @Mutation
  ["setOEdit"](payload) {
    this.officesEdit = payload;
  }

  @Mutation
  ["setCOEdit"](payload) {
    this.communicationOEdit = payload;
  }

  @Mutation
  ["setAOEdit"](payload) {
    this.addressOEdit = payload;
  }

  @Mutation
  ["setCompanyG"](payload) {
    this.companiesListG.push(payload);
  }

  @Mutation
  ["updateCompany"](payload) {
    const index = payload.index;
    this.companiesListG[index] = payload.item;
  }

  @Mutation
  ["removeCompany"](index) {
    this.companiesListG.splice(index, 1);
  }

  @Mutation
  ["setOfficeCompany"](payload) {
    this.officesList.push(payload);
  }

  @Mutation
  ["updateOfficeCompany"](payload) {
    const index = payload.index;
    this.officesList[index] = payload.item;
  }

  @Mutation
  ["removeOfficeCompany"](index) {
    this.officesList.splice(index, 1);
  }

  @Mutation
  ["setCOAddress"](payload) {
    this.coaddressesList.push(payload);
  }

  @Mutation
  ["updateCOAddress"](payload) {
    const index = payload.index;
    this.coaddressesList[index] = payload.item;
  }

  @Mutation
  ["removeCOAddress"](index) {
    this.coaddressesList.splice(index, 1);
  }

  @Mutation
  ["cleanCOAddress"](index) {
    this.coaddressesList = [];
  }

  @Mutation
  ["setCOCommunication"](payload) {
    this.cocommunicationList.push(payload);
  }

  @Mutation
  ["updateCOCommunication"](payload) {
    const index = payload.index;
    this.cocommunicationList[index] = payload.item;
  }

  @Mutation
  ["removeCOCommunication"](index) {
    this.cocommunicationList.splice(index, 1);
  }

  @Mutation
  ["setAccountingPlan"](payload) {
    this.accountingPlanList = payload;
  }

  @Mutation
  ["addAccountingPlan"](payload) {
    if (payload && Array.isArray(this.accountingPlanList)) {
      const index = this.accountingPlanList.findIndex(
        (x) => x.id === payload.id
      );
      if (index === -1) {
        this.accountingPlanList.push(payload);
      }
    }
  }

  @Mutation
  ["cleanCOCommunication"](index) {
    this.cocommunicationList = [];
  }

  @Mutation["resetOfficeCompany"](){
    this.officesList=[];
  }

  @Mutation
  ["resetOfficeSelectModule"]() {
    this.itemGCId = undefined;
    this.companyGroupTab = {};
    this.companyGroupItem = {
      name: "",
    };
    this.companyItem = {
      id: undefined,
      company_group_id: undefined,
      name: "",
      cif: "",
      website: "",
      logo: "",
      logodoc: "",
      code: "",
      fiscal_data: "",
    };
    this.officeCItem = {
      id: undefined,
      company_id: undefined,
      name: "",
      is_main: false,
      logo: "",
      remarks: "",
      users: [],
      addresses: [],
      communications: [],
    };
    this.addressOItem = {
      id: undefined,
      company_id: undefined,
      office_id: undefined,
      entity_id: undefined,
      contact_id: undefined,
      street: "",
      number: "",
      floor_number: "",
      door: "",
      location_id: undefined,
      latitude: "",
      longitude: "",
    };
    this.companiesEdit = false;
    this.officesEdit = false;
    this.communicationOEdit = false;
    this.addressOEdit = false;
    this.companiesListG = [];
    this.officesList = [];
    this.coaddressesList = [];
    this.cocommunicationList = [];
    this.officesList = [];
  }

  @Action
  ["saveCompanyGroup"]({ payload, itemId }) {
    if (itemId === "") {
      return new Promise<void>((resolve, reject) => {
        ApiService.post("/api/companiesGroups", payload)
          .then(({ data }) => {
            this.context.commit("setCompanyGroup", {
              id: data.id,
              name: data.name,
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        ApiService.put(`/api/companiesGroups/${itemId}`, payload)
          .then(({ data }) => {
            this.context.commit("setCompanyGroup", {
              id: data.id,
              name: data.name,
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    }
  }

  @Action
  ["saveCompany"]({ payload, itemId }) {
    if (itemId === "" || itemId === undefined) {
      return new Promise<void>((resolve, reject) => {
        ApiService.postAttach("/api/companies", payload)
          .then(({ data }) => {
            this.context.commit("setCompanyG", {
              id: data.id,
              company_group_id: data.company_group_id,
              entity_id: data.entity_id,
              entity_name: data.entity ? data.entity.name : "",
              name: data.name,
              cif: data.cif,
              website: data.website,
              logo: data.logo,
              logodoc: data.logodoc,
              addresses: [],
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        ApiService.postAttach(`/api/companies/update/${itemId}`, payload)
          .then(({ data }) => {
            const index = this.companiesListG.findIndex((x) => x.id === itemId);
            this.context.commit("updateCompany", {
              index: index,
              item: {
                id: data.id,
                company_group_id: data.company_group_id,
                entity_id: data.entity_id ? data.entity_id : "",
                entity_name: data.name ? data.entity.name : "",
                name: data.name,
                cif: data.cif,
                website: data.website,
                logo: data.logo,
                logodoc: data.logodoc,
              },
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    }
  }

  @Action
  ["saveCGOffice"]({ payload, itemId }) {
    if (!itemId) {
      return new Promise<void>((resolve, reject) => {
        ApiService.postAttach("/api/offices", payload)
          .then(({ data }) => {
            const result = {
              id: data.id,
              company_id: data.company.name,
              name: data.name,
              is_main: data.is_main !== 0,
              logo: data.logo,
              remarks: data.remarks,
            };
            this.context.commit("setOfficeC", result);
            this.context.commit("setOfficeCompany", result);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        ApiService.postAttach(`/api/offices/update/${itemId}`, payload)
          .then(({ data }) => {
            const result = {
              id: data.id,
              company_id: data.company.name,
              name: data.name,
              is_main: data.is_main !== 0,
              logo: data.logo,
              remarks: data.remarks,
            };
            const index = this.officesList.findIndex((x) => x.id === itemId);
            this.context.commit("setOfficeC", result);
            this.context.commit("updateOfficeCompany", {
              index: index,
              item: result,
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    }
  }

  @Action
  ["saveOAddress"]({ payload, itemId }) {
    if (!itemId || itemId === undefined) {
      return new Promise<void>((resolve, reject) => {
        ApiService.post("/api/address", payload)
          .then(({ data }) => {
            this.context.commit("setCOAddress", {
              id: data.id,
              company_id: data.company_id,
              entity_id: data.entity_id,
              contact_id: data.contact_id,
              address_types: data.address_types.map((x) => x.id),
              office_id: data.office_id,
              street: data.street,
              number: data.number,
              floor_number: data.floor_number,
              door: data.door,
              zip_code: data.zip_code,
              latitude: data.latitude,
              longitude: data.longitude,
              location_id: data.location_id,
              location_name: data.location_id ? data.location.name : null,
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        ApiService.put(`/api/address/${itemId}`, payload)
          .then(({ data }) => {
            const index = this.coaddressesList.findIndex(
              (x) => x.id === itemId
            );
            this.context.commit("updateCOAddress", {
              index: index,
              item: {
                id: data.id,
                company_id: data.company_id,
                entity_id: data.entity_id,
                contact_id: data.contact_id,
                address_types: data.address_types.map((x) => x.id),
                office_id: data.office_id,
                street: data.street,
                number: data.number,
                floor_number: data.floor_number,
                door: data.door,
                zip_code: data.zip_code,
                latitude: data.latitude,
                longitude: data.longitude,
                location_id: data.location_id,
                location_name: data.location_id ? data.location.name : null,
              },
            });
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    }
  }

  @Action
  ["removeOAddress"]({ itemId, force }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`/api/address/${itemId}?force_delete=${force}`)
        .then(({ data }) => {
          this.context.commit("setCOAddress", data);
          const index = this.coaddressesList.findIndex((x) => x.id === itemId);
          this.context.commit("removeCOAddress", index);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
