
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InputNumber",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1000000000000000,
    },
    val: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    style: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    positionContols: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onChangeValue"],
  setup(props, { emit }) {
    const showError = ref(false);
    const current_val = ref(props.val);
    const validateInput = (val) => {
      if (val === "") {
        current_val.value = 0;
        emit("onChangeValue", 0);
      } else {
        emit("onChangeValue", val);
      }
    };
    watch(
      () => props.val,
      (first) => {
        if (first) {
          current_val.value = first;
        }
      },
      { deep: true }
    );
    return {
      showError,
      current_val,
      validateInput,
    };
  },
});
