import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElNotification } from "element-plus";
import store from "@/store";
import router from "@/router";
import {isArray} from "element-plus/es/utils/util";
import i18n from "@/core/plugins/i18n";
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.headers.common[
      "accept"
    ] = `Accept: application/json`;
    const userLang = window.navigator.language || "en";
    const lang = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : userLang.slice(0, 2) || "en";
    ApiService.setHeaderLocale(lang);
    ApiService.interceptor();
    if (store.getters.isUserAuthenticated) {
      ApiService.setHeader();
    }
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setResponseTypeBlob(): void {
    ApiService.vueInstance.axios.defaults.responseType = "blob";
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setResponseTypeJson(): void {
    ApiService.vueInstance.axios.defaults.responseType = "json";
  }

  /**
   * @description set the default locale request headers
   */
  public static setHeaderLocale(lang: string): void {
    ApiService.vueInstance.axios.defaults.headers.common["locale"] = lang;
  }

  // interceptors for axios
  public static interceptor() {
    ApiService.vueInstance.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        store.dispatch("addBodyClassName", "page-loading");
        return config;
      },
      (error) => {
        store.dispatch("removeBodyClassName", "page-loading");
        store.commit("setLoading", true);
        store.commit("setLoadingStatus", false);
        return Promise.reject(error);
      }
    );

    ApiService.vueInstance.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        store.dispatch("removeBodyClassName", "page-loading");
        store.commit("setLoading", true);
        if (response.config.method !== "get") {
          ElNotification({
            title: "Success",
            message: response.data.message || i18n.global.t("successOperation"),
            type: "success",
          });
        }
        return response.data;
      },
      ({ response }) => {
        store.dispatch("removeBodyClassName", "page-loading");
        store.commit("setLoading", true);
        store.commit("setLoadingStatus", false);
        const message =
          response.data.message || i18n.global.t("unexpectedError");
        // store.commit("setError", [message]);
        if (
          response.status === 401 &&
          response.config.url !== "/api/auth/login"
        ) {
          Swal.fire({
            text:
              message == "Unauthenticated."
                ? i18n.global.t("unauthenticated")
                : message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: i18n.global.t("logout"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            store.commit("logOut");
            router.go(0);
          });
        } else {
          let k,
            tr = "";
          if (response.data.errors && !isArray(response.data.errors)) {
            for (k in response.data.errors) {
              tr += "• " + response.data.errors[k] + "<br><br><br>";
            }
          } else {
            tr = message;
          }
          Swal.fire({
            html: tr,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: i18n.global.t("tryAgain"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        return Promise.reject(response);
      }
    );
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig | Record<any, unknown>
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the PATCH HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static patch(
    resource: string,
    params: AxiosRequestConfig | Record<string, unknown>
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the POST HTTP request with attach
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postAttach(
    resource: string,
    params: FormData
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios({
      method: "POST",
      url: `${resource}`,
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig | Record<string, unknown>
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the PUT HTTP request with attach
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static putAttach(
    resource: string,
    params: FormData
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios({
      method: "PUT",
      url: `${resource}`,
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
