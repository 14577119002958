import ApiService from "@/core/services/ApiService";
import { ForwarderJobEndpointEnum } from "@/enums/api_endpoints/forwarder/ForwarderJobEnums";
import { ForwarderJobShortEndpointEnum } from "@/enums/api_endpoints/forwarder/ForwarderJobShortEnums";
import { ForwarderJobItem } from "@/interfaces/forwarder/ForwarderJobInterfaces.d";
import { ForwarderJobShortItem } from "@/interfaces/forwarder/ForwarderJobShortInterfaces.d";
import {
  ForwarderJobRoute,
  ForwarderJobRouteList,
} from "@/interfaces/forwarder/ForwarderJobRouteInterfaces";
import { ForwarderJobCargoItem } from "@/interfaces/forwarder/ForwarderJobCargoInterfaces";
import { Alert } from "@/interfaces/general/GeneralInterfaces";
import { AxiosResponse } from "axios";
import { ForwarderJobParticipantList } from "@/interfaces/forwarder/ForwarderJobParticipantInterfaces";

export async function mountedForwarderJobBig(store, id: number) {
  await ApiService.get(`${ForwarderJobEndpointEnum.CRUD}/${id}`).then(
    ({ data }: AxiosResponse<ForwarderJobItem>) => {
      console.log(data)
      store.commit("resetForwarderModule");
      store.commit("setCreator", [data.creator]);
      store.commit("setLast_Update", [data.last_update]);
      store.commit("setForwarderMasterId", [data.master]);
      store.commit(
        "setForwarderObservationsTab",
        [data.notes].concat(data.incidences)
      );
      store.commit("setForwarderJobItem", data as ForwarderJobItem);
      store.commit("setForwarderOffice", [data.office]);
      store.commit("setForwarderJobCategory", [data.forwarder_job_categories]);
      store.commit("setForwarderOfferType", [data.forwarder_offer]);
      store.commit("setForwarderJobIncoterm", [
        data.forwarder_job_incoterm_types,
      ]);
      store.commit("setforwarderJobTraffic", [
        data.forwarder_job_traffic_types,
      ]);
      store.commit("setForwarderJobGroupageType", [
        data.forwarder_job_groupage_types,
      ]);
      store.commit("setForwarderMastter", [data.matter]);
      store.commit("setForwarderJobTypeShippedVia", [
        data.forwarder_job_shipped_vias,
      ]);
      store.commit("setForwarderJobOperationType", [
        data.forwarder_job_operation_types,
      ]);
      store.commit("setforwaderJobGoodStatus", [
        data.forwarder_job_good_statuses,
      ]);
      store.commit("setforwarderJobServiceLines", [
        data.forwarder_job_service_lines,
      ]);
      store.commit("setforwarderCarrierGeneral", [data.forwarder_job_carrier]);
      store.commit("setforwarderJobFreightTypes", [
        data.forwarder_job_freight_types,
      ]);
      store.commit("setForwarderJobPaymentMethod", [
        data.forwarder_job_payment_method,
      ]);
      store.commit("setForwarderJobStatus", [data.forwarder_job_statuses]);
      store.commit("setForwarderClientGeneral", [data.client]);
      store.commit("setForwarderCorrespondentGeneral", [data.correspondent]);
      store.commit("setContact", [data.contact]);
      store.commit("setForwarderCommunication", [data.communication]);
      // store.commit("setForwarderUser", [data.sales_associate_id])
      // store.commit("setForwarderUserAgency", [data.responsible_associate_id])
      store.commit("setVoyageType", [data.forwarder_voyage_type]);
      store.commit("setVoyage", [data.forwarder_voyage]);
      store.commit(
        "setForwarderJobParticipant",
        data.forwarder_job_participant as ForwarderJobParticipantList[]
      );
      if (data.forwarder_job_route) {
        if (data.forwarder_job_route) {
          // We need to create a new object based on the incoming route data
          // for simplicity in its manipulation in the front-end.
          const ruta: ForwarderJobRouteList[] =
            data.forwarder_job_route as ForwarderJobRouteList[];
          const dataDetails: ForwarderJobRouteList[] = [];
          ruta.forEach((element: ForwarderJobRouteList, index: number) => {
            // Create a new object based on the data of the original.
            let newData = { ...element };
            // Delete data that would be otherwise duplicated between the route and its details.
            delete newData.created_at;
            delete newData.updated_at;
            delete newData.deleted_at;
            // Push the new object, combined with its own details, to the array.
            dataDetails.push(
              Object.assign(newData, element.forwarder_job_route_details)
            );
            // Delete the details property, as its data is already on the same level as the parent.
            delete newData.forwarder_job_route_details;
          });
          store.commit("setForwarderJobRoutes", dataDetails);
        }
      }
      if (data.forwarder_job_cargo) {
        data.forwarder_job_cargo.forEach((element: ForwarderJobCargoItem) => {
          store.commit("addForwarderJobCargo", element);
        });
      }

      if (data.house_jobs) {
        // data.house_jobs.forEach((element) => {
        //   element['forwarder_job_cargo'].forEach((e_fjc) => {
        //     store.commit("addForwarderJobCargo", e_fjc);
        //   })
        // });
      }

      if (data.alert) {
        data.alert.forEach((element: Alert) => {
          store.commit("setForwaderAlert", element);
        });
      }

      if (data.equipment_involved) {
        store.commit(
          "setforwarderEquipmentInvolvedSave",
          data.equipment_involved
        );
      }

      store.commit("setLoading", false);
      store.commit("setLoadingForwarder", false);
    }
  );
}
export async function mountedForwarderJobShort(store, id: number) {
  await ApiService.get(`${ForwarderJobShortEndpointEnum.CRUD}/${id}`).then(
    ({ data }: AxiosResponse<ForwarderJobShortItem>) => {
      store.commit("resetForwarderModule");
      store.commit("setForwarderJobShortItem", data as ForwarderJobShortItem);
      store.commit("setForwarderCommunication", [data.communication]);
      store.commit("setForwarderAddress", [data.address]);
      store.commit("setContact", [data.contact]);
      if (data.alert) {
        data.alert.forEach((element: Alert) => {
          store.commit("setForwaderAlert", element);
        });
      }
      store.commit("setLoading", false);
      store.commit("setLoadingForwarder", false);
    }
  );
}
