import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import index from "vuex-persistedstate";
import type { SummaryDeclaration } from "@/interfaces/general/SummaryDeclarationInterfaces";
import type { SummaryDeclarationItem } from "@/interfaces/general/SummaryDeclarationInterfaces";
import type { SummaryTransportMovement } from "@/interfaces/general/SummaryTransportMovementInterfaces";
import type { Shipper } from "@/interfaces/general/SyncronizeEntitieInterfaces";
import ApiService from "@/core/services/ApiService";
import { renderThumbStyle } from "element-plus";

interface ExpirationDates {
  expiration_date: string;
  amount: string;
  remarks: string;
}
interface BillingPayments {
  amount: string;
  currency?: string;
  currency_id: string;
  payment_date: string;
  remarks: string;
}
interface FormMaritimeFile {
  id?: number;
  code: string;
  file_type_id: number | undefined;
  office_id: number | undefined;
  port_id: number | undefined;
  vessel_id: number | undefined;
  ship_consignee_id: number | undefined;
  customer_id: number | undefined;
  contact_id: number | undefined;
  check_in_id: number | undefined;
  check_out_id: number | undefined;
  operation_id: number | undefined;
  prefix: string;
  calls: string;
  import: string;
  export: string;
}

interface VesselRoute {
  id?: number;
  ue?: number;
  location_id: number;
  order: number;
  arrival_date: number;
  departure_date: string;
  descriptions: string;
}

interface MaritimeTrip {
  id?: number;
  maritime_file_id?: number;
  code: string;
  shipowner_id: string;
  trip_type: string;
  remarks: string;
}

interface GoodsConsignee {
  id?: number;
  maritime_file_id?: number;
  consignee_id: string;
  type: string;
  sections: number;
  initial_bl?: number;
  final_bl?: number;
  initial_departure?: number;
  final_departure?: number;
  base_section?: number;
  section_identificator?: number;
}

interface ShipMooring {
  id?: number;
  maritime_file_id?: number;
  requirement_id: string;
  purpose_id: string;
  dock_id: string;
  check_in: string;
  check_out: string;
}

interface MooringOperation {
  id?: number;
  ship_mooring_id?: number;
  operation_type_id: string;
  station_id: string;
  goods_type_id: string;
}

interface Booking {
  id?: number;
  maritime_voyage_id: number;
  trip_type_id: string;
  booking_number: string;
  booking_date: string;
  reference: string;
}

interface BookingBL {
  id?: number;
  maritime_voyage_id: number;
  trip_type_id: string;
  booking_number: string;
  booking_date: string;
  booking_id?: number;
  bl_date: string;
  reference: string;
}

interface BookingRoute {
  id?: number;
  booking_id: number;
  route_type_id: number;
  location_id?: number;
  order: string;
  remarks: string;
  ue: number;
}

interface Participant {
  id?: number;
  booking_id: number;
  entity_id?: number;
  participant_type_id: number;
  entity_data: string;
}

interface BillOfLandingType {
  id?: number;
  code: string;
  name: string;
}

interface NextPreviousTransportMethod {
  id?: number;
  name: string;
  code: string;
}

interface BillOfLanding {
  id?: number;
  booking_id: number;
  bl_date: string;
  bill_of_lading_type_id: BillOfLandingType;
  next_previous_transport_method_id: NextPreviousTransportMethod
  humanitary_aid: boolean;
  military_purposes: boolean;
  accoutrements: boolean;
}




interface Shipload {
  id?: number;
  booking_id: number;
  bl_id?: number;
  container?: number;
  registered_container?: number;
  goods: string;
  remarks: string;
  imo: string;
  registered_equipments?: number;
}

interface EquipmentsOT {
  bl_id?: number;
  booking_id?: number;
  check?: string;
  connected_in_station?: string;
  connected_onboard?: string;
  connected_transport?: string;
  equipment_id?: number;
  equipment_supplier?: string;
  id?: number;
  is_empty?: string;
  registered_equipment_id?: number;
  registration_number?: string;
  seal_number?: string;
  pif?: string;
  customs?: string;
  under_cover?: string;
  weighers?: string;
  dispatched?: string;
  is_shipper_owner?: string;
}

interface GoodFeature {
  id?: number;
  shipload_id: number;
  measure_type_id: number;
  measure_unit_id: number;
  measure: string;
  remarks: string;
}

interface GoodImoFeature {
  id?: number;
  good_id?: number;
  dangerous_goods_type_id?: number;
  name?: string;
  packaging_type_id?: number;
}

interface GoodMeasures {
  id?: number;
  good_id?: number;
  measure_type_id?: number;
  measure_unit_id?: number;
  measure: string;
  remarks: string;
}

interface GoodImoMeasures {
  id?: number;
  dangerous_goods_id?: number;
  measure_type_id?: number;
  measure_unit_id?: number;
  measure: string;
  remarks: string;
}

interface Overdimensions {
  id?: number;
  equipment_id?: number;
  measure_type_id?: number;
  measure_unit_id?: number;
  measure: string;
  remarks: string;
}

interface OverdimensionsOT {
  id?: string;
  equipment_id?: number;
  measure_type_id?: number;
  measure_unit_id?: number;
  measure: string;
  remarks: string;
}

interface OverdimensionsOTNoCheck {
  id?: string;
  equipment_id?: number;
  measure_type_id?: number;
  measure_unit_id?: number;
  measure: string;
  remarks: string;
}

interface Equipment {
  select?: boolean;
  id?: number;
  good_id?: number;
  identification: string;
  equipment_type?: number;
}

interface EquipmentOT {
  select?: boolean;
  to_special_condition_id?: number;
  observations?: string;
  id?: number;
  good_id?: number;
  identification: string;
  equipment_type?: number;
}

interface MaritimeFileTab {
  tab?: string;
  item_id?: number;
}

interface BlBoTab {
  tab?: string;
  item_id?: number;
}

interface Prebilling {
  id?: number;
  bl_prebilling_id?: number;
  cost_type_id: number;
  units: string;
  unit_amount: string;
  total_amount: string;
  currency_id?: number;
  tax_id?: number;
  all_data: any;
  remarks: string;
}

interface Pagination {
  page: number;
  rowsPerPage: number;
  last_page: number;
  total: number;
}


interface Pagination {
  page: number;
  rowsPerPage: number;
  last_page: number;
  total: number;
}

@Module
export default class MaritimeFileModule extends VuexModule {
  selectedTab = "overview";
  bookingPosition?: number = undefined;
  loadinga = true;
  maritime_files = [] as any;
  bls = [] as any;
  isLoadingTable = false;
  isLoadingHistorical = false;
  maritimeFileId: undefined;
  shipLoadBlOT: [] = [] as any;
  shipLoadBlUOT: [] = [] as any;
  maritimeFileIdSelected: undefined;
  officeCustomers = [] as any;
  billing_payments: BillingPayments[] = [];
  maritimeFile: FormMaritimeFile = {
    id: undefined,
    code: "",
    file_type_id: 4,
    office_id: undefined,
    port_id: undefined,
    vessel_id: undefined,
    ship_consignee_id: undefined,
    customer_id: undefined,
    contact_id: undefined,
    check_in_id: undefined,
    check_out_id: undefined,
    operation_id: undefined,
    prefix: "",
    calls: "",
    import: "",
    export: "",
  };
  deleteId: undefined;
  duplicateId: undefined;
  bookingOpen: undefined;
  blOpen: undefined;
  prebillingOpen: undefined;
  currentElement: undefined;
  maritimeFileTab: MaritimeFileTab = {};
  blBoTab: BlBoTab = {};
  maritimeFileCodes: string[] = [];
  vesselRouteList: VesselRoute[] = [];
  maritimeTripsList: MaritimeTrip[] = [];
  goodsConsigneesList: GoodsConsignee[] = [];
  shipMooringsList: ShipMooring[] = [];
  mooringOperationsList: MooringOperation[] = [];
  expirationDates: ExpirationDates[] = [];

  bookingsList: Booking[] = [];
  bookingsTable: Booking[] = [];
  bookingsPagination: Pagination = {
    page: 1,
    rowsPerPage: 10,
    last_page: 1,
    total: 1,
  };
  bookingRouteList: BookingRoute[] = [];
  participantList: Participant[] = [];
  shiploadList: Shipload[] = [];
  equipmentsOT: EquipmentsOT[] = [];
  goodList: GoodFeature[] = [];
  goodOT: GoodFeature[] = [];
  goodMeasuresList: GoodMeasures[] = [];
  goodImoList: GoodImoFeature[] = [];
  goodImoMeasuresList: GoodImoMeasures[] = [];
  goodMeasuresListCopy: GoodMeasures[] = [];
  equipmentsList: Equipment[] = [];
  overdimensionsList: Overdimensions[] = [];
  overdimensionsOT: Overdimensions[] = [];
  overdimensionsListCopy: Overdimensions[] = [];
  imoDeclarationList: any[] = [];

  bl_element: undefined;
  booking_element: undefined;
  BLTable: BookingBL[] = [];
  bookingRouteBLList: BookingRoute[] = [];
  blPagination: Pagination = {
    page: 1,
    rowsPerPage: 10,
    last_page: 1,
    total: 1,
  };
  participantBLList: Participant[] = [];
  shiploadBLList: Shipload[] = [];
  goodBLList: GoodFeature[] = [];
  goodMeasuresBLList: GoodMeasures[] = [];
  goodsMeasuresOT: GoodMeasures[] = [];f
  goodMeasuresBLListCopy: GoodMeasures[] = [];
  goodImoBLList: GoodImoFeature[] = [];
  goodImoMeasuresBLList: GoodImoMeasures[] = [];
  equipmentsBLList: Equipment[] = [];
  shiploadOT: EquipmentOT[] = [];
  overdimensionsBLList: Overdimensions[] = [];
  measuresOTList: OverdimensionsOT[] = [];
  measuresOTListNoCheck: OverdimensionsOTNoCheck[] = [];
  overdimensionsBLListCopy: Overdimensions[] = [];
  billOfLandingList: BillOfLanding[] = [];
  billOfLandingTypesList: BillOfLandingType[] = [];
  nextPreviousTransportMethodList: NextPreviousTransportMethod[] = [];
  dispatches: any = [];
  currency_exchanges: any = [];

  prefactureTable: Prebilling[] = [];
  prefactureList: Prebilling[] = [];
  mf_prefactureList: Prebilling[] = [];
  mf_prefactureTable: Prebilling[] = [];
  c_prefactureList: Prebilling[] = [];
  c_prefactureTable: Prebilling[] = [];
  loadingEquipement = true;
  isActive = false;
  tableSelectList: any[] = [];
  taxesDetails: any[] = [];
  transportation_orders: any = [];

  // Summarys
  summaryDeclarationList: SummaryDeclaration[] = [];
  summaryDeclarationItem: SummaryDeclaration | undefined;
  entryTransportMovementList: SummaryTransportMovement[] = [];
  exitTransportMovementList: SummaryTransportMovement[] = [];
  entryRegularServiceCodeList:  SummaryTransportMovement[] = [];
  exitRegularServiceCodeList:  SummaryTransportMovement[] = [];
  entrySimplifiedCommunalTransitProcedureList:  SummaryTransportMovement[] = [];
  exitSimplifiedCommunalTransitProcedureList:  SummaryTransportMovement[] = [];
  shipperList:  Shipper[] = [];

  get BillingPaymentsData(): BillingPayments[] {
    return this.billing_payments;
  }
  get CurrenciesExchange(): [] {
    return this.currency_exchanges;
  }
  get TransportationOrders(): [] {
    return this.transportation_orders;
  }
  get GetloadingEquipement(): boolean {
    return this.loadingEquipement;
  }
  get GetLoadingHistorical(): boolean {
    return this.isLoadingHistorical;
  }
  get GetTaxesDetails(): any {
    return this.taxesDetails;
  }
  get loadingElements(): boolean {
    return this.loadinga;
  }

  get IsLoadingTable(): boolean {
    return this.isLoadingTable;
  }

  get MaritimeFiles(): [] {
    return this.maritime_files;
  }

  get BLS(): [] {
    return this.bls;
  }
  get getTabSelected(): string {
    return this.selectedTab;
  }
  get getBookingPosition(): number | undefined {
    return this.bookingPosition;
  }

  get maritimeId(): any {
    return this.maritimeFileId;
  }

 
  get maritimeIdSelected(): any {
    return this.maritimeFileIdSelected;
  }

  get maritimeFileItem(): any {
    return this.maritimeFile;
  }
  get getOfficeCustomers(): any {
    return this.officeCustomers;
  }
  get getShiploadOT(): any {
    return this.shipLoadBlOT;
  }
  get getShiploadUOT(): any {
    return this.shipLoadBlUOT;
  }

  get DeleteId(): any {
    return this.deleteId;
  }

  get DuplicateId(): any {
    return this.duplicateId;
  }

  get bookingOpenItem(): any {
    return this.bookingOpen;
  }

  get blOpenItem(): any {
    return this.blOpen;
  }

  get billTempExpirationDates(): any {
    return this.expirationDates;
  }

  get prebillingOpenItem(): any {
    return this.prebillingOpen;
  }

  get currentElementItem(): any {
    return this.currentElement;
  }

  get maritimeFileTabItem(): MaritimeFileTab {
    return this.maritimeFileTab;
  }

  get blBoTabItem(): BlBoTab {
    return this.blBoTab;
  }

  get maritimeFileCodesList(): any {
    return this.maritimeFileCodes;
  }

  get VesselRoutes(): VesselRoute[] {
    return this.vesselRouteList;
  }

  get MaritimeTrips(): MaritimeTrip[] {
    return this.maritimeTripsList;
  }

  get GoodsConsignees(): GoodsConsignee[] {
    return this.goodsConsigneesList;
  }
  get Active(): boolean {
    return this.isActive;
  }

  get ShipMoorings(): ShipMooring[] {
    return this.shipMooringsList || "";
  }

  get MooringOperations(): MooringOperation[] {
    return this.mooringOperationsList || "";
  }

  get Bookings(): Booking[] {
    return this.bookingsList || "";
  }

  get BookingsTable(): Booking[] {
    return this.bookingsTable || "";
  }

  get BookingsPagination(): Pagination {
    return this.bookingsPagination;
  }

  get PrebillingTable(): Prebilling[] {
    return this.prefactureTable || "";
  }

  get Prebilling(): Prebilling[] {
    return this.prefactureList || "";
  }

  get MFPrebilling(): Prebilling[] {
    return this.mf_prefactureList;
  }
  get MFPrebillingTable(): Prebilling[] {
    return this.mf_prefactureTable;
  }
  get CPrebilling(): Prebilling[] {
    return this.c_prefactureList;
  }
  get CPrebillingTable(): Prebilling[] {
    return this.c_prefactureTable;
  }

  get BLElement(): any {
    return this.bl_element;
  }

  get BookingElement(): any {
    return this.booking_element;
  }

  get BookingsBLTable(): Booking[] {
    return this.BLTable || "";
  }

  get BlPagination(): Pagination {
    return this.blPagination;
  }

  get BookingRoutes(): BookingRoute[] {
    return this.bookingRouteList || "";
  }

  get BookingRoutesBL(): BookingRoute[] {
    return this.bookingRouteBLList;
  }

  get Participants(): Participant[] {
    return this.participantList || "";
  }

  get ParticipantsBL(): Participant[] {
    return this.participantBLList || "";
  }

  get BillsOfLanding(): BillOfLanding[] {
    return this.billOfLandingList || "";
  }

  get BillsOfLandingTypes(): BillOfLandingType[] {
    return this.billOfLandingTypesList;
  }

  get nextPreviousTransportMethod(): NextPreviousTransportMethod[] {
    return this.nextPreviousTransportMethodList;
  }

  get Dispatches(): any[] {
    return this.dispatches;
  }

  get Shiploads(): Shipload[] {
    return this.shiploadList || "";
  }

  get EquipmentsOT(): EquipmentsOT[] {
    return this.equipmentsOT || "";
  }

  get ShiploadsBL(): Shipload[] {
    return this.shiploadBLList || "";
  }

  get Goods(): GoodFeature[] {
    return this.goodList || "";
  }

  get GoodOT(): GoodFeature[] {
    return this.goodOT || "";
  }

  get GoodsMeasures(): GoodMeasures[] {
    return this.goodMeasuresList || "";
  }

  get GoodsImo(): GoodImoFeature[] {
    return this.goodImoList || "";
  }

  get GoodsImoMeasures(): GoodMeasures[] {
    return this.goodImoMeasuresList || "";
  }

  get GoodsMeasuresCopy(): GoodMeasures[] {
    return this.goodMeasuresListCopy || "";
  }

  get Equipments(): Equipment[] {
    return this.equipmentsList || "";
  }

  get Overdimensions(): Overdimensions[] {
    return this.overdimensionsList || "";
  }

  get OverdimensionsOT(): Overdimensions[] {
    return this.overdimensionsOT || "";
  }

  get OverdimensionsCopy(): Overdimensions[] {
    return this.overdimensionsListCopy || "";
  }

  get ImoDeclaration(): any[] {
    return this.imoDeclarationList || "";
  }

  get GoodsBL(): GoodFeature[] {
    return this.goodBLList || "";
  }

  get GoodsMeasuresBL(): GoodMeasures[] {
    return this.goodMeasuresBLList || "";
  }

  get GoodsMeasuresOT(): GoodMeasures[] {
    return this.goodsMeasuresOT || "";
  }

  get GoodsImoBL(): GoodImoFeature[] {
    return this.goodImoBLList || "";
  }

  get GoodsImoMeasuresBL(): GoodMeasures[] {
    return this.goodImoMeasuresBLList || "";
  }

  get GoodsMeasuresBLCopy(): GoodMeasures[] {
    return this.goodMeasuresBLListCopy || "";
  }

  get EquipmentsBL(): Equipment[] {
    return this.equipmentsBLList || "";
  }
  get ShiploadOT(): EquipmentOT[] {
    return this.shiploadOT || "";
  }

  get MeasuresOT(): OverdimensionsOT[] {
    return this.measuresOTList || "";
  }

  get MeasuresOTNoCheck(): OverdimensionsOTNoCheck[] {
    return this.measuresOTListNoCheck || "";
  }

  get OverdimensionsBL(): Overdimensions[] {
    return this.overdimensionsBLList || "";
  }

  get OverdimensionsBLCopy(): Overdimensions[] {
    return this.overdimensionsBLListCopy || "";
  }

  get TableSelectList(): any[] {
    return this.tableSelectList || "";
  }

  get SummaryDeclarationItem(): SummaryDeclaration  | undefined {
    return this.summaryDeclarationItem;
  }

  get EntryTransportMovement(): SummaryTransportMovement[] {
    return this.entryTransportMovementList;
  }

  get ExitTransportMovement(): SummaryTransportMovement[] {
    return this.exitTransportMovementList;
  }

  get EntryRegularServiceCode(): SummaryTransportMovement[] {
    return this.entryRegularServiceCodeList;
  }

  get ExitRegularServiceCode(): SummaryTransportMovement[] {
    return this.exitRegularServiceCodeList;
  }

  get EntrySimplifiedCommunalTransitProcedure(): SummaryTransportMovement[]{
    return this.entrySimplifiedCommunalTransitProcedureList;
  }
  get ExitSimplifiedCommunalTransitProcedure(): SummaryTransportMovement[] {
    return this.exitSimplifiedCommunalTransitProcedureList;
  }

  get Shipper(): Shipper[] | undefined {
    return this.shipperList;
  }



  @Action
  ["SummaryDeclarationFetchId"](param: { maritime_file_id?: number }): Promise<SummaryDeclarationItem> {
    return new Promise((resolve, reject) => {
      const endpoint = '/api/summary_declaration';
  
      ApiService.query(endpoint, {
        params: {
          ...param,
        },
      })
        .then(({ data }) => {
          console.log(data);
          resolve(data.summary_declarations[0].id);
        })
        .catch((error) => {
          console.error(error);
          reject("error");
        });
    });
  }




  @Mutation
  ["setLoadinga"](payload) {
    this.loadinga = payload;
  }
  @Mutation
  ["setLoadingHistorical"](payload) {
    this.isLoadingHistorical = payload;
  }
  @Mutation
  ["addTransportationOrders"](payload) {
    this.transportation_orders = payload;
  }


  @Mutation
  ["setIsLoadingTable"](payload) {
    this.isLoadingTable = payload;
  }
  @Mutation
  ["setCurrencieEchanges"](payload) {
    this.currency_exchanges = payload;
  }
  @Mutation
  ["clearCurrencieEchanges"]() {
    this.currency_exchanges = [];
  }

  @Mutation
  ["setIsActive"](payload) {
    this.isActive = payload;
  }

  @Mutation
  ["setExpirationDates"](payload) {
    this.expirationDates = payload;
  }

  @Mutation
  ["setTaxesDetails"](payload) {
    this.taxesDetails = payload;
  }
  @Mutation
  ["updateTaxesDetails"](payload) {
    this.taxesDetails.push(payload);
  }
  @Mutation
  ["clearTaxesDetails"](payload) {
    this.taxesDetails = [];
  }

  @Mutation
  ["resetExpirationDates"](payload) {
    this.expirationDates = [];
  }
  @Mutation
  ["setofficeCustomers"](payload) {
    this.officeCustomers = payload;
  }
  @Mutation
  ["setShiploadBlOT"](payload) {
    this.shipLoadBlOT = payload;
  }
  @Mutation
  ["setShiploadBlUOT"](payload) {
    this.shipLoadBlUOT = payload;
  }

  @Mutation
  ["setBookingPosition"](payload) {
    this.bookingPosition = payload;
  }
  @Mutation
  ["removeBookingPosition"]() {
    this.bookingPosition = undefined;
  }

  @Mutation
  ["clearExpirationDate"]() {
    this.expirationDates = [];
  }

  @Mutation
  ["addExpirationDate"](payload) {
    this.expirationDates.push({
      expiration_date: payload.expiration_date,
      amount: payload.bill_total,
      remarks: "",
    });
  }

  @Mutation
  ["addPaymentDate"](payload) {
    this.billing_payments.push({
      payment_date: payload.payment_date,
      currency: "",
      currency_id: payload.currency_id,
      amount: payload.amount,
      remarks: "",
    });
  }

  @Mutation
  ["setPaymentDate"](payload) {
    this.billing_payments = payload;
  }

  @Mutation
  ["deletePaymentDate"](index) {
    this.billing_payments.splice(index, 1);
  }

  @Mutation
  ["emptyPaymentDate"]() {
    this.billing_payments = [];
  }

  @Mutation
  ["setMaritimeFileId"](payload) {
    this.maritimeFileId = payload;
  }
  @Mutation
  ["setMaritimeFileIdSelected"](payload) {
    this.maritimeFileIdSelected = payload;
  }
  @Mutation
  ["cancelBooking"](payload) {
    this.bookingsTable = this.bookingsTable.filter((x) => x.id !== undefined);
    this.bookingsList = this.bookingsList.filter((x) => x.id !== undefined);
  }
  @Mutation
  ["setMaritimeFile"](payload) {
    this.maritimeFile = payload;
  }
  @Mutation
  ["setDeleteId"](payload) {
    this.deleteId = payload;
  }
  @Mutation
  ["setDuplicateId"](payload) {
    this.duplicateId = payload;
  }
  @Mutation
  ["setTabSelected"](payload) {
    this.selectedTab = payload;
  }

  @Mutation
  ["setBookingOpen"](payload) {
    this.bookingOpen = payload;
  }

  @Mutation
  ["setBlOpen"](payload) {
    this.blOpen = payload;
  }

  @Mutation
  ["setPrebillingOpen"](payload) {
    this.prebillingOpen = payload;
  }

  @Mutation
  ["setCurrentElement"](payload) {
    this.currentElement = payload;
  }

  @Mutation
  ["removeCurrentElement"]() {
    this.currentElement = undefined;
  }

  @Mutation
  ["setMaritimeFileTab"](payload) {
    this.maritimeFileTab = payload;
  }

  @Mutation
  ["removeMaritimeFileTab"]() {
    this.maritimeFileTab = {};
  }

  @Mutation
  ["setBlBoTab"](payload) {
    this.blBoTab = payload;
  }

  @Mutation
  ["removeBlBoTab"]() {
    this.blBoTab = {};
  }

  @Mutation
  ["setMaritimeFileCodes"](payload) {
    this.maritimeFileCodes = payload;
  }

  @Mutation
  ["removeMaritimeFileCode"](payload) {
    const index = this.maritimeFileCodes.findIndex((x) => x === payload);
    this.maritimeFileCodes.splice(index, 1);
  }

  @Mutation
  ["setVesselRoute"](payload) {
    this.vesselRouteList.push(payload);
  }

  @Mutation
  ["setAllVesselRoute"](payload) {
    this.vesselRouteList = payload;
  }

  @Mutation
  ["editVesselRoute"](payload) {
    this.vesselRouteList[payload.index]["ue"] = payload.is_ue;
  }

  @Mutation
  ["ressetVesselRoute"]() {
    this.vesselRouteList = [];
  }

  @Mutation
  ["setOrderVesselRoute"](payload) {
    const newIndex = payload.newIndex;
    const oldIndex = payload.oldIndex;
    const newOrder = this.vesselRouteList[newIndex].order;
    const oldOrder = this.vesselRouteList[oldIndex].order;
    this.vesselRouteList[oldIndex].order = newOrder;
    this.vesselRouteList[newIndex].order = oldOrder;
  }

  @Mutation
  ["cleanVesselRoute"]() {
    this.vesselRouteList = [];
  }

  @Mutation
  ["removeVesselRoute"](index) {
    this.vesselRouteList.splice(index, 1);
  }

  @Mutation
  ["setMaritimeTrip"](payload) {
    this.maritimeTripsList.push(payload);
  }

  @Mutation
  ["removeMaritimeTrip"](index) {
    this.maritimeTripsList.splice(index, 1);
  }

  @Mutation
  ["cleanMaritimeVoyage"]() {
    this.maritimeTripsList = [];
  }

  @Mutation
  ["setGoodsConsignees"](payload) {
    this.goodsConsigneesList.push(payload);
  }

  @Mutation
  ["removeGoodsConsignees"](index) {
    this.goodsConsigneesList.splice(index, 1);
  }

  @Mutation
  ["setShipMooring"](payload) {
    this.shipMooringsList.push(payload);
  }

  @Mutation
  ["removeShipMooring"](index) {
    this.shipMooringsList.splice(index, 1);
  }

  @Mutation
  ["setMooringOperation"](payload) {
    this.mooringOperationsList.push(payload);
  }

  @Mutation
  ["removeMooringOperation"](index) {
    this.mooringOperationsList.splice(index, 1);
  }
  @Mutation
  ["resetMooringOperation"]() {
    this.mooringOperationsList = [];
  }

  @Mutation
  ["setBooking"](payload) {
    const index = this.bookingsList.findIndex((x) => x.id === payload.id);
    if (index === -1) {
      this.bookingsList.push(payload);
    }
  }

  @Mutation
  ["updateBooking"](payload) {
    if (payload.id === undefined) {
      const index = this.bookingsList.findIndex((x) => x.id === undefined);
      this.bookingsList[index] = payload;
    } else {
      const index = this.bookingsList.findIndex((x) => x.id === payload.id);
      this.bookingsList[index] = payload;
    }
  }

  @Mutation
  ["removeBooking"](index) {
    this.bookingsList.splice(index, 1);
  }

  @Mutation
  ["setBL"](payload) {
    this.billOfLandingList.push(payload);
  }

  @Mutation
  ["setBLType"](payload: BillOfLandingType[]) {
    this.billOfLandingTypesList = payload;
  }

  @Mutation
  ["setNextPreviousTransportMethod"](payload: NextPreviousTransportMethod[]) {
    this.nextPreviousTransportMethodList  = payload;
  }

  ["updateBL"](payload) {
    const index = this.billOfLandingList.findIndex((x) => x.id === payload.id);
    this.billOfLandingList[index] = payload;
  }

  @Mutation
  ["removeBL"](index) {
    this.billOfLandingList.splice(index, 1);
  }

  @Mutation
  ["setBookingTable"](payload) {
    this.bookingsTable.push(payload);
  }

  @Mutation
  ["setDispatches"](payload) {
    this.dispatches.push(payload);
  }

  @Mutation
  ["setBookingsPagination"](payload) {
    this.bookingsPagination = payload;
  }

  @Mutation
  ["updateBookingsPagination"](value) {
    this.bookingsPagination.rowsPerPage = value;
  }

  @Mutation
  ["filterBookingTable"](payload) {
    this.bookingsTable = payload;
  }

  @Mutation
  ["cleanBookingTable"]() {
    this.bookingsTable = [];
  }

  @Mutation
  ["updateBookingTable"](payload) {
    if (payload.id === undefined) {
      const index = this.bookingsTable.findIndex((x) => x.id === undefined);
      this.bookingsTable[index] = payload;
    } else {
      const index = this.bookingsTable.findIndex((x) => x.id === payload.id);
      this.bookingsTable[index] = payload;
    }
  }

  @Mutation
  ["removeBookingTable"](index) {
    this.bookingsTable.splice(index, 1);
  }

  @Mutation
  ["setBLElement"](payload) {
    this.bl_element = payload;
  }

  @Mutation
  ["setBookingElement"](payload) {
    this.booking_element = payload;
  }

  @Mutation
  ["setBLTable"](payload) {
    this.BLTable = payload;
  }

  @Mutation
  ["addBLTable"](payload) {
    this.BLTable.push(payload);
  }

  @Mutation
  ["filterBLTable"](payload) {
    this.BLTable = payload;
  }

  @Mutation
  ["cleanBLTable"]() {
    this.BLTable = [];
  }

  @Mutation
  ["updateBLTable"](payload) {
    const index = this.BLTable.findIndex((x) => x.booking_id === payload.id);
    this.BLTable[index] = payload;
  }

  @Mutation
  ["removeBLTable"](index) {
    this.BLTable.splice(index, 1);
  }

  @Mutation
  ["setBlPagination"](payload) {
    this.blPagination = payload;
  }

  @Mutation
  ["updateBlPagination"](value) {
    this.blPagination.rowsPerPage = value;
  }

  @Mutation
  ["setBookingRoute"](payload) {
    this.bookingRouteList.push(payload);
  }

  @Mutation
  ["editBookingRoute"](payload) {
    this.bookingRouteList[payload.index]["ue"] = payload.is_ue;
  }

  @Mutation
  ["updateBookingRoute"](payload) {
    this.bookingRouteList = payload;
  }

  @Mutation
  ["removeBookingRoute"](index) {
    this.bookingRouteList.splice(index, 1);
  }

  @Mutation
  ["setBookingRouteBL"](payload) {
    this.bookingRouteBLList = payload;
  }

  @Mutation
  ["editBookingRouteBL"](payload) {
    this.bookingRouteBLList[payload.index]["ue"] = payload.is_ue;
  }

  @Mutation
  ["updateBookingRouteBL"](payload) {
    this.bookingRouteBLList = payload;
  }

  @Mutation
  ["removeBookingRouteBL"](index) {
    this.bookingRouteBLList.splice(index, 1);
  }

  @Mutation
  ["setParticipant"](payload) {
    this.participantList.push(payload);
  }

  @Mutation
  ["updateMaritimeFiles"](payload) {
    if (payload && Array.isArray(this.maritime_files)) {
      const index = this.maritime_files.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.maritime_files.push(payload);
      }
    }
  }
  @Mutation
  ["clearMaritimeFiles"]() {
    this.maritime_files = [];
  }
  @Mutation
  ["setMaritimeFiles"](payload) {
    this.maritime_files = payload;
  }
  @Mutation
  ["updateBls"](payload) {
    if (payload && Array.isArray(this.bls)) {
      const index = this.bls.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.bls.push(payload);
      }
    }
  }
  @Mutation
  ["setBls"](payload) {
    this.bls = payload;
  }
  @Mutation
  ["clearBls"]() {
    this.bls = [];
  }

  @Mutation
  ["removeParticipant"](index) {
    this.participantList.splice(index, 1);
  }

  @Mutation
  ["updateParticipant"](payload) {
    this.participantList = payload;
  }

  @Mutation
  ["setParticipantBL"](payload) {
    this.participantBLList.push(payload);
  }

  @Mutation
  ["updateParticipantBL"](payload) {
    this.participantBLList = payload;
  }

  @Mutation
  ["removeParticipantBL"](index) {
    this.participantBLList.splice(index, 1);
  }

  @Mutation
  ["setLoadingEquipement"](payload) {
    this.loadingEquipement = payload;
  }
  @Mutation
  ["setBillOfLanding"](payload) {
    this.billOfLandingList.push(payload);
  }
  @Mutation
  ["setAllBillOfLanding"](payload) {
    this.billOfLandingList = payload;
  }

  @Mutation
  ["removeBillOfLanding"](index) {
    this.billOfLandingList.splice(index, 1);
  }

  @Mutation
  ["setShipload"](payload) {
    this.shiploadList.push(payload);
  }

  @Mutation
  ["updateShipload"](payload) {
    this.shiploadList = payload;
  }

  @Mutation
  ["removeShipload"](index) {
    this.shiploadList.splice(index, 1);
  }

  @Mutation
  ["setEquipmentsOT"](payload) {
    this.equipmentsOT.push(payload);
  }

  @Mutation
  ["updateEquipmentsOT"](payload) {
    this.equipmentsOT = payload;
  }

  @Mutation
  ["removeEquipmentsOT"](index) {
    this.equipmentsOT.splice(index, 1);
  }

  @Mutation
  ["setShiploadBL"](payload) {
    this.shiploadBLList.push(payload);
  }

  @Mutation
  ["updateShiploadBL"](payload) {
    this.shiploadBLList = payload;
  }

  @Mutation
  ["removeShiploadBL"](index) {
    this.shiploadBLList.splice(index, 1);
  }

  @Mutation
  ["setGood"](payload) {
    this.goodList.push(payload);
  }

  @Mutation
  ["updateGood"](payload) {
    this.goodList = payload;
  }

  @Mutation
  ["removeGood"](index) {
    this.goodList.splice(index, 1);
  }

  @Mutation
  ["setGoodOT"](payload) {
    this.goodOT.push(payload);
  }

  @Mutation
  ["updateGoodOT"](payload) {
    this.goodOT = payload;
  }

  @Mutation
  ["removeGoodOT"](index) {
    this.goodOT.splice(index, 1);
  }

  @Mutation
  ["resetGoodMeasure"]() {
    this.goodMeasuresList = this.goodMeasuresListCopy.slice();
  }

  @Mutation
  ["setGoodMeasure"](payload) {
    this.goodMeasuresList.push(payload);
  }

  @Mutation
  ["updateGoodMeasure"](payload) {
    this.goodMeasuresList = payload;
  }

  @Mutation
  ["setEmptyImoGood"]() {
    this.goodImoList = [];
  }

  @Mutation
  ["setImoGood"](payload) {
    this.goodImoList.push(payload);
  }

  @Mutation
  ["updateImoGood"](payload) {
    this.goodImoList = payload;
  }

  @Mutation
  ["setEmptyGoodImoMeasure"]() {
    this.goodImoMeasuresList = [];
  }

  @Mutation
  ["setGoodImoMeasure"](payload) {
    this.goodImoMeasuresList.push(payload);
  }

  @Mutation
  ["updateGoodImoMeasure"](payload) {
    this.goodImoMeasuresList = payload;
  }

  @Mutation
  ["filterGoodMeasure"](ids) {
    const measures = [] as any;
    ids.forEach((x) => {
      const items = this.goodMeasuresListCopy.filter((y) => y.good_id === x);
      items.forEach((y) => measures.push(y));
    });
    this.goodMeasuresList = measures;
  }

  @Mutation
  ["removeGoodMeasure"](index) {
    this.goodMeasuresList.splice(index, 1);
  }

  @Mutation
  ["setGoodMeasureCopy"](payload) {
    this.goodMeasuresListCopy.push(payload);
  }

  @Mutation
  ["updateGoodMeasureCopy"](payload) {
    this.goodMeasuresListCopy = payload;
  }

  @Mutation
  ["removeGoodMeasureCopy"](index) {
    this.goodMeasuresListCopy.splice(index, 1);
  }

  @Mutation
  ["setEquipments"](payload) {
    this.equipmentsList.push(payload);
  }

  @Mutation
  ["updateEquipments"](payload) {
    this.equipmentsList = payload;
  }

  @Mutation
  ["removeEquipments"](index) {
    this.equipmentsList.splice(index, 1);
  }

  @Mutation
  ["resetOverdimensions"]() {
    this.overdimensionsList = this.overdimensionsListCopy.slice();
  }

  @Mutation
  ["setOverdimensions"](payload) {
    this.overdimensionsList.push(payload);
  }

  @Mutation
  ["updateOverdimensions"](payload) {
    this.overdimensionsList = payload;
  }

  @Mutation
  ["removeOverdimensionsOT"](index) {
    this.overdimensionsOT.splice(index, 1);
  }

  @Mutation
  ["setOverdimensionsOT"](payload) {
    this.overdimensionsOT.push(payload);
  }

  @Mutation
  ["updateOverdimensionsOT"](payload) {
    this.overdimensionsOT = payload;
  }

  @Mutation
  ["filterOverdimensions"](ids) {
    const overdimendions = [] as any;
    ids.forEach((x) => {
      const items = this.overdimensionsListCopy.filter(
        (y) => y.equipment_id === x
      );
      items.forEach((y) => overdimendions.push(y));
    });
    this.overdimensionsList = overdimendions;
  }

  @Mutation
  ["removeOverdimensions"](index) {
    this.overdimensionsList.splice(index, 1);
  }

  @Mutation
  ["setOverdimensionsCopy"](payload) {
    this.overdimensionsListCopy.push(payload);
  }

  @Mutation
  ["updateOverdimensionsCopy"](payload) {
    this.overdimensionsListCopy = payload;
  }

  @Mutation
  ["removeOverdimensionsCopy"](index) {
    this.overdimensionsListCopy.splice(index, 1);
  }

  @Mutation
  ["setImoDeclarationList"](payload) {
    this.imoDeclarationList.push(payload);
  }

  @Mutation
  ["updateImoDeclarationList"](payload) {
    this.imoDeclarationList = payload;
  }

  @Mutation
  ["removeImoDeclarationList"](index) {
    this.imoDeclarationList.splice(index, 1);
  }

  @Mutation
  ["resetImoDeclarationList"](index) {
    this.imoDeclarationList = [];
  }

  @Mutation
  ["setGoodBL"](payload) {
    this.goodBLList.push(payload);
  }

  @Mutation
  ["updateGoodBL"](payload) {
    this.goodBLList = payload;
  }

  @Mutation
  ["removeGoodBL"](index) {
    this.goodBLList.splice(index, 1);
  }

  @Mutation
  ["resetGoodMeasuresBL"]() {
    this.goodMeasuresBLList = this.goodMeasuresBLListCopy.slice();
  }

  @Mutation
  ["setGoodMeasuresBL"](payload) {
    this.goodMeasuresBLList.push(payload);
  }

  @Mutation
  ["updateGoodMeasuresBL"](payload) {
    this.goodMeasuresBLList = payload;
  }

  @Mutation
  ["resetGoodMeasuresOT"]() {
    this.goodsMeasuresOT = this.goodMeasuresBLListCopy.slice();
  }

  @Mutation
  ["setGoodMeasuresOT"](payload) {
    this.goodsMeasuresOT.push(payload);
  }

  @Mutation
  ["updateGoodMeasuresOT"](payload) {
    this.goodsMeasuresOT = payload;
  }

  @Mutation
  ["setEmptyImoGoodBL"]() {
    this.goodImoBLList = [];
  }

  @Mutation
  ["setImoGoodBL"](payload) {
    this.goodImoBLList.push(payload);
  }

  @Mutation
  ["updateImoGoodBL"](payload) {
    this.goodImoBLList = payload;
  }

  @Mutation
  ["setEmptyGoodImoMeasureBL"]() {
    this.goodImoMeasuresBLList = [];
  }

  @Mutation
  ["setGoodImoMeasureBL"](payload) {
    this.goodImoMeasuresBLList.push(payload);
  }

  @Mutation
  ["updateGoodImoMeasureBL"](payload) {
    this.goodImoMeasuresBLList = payload;
  }

  @Mutation
  ["filterGoodMeasureBL"](ids) {
    const measures = [] as any;
    ids.forEach((x) => {
      const items = this.goodMeasuresBLListCopy.filter((y) => y.good_id === x);
      items.forEach((y) => measures.push(y));
    });
    this.goodMeasuresBLList = measures;
  }

  @Mutation
  ["removeGoodMeasuresBL"](index) {
    this.goodMeasuresBLList.splice(index, 1);
  }

  @Mutation
  ["setGoodMeasureBLCopy"](payload) {
    this.goodMeasuresBLListCopy.push(payload);
  }

  @Mutation
  ["removeGoodMeasureBLCopy"](index) {
    this.goodMeasuresBLListCopy.splice(index, 1);
  }

  @Mutation
  ["setEquipmentsBL"](payload) {
    this.equipmentsBLList.push(payload);
  }

  @Mutation
  ["updateEquipmentsBL"](payload) {
    this.equipmentsBLList = payload;
  }

  @Mutation
  ["removeEquipmentsBL"](index) {
    this.equipmentsBLList.splice(index, 1);
  }
  @Mutation
  ["setShiploadOT"](payload) {
    this.shiploadOT.push(payload);
  }

  @Mutation
  ["updateShiploadOT"](payload) {
    this.shiploadOT = payload;
  }

  @Mutation
  ["removeShiploadOT"](index) {
    this.shiploadOT.splice(index, 1);
  }

  @Mutation
  ["resetOverdimensionsBL"]() {
    this.overdimensionsBLList = this.overdimensionsBLListCopy.slice();
  }

  @Mutation
  ["setOverdimensionsBL"](payload) {
    this.overdimensionsBLList.push(payload);
  }

  @Mutation
  ["setMeasureOT"](payload) {
    this.measuresOTList.push(payload);
  }

  @Mutation
  ["clearMeasureOT"]() {
    this.measuresOTList = [];
  }

  @Mutation
  ["setMeasureOTNoCheck"](payload) {
    this.measuresOTListNoCheck.push(payload);
  }

  @Mutation
  ["clearMeasureOTNoCheck"]() {
    this.measuresOTListNoCheck = [];
  }

  @Mutation
  ["updateOverdimensionsBL"](payload) {
    this.overdimensionsBLList = payload;
  }

  @Mutation
  ["filterOverdimensionsBL"](ids) {
    const overdimendions = [] as any;
    ids.forEach((x) => {
      const items = this.overdimensionsBLListCopy.filter(
        (y) => y.equipment_id === x
      );
      items.forEach((y) => overdimendions.push(y));
    });
    this.overdimensionsBLList = overdimendions;
  }

  @Mutation
  ["removeOverdimensionsBL"](index) {
    this.overdimensionsBLList.splice(index, 1);
  }

  @Mutation
  ["setOverdimensionsBLCopy"](payload) {
    this.overdimensionsBLListCopy.push(payload);
  }

  @Mutation
  ["removeOverdimensionsBLCopy"](index) {
    this.overdimensionsBLListCopy.splice(index, 1);
  }

  @Mutation
  ["setPrefactureTable"](payload) {
    this.prefactureTable.push(payload);
  }

  @Mutation
  ["setSummaryDeclarationItem"](payload: SummaryDeclaration) {
    this.summaryDeclarationItem = payload;
  }

  @Mutation
  ["addPrefactureTableShipLoad"](payload) {
    if (payload.all_data.shipload_id !== null) {
      const index = this.prefactureTable.findIndex(
        (x) =>
          x.all_data.billing_concept_id ===
            payload.all_data.billing_concept_id &&
          x.all_data.shipload_id === payload.all_data.shipload_id
      );
      if (index !== -1) {
        this.prefactureTable[index] = payload;
      } else {
        this.prefactureTable.push(payload);
      }
    } else {
      const index = this.prefactureTable.findIndex(
        (x) =>
          x.all_data.bl_id === payload.all_data.bl_id &&
          x.all_data.billing_concept_id === payload.all_data.billing_concept_id
      );
      if (index !== -1) {
        this.prefactureTable[index] = payload;
      } else {
        this.prefactureTable.push(payload);
      }
    }
  }
  @Mutation
  ["addPrefactureTableOT"](payload) {
    this.prefactureTable = this.prefactureTable.filter(
      (x) =>
        x.all_data.billing_concept_id !== payload.all_data.billing_concept_id &&
        x.all_data.transportation_order_id !==
          payload.all_data.transportation_order_id
    );
    this.prefactureTable.push(payload);
  }

  @Mutation
  ["cleanPrefactureTable"]() {
    this.prefactureTable = [];
    this.prefactureList = [];
  }
  @Mutation
  ["cleanMFPrefacture"]() {
    this.mf_prefactureList = [];
  }
  @Mutation
  ["cleanMFPrefactureTable"]() {
    this.mf_prefactureTable = [];
  }
  @Mutation
  ["cleanCPrefacture"]() {
    this.c_prefactureList = [];
  }
  @Mutation
  ["cleanCPrefactureTable"]() {
    this.c_prefactureTable = [];
  }

  @Mutation
  ["updatePrefactureTable"](payload) {
    const index = this.prefactureTable.findIndex((x) => x.id === payload.id);
    this.prefactureTable[index] = payload;
  }
  @Mutation
  ["updateMFPrefactureTable"](payload) {
    const index = this.mf_prefactureTable.findIndex((x) => x.id === payload.id);
    this.mf_prefactureTable[index] = payload;
  }
  @Mutation
  ["updateMFPrefactureList"](payload) {
    const index = this.mf_prefactureList.findIndex((x) => x.id === payload.id);
    this.mf_prefactureList[index] = payload;
  }
  @Mutation
  ["removePrefactureTable"](index) {
    this.prefactureTable.splice(index, 1);
  }
  @Mutation
  ["removeMFPrefactureList"](index) {
    this.mf_prefactureList.splice(index, 1);
  }
  @Mutation
  ["removeMFPrefactureTable"](index) {
    this.mf_prefactureTable.splice(index, 1);
  }

  @Mutation
  ["setPrefactureListCurrency"](payload) {
    this.prefactureList.forEach((x) => {
      x.currency_id = payload;
    });
  }
  @Mutation
  ["setMFPrefactureListCurrency"](payload) {
    this.mf_prefactureList.forEach((x) => {
      x.currency_id = payload;
    });
  }
  @Mutation
  ["setMFPrefactureTableCurrency"](payload) {
    this.mf_prefactureTable.forEach((x) => {
      x.currency_id = payload;
    });
  }

  @Mutation
  ["cleanPrebilling"]() {
    this.prefactureList = [];
  }

  @Mutation
  ["setPrebilling"](payload) {
    this.prefactureList.push(payload);
  }
  @Mutation
  ["setMFPrebilling"](payload) {
    this.mf_prefactureList.push(payload);
  }
  @Mutation
  ["setMFPrebillingTable"](payload) {
    this.mf_prefactureTable.push(payload);
  }
  @Mutation
  ["setCPrebilling"](payload) {
    this.c_prefactureList.push(payload);
  }
  @Mutation
  ["setCPrebillingTable"](payload) {
    this.c_prefactureTable.push(payload);
  }

  @Mutation
  ["updatePrebilling"](payload) {
    const index = this.prefactureList.findIndex(
      (x) => x.bl_prebilling_id === payload.bl_prebilling_id
    );
    this.prefactureList[index] = payload;
  }

  @Mutation
  ["removePrebilling"](index) {
    this.prefactureList.splice(index, 1);
  }
  @Mutation
  ["removeMFPrebilling"](index) {
    this.mf_prefactureList.splice(index, 1);
  }
  @Mutation
  ["removeMFPrebillingTable"](index) {
    this.mf_prefactureTable.splice(index, 1);
  }
  @Mutation
  ["removeCPrebilling"](index) {
    this.c_prefactureList.splice(index, 1);
  }
  @Mutation
  ["removeCPrebillingTable"](index) {
    this.c_prefactureTable.splice(index, 1);
  }

  @Mutation
  ["setTableSelectList"](payload) {
    this.tableSelectList = payload;
    //this.tableSelectList.push(payload);
  }


  @Mutation
  ["removeTableSelectList"](index) {
    this.tableSelectList.splice(index, 1);
  }

  @Mutation
  ["setEntryTransportMovement"](payload: SummaryTransportMovement[])
  {
    this.entryTransportMovementList = payload;
  }

  @Mutation
  ["setExitTransportMovement"](payload: SummaryTransportMovement[])
  {
    this.exitTransportMovementList = payload;
  }

  @Mutation
  ["setEntryRegularServiceCode"](payload: SummaryTransportMovement[])
  {
    console.log(payload);
    this.entryRegularServiceCodeList = payload;
  }

  @Mutation
  ["setExitRegularServiceCode"](payload: SummaryTransportMovement[])
  {
    this.exitRegularServiceCodeList = payload;
  }

  @Mutation
  ["setEntrySimplifiedCommunalTransitProcedure"](payload: SummaryTransportMovement[])
  {
    this.entrySimplifiedCommunalTransitProcedureList = payload;
  }

  @Mutation
  ["setExitSimplifiedCommunalTransitProcedure"](payload: SummaryTransportMovement[])
  {
    this.exitSimplifiedCommunalTransitProcedureList = payload;
  }

  @Mutation
  ["setShipper"](payload: Shipper[])
  {
    this.shipperList = payload;
  }

  @Mutation
  ["resetBookingTab"]() {
    this.maritimeFileTab = {};
    this.blBoTab = {};
    this.bookingRouteList = [];
    this.participantList = [];
    this.shiploadList = [];
    this.equipmentsOT = [];
    this.goodList = [];
    this.goodMeasuresList = [];
    this.goodMeasuresListCopy = [];
    this.overdimensionsList = [];
    this.overdimensionsListCopy = [];
    this.imoDeclarationList = [];
    this.equipmentsList = [];
    this.goodImoMeasuresList = [];
    this.goodImoList = [];
    this.isLoadingTable = false;
  }

  @Mutation
  ["resetBLTab"]() {
    this.maritimeFileTab = {};
    this.shiploadBLList = [];
    this.overdimensionsBLList = [];
    this.overdimensionsBLListCopy = [];
    this.goodBLList = [];
    this.goodMeasuresBLList = [];
    this.goodMeasuresBLListCopy = [];
    this.equipmentsBLList = [];
    this.blBoTab = {};
    this.bookingRouteBLList = [];
    this.participantBLList = [];
    this.billOfLandingList = [];
    this.billOfLandingTypesList = [];
    this.nextPreviousTransportMethodList = [];
    this.goodsMeasuresOT = [];
    this.shiploadOT = [];
    this.goodImoMeasuresBLList = [];
    this.goodImoBLList = [];
    this.isLoadingTable = false;
  }

  @Mutation
  ["resetCustomTab"]() {
    this.summaryDeclarationItem = undefined;
    this.summaryDeclarationList = [];
    this.entryTransportMovementList = [];
    this.exitTransportMovementList = [];
    this.entryRegularServiceCodeList = [];
    this.exitRegularServiceCodeList = [];
    this.entrySimplifiedCommunalTransitProcedureList = [];
    this.exitSimplifiedCommunalTransitProcedureList = [];
    this.shipperList = [];
  }

  @Mutation
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ["resetPrefactureTab"]() {}

  @Mutation
  ["resetModule"]() {
    this.loadinga = true;
    this.maritimeFileId = undefined;
    this.maritimeFileIdSelected = undefined;
    this.selectedTab = "overview";
    this.maritimeFile = {
      id: undefined,
      code: "",
      file_type_id: 4,
      office_id: undefined,
      port_id: undefined,
      vessel_id: undefined,
      ship_consignee_id: undefined,
      customer_id: undefined,
      contact_id: undefined,
      check_in_id: undefined,
      check_out_id: undefined,
      operation_id: undefined,
      prefix: "",
      calls: "",
      import: "",
      export: "",
    };
    this.deleteId = undefined;
    this.duplicateId = undefined;
    this.bookingOpen = undefined;
    this.blOpen = undefined;
    this.prebillingOpen = undefined;
    this.currentElement = undefined;
    // this.maritimeFileTab = {};
    this.maritimeFileCodes = [];
    this.maritimeTripsList = [];
    this.vesselRouteList = [];
    this.goodsConsigneesList = [];
    this.shipMooringsList = [];
    this.mooringOperationsList = [];

    this.bookingsList = [];
    this.bookingsTable = [];
    this.bookingsPagination = {
      page: 1,
      rowsPerPage: 10,
      last_page: 1,
      total: 1,
    };
    this.prefactureTable = [];
    this.bl_element = undefined;
    this.booking_element = undefined;
    this.BLTable = [];
    this.blPagination = {
      page: 1,
      rowsPerPage: 10,
      last_page: 1,
      total: 1,
    };
    this.bookingRouteList = [];
    this.bookingRouteBLList = [];
    this.participantList = [];
    this.participantBLList = [];
    this.billOfLandingList = [];
    this.billOfLandingTypesList = [];
    this.nextPreviousTransportMethodList = [];
    this.shiploadList = [];
    this.equipmentsOT = [];
    this.shiploadBLList = [];
    this.goodList = [];
    this.goodMeasuresList = [];
    this.goodMeasuresListCopy = [];
    this.overdimensionsList = [];
    this.overdimensionsListCopy = [];
    this.imoDeclarationList = [];
    this.equipmentsList = [];
    this.goodBLList = [];
    this.goodMeasuresBLList = [];
    this.goodsMeasuresOT = [];
    this.goodMeasuresBLListCopy = [];
    this.equipmentsBLList = [];
    this.shiploadOT = [];
    this.overdimensionsBLList = [];
    this.overdimensionsBLListCopy = [];
    this.goodImoMeasuresList = [];
    this.goodImoList = [];
    this.goodImoMeasuresBLList = [];
    this.goodImoBLList = [];
    this.summaryDeclarationItem = undefined;
    this.summaryDeclarationList = [];
    this.entryTransportMovementList = [];
    this.exitTransportMovementList = [];
    this.entryRegularServiceCodeList = [];
    this.exitRegularServiceCodeList = [];
    this.entrySimplifiedCommunalTransitProcedureList = [];
    this.exitSimplifiedCommunalTransitProcedureList = [];
    this.shipperList = [];
  }
}
