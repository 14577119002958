export enum ForwarderOfferEndpointEnum {
    CRUD = '/api/forwarder/offer/forwarder_offers',
    UploadFiles = '/api/forwarder/offer/attach_file/'
};

export enum ForwarderOfferSearchFieldsEnum {
    Client = 'client_id',
    ClientReference = 'client_reference',
    Provider = 'forwarder_job_provider_id',
    Contact = 'contact_id',
    Communication = 'communication_id',
    Correspondent = 'correspondent_id',
    FileCreator = 'file_creator_id',
    FileLastUpdatedBy = 'file_last_updated_by_id',
    File = 'file_id',
    Incoterm = 'forwarder_job_incoterm_types_id',
    Matter = 'matter',
    Office = 'office_id',
    PaymentMethod = 'forwarder_job_payment_method_id',
    Template = 'template',
    SalesAssociate = 'sales_associate_id',
    OfferType = 'forwarder_offer_types_id',
    OfferStatus = 'forwarder_offer_statuses_id',
    TrafficType = 'forwarder_job_traffic_types_id',
    ShippedVia = 'forwarder_job_shipped_vias_id',
    OperationType = 'forwarder_job_operation_types_id',
    Language = 'forwarder_job_languages_id',
    ValidFromStart = 'valid_from_from',
    ValidFromEnd = 'valid_from_to',
    ValidUntilStart = 'valid_until_from',
    ValidUntilEnd = 'valid_until_to',
    CreatedStart = 'created_from',
    CreatedEnd = 'created_to',
    DateStart = 'date_from',
    DateEnd = 'date_to'
}