export const GeneralEN = {
    Operations: 'Operations',
    Description: 'Description',
    EquipmentType: 'Equipment Type',
    Equipments: 'Equipments',
    Units: 'Units',
    Weight: 'Weight',
    Bulks: 'Bulks',
    PackagingType: 'Packaging Type',
    Width: 'Width',
    Height: 'Height',
    Length: 'Length',
    Volume: 'Volume',
    Autocalculated: 'Autocalculated',
    Metric_unit: 'Metric Unit',
    Equipment_class: 'Equipment Class',
    Goods_group: 'Goods Group',
    Linear_meters: 'Linear meters',
    Assessable_weight: 'Assessable weight',
    Unit_weight: 'Unit weight',
    Dangerous: 'Dangerous',
    TotalsTable: 'Tabla de totales',
    RegistrationNumber: 'Registration Number',
    Size: 'Size',
    Type: 'Type',
    Tare: 'Tare',
    SpecialConditions: 'Specia Conditions',
    ConnectedTo: 'Connected %{transport}',
    OnBoard: 'On Board',
    OnTerminal: 'On Terminal',
    OnTransport: 'On Transport',
    RelativeHumidity: 'Relative Humidity',
    SealNumber: 'Seal Number',
    OriginContainerization: 'Origin Containerization',
    DestinationContainerization: 'Destination Containerization',
    Calculate: 'Calculate',
    Containers: 'Containers',
    Goods: 'Goods'
}