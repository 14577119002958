import ApiService from "@/core/services/ApiService";
import { ForwarderAerialVoyageEndpointEnum } from "@/enums/api_endpoints/forwarder/ForwarderAerialVoyageEnum";
import type { ForwarderAerialVoyage, ForwarderAerialVoyageItem } from "@/interfaces/forwarder/ForwarderAerialVoyageInterfaces";
import type { ForwarderAerialVoyageRoute } from "@/interfaces/forwarder/ForwarderAerialVoyageRouteInterfaces";
import type { ForwarderAirWayBill } from "@/interfaces/forwarder/ForwarderAirWayBillsInterfaces";
import { ForwarderTrafficType } from "@/interfaces/forwarder/ForwarderTrafficType";
import { AxiosResponse } from "axios";

export async function mountAerialGuide(store, id: number) {
    await ApiService.get(`${ForwarderAerialVoyageEndpointEnum.CRUD}${id}`).then(({ data }: AxiosResponse<ForwarderAerialVoyageItem>) => {
        store.commit("resetForwarderModule");
        store.commit("setforwarderAirWayBill", [data.forwarder_air_way_bill as ForwarderAirWayBill]);
        store.commit("setForwarderTrafficType", [data.traffic_type as ForwarderTrafficType]);
        store.commit("setForwarderAerialVoyageRoute", data.forwarder_aerial_voyage_route);
        store.commit("setforwarderAerialVoyageItem", data as ForwarderAerialVoyage);

        store.commit("setLoading", false);
        store.commit("setLoadingForwarder", false);
    });
};