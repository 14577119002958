import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class CommonModule extends VuexModule {
  editElement = false;
  duplicate1Element = false;
  duplicate2Element = false;
  deleteElement = false;
  downloadElement = false;

  get EditElement(): boolean {
    return this.editElement;
  }

  get Duplicate1Element(): boolean {
    return this.duplicate1Element;
  }

  get Duplicate2Element(): boolean {
    return this.duplicate2Element;
  }

  get DeleteElement(): boolean {
    return this.deleteElement;
  }

  get DownloadElement(): boolean {
    return this.downloadElement;
  }

  @Mutation
  ["setEditElement"](payload) {
    this.editElement = payload;
  }

  @Mutation
  ["setDuplicate1Element"](payload) {
    this.duplicate1Element = payload;
  }

  @Mutation
  ["setDuplicate2Element"](payload) {
    this.duplicate2Element = payload;
  }

  @Mutation
  ["setDeleteElement"](payload) {
    this.deleteElement = payload;
  }

  @Mutation
  ["setDownloadElement"](payload) {
    this.downloadElement = payload;
  }

  @Mutation
  ["resetCommonModule"]() {
    this.editElement = false;
    this.duplicate1Element = false;
    this.duplicate2Element = false;
    this.deleteElement = false;
    this.downloadElement = false;
  }
}
