import store from "@/store";
import { useI18n } from "vue-i18n";
import MainMenuConfig from "@/core/config/MainMenuConfig";
/**
 * translate
 * @returns {string}
 */
export const translate = (text): string => {
  const { t, te } = useI18n();
  if (te(text)) {
    return t(text);
  } else {
    return text;
  }
};

/**
 * has Permsission
 * @returns {string}
 */
export const hasPermission = (text: string): boolean => {
  if (!text) {
    return false;
  }
  return store.getters.permissions.some(({ name }) => name === text);
};

export const permissions = (): Record<string, any> => {
  return new Promise((resolve) => {
    const filters = MainMenuConfig.filter(
      (ele) => !ele.permission || hasPermission(ele.permision)
    ).map(async (ele) => {
      ele.pages = await Promise.all(
        ele.pages
          .filter((page) => !page.permission || hasPermission(page.permission))
          .map(async (page) => {
            page.sub = await page.sub?.filter((sub) => {
              return !sub.permission || hasPermission(sub.permission);
            });
            return page;
          })
      );
      ele.pages = ele.pages.filter((page) => !page.sub || page.sub.length > 0);
      return ele;
    });
    resolve(Promise.all(filters));
  });
};
