import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface Tab {
  tab?: string;
  item_id?: number;
}

interface FormCRM {
  id?: number;
  entity_id: number | undefined;
  office_id: number | undefined;
  type_id: number | undefined;
  status_id: number | undefined;
  reason_id: number | undefined;
  result_id: number | undefined;
  description: string;
  observations: string;
  discharge_date: string;
  start_time: string;
  end_time: string;
  next_action_date: string;
  files: [];
  participant_id: string[];
  responsible_id: string[];
}

@Module
export default class CRMSelectsModule extends VuexModule {
  loadingc = true;
  actionItem: FormCRM = {
    id: undefined,
    entity_id: undefined,
    office_id: undefined,
    type_id: undefined,
    status_id: undefined,
    reason_id: undefined,
    result_id: undefined,
    description: "",
    observations: "",
    discharge_date: "",
    start_time: "",
    end_time: "",
    next_action_date: "",
    files: [],
    participant_id: [],
    responsible_id: [],
  };
  actionTab: Tab = {};
  actionEntityList: any[] = [];
  actionOfficeList: any[] = [];
  filteredEntitiesList: any[] = [];

  get loadingCRMElements(): boolean {
    return this.loadingc;
  }

  get Action(): any {
    return this.actionItem;
  }

  get ActionTabItem(): Tab {
    return this.actionTab;
  }

  get ActionEntity(): any {
    return this.actionEntityList;
  }

  get ActionOffice(): any {
    return this.actionOfficeList;
  }

  get FilteredEntities(): any {
    return this.filteredEntitiesList;
  }

  @Mutation
  ["setLoadingc"](payload) {
    this.loadingc = payload;
  }

  @Mutation
  ["setAction"](payload) {
    this.actionItem = payload;
  }

  @Mutation
  ["setObservation"](payload) {
    this.actionItem.observations = payload;
  }

  @Mutation
  ["setActionTab"](payload) {
    this.actionTab = payload;
  }

  @Mutation
  ["removeActionTab"]() {
    this.actionTab = {};
  }

  @Mutation
  ["setActionEntity"](payload) {
    this.actionEntityList = payload;
  }

  @Mutation
  ["addActionEntity"](payload) {
    if (payload && Array.isArray(this.actionEntityList)) {
      const index = this.actionEntityList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.actionEntityList.push(payload);
      }
    }
  }

  @Mutation
  ["setActionOffice"](payload) {
    this.actionOfficeList = payload;
  }

  @Mutation
  ["addActionOffice"](payload) {
    if (payload && Array.isArray(this.actionOfficeList)) {
      const index = this.actionOfficeList.findIndex((x) => x.id === payload.id);
      if (index === -1) {
        this.actionOfficeList.push(payload);
      }
    }
  }

  @Mutation
  ["setFilteredEntities"](payload) {
    this.filteredEntitiesList = payload;
  }

  @Mutation
  ["resetCRMSelectModule"]() {
    this.loadingc = true;
    this.actionItem = {
      id: undefined,
      entity_id: undefined,
      office_id: undefined,
      type_id: undefined,
      status_id: undefined,
      reason_id: undefined,
      result_id: undefined,
      description: "",
      observations: "",
      discharge_date: "",
      start_time: "",
      end_time: "",
      next_action_date: "",
      files: [],
      participant_id: [],
      responsible_id: [],
    };
    this.actionTab = {};
    this.actionEntityList = [];
    this.actionOfficeList = [];
    this.filteredEntitiesList = [];
  }
}
