
import { computed, onMounted, onUpdated, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "MaritimeVoyageSelect",
  props: {
    maritime_voyage_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    transshipment: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    booking: {
      type: Boolean,
      default: false,
    },
    allVoyages: {
      type: Boolean,
      default: false,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:maritime_voyage_id", "updateVoyageType"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const maritimeFile = computed(() => store.getters.maritimeFileItem);
    const tripTableData = computed(() => store.getters.MaritimeTrips);
    const voyagesData = computed(() => store.getters.MaritimeVoyage);
    const inputElements = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isMaritimeVoyageRequired = (value) => {
      if (props.required && !value) {
        return t("rmaritimevoyage");
      }
      return true;
    };

    let { errorMessage, value } = useField(
      "maritime_voyage_id",
      isMaritimeVoyageRequired
    );

    const getLabel = (element) => {
      const office_part = "";
      let result = `${element.id ? element.id : ""} ${element.trip_type} ${
        element.code
      } ${office_part}`;

      if (element.hasOwnProperty("maritime_file") && element.maritime_file) {
        result = `${result} - (EXP: ${element.maritime_file.code})`;
      }
      return result;
    };

    const getVoyage = () => {
      if (!props.allVoyages) {
        inputElements.value.list = tripTableData.value;
        inputElements.value.options = tripTableData.value;
      } else {
        ApiService.query(`/api/maritimevoyages/lists`, {
          params: { per_page: 10, martitime_files: [maritimeFile.value.id] },
        }).then(({ data }) => {
          data.maritime_voyages.forEach((v) => {
            store.commit("addMaritimeVoyage", v);
          });
          inputElements.value.list = data.maritime_voyages;
          inputElements.value.options = data.maritime_voyages;
        });
      }
    };
    const selectVoyage = (query) => {
      if (query !== "") {
        inputElements.value.loading = true;
        setTimeout(() => {
          inputElements.value.loading = false;
          inputElements.value.options = inputElements.value.list.filter(
            (item: Record<string, any>) => {
              return item.code.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputElements.value.options.length) {
            ApiService.query(`/api/maritimevoyages/lists`, {
              params: { per_page: 10, code: query, martitime_files: [maritimeFile.value.id]  },
            }).then(({ data }) => {
              data.maritime_voyages.forEach((v) => {
                store.commit("addMaritimeVoyage", v);
              });
              inputElements.value.list = data.maritime_voyages;
              inputElements.value.options = data.maritime_voyages;
            });
          }
        }, 200);
      } else {
        inputElements.value.options = [];
      }
    };

    watch(
      () => props.maritime_voyage_id,
      (first) => {
        element_id.value = first;
        const voyage = inputElements.value.options.find(
          (item: Record<string, any>) => {
            return item.id === first;
          }
        );
        const payload = voyage ? voyage["trip_type"] : "";
        emit("updateVoyageType", payload);
      }
    );

    watch(
      () => tripTableData.value,
      (first) => {
        if (!props.transshipment) {
          inputElements.value.list = first;
          inputElements.value.options = first;
        }
      }
    );

    onMounted(() => {
      element_id.value = props.maritime_voyage_id;
      if (props.mounted) {
        getVoyage();
      } else {
        inputElements.value.list = voyagesData.value;
        inputElements.value.options = voyagesData.value;
      }
    });

    return {
      element_id,
      inputElements,
      errorMessage,
      maritimeFile,
      getLabel,
      getVoyage,
      selectVoyage,
    };
  },
};
