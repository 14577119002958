import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import GeneralModule from "@/store/modules/GeneralModule";
import MaritimeFileModule from "@/store/modules/MaritimeFileModule";
import MaritimeFileSelectsModule from "@/store/modules/MaritimeFileSelectsModule";
import OfficesModule from "@/store/modules/OfficesModule";
import CompaniesGroupModule from "@/store/modules/CompaniesGroupModule";
import EntitiesModule from "@/store/modules/EntitiesModule";
import SaleJobModule from "@/store/modules/SaleJobModule";
import CRMSelectsModule from "@/store/modules/CRMSelectsModule";
import LoadElementsModule from "@/store/modules/LoadElementsModule";
import CommonModule from "@/store/modules/CommonModule";
import QuotationsModule from "@/store/modules/QuotationsModule";
import OtModule from "@/store/modules/OtModule";
import ChatModule from "@/store/modules/ChatModule";
import ActivitiesLogsModule from "@/store/modules/ActivitiesLogsModule";
import DocumentsModule from "@/store/modules/DocumentsModule";
import ForwarderModule from "@/store/modules/ForwarderModule";
import FilesModule from "@/store/modules/FilesModules";
import ImoDeclarationModule from "@/store/modules/ImoDeclarationModule";
// import createPersistedState from "vuex-persistedstate";
config.rawError = true;

const store = createStore({
  modules: {
    FilesModule,
    ChatModule,
    AuthModule,
    OtModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    GeneralModule,
    MaritimeFileModule,
    MaritimeFileSelectsModule,
    CompaniesGroupModule,
    EntitiesModule,
    OfficesModule,
    SaleJobModule,
    CRMSelectsModule,
    LoadElementsModule,
    CommonModule,
    QuotationsModule,
    DocumentsModule,
    ForwarderModule,
    ActivitiesLogsModule,
    ImoDeclarationModule,
  },
  // plugins: [
  //   createPersistedState({
  //     storage: sessionStorage,
  //     reducer(val) {
  //       if (!val.AuthModule.isAuthenticated) {
  //         val.ActivitiesLogsModule.recentlyList = [];
  //         return {};
  //       }
  //       return val;
  //     },
  //   }),
  // ],
});

export default store;
